import CONSTANTS from "./constants";
import { flattenSumObjValue } from "./utils/converter";
import { caculateEthBalance } from "../services/utils/converter";
import { BigNumber } from "bignumber.js";

export default class Token {
    constructor(
        info,
        reserveBalance = CONSTANTS.INNIT_TOKEN_RESERVE_BALANCE,
        reserveTarget = 0,
        totalQty = 0,
        totalTarget = 0,
        rates = CONSTANTS.INNIT_TOKEN_RATES, // { bittrex: {ask: xxx, bid: xxxx}, ...}
        exchangeBalance = CONSTANTS.INNIT_TOKEN_EXCHANGE_BALANCE, // { bittrex: 0, binance: 0, ...}
        estimateEthValue = 0,

        pendingAmount = {
            withdraw: 0,
            deposit: {},
        }
    ) {
        this.info = info;

        this.reserveBalance = reserveBalance;
        this.exchangeBalance = exchangeBalance;

        this.reserveTarget = reserveTarget;
        this.totalTarget = totalTarget;

        this.rates = rates;
        this.rebalanceThreshold = 0;

        // this.totalQty = this.caculateTotalBalance()

        // this.estimateEthValue = this.caculateEthBalance(this.totalQty, this.rates)

        this.pendingAmount = pendingAmount;

        this.totalExchangeBalanceWithDeposit = "0";
        this.reserveBalanceWithWithdraw = "0";
        this.reserveBalanceWithWithdrawTitle = "0";
        this.totalQty = "0";
        this.percent = 0;
        this.reservePercent = 0;

        this.triggered = 0;
        this.triggerConfigured = 0;
    }

    reCalculate() {
        // display exchange balance with extra pending deposit
        let totalExchangeBalanceWithDeposit = new BigNumber(0);
        if (this.exchangeBalance) {
            Object.keys(this.exchangeBalance).map((exId) => {
                const bigAmount = this.exchangeBalance[exId].available
                    ? new BigNumber(this.exchangeBalance[exId].available)
                    : new BigNumber(0);
                const bigPendingDeposit = this.pendingAmount.deposit[exId]
                    ? new BigNumber(this.pendingAmount.deposit[exId])
                    : new BigNumber(0);
                const bigMarginFreeBalance = this.exchangeBalance[exId]?.margin_balance?.free
                    ? new BigNumber(this.exchangeBalance[exId].margin_balance.free)
                    : new BigNumber(0);
                const bigMarginBorrowBalance = this.exchangeBalance[exId]?.margin_balance?.borrowed
                    ? new BigNumber(this.exchangeBalance[exId].margin_balance.borrowed)
                    : new BigNumber(0);


                const bigPerpetualPosition = this.exchangeBalance[exId]?.perpetual_position?.positionAmt
                    ? new BigNumber(this.exchangeBalance[exId].perpetual_position.positionAmt)
                    : new BigNumber(0);

                const bigPerpetualBalance = this.exchangeBalance[exId]?.perpetual_balance
                    ? new BigNumber(this.exchangeBalance[exId].perpetual_balance)
                    : new BigNumber(0);

                const bigPerpetualVirtualBalance = this.exchangeBalance[exId]?.perpetual_virtual_balance
                    ? new BigNumber(this.exchangeBalance[exId].perpetual_virtual_balance)
                    : new BigNumber(0);

                const exchangeBalanceWithDeposit = bigAmount.plus(bigPendingDeposit).plus(bigMarginFreeBalance).minus(bigMarginBorrowBalance);

                totalExchangeBalanceWithDeposit = totalExchangeBalanceWithDeposit.plus(exchangeBalanceWithDeposit);
                totalExchangeBalanceWithDeposit = totalExchangeBalanceWithDeposit.plus(bigPerpetualPosition).plus(bigPerpetualBalance).plus(bigPerpetualVirtualBalance);
            });
            this.totalExchangeBalanceWithDeposit = totalExchangeBalanceWithDeposit.toString();
        }

        const bigReserveBalance = this.reserveBalance
            ? new BigNumber(this.reserveBalance.toString())
            : new BigNumber(0);
        const bigPendingWithdraw = this.pendingAmount.withdraw
            ? new BigNumber(this.pendingAmount.withdraw.toString())
            : new BigNumber(0);
        const bigReserveTarget = this.reserveTarget ? new BigNumber(this.reserveTarget.toString()) : new BigNumber(0);

        const bigReserveBalanceWithWithdraw = bigReserveBalance.plus(bigPendingWithdraw);
        this.reserveBalanceWithWithdraw = bigReserveBalanceWithWithdraw.toString();
        this.reserveBalanceWithWithdrawTitle = bigPendingWithdraw.gt(0)
            ? bigReserveBalance.toString() + " + " + bigPendingWithdraw.toString()
            : bigReserveBalance.toString();

        const bigTotalQty = bigReserveBalanceWithWithdraw.plus(totalExchangeBalanceWithDeposit);
        this.totalQty = bigTotalQty.toString();

        const bigTotalTarget = this.totalTarget ? new BigNumber(this.totalTarget.toString()) : new BigNumber(0);
        if (bigTotalQty.gt(0) && bigTotalTarget.gt(0)) {
            this.percent = bigTotalQty.dividedBy(bigTotalTarget).multipliedBy(100).decimalPlaces(2).toString();
        }

        if (bigReserveBalanceWithWithdraw.gt(0) && bigReserveTarget.gt(0)) {
            this.reservePercent = bigReserveBalanceWithWithdraw
                .dividedBy(bigReserveTarget)
                .multipliedBy(100)
                .decimalPlaces(2)
                .toString();
        }
    }

    setRates(rates) {
        this.rates = rates;
    }

    setExchangeBalance(exchangeBalance) {
        if (exchangeBalance) {
            this.exchangeBalance = exchangeBalance.exchanges;
        }
    }

    setReserveBalance(reserveBalance) {
        this.reserveBalance = reserveBalance;
        // this.totalQty = this.caculateTotalBalance()
        // this.estimateEthValue = this.caculateEthBalance(this.totalQty, this.rates)
    }

    setPendingAmount(pendingAmountData) {
        this.pendingAmount = pendingAmountData;
    }

    caculateEthBalance = (balance, rates) => {
        if (this.info && this.info.symbol.toLowerCase() == "eth") {
            return balance.toString();
        }
        const ethBalance = caculateEthBalance(balance, rates);
        return ethBalance;
    };

    setReserveTarget(reserveTarget) {
        this.reserveTarget = reserveTarget;
    }

    setTotalTarget(totalTarget) {
        this.totalTarget = totalTarget;
    }

    setRebalanceThreshold(rebalanceThreshold) {
        this.rebalanceThreshold = rebalanceThreshold;
    }

    setTriggered(triggered) {
        this.triggered = triggered;
    }

    setTriggerConfigured(triggerConfigured) {
        this.triggerConfigured = triggerConfigured;
    }

    caculateTotalBalance() {
        return flattenSumObjValue(this.exchangeBalance, this.reserveBalance);
    }
}
