import React, { Component } from "react";
import OrdersView from "./orders.view";

import { connect } from "react-redux";
import { syncOpenOrdersList } from "../../services/orders";
import CONSTANTS from "../../services/constants";
import {
    TableData,
    SetRateDetailView,
    TradeDetailView,
    DepositDetailView,
    WithdrawDetailView,
    TransferDetailView,
    CrossChainTradeDetailView,
} from "../Share";
import { getListExchange } from "../../services/configuration";
import { sumBig } from "../../services/utils/converter";
import { mappingOpenOder } from "../../services/utils/standardize";
import { caculatePercent } from "../../services/utils/converter";
import Select from "react-select";
import moment from "moment";
import { Switch } from "../Share";

@connect((store) => {
    const apiService = store.global.apiService;
    const hostData = { ...store.global.hostData };
    const reserveAddr = store.global.reserveAddr;
    const networkId = store.global.networkId;
    return {
        apiService,
        host: store.global.host,
        hostData,
        reserveAddr,
        tokens: store.global.tokens,
        networkId,
    };
})
class Orders extends Component {
    constructor(props) {
        super(props);
        this.intervalUpdateRate = true;
        this.intervalUpdateCoreRate = true;
        this.intervalUpdateReserveRate = true;

        this.intervalUpdateOrderData;

        this.oneDay = 24 * 60 * 60 * 1000;

        this.currentCoreTimeStamp;
        this.currentTimeStamp;
        this.currentReserveTimeStamp;
        this.state = {
            orderData: [],
            listExchanges: {},
            blockchainRateData: {},
            reserveRateData: {},
            warningDuration: "00:08",
            selected: CONSTANTS.DEFAULT_TOKEN,
            isFetching: false,
            otherActions: [],
            isGroup: false,
            reserveAddr: "0x63825c174ab367968EC60f061753D3bbD36A0D8F",
            thirdPartyAddr: "0x2aab2b157a03915c8a73adae735d0cf51c872f31",
            isEdit: false,

            filterParams: {},

            pairFilter: [],
            typeFilter: [],
            exchangeFilter: [],
            sideFilter: [],
        };
    }

    componentDidMount() {
        this.getExchange();

        this.intervalUpdateOrderData = setInterval(this.updateOrdersList, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalUpdateOrderData);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getExchange();
    }

    endFetching() {
        this.setState({
            isFetching: false,
        });
    }

    async getExchange() {
        const result = await getListExchange(this.props.apiService);
        // const result = mockExchange
        if (!result.success) return;
        if (result && result.data) {
            const listExchanges = {};
            result.data.map((exData) => {
                listExchanges[exData.id] = exData;
            });
            this.setState(
                {
                    listExchanges,
                },
                () => {
                    this.updateOrdersList();
                }
            );
        }
    }

    updateOrdersList = async () => {
        this.setState({
            isFetching: true,
        });
        const data = await syncOpenOrdersList(this.props.apiService);

        if (!data) return this.endFetching();

        const filteredData = mappingOpenOder(data.data, this.state.listExchanges);
        this.setState({
            isFetching: false,
            orderData: filteredData.arrayOrders,
            filterParams: filteredData.filterParams,
        });
    };

    getOrderSide(row) {
        let rowClass = "";
        switch (row.value) {
            case "BUY":
                rowClass = "text-success";
                break;
            case "SELL":
                rowClass = "text-danger";
                break;
            default:
                break;
        }

        return <p className={rowClass}>{row.value}</p>;
    }

    selectChange(value) {
        this.setState({
            selected: value,
        });
    }

    onTimeChangeHandler(event) {
        this.setState({
            warningDuration: event,
        });
    }

    onFilterPairChange(data) {
        this.setState({
            pairFilter: data.map((i) => i.value),
        });
    }

    onFilterTypeChange(e, key) {
        const currentType = this.state.typeFilter;
        currentType[key] = e.target.checked;
        this.setState({
            typeFilter: currentType,
        });
    }

    onFilterExchangeChange(e, key) {
        const currentExchangeStatus = this.state.exchangeFilter;
        currentExchangeStatus[key] = e.target.checked;
        this.setState({
            exchangeFilter: currentExchangeStatus,
        });
    }

    onFilterSideChange(e, key) {
        const currentSide = this.state.sideFilter;
        currentSide[key] = e.target.checked;
        this.setState({
            sideFilter: currentSide,
        });
    }

    filterData(data) {
        const isFilterPair = this.state.pairFilter && this.state.pairFilter.length ? true : false;
        const isFilterExchange =
            this.state.exchangeFilter && Object.values(this.state.exchangeFilter).indexOf(true) > -1;
        const isFilterType = this.state.typeFilter && Object.values(this.state.typeFilter).indexOf(true) > -1;
        const isFilterSide = this.state.sideFilter && Object.values(this.state.sideFilter).indexOf(true) > -1;
        return data.filter((d) => {
            let result = true;

            if (isFilterPair) {
                result = result && this.state.pairFilter.indexOf(d.Pair) > -1;
            }
            if (isFilterExchange) {
                result = result && this.state.exchangeFilter[d.Exchange];
            }
            if (isFilterType) {
                result = result && this.state.typeFilter[d.Type];
            }
            if (isFilterSide) {
                result = result && this.state.sideFilter[d.Side];
            }
            return result;
        });
    }

    SubComponent = (row) => {
        var rowData = row.original;
        switch (rowData.action) {
            case "set_rates":
                return <SetRateDetailView data={rowData} networkId={this.props.networkId} />;
            case "trade":
                return <TradeDetailView data={rowData} networkId={this.props.networkId} />;
            case "perpetual_trade":
                return <TradeDetailView data={rowData} networkId={this.props.networkId} />;
            case "cross_margin_transfer":
                return <CrossChainTradeDetailView data={rowData} />;
            case "cross_chain_trade":
                return <TransferDetailView data={rowData} showSpotToMargin />;
            case "withdraw":
                return <WithdrawDetailView data={rowData} networkId={this.props.networkId} />;
            case "deposit":
                return <DepositDetailView data={rowData} networkId={this.props.networkId} />;
            case "borrow_transfer":
                return <TransferDetailView data={rowData} />;
            case "borrow":
                return <TransferDetailView data={rowData} />;
            case "transfer_repay":
                return <TransferDetailView data={rowData} />;
            default:
                return <div />;
        }
    };

    render() {
        const tableActivities = (
            <TableData
                data={this.filterData(this.state.orderData)}
                title={"Open Orders"}
                columns={[
                    {
                        Header: "Exchange",
                        id: "exchange",
                        accessor: "exchange",
                    },
                    {
                        Header: "Order Id",
                        id: "orderID",
                        accessor: "order_id",
                    },
                    {
                        Header: "Pair",
                        id: "pair",
                        accessor: "symbol",
                    },
                    {
                        Header: "Type",
                        id: "type",
                        accessor: "type",
                    },
                    {
                        Header: "Side",
                        id: "side",
                        accessor: "side",
                        Cell: (row) => this.getOrderSide(row),
                    },
                    {
                        Header: "Price",
                        id: "price",
                        accessor: "price",
                    },

                    {
                        Header: "OrigQty",
                        id: "origQty",
                        accessor: "orig_qty",
                    },
                    {
                        Header: "ExecutedQty",
                        id: "executedQty",
                        accessor: "executed_qty",
                    },
                    {
                        Header: "Filled",
                        id: "filled",
                        accessor: (d) => caculatePercent(d.ExecutedQty, d.OrigQty) + " %",
                    },
                ]}
                isShowPageSize={true}
                isShowPaginate={true}
            />
        );

        const filterPair = (
            <Select
                className="mb-3"
                options={this.state.filterParams.pair || []}
                onChange={this.onFilterPairChange.bind(this)}
                isMulti
            />
        );

        const filterExchange =
            this.state.filterParams.exchange &&
            this.state.filterParams.exchange.map((d, i) => (
                <div key={i} className="col-3">
                    <div className="pretty p-default">
                        <input type="checkbox" onChange={(e) => this.onFilterExchangeChange(e, d.value || "")} />
                        <div className="state p-success">
                            <label>{d.value || "none"}</label>
                        </div>
                    </div>
                </div>
            ));

        const filterType =
            this.state.filterParams.type &&
            this.state.filterParams.type.map((s, i) => (
                <div key={i} className="col-3">
                    <div className="pretty p-default">
                        <input type="checkbox" onChange={(e) => this.onFilterTypeChange(e, s.value || "")} />
                        <div className="state p-success">
                            <label>{s.value || "none"}</label>
                        </div>
                    </div>
                </div>
            ));

        const filterSide =
            this.state.filterParams.side &&
            this.state.filterParams.side.map((s, i) => (
                <div key={i} className="col-3">
                    <div className="pretty p-default">
                        <input type="checkbox" onChange={(e) => this.onFilterSideChange(e, s.value || "")} />
                        <div className="state p-success">
                            <label>{s.value || "none"}</label>
                        </div>
                    </div>
                </div>
            ));

        return (
            <OrdersView
                tableActivities={tableActivities}
                filterPair={filterPair}
                filterExchange={filterExchange}
                filterType={filterType}
                filterSide={filterSide}
            />
        );
    }
}

export default Orders;
