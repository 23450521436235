import { useEffect, useState } from "react";
import styled from "styled-components";

interface IProps {
    className?: string;
    fromAmount: number;
    toAmount: number;
    onAmountsChange: (from: number, to: number) => void;
}

const Config: React.FC<IProps> = ({ className, fromAmount, toAmount, onAmountsChange }) => {
    const [localFromAmount, setLocalFromAmount] = useState(fromAmount);
    const [localToAmount, setLocalToAmount] = useState(toAmount);

    useEffect(() => {
        setLocalFromAmount(fromAmount);
    }, [fromAmount]);

    useEffect(() => {
        setLocalToAmount(toAmount);
    }, [toAmount]);

    return (
        <div className={className}>
            <div className="row">
                <div className="col-sm-12">
                    <p className="text-dark mb-2">Config</p>

                    <div className="row">
                        <div className="col-sm-4 col-lg-4">
                            <label>From</label>
                            <input
                                type="number"
                                className="form-control text-left"
                                value={localFromAmount}
                                step="0.1"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setLocalFromAmount(Number(value));
                                }}
                            />
                        </div>

                        <div className="col-sm-4 col-lg-4">
                            <label>To</label>
                            <input
                                type="number"
                                className="form-control text-left"
                                value={localToAmount}
                                step="0.1"
                                onChange={(e) => setLocalToAmount(Number(e.target.value))}
                            />
                        </div>

                        <div className="col-sm-4 col-lg-4 d-flex flex-row">
                            <div
                                className="align-self-end"
                                style={{
                                    height: "auto",
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => onAmountsChange(localFromAmount, localToAmount)}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(Config)`
    margin-bottom: 16px;
`;
