import React from "react";
// import { KModal } from "../../Common"
import AmCharts from "@amcharts/amcharts3-react";

const LineChartView = ({ config }) => {
    return (
        <AmCharts.React
            style={{
                width: "100%",
                height: "400px",
            }}
            options={config}
        />
    );
};

export default LineChartView;
