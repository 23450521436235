const OverlapImg = ({ images }) => {
    if (!images?.length) return <div />;
    const exceed = images.length > 3 ? images.length - 3 : 0;

    return (
        <div className="d-flex justify-content-center  mt-2 overlap-img">
            {images.slice(0, 3).map((src, id) => (
                <img src={src} key={id} />
            ))}
            {exceed > 0 && <div className="more-img">+{exceed}</div>}
        </div>
    );
};

export default OverlapImg;
