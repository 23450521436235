import { Component } from "react";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import _ from "lodash";

import TokensService from "services/tokens";
import { saveQtyGetRate } from "actions/globalActions";
import { getAllTokens, getListExchange } from "services/configuration";
import { saveTokens, saveExchange } from "actions/globalActions";
import { saveNetworkAddress } from "actions/signActions";

import "react-table/react-table.css";

import TokenSummary from "./TokenSummary";
import InfoModal from "./InfoModal";
import dashboardSlice from "../dashboard.slice";
import IndicatorInfo from "./IndicatorInfo";
import WarningTokens from "./WarningTokens";
import { DisplayMode } from "./TokenDisplay/consts";

@connect((store) => {
    const { apiService, hostData, tokens, kyberTokens, qtyGetRate, exchanges } = store.global;
    const { error } = store.dashboard;

    const tokensService = new TokensService({
        tokens,
        kyberTokens,
        exchanges,
    });

    return {
        apiService,
        host: store.global.host,
        hostData,
        tokens,
        kyberTokens,
        tokensService,
        qtyGetRate,
        exchanges,
        error,
    };
})
class TokenInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            moreInfo: {},
            tokenConfig: {},
            error: "",

            isOpenModal: false,
            modalContent: null,
            modalTitle: null,
            modalId: "tokenInfoModal",
            displayMode: DisplayMode.Simple,
            qtyGetRate: props.qtyGetRate,
            selectedTokenInfo: null,
            showHelper: false,
        };
    }

    componentDidMount() {
        this.modalInfo = document.getElementById(this.state.modalId);
        this.syncAllTokenData();
        this.intervalFetchTokenData = setInterval(this.syncAllTokenData, 20000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalFetchTokenData);
    }

    getTokenConfigFromCore = async () => {
        const apiService = this.props.apiService;

        const addresses = await apiService.getAddress();
        const assets = await getAllTokens(this.props.apiService);
        const exchanges = await getListExchange(this.props.apiService);

        if (assets.success) {
            await this.props.dispatch(saveTokens(assets.data));
        }

        if (exchanges.success) {
            await this.props.dispatch(saveExchange(exchanges.data));
        }

        if (addresses.success) {
            await this.props.dispatch(saveNetworkAddress(addresses.data.addresses));
        }
    };

    syncAllTokenData = async () => {
        await this.getTokenConfigFromCore();

        this.props.dispatch(dashboardSlice.actions.getTokenDataRequest());
    };

    onChangeQtyRate(e) {
        e.persist();
        this.setState({
            qtyGetRate: +e.target.value,
        });
        if (!this.debouncedFn) {
            this.debouncedFn = _.debounce(() => {
                this.onSubmitQty(+e.target.value);
            }, 1500);
        }
        this.debouncedFn();
    }

    onSubmitQty(qty) {
        this.props.dispatch(saveQtyGetRate(qty));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.qtyGetRate != this.props.qtyGetRate) {
            this.props.dispatch(dashboardSlice.actions.getPricingFromIntegrationRequest());
            this.props.dispatch(dashboardSlice.actions.getTokenDataRequest());
        }
    }

    onClickToken(tokenId) {
        this.props.dispatch(dashboardSlice.actions.setSelectedTokenId(tokenId));

        if (this.modalInfo) {
            $(`#${this.state.modalId}`).modal();
        }
    }

    changeDisplay(mode) {
        this.setState({
            displayMode: mode,
        });
    }

    handleClickInfoButton() {
        this.setState((state) => ({
            showHelper: !state.showHelper,
        }));
    }

    render() {
        return (
            <div className="panel panel-table">
                <div className="panel-header row justify-content-between">
                    <form className="form-inline ml-3">
                        <div className="form-group">
                            <label className="font-weight-bold">
                                Quantity get rate ({this.props.tokens?.[1] ? this.props.tokens[1].symbol : "ETH"}):
                            </label>
                        </div>

                        <div className="form-group ml-3">
                            <input
                                type="number"
                                style={{ width: "100px" }}
                                className="form-control"
                                placeholder="Qty get rate"
                                aria-label="Qty get rate"
                                aria-describedby="basic-addon2"
                                onChange={this.onChangeQtyRate.bind(this)}
                                value={this.state.qtyGetRate}
                            />
                        </div>
                    </form>

                    <div className="expand-compress mx-4">
                        <button
                            type="button"
                            className={`btn btn-light ${this.state.displayMode === DisplayMode.Simple && "active"}`}
                            onClick={() => this.changeDisplay.bind(this)(DisplayMode.Simple)}
                        >
                            <i className="fa fa-th-large" aria-hidden="true" />
                        </button>

                        <button
                            type="button"
                            className={`btn btn-light ${this.state.displayMode === DisplayMode.Full && "active"}`}
                            onClick={() => this.changeDisplay.bind(this)(DisplayMode.Full)}
                        >
                            <i className="fa fa-th-list" aria-hidden="true" />
                        </button>

                        <button
                            type="button"
                            className={`btn btn-light ${this.state.showHelper && "active"}`}
                            onClick={() => this.handleClickInfoButton.bind(this)()}
                        >
                            <i className="fa fa-info-circle" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                {this.state.showHelper ? <IndicatorInfo /> : null}
                <WarningTokens />
                <div className="panel-header row pb-0">
                    <TokenSummary display={this.state.displayMode} onClickToken={this.onClickToken.bind(this)} />
                </div>
                <div className="panel-header row">
                    <TokenSummary
                        title="Unlisted assets"
                        display={this.state.displayMode}
                        onClickToken={this.onClickToken.bind(this)}
                        unlistedOnly
                    />
                </div>
                <InfoModal modalId={this.state.modalId} apiService={this.props.apiService} />
            </div>
        );
    }
}
export default withAlert()(TokenInfo);
