import { useSelector } from "react-redux";
import { roundingNumber } from "services/utils/converter";

const getRateTargetValue = (data) => {
    data = data.filter((d) => d.quoteConversion == "USDT");
    let rateObj = data.find((d) => d.exchange == "custom" && d.afpAsk > 0 && d.afpBid > 0);
    if (!rateObj) rateObj = data.find((d) => d.afpAsk > 0 || d.afpBid > 0);
    if (rateObj) {
        if (rateObj.afpAsk == 0) return rateObj.afpBid;
        if (rateObj.afpBid == 0) return rateObj.afpAsk;
        return (rateObj.afpAsk + rateObj.afpBid) / 2;
    }

    return 0;
};

const ETHPrice = () => {
    const token = useSelector((state) => state.dashboard.tokenDataById[1]);

    return (
        <div style={{ paddingRight: "5%" }}>
            $ <span className="text-info">{roundingNumber(getRateTargetValue(token?.rates || []), 5)}</span>
        </div>
    );
};

export default ETHPrice;
