import TokenCell from "../TokenCell";
import ConfigTable from "./table";

const RebalanceQuadratic = () => {
    return (
        <div className="panel panel-table">
            <ConfigTable
                title="REBALANCE QUADRATIC"
                pendingUrl="/v3/setting-change-rbquadratic"
                fields={{
                    symbol: {
                        title: "Asset",
                        cell: (row) => {
                            return <TokenCell symbol={row.value} />;
                        },
                        const: true,
                    },
                    size_a: {
                        title: "size A",
                    },
                    size_b: {
                        title: "size B",
                    },
                    size_c: {
                        title: "size C",
                    },
                    price_a: {
                        title: "price A",
                    },
                    price_b: {
                        title: "price B",
                    },
                    price_c: {
                        title: "price C",
                    },
                    price_offset: {
                        title: "PRICE OFFSET",
                    },
                }}
            />
        </div>
    );
};
export default RebalanceQuadratic;
