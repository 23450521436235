const TimeRangePickerView = (props) => {
    return (
        <div>
            <p className="text-dark mb-2">Select Day (UTC)</p>
            <div className="row">
                <div className="col-sm-6">
                    <p className="mb-1 text-gray-4">From</p>
                    {props.fromTimePicker}
                </div>
                <div className="col-sm-6">
                    <p className="mb-1 text-gray-4">To</p>
                    {props.toTimePicker}
                </div>
            </div>
        </div>
    );
};

export default TimeRangePickerView;
