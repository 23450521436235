import BigNumber from "bignumber.js";

import { getTokenPrice } from "services/configuration";

import { ITokenWithQuoteDetail, IQuoteOrderDetail } from "./model";

const NUMBER_OF_DECIMALS = 7;

const getQuotes = async (
    apiService: any,
    {
        sellTokenAddress,
        buyTokenAddress,
        buyAmountBaseUnits,
        sellAmountBaseUnits,
    }: {
        sellTokenAddress: string;
        buyTokenAddress: string;
        buyAmountBaseUnits?: string;
        sellAmountBaseUnits?: string;
    }
) => {
    const params: Record<string, any> = {
        sellTokenAddress,
        buyTokenAddress,
        buyAmountBaseUnits,
        sellAmountBaseUnits,
        takerAddress: "0x0000000000000000000000000000000000000000",
        txOrigin: "0xBA6a9d57D22889630FB731f8062507786EE36157",
        comparisonPrice: 0.000833,
        protocolVersion: 4,
    };

    const query = Object.keys(params)
        .filter((key) => !!params[key])
        .map((key: string) => `${key}=${params[key]}`)
        .join("&");

    const result: IQuoteOrderDetail = await getTokenPrice(apiService, query);

    return result;
};

export const fetchQuotesOfEachToken = async (
    apiService: any,
    tokenAddress: string,
    tokenDecimals: number,
    amounts: number[]
): Promise<ITokenWithQuoteDetail["quotes"]> => {
    const fetchBuyQuotes = () => {
        return Promise.all(
            amounts.map((value) =>
                getQuotes(apiService, {
                    buyTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                    buyAmountBaseUnits: new BigNumber("1e18").multipliedBy(value).toString(),
                    sellTokenAddress: tokenAddress,
                }).then((response) => {
                    const { makerAmount, takerAmount } = response;

                    const maker = new BigNumber(makerAmount).dividedBy(new BigNumber("1e18"));
                    const taker = new BigNumber(takerAmount).dividedBy(new BigNumber(`1e${tokenDecimals}`));

                    const rate = maker.dividedBy(taker).toFixed(NUMBER_OF_DECIMALS);

                    return {
                        ...response,
                        rate,
                    };
                })
            )
        );
    };

    const fetchSellQuotes = () => {
        return Promise.all(
            [...amounts].reverse().map((value) =>
                getQuotes(apiService, {
                    buyTokenAddress: tokenAddress,
                    sellTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                    sellAmountBaseUnits: new BigNumber("1e18").multipliedBy(value).toString(),
                }).then((response) => {
                    const { makerAmount, takerAmount } = response;

                    const taker = new BigNumber(takerAmount).dividedBy(new BigNumber("1e18"));
                    const maker = new BigNumber(makerAmount).dividedBy(new BigNumber(`1e${tokenDecimals}`));

                    const rate = taker.dividedBy(maker).toFixed(NUMBER_OF_DECIMALS);

                    return {
                        ...response,
                        rate,
                    };
                })
            )
        );
    };

    const [buy, sell] = [await fetchBuyQuotes(), await fetchSellQuotes()];

    const warning = ![...buy, ...sell]
        .map((detail) => detail.rate)
        .reduce((result, rate, index, rates) => {
            if (index === 0) {
                return result;
            }

            return result && Number(rates[index - 1]) <= Number(rate);
        }, true);

    if (warning) {
        console.log(`${tokenAddress} warning`);
    }

    return { buy, sell, warning };
};
