import Token from "./token";
import {
    mappingAllRate,
    mappingAllExchangeBalance,
    mappingAllReserveBalance,
    mappingAllPendingAmount,
} from "./utils/standardize";

export default class TokensService {
    constructor(props) {
        this.allTokens = props.tokens;
        this.kyberTokens = props.kyberTokens;
        this.exchanges = props.exchanges;
        this.tokens = {
            data: {},
            pendingActivities: [],
            totalAllQty: 0,
            error: "",
        };

        this.sampleTokenData = {};
        Object.keys(props.kyberTokens).forEach((tokenName) => {
            this.sampleTokenData[tokenName] = new Token(props.tokens[tokenName]);
        });

        this.tokens.data = { ...this.sampleTokenData };
    }

    getTokens() {
        return this.tokens.data;
    }

    getAllKyberRate(service) {
        return service.getAllKyberRate();
    }

    async syncAll(service, qtyGetRate) {
        this.tokens.data = { ...this.sampleTokenData };

        const allRate = await service.getPriceAllBaseQuotePair(this.kyberTokens)
        const allBalance = await service.getAuthData()

        let mappedAllRate = allRate ? mappingAllRate(qtyGetRate, allRate.data, this.kyberTokens, this.exchanges) : null;

        const mappedAllExchangeBalance =
            allBalance && allBalance.data && allBalance.data.balances
                ? mappingAllExchangeBalance(allBalance.data.balances, this.kyberTokens)
                : null;

        const mappedAllPendingAmount =
            allBalance && allBalance.data && allBalance.data.pending_activities
                ? mappingAllPendingAmount(allBalance.data.pending_activities)
                : null;

        const mappedAllReserveBalance =
            allBalance && allBalance.data && allBalance.data.balances
                ? mappingAllReserveBalance(allBalance.data.balances)
                : null;

        Object.keys(this.kyberTokens).forEach((tokenId) => {
            if (mappedAllRate && mappedAllRate[tokenId]) {
                this.tokens.data[tokenId].setRates(mappedAllRate[tokenId]);
            }

            if (mappedAllPendingAmount && mappedAllPendingAmount[tokenId]) {
                this.tokens.data[tokenId].setPendingAmount(mappedAllPendingAmount[tokenId]);
            } else {
                this.tokens.data[tokenId].setPendingAmount({
                    withdraw: 0,
                    deposit: {},
                });
            }

            if (mappedAllExchangeBalance && mappedAllExchangeBalance[tokenId]) {
                this.tokens.data[tokenId].setExchangeBalance(mappedAllExchangeBalance[tokenId]);
            }

            if (mappedAllReserveBalance && mappedAllReserveBalance[tokenId]) {
                this.tokens.data[tokenId].setReserveBalance(mappedAllReserveBalance[tokenId]);
            }

            const hasTarget = this.allTokens[tokenId]?.target
            this.tokens.data[tokenId].setReserveTarget(hasTarget ? this.allTokens[tokenId].target.reserve : 0);
            this.tokens.data[tokenId].setTotalTarget(hasTarget ? this.allTokens[tokenId].target.total : 0);
            this.tokens.data[tokenId].setRebalanceThreshold(
                hasTarget ? this.allTokens[tokenId].target.rebalance_threshold : 0
            );

            this.tokens.data[tokenId].setTriggerConfigured(this.allTokens[tokenId].normal_update_per_period);
            this.tokens.data[tokenId].reCalculate();
        });

        this.tokens.pendingActivities =
            allBalance && allBalance.data && allBalance.data.pending_activities
                ? allBalance.data.pending_activities
                : [];
        this.tokens.error = "";
        return this.tokens;
    }

    async getPendingActivities(service) {
        const allBalance = await service.getAuthData()

        const pendingActivityData = allBalance && allBalance.data ? allBalance.data.pending_activities : null;
        let pendingActivityArray = [];
        if (pendingActivityData) {
            pendingActivityArray = [
                ...(pendingActivityData.withdraw || []),
                ...(pendingActivityData.deposit || []),
                ...(pendingActivityData.cross_chain_trade || []),
            ];
        }
        return pendingActivityArray;
    }
}
