import Provider from "./provider";

import ConfigurationService from "./configuration.service";
import TokenInfoService from "./tokenInfo.service";
import RateGraphService from "./rateGraph.service";
import VolumeService from "./volume.service";

import { SUPPORTED_HOST } from "../constants";

class ApiService {
    constructor({ hosts, addresses, nodeEndpoint }) {
        this.provider = new Provider({
            type: "http",
            hosts,
            addresses,
            nodeEndpoint,
        });
    }
    updateNodeEndpoint(nodeEndpoint) {
        this.provider.updateNodeEndpoint(nodeEndpoint);
    }

    updateHostData(hostName, hostUrl, userName, keyString) {
        this.provider.updateHostData(hostName, hostUrl, userName, keyString);
    }

    updateNetworkAddress(addresses, nodeEndpoint) {
        this.provider.updateNetworkAddress(addresses, nodeEndpoint);
    }

    // Address service
    getAddress() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/addresses", "GET", null, null, false)
            .catch(this.handleNetErr);
    }

    getAllSetting() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/setting/all-settings", "GET", null)
            .catch(this.handleNetErr);
    }

    getAddressBalanceFromBlockchain(address) {
        return this.provider.call("getAddressBalanceFromBlockchain")(address);
    }

    getSanityOperatorsFromReserve(address) {
        return this.provider.call("getSanityOperatorsFromReserve")(address);
    }
    // Address service

    handleNetErr(e) {
        console.log("handleNetErr", e);
        return e;
    }
}

Object.assign(ApiService.prototype, TokenInfoService, RateGraphService, ConfigurationService, VolumeService);

export default ApiService;
