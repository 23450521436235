import waiting from "assets/img/waiting.svg";

const BLOCK_EXPLORER_URL = {
    DEFAULT: "https://etherscan.io/address/",
    1: "https://etherscan.io/address/",
    56: "https://bscscan.com/address/",
    97: "https://testnet.bscscan.com/address/",
};

const WaitingIndicator = () => {
    return <img src={waiting} />;
};

const beautifyName = (name) => {
    if (!name) return "";
    return name.replace("_operator", "").replaceAll("_", " ");
};
const Operator = ({ name, address, externalUrl, balance, warning }) => {
    return (
        <div className="panel-row">
            <div className="row">
                <div className="col-4 text-gray-4 mb-1 semi-b" style={{ textTransform: "capitalize" }}>
                    {beautifyName(name)}:
                </div>
                <div className="col-8">
                    <a className="text-dark" href={externalUrl} target="blank">
                        {address}
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-4 text-gray-4 mb-1 semi-b">Balance:</div>
                <div className="col-8">
                    <span className={`${warning ? "text-danger" : "text-success"}`}>
                        {balance === -1 ? <WaitingIndicator /> : balance}
                    </span>
                </div>
            </div>
        </div>
    );
};

const PricingOperatorView = (props) => {
    const blockExplorerUrl =
        props.networkId && BLOCK_EXPLORER_URL[props.networkId]
            ? BLOCK_EXPLORER_URL[props.networkId]
            : BLOCK_EXPLORER_URL.DEFAULT;

    return (
        <div className="panel">
            <div className="panel-header">
                <h5 className="panel-title">Operator</h5>
            </div>
            <div className="panel-body">
                <div className="panel-row">
                    <div className="d-flex align-items-center">
                        <p className="mb-0">Balance threshold</p>
                        <div className="ml-auto">
                            <input
                                type="text"
                                className="form-control"
                                value={props.balanceThreshold}
                                onChange={props.onChangeBalanceThreshold}
                            />
                        </div>
                    </div>
                </div>
                {props.operators?.map((op, id) => (
                    <Operator
                        key={id}
                        name={op.name}
                        address={op.address}
                        externalUrl={blockExplorerUrl + op.address}
                        balance={op.balance}
                        warning={op.warning}
                    />
                ))}

                {props.loadingSanity || (props.sanityOperators && props.sanityOperators.length) ? (
                    <div className="panel-row">
                        <p className="text-gray-4 mb-1 semi-b">Sanity Operators:</p>
                        {props.sanityOperators.map((op, i) => (
                            <p key={i}>
                                <span className="text-gray-4 mr-2">[{i}]</span>
                                <a className="text-dark" href={blockExplorerUrl + op.address} target="blank">
                                    {op.address}{" "}
                                </a>
                                <br />
                                <span className="text-gray-4 mr-2">Balance</span>
                                <span className="text-success text-size-2">{op.balance}</span>
                            </p>
                        ))}
                    </div>
                ) : (
                    <div className="alert alert-warning" role="alert">
                        <strong>Sanity is not enabled</strong> <br />
                        Sanity is not enabled in contract, be careful!
                    </div>
                )}

                <div className="panel-row">
                    <p className="text-gray-4 mb-1 semi-b">RESERVE ADDRESS:</p>
                    <a className="text-dark" href={blockExplorerUrl + props.reserveAddress} target="blank">
                        {props.reserveAddress}{" "}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PricingOperatorView;
