interface IProps {
    prependingText: string;
    name: string;
    text: string;
    placeholder?: string;
    required?: boolean;
    id?: string;
    onTextChange: (value: string) => void;
}

const Input: React.FC<IProps> = ({ prependingText, id, name, text, onTextChange, placeholder, required = false }) => {
    return (
        <div className="input-group mb-3">
            {prependingText && (
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        {prependingText} {required ? <span className="text-danger">*</span> : null}
                    </span>
                </div>
            )}

            <input
                type="text"
                id={id}
                name={name}
                className="form-control"
                placeholder={placeholder}
                value={text}
                onChange={(e) => onTextChange(e.target.value)}
            />
        </div>
    );
};

export default Input;
