import { SUPPORTED_HOST, RESERVE_LIST } from "../constants";
export default {
    getAllActivities(fromTime, toTime, fetchedActions) {
        // let path = timeStamp ? "activities?fromTime=" + timeStamp : "activities"
        const params = {
            fromTime,
            toTime,
        };
        if (fetchedActions) {
            const arrayFetchedAction = Object.keys(fetchedActions || {}).filter((k) => fetchedActions[k] == "true");
            params.actions = arrayFetchedAction;
        }
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/activities", "GET", null, params, false)
            .catch(this.handleNetErr);
    },

    getBlockchainRateFromCore(fromTime, toTime) {
        // let path = timeStamp ? "activities?fromTime=" + timeStamp : "activities"
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(
                "/v3/get-all-rates",
                "GET",
                null,
                {
                    fromTime,
                    toTime,
                },
                false
            )
            .catch(this.handleNetErr);

        // return this.provider.call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("reserve-rates", "GET", null, {
        //   fromTime: fromTime,
        //   toTime: toTime,
        //   reserve: RESERVE_LIST[0].value
        // }).catch(this.handleNetErr)
    },

    getReserveRate(reserveAddr, fromTime, toTime) {
        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-reserve-rate", "GET", {
        //   fromTime: fromTime,
        //   toTime: toTime,
        //   reserveAddr: reserveAddr
        // }).catch(this.handleNetErr)

        const requestData = {};

        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        const reserveArray = [];
        // if(reserveAddr) requestData.reserve = reserveAddr
        if (reserveAddr) reserveArray.push(reserveAddr);
        if (reserveArray.length) requestData.reserve = reserveArray;
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/reserve-rates", "GET", null, requestData)
            .catch(this.handleNetErr);
    },
};
