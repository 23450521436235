import { call, takeLatest, ForkEffect, select, put, delay, all } from "redux-saga/effects";

import { getListTokens } from "services/configuration";
import { RootState } from "reducers";

import { ITokenDetail } from "./model";
import { fetchQuotesOfEachToken } from "./quote0x.api";
import quote0xSlice from "./quote0x.slice";

function* fetchQuotesOfEachTokenSaga(apiService: any, token: ITokenDetail, amounts: number[], delayTime: number) {
    yield delay(delayTime);

    // @ts-ignore
    const quotes = yield call(fetchQuotesOfEachToken, apiService, token.address, token.decimals, amounts);

    yield put(quote0xSlice.actions.fetchQuoteSuccess({ ...token, quotes }));
}

function* fetchEnabledTokensSaga() {
    const state: RootState = yield select((state: RootState) => state);

    const apiService = state.global.apiService;

    // @ts-ignore
    const result = yield call(getListTokens, apiService);

    const tokens: ITokenDetail[] = result.data?.filter?.((token: ITokenDetail) => token.zero_x?.enabled) || [];

    yield put(quote0xSlice.actions.fetchEnabledTokensSuccess(tokens));
}

function* fetchQuotesSaga(action: { payload: { amounts: number[] } }) {
    const state: RootState = yield select((state: RootState) => state);

    const apiService = state.global.apiService;
    const tokens = state.quote0x.tokens;

    const { amounts } = action.payload;

    yield all(tokens.map((token, index) => call(fetchQuotesOfEachTokenSaga, apiService, token, amounts, 1000 * index)));

    yield put(quote0xSlice.actions.fetchQuotesSuccess());
}

function* quote0xSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(quote0xSlice.actions.fetchEnabledTokensRequest, fetchEnabledTokensSaga);
    yield takeLatest(quote0xSlice.actions.fetchQuotesRequest, fetchQuotesSaga);
}

export default quote0xSaga;
