import { Component } from "react";
import { connect } from "react-redux";
import { toT, isLessThan } from "services/utils/converter";
import { isAddress } from "services/utils/validator";
import { getBalanceFromBlockchain, getSanityOperatorsFromReserve } from "services/address";
import { saveReserveAddress, saveBalanceThreshold } from "actions/signActions";
import PricingOperatorView from "./pricingOperator.view";

@connect((store) => {
    return {
        apiService: store.global.apiService,
        balanceThreshold: store.global.balanceThreshold,
        reserveAddr: store.global.reserveAddr,
        networkId: store.global.networkId,
    };
})
class PricingOperator extends Component {
    constructor(props) {
        super(props);

        this.intervalCheckOperatorBalance;
        this.isInit = true;
        this.state = {
            reserveAddress: "",
            operators: [],
            sanityOperators: [],
            loadingSanity: true,
        };
    }

    async componentDidMount() {
        await this.getAddressFromCore();
        this.updateOperatorBalance();
        this.intervalCheckOperatorBalance = setInterval(this.updateOperatorBalance.bind(this), 30_000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalCheckOperatorBalance);
    }

    async getAddressFromCore() {
        const apiService = this.props.apiService;
        const data = await apiService.getAddress();
        if (data && data.data) {
            const addresses = data.data.addresses;
            const operators = Object.entries(addresses)
                .filter(([name]) => name.includes("operator"))
                .map(([name, address]) => {
                    return { name, address };
                });
            for (let i = 0; i < operators.length; i++) {
                if (operators[i].name.includes("deposit")) {
                    const d = operators[i];
                    operators[i] = operators[0];
                    operators[0] = d;
                    break;
                }
            }
            this.setState({
                operators,
                reserveAddress: addresses.reserve,
            });

            if (this.isInit) {
                this.isInit = false;
                const reserveAddr = addresses.reserve;

                if (isAddress(reserveAddr)) {
                    const operators = await getSanityOperatorsFromReserve(this.props.apiService, reserveAddr);
                    const balances = await Promise.all(
                        operators.map((op) => getBalanceFromBlockchain(this.props.apiService, op))
                    );
                    const sanityOperators = operators.map((op, i) => ({
                        address: op,
                        balance: toT(balances[i]),
                    }));
                    return this.setState({
                        sanityOperators,
                        loadingSanity: false,
                    });
                }
            }

            if (addresses.reserve !== this.props.reserveAddr) {
                this.props.dispatch(saveReserveAddress(addresses.reserve));
            }
        }
    }

    async updateOperatorBalance() {
        const balances = await Promise.all(
            this.state.operators.map((op) =>
                op.address ? getBalanceFromBlockchain(this.props.apiService, op.address) : 0
            )
        );
        const operators = this.state.operators.map((op, i) => {
            const balance = toT(balances[i]);
            return {
                ...op,
                balance,
                warning: isLessThan(balance, this.props.balanceThreshold),
            };
        });
        this.setState({ operators });

        if (this.state.sanityOperators && this.state.sanityOperators.length) {
            const balances = await Promise.all(
                this.state.sanityOperators.map((op) => getBalanceFromBlockchain(this.props.apiService, op.address))
            );
            const sanityOperators = this.state.sanityOperators.map((op, i) => ({
                address: op.address,
                balance: toT(balances[i]),
            }));
            this.setState({
                sanityOperators,
            });
        }
    }

    onChangeBalanceThreshold(evt) {
        const threshold = evt.target.value;
        this.props.dispatch(saveBalanceThreshold(threshold));

        this.setState({
            operators: this.state.operators.map((op) => {
                return {
                    ...op,
                    warning: isLessThan(op.balance, threshold),
                };
            }),
        });
    }

    render() {
        return (
            <PricingOperatorView
                operators={this.state.operators}
                sanityOperators={this.state.sanityOperators}
                loadingSanity={this.state.loadingSanity}
                reserveAddress={this.state.reserveAddress}
                balanceThreshold={this.props.balanceThreshold}
                onChangeBalanceThreshold={this.onChangeBalanceThreshold.bind(this)}
                networkId={this.props.networkId}
            />
        );
    }
}

export default PricingOperator;
