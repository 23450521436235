import { REHYDRATE } from "redux-persist/lib/constants";
import ApiService from "../services/Connection/api.service";


const initState = {
    apiService: new ApiService({}),
    reserveAddr: "",
    hostData: {
        CORE: {
            id: "",
            url: "",
            key: "",
        },
        STATS: {
            id: "",
            url: "",
            key: "",
        },
    },
    balanceThreshold: 1,
    tokens: {},
    addresses: {},
    kyberTokens: {},
    kyberTokensNotETH: {},

    apiTokens: {},
    mappedAddress: {},
    timezone: {
        label: "UTC",
        name: "UTC",
        offset: 0,
    },
    tables: {},
    qtyGetRate: 0.1,
    networkId: 1,
    exchanges: null,
    defaultNode: "",
    nodeEndpoint: "",
};

const global = (state = initState, action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (action.key === "global") {
                var payload = action.payload;

                if (payload) {
                    const newState = { ...state };

                    const hostData = payload.hostData;
                    const addresses = payload.addresses;
                    const timezone = payload.timezone;
                    const tables = payload.tables;
                    const defaultNode = payload.defaultNode;
                    const nodeEndpoint = payload.nodeEndpoint;
                    const networkId = payload.networkId;
                    const newApiService = new ApiService({
                        hosts: hostData,
                        addresses,
                        nodeEndpoint,
                    });

                    return {
                        ...newState,
                        apiService: newApiService,
                        hostData,
                        addresses,
                        timezone,
                        tables,
                        defaultNode,
                        networkId,
                        // fetchedActions
                    };
                }

                return initState;
            } else {
                return state;
            }
        }

        case "SIGN.SAVE_SECRET_KEY": {
            const newState = { ...state };
            newState.keyString = action.payload;
            newState.apiService.setSecretKey(newState.keyString);
            return newState;
        }

        case "SIGN.SAVE_NETWORK_ADDRESS": {
            const newState = { ...state };
            const addresses = action.payload;

            newState.apiService.updateNetworkAddress(addresses, newState.nodeEndpoint);
            return { ...newState, addresses };
        }

        case "SIGN.SAVE_NODE_URL": {
            const newState = { ...state };
            const nodeUrl = action.payload;
            newState.defaultNode = nodeUrl;
            newState.apiService.updateNodeEndpoint(nodeUrl);
            return newState;
        }

        case "SIGN.SAVE_NODE_ENDPOINT": {
            const newState = { ...state };
            newState.nodeEndpoint = action.payload;
            newState.apiService.updateNodeEndpoint(newState.nodeEndpoint);
            return newState;
        }

        case "SIGN.SAVE_NETWORK_ID": {
            const newState = { ...state };
            newState.networkId = action.payload;
            return newState;
        }

        case "SIGN.SAVE_HOSTS_DATA": {
            const newState = { ...state };
            const dataPayload = action.payload;

            if (dataPayload) {
                const newHostData = {};
                Object.keys(dataPayload).forEach((hostSymbol) => {
                    newState.apiService.updateHostData(
                        hostSymbol,
                        dataPayload[hostSymbol].url,
                        dataPayload[hostSymbol].id,
                        dataPayload[hostSymbol].key
                    );
                    newHostData[hostSymbol] = dataPayload[hostSymbol];
                });

                return { ...newState, hostData: newHostData };
            } else {
                return state;
            }
        }

        case "HOST.SAVE_HOST": {
            const newState = { ...state };
            newState.host = action.payload;
            newState.apiService.setHost(newState.host);
            return newState;
        }

        case "SIGN.CLEAR_SECRET_KEY": {
            const newState = { ...state };
            newState.keyString = "";
            newState.apiService.clearSecretKey();
            return newState;
        }

        case "SIGN.SAVE_RESERVE_ADDR": {
            const newState = { ...state };
            newState.reserveAddr = action.payload;
            return newState;
        }

        case "SIGN.SAVE_BALANCE_THRESHOLD": {
            const newState = { ...state };
            newState.balanceThreshold = action.payload;
            return newState;
        }

        case "GLOBAL.SET_TIMEZONE": {
            const newState = { ...state };
            newState.timezone = action.payload;
            return newState;
        }

        case "GLOBAL.SAVE_NOTI": {
            const newState = { ...state };
            newState.notification = action.payload;
            return newState;
        }

        case "GLOBAL.SAVE_TOKENS": {
            const newState = { ...state };
            const arrayToken = action.payload;
            const kyberTokens = {};
            const kyberTokensNotETH = {};
            const allTokens = {};
            const allTokensNotETH = {};
            const mappedAddress = {};
            const tokenHasRate = {};
            arrayToken.map((token) => {
                allTokens[token.id] = { ...token };
                if (token.symbol !== "ETH") {
                    allTokensNotETH[token.id] = { ...token };
                }
                if (token.set_rate || token.rebalance) {
                    kyberTokens[token.id] = { ...token };
                    if (token.symbol !== "ETH") {
                        kyberTokensNotETH[token.id] = { ...token };
                    }
                }
                if (token.set_rate !== "not_set") {
                    tokenHasRate[token.id] = { ...token };
                }
                mappedAddress[token.address.toLowerCase()] = token.id;
            });

            newState.tokens = { ...allTokens };
            newState.kyberTokens = { ...kyberTokens };

            newState.mappedAddress = mappedAddress;

            newState.kyberTokensNotETH = { ...kyberTokensNotETH };
            newState.allTokensNotETH = { ...allTokensNotETH };
            newState.tokenHasRate = { ...tokenHasRate };

            return newState;
        }

        case "GLOBAL.SAVE_EXCHANGE": {
            const newState = { ...state };
            const arrayExchange = action.payload;
            const exchangesObj = {};
            arrayExchange.map((ex) => {
                exchangesObj[ex.id] = ex;
            });
            newState.exchanges = exchangesObj;
            return newState;
        }

        case "GLOBAL.SAVE_API_TOKENS": {
            const newState = { ...state };
            const arrayToken = action.payload;
            const allTokens = {};
            const mappedAddress = {};
            arrayToken.map((token) => {
                allTokens[token.symbol] = { ...token, symbol: token.id };
                mappedAddress[token.address.toLowerCase()] = token;
            });
            newState.apiTokens = { ...allTokens };
            newState.mappedApiAddress = mappedAddress;

            return newState;
        }

        case "GLOBAL.SAVE_TABLE_CONFIG": {
            const newState = { ...state };
            const tableName = action.payload.tableName;
            const config = action.payload.config;
            newState.tables[tableName] = config;
            return newState;
        }

        case "GLOBAL.SAVE_QTY_RATE": {
            const newState = { ...state };
            const qty = action.payload;
            newState.qtyGetRate = qty;
            return newState;
        }

        case "GLOBAL.SAVE_SELECTED_FETCH_ACTION": {
            const newState = { ...state };
            const key = action.payload.key;
            const checked = action.payload.checked;
            currentFetchedActions[key] = checked;
            return newState;
        }

        default:
            return state;
    }
};

export default global;
