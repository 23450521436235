import styled from "styled-components";

import { getIconSource } from "utils/index.js";

interface IProps {
    className?: string;
    symbol: string;
    hideIcon?: boolean;
}

const TokenCell: React.FC<IProps> = ({ className, symbol, hideIcon = false }) => {
    return (
        <div className={className}>
            {hideIcon ? null : (
                <div className="iconWrapper">
                    <img src={getIconSource(symbol)} className="rounded-circle" />
                </div>
            )}
            <span>{symbol.toUpperCase()}</span>
        </div>
    );
};

export default styled(TokenCell)`
    display: flex;
    align-items: center;

    .iconWrapper {
        height: 30px;
        flex: 0 0 30px;
    }

    img {
        margin: 0 10px;
        width: 30px;
        height: 30px;
        border: 1px solid #bdc3c7;
        border-radius: 50%;
        overflow: hidden;
    }
`;
