import styled from "styled-components";
import cx from "classnames";

import styles from "./styles";

interface IProps {
    className?: string;
}

const CloseMark: React.FC<IProps> = ({ className }) => {
    return (
        <div className={cx(className, "icon")}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.46967 4.13092C3.76256 3.83803 4.23744 3.83803 4.53033 4.13092L12.5303 12.1309C12.8232 12.4238 12.8232 12.8987 12.5303 13.1916C12.2374 13.4845 11.7626 13.4845 11.4697 13.1916L3.46967 5.19158C3.17678 4.89869 3.17678 4.42382 3.46967 4.13092Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5303 4.13092C12.8232 4.42382 12.8232 4.89869 12.5303 5.19158L4.53033 13.1916C4.23744 13.4845 3.76256 13.4845 3.46967 13.1916C3.17678 12.8987 3.17678 12.4238 3.46967 12.1309L11.4697 4.13092C11.7626 3.83803 12.2374 3.83803 12.5303 4.13092Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default styled(CloseMark)`
    ${styles}

    width: 16px;
    height: 16px;
`;
