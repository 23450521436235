export const COLORS = {
    RED: "#e63946",
    YELLOW: "#faa307",
    PURPLE: "#7b2cbf",
};

export enum DisplayMode {
    Simple = "Simple",
    Full = "Full",
}
