import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { ToastType } from "./consts";

export interface Toast {
    id?: string;
    text?: string;
    title?: string;
    type: ToastType;
}

const initialState: Toast = {
    text: "",
    title: "",
    type: "error",
};

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        clearNotification: () => initialState,
        toast: (state, action: PayloadAction<Toast>) => {
            const { id = nanoid(), text, type, title } = action.payload;

            state.id = id;
            state.type = type;
            state.title = title;
            state.text = text;
        },
    },
});

export const { toast } = toastSlice.actions;
export default toastSlice;
