export const timezones = {
    "(GMT-11) Niue": "-11",
    "(GMT-11) Pago Pago": "-11",
    "(GMT-10) Hawaii Time": "-10",
    "(GMT-10) Rarotonga": "-10",
    "(GMT-10) Tahiti": "-10",

    "(GMT-09) Alaska Time": "-9",
    "(GMT-09) Gambier": "-9",
    "(GMT-08) Pacific Time": "-8",
    "(GMT-08) Pacific Time - Tijuana": "-8",
    "(GMT-08) Pacific Time - Vancouver": "-8",
    "(GMT-08) Pacific Time - Whitehorse": "-8",
    "(GMT-08) Pitcairn": "-8",
    "(GMT-07) Mountain Time": "-7",
    "(GMT-07) Mountain Time - Arizona": "-7",
    "(GMT-07) Mountain Time - Chihuahua, Mazatlan": "-7",
    "(GMT-07) Mountain Time - Dawson Creek": "-7",
    "(GMT-07) Mountain Time - Edmonton": "-7",
    "(GMT-07) Mountain Time - Hermosillo": "-7",
    "(GMT-07) Mountain Time - Yellowknife": "-7",
    "(GMT-06) Belize": "-6",
    "(GMT-06) Central Time": "-6",
    "(GMT-06) Central Time - Mexico City": "-6",
    "(GMT-06) Central Time - Regina": "-6",
    "(GMT-06) Central Time - Tegucigalpa": "-6",
    "(GMT-06) Central Time - Winnipeg": "-6",
    "(GMT-06) Costa Rica": "-6",
    "(GMT-06) El Salvador": "-6",
    "(GMT-06) Galapagos": "-6",
    "(GMT-06) Guatemala": "-6",
    "(GMT-06) Managua": "-6",
    "(GMT-05) America Cancun": "-5",
    "(GMT-05) Bogota": "-5",
    "(GMT-05) Easter Island": "-5",
    "(GMT-05) Eastern Time": "-5",
    "(GMT-05) Eastern Time - Iqaluit": "-5",
    "(GMT-05) Eastern Time - Toronto": "-5",
    "(GMT-05) Guayaquil": "-5",
    "(GMT-05) Havana": "-5",
    "(GMT-05) Jamaica": "-5",
    "(GMT-05) Lima": "-5",
    "(GMT-05) Nassau": "-5",
    "(GMT-05) Panama": "-5",
    "(GMT-05) Port-au-Prince": "-5",
    "(GMT-05) Rio Branco": "-5",
    "(GMT-04) Atlantic Time - Halifax": "-4",
    "(GMT-04) Barbados": "-4",
    "(GMT-04) Bermuda": "-4",
    "(GMT-04) Boa Vista": "-4",
    "(GMT-04) Caracas": "-4",
    "(GMT-04) Curacao": "-4",
    "(GMT-04) Grand Turk": "-4",
    "(GMT-04) Guyana": "-4",
    "(GMT-04) La Paz": "-4",
    "(GMT-04) Manaus": "-4",
    "(GMT-04) Martinique": "-4",
    "(GMT-04) Port of Spain": "-4",
    "(GMT-04) Porto Velho": "-4",
    "(GMT-04) Puerto Rico": "-4",
    "(GMT-04) Santo Domingo": "-4",
    "(GMT-04) Thule": "-4",

    "(GMT-03) Araguaina": "-3",
    "(GMT-03) Asuncion": "-3",
    "(GMT-03) Belem": "-3",
    "(GMT-03) Buenos Aires": "-3",
    "(GMT-03) Campo Grande": "-3",
    "(GMT-03) Cayenne": "-3",
    "(GMT-03) Cuiaba": "-3",
    "(GMT-03) Fortaleza": "-3",
    "(GMT-03) Godthab": "-3",
    "(GMT-03) Maceio": "-3",
    "(GMT-03) Miquelon": "-3",
    "(GMT-03) Montevideo": "-3",
    "(GMT-03) Palmer": "-3",
    "(GMT-03) Paramaribo": "-3",
    "(GMT-03) Punta Arenas": "-3",
    "(GMT-03) Recife": "-3",
    "(GMT-03) Rothera": "-3",
    "(GMT-03) Salvador": "-3",
    "(GMT-03) Santiago": "-3",
    "(GMT-03) Stanley": "-3",
    "(GMT-02) Noronha": "-3",
    "(GMT-02) Sao Paulo": "-3",
    "(GMT-02) South Georgia": "-3",
    "(GMT-01) Azores": "-1",
    "(GMT-01) Cape Verde": "-1",
    "(GMT-01) Scoresbysund": "-1",

    UTC: "0",

    "(GMT+01) Algiers": "1",
    "(GMT+01) Amsterdam": "1",
    "(GMT+01) Andorra": "1",
    "(GMT+01) Berlin": "1",
    "(GMT+01) Brussels": "1",
    "(GMT+01) Budapest": "1",
    "(GMT+01) Central European Time - Belgrade": "1",
    "(GMT+01) Central European Time - Prague": "1",
    "(GMT+01) Ceuta": "1",
    "(GMT+01) Copenhagen": "1",
    "(GMT+01) Gibraltar": "1",
    "(GMT+01) Lagos": "1",
    "(GMT+01) Luxembourg": "1",
    "(GMT+01) Madrid": "1",
    "(GMT+01) Malta": "1",
    "(GMT+01) Monaco": "1",
    "(GMT+01) Ndjamena": "1",
    "(GMT+01) Oslo": "1",
    "(GMT+01) Paris": "1",
    "(GMT+01) Rome": "1",
    "(GMT+01) Stockholm": "1",
    "(GMT+01) Tirane": "1",
    "(GMT+01) Tunis": "1",
    "(GMT+01) Vienna": "1",
    "(GMT+01) Warsaw": "1",
    "(GMT+01) Zurich": "1",
    "(GMT+02) Amman": "2",
    "(GMT+02) Athens": "2",
    "(GMT+02) Beirut": "2",
    "(GMT+02) Bucharest": "2",
    "(GMT+02) Cairo": "2",
    "(GMT+02) Chisinau": "2",
    "(GMT+02) Damascus": "2",
    "(GMT+02) Gaza": "2",
    "(GMT+02) Helsinki": "2",
    "(GMT+02) Jerusalem": "2",
    "(GMT+02) Johannesburg": "2",
    "(GMT+02) Khartoum": "2",
    "(GMT+02) Kiev": "2",
    "(GMT+02) Maputo": "2",
    "(GMT+02) Moscow-01 - Kaliningrad": "2",
    "(GMT+02) Nicosia": "2",
    "(GMT+02) Riga": "2",
    "(GMT+02) Sofia": "2",
    "(GMT+02) Tallinn": "2",
    "(GMT+02) Tripoli": "2",
    "(GMT+02) Vilnius": "2",
    "(GMT+02) Windhoek": "2",
    "(GMT+03) Baghdad": "3",
    "(GMT+03) Istanbul": "3",
    "(GMT+03) Minsk": "3",
    "(GMT+03) Moscow - Moscow": "3",
    "(GMT+03) Nairobi": "3",
    "(GMT+03) Qatar": "3",
    "(GMT+03) Riyadh": "3",
    "(GMT+03) Syowa": "3",

    "(GMT+04) Baku": "4",
    "(GMT+04) Dubai": "4",
    "(GMT+04) Mahe": "4",
    "(GMT+04) Mauritius": "4",
    "(GMT+04) Moscow+01 - Samara": "4",
    "(GMT+04) Reunion": "4",
    "(GMT+04) Tbilisi": "4",
    "(GMT+04) Yerevan": "4",

    "(GMT+05) Aqtau": "5",
    "(GMT+05) Aqtobe": "5",
    "(GMT+05) Ashgabat": "5",
    "(GMT+05) Dushanbe": "5",
    "(GMT+05) Karachi": "5",
    "(GMT+05) Kerguelen": "5",
    "(GMT+05) Maldives": "5",
    "(GMT+05) Mawson": "5",
    "(GMT+05) Moscow+02 - Yekaterinburg": "5",
    "(GMT+05) Tashkent": "5",

    "(GMT+06) Almaty": "6",
    "(GMT+06) Bishkek": "6",
    "(GMT+06) Chagos": "6",
    "(GMT+06) Dhaka": "6",
    "(GMT+06) Moscow+03 - Omsk": "6",
    "(GMT+06) Thimphu": "6",
    "(GMT+06) Vostok": "6",

    "(GMT+07) Bangkok": "7",
    "(GMT+07) Christmas": "7",
    "(GMT+07) Davis": "7",
    "(GMT+07) Hanoi": "7",
    "(GMT+07) Hovd": "7",
    "(GMT+07) Jakarta": "7",
    "(GMT+07) Moscow+04 - Krasnoyarsk": "7",
    "(GMT+08) Brunei": "8",
    "(GMT+08) China Time - Beijing": "8",
    "(GMT+08) Choibalsan": "8",
    "(GMT+08) Hong Kong": "8",
    "(GMT+08) Kuala Lumpur": "8",
    "(GMT+08) Macau": "8",
    "(GMT+08) Makassar": "8",
    "(GMT+08) Manila": "8",
    "(GMT+08) Moscow+05 - Irkutsk": "8",
    "(GMT+08) Singapore": "8",
    "(GMT+08) Taipei": "8",
    "(GMT+08) Ulaanbaatar": "8",
    "(GMT+08) Western Time - Perth": "8",

    "(GMT+09) Dili": "9",
    "(GMT+09) Jayapura": "9",
    "(GMT+09) Moscow+06 - Yakutsk": "9",
    "(GMT+09) Palau": "9",
    "(GMT+09) Seoul": "9",
    "(GMT+09) Tokyo": "9",

    "(GMT+10) Dumont D'Urville": "10",
    "(GMT+10) Eastern Time - Brisbane": "10",
    "(GMT+10) Guam": "10",
    "(GMT+10) Moscow+07 - Vladivostok": "10",
    "(GMT+10) Port Moresby": "10",
    "(GMT+10) Truk": "10",

    "(GMT+11) Casey": "11",
    "(GMT+11) Eastern Time - Hobart": "11",
    "(GMT+11) Eastern Time - Melbourne, Sydney": "11",
    "(GMT+11) Efate": "11",
    "(GMT+11) Guadalcanal": "11",
    "(GMT+11) Kosrae": "11",
    "(GMT+11) Moscow+08 - Magadan": "11",
    "(GMT+11) Norfolk": "11",
    "(GMT+11) Noumea": "11",
    "(GMT+11) Ponape": "11",
    "(GMT+12) Funafuti": "12",
    "(GMT+12) Kwajalein": "12",
    "(GMT+12) Majuro": "12",
    "(GMT+12) Moscow+09 - Petropavlovsk-Kamchatskiy": "12",
    "(GMT+12) Nauru": "12",
    "(GMT+12) Tarawa": "12",
    "(GMT+12) Wake": "12",
    "(GMT+12) Wallis": "12",
    "(GMT+13) Auckland": "13",
    "(GMT+13) Enderbury": "13",
    "(GMT+13) Fakaofo": "13",
    "(GMT+13) Fiji": "13",
    "(GMT+13) Tongatapu": "13",
    "(GMT+14) Apia": "14",
    "(GMT+14) Kiritimati": "14",
};
