export function setSecretKey(seyString) {
    return {
        type: "GLOBAL.SET_SECRET_KEY",
        payload: seyString,
    };
}

export function setTimeZone(timezone) {
    return {
        type: "GLOBAL.SET_TIMEZONE",
        payload: timezone,
    };
}

export function setOldNotification(notiData) {
    return {
        type: "GLOBAL.SAVE_NOTI",
        payload: notiData,
    };
}

export function initConfig() {
    return {
        type: "GLOBAL.INIT_CONFIG",
    };
}

export function saveTokens(tokensData) {
    return {
        type: "GLOBAL.SAVE_TOKENS",
        payload: tokensData,
    };
}

export function saveExchange(exchangeArray) {
    return {
        type: "GLOBAL.SAVE_EXCHANGE",
        payload: exchangeArray,
    };
}

export function saveApiToken(apiTokenData) {
    return {
        type: "GLOBAL.SAVE_API_TOKENS",
        payload: apiTokenData,
    };
}

export function saveTableConfig(tableName, config) {
    return {
        type: "GLOBAL.SAVE_TABLE_CONFIG",
        payload: { tableName, config },
    };
}

export function saveQtyGetRate(qty) {
    return {
        type: "GLOBAL.SAVE_QTY_RATE",
        payload: qty,
    };
}

export function saveFetchedAction(key, checked) {
    return {
        type: "GLOBAL.SAVE_SELECTED_FETCH_ACTION",
        payload: { key, checked },
    };
}
