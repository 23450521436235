import { RootState } from "reducers";
import { call, takeLatest, ForkEffect, select, put } from "redux-saga/effects";

import RatesService from "services/rates";
import { toast } from "components/_base/Toast/slice";

import { IActionConfig } from "./model";
import otherActivitiesSlice from "./otherActivities.slice";

function* fetchRatesRequestSaga() {
    const state: RootState = yield select((state: RootState) => state);

    const { tokens, apiService } = state.global;
    const { fetchConfig } = state.otherActivities;
    const { actionConfig, fromTime, toTime } = fetchConfig;

    if (!apiService) {
        return;
    }

    const ratesService = new RatesService();

    const actionConfigToFetch = Object.keys(actionConfig)
        .filter((key) => actionConfig[key as keyof IActionConfig])
        .reduce((config: Record<string, string>, key: string) => {
            config[key] = actionConfig[key as keyof IActionConfig]!.toString();
            return config;
        }, {});

    // @ts-ignore
    const data = yield call(
        [ratesService, ratesService.syncOtherActivities],
        apiService,
        fromTime,
        toTime,
        tokens,
        actionConfigToFetch
    );

    if (!data) {
        yield put(otherActivitiesSlice.actions.fetchRatesFailure());
        yield put(toast({ text: "Something went wrong while fetching", type: "error" }));
        return;
    }

    const filteredData = data.filteredData;

    yield put(otherActivitiesSlice.actions.fetchRatesSuccess(filteredData.otherActions));
    yield put(otherActivitiesSlice.actions.setFilterOptions(filteredData.filterOptions));
}

function* otherActivitiesSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(otherActivitiesSlice.actions.fetchRatesRequest, fetchRatesRequestSaga);
}

export default otherActivitiesSaga;
