import CONSTANTS from "./constants";

import { BigNumber } from "bignumber.js";
import {
    filterSetRateAction,
    mappingSetRateHistory,
    mappingCoreRates,
    mappingReserveRate,
    filterOtherActivities,
} from "./utils/standardize";
import { stretchArray } from "./utils/converter";

const TIME_STEP = 60 * 60 * 1000 * 3; //3 hour

export default class RatesService {
    constructor() {
        this.rates = {
            data: {},
        };
    }

    getAllActivities(apiService, fromTime, toTime, fetchedActions) {
        const ONE_DAY = 24 * 60 * 60 * 1000;

        const now = new Date().getTime() + 5 * 60 * 1000; // extra 5 mins

        const end = toTime || now;

        const numberPoint = Math.ceil((end - fromTime) / ONE_DAY);
        const timeFrames = [];

        for (let i = 0; i < numberPoint; i++) {
            const startTime = fromTime + ONE_DAY * i + 1;
            const endTime = i == numberPoint - 1 ? end : fromTime + ONE_DAY * (i + 1);

            timeFrames.push({ startTime, endTime });
        }

        if (timeFrames.length) {
            return Promise.all(
                timeFrames.map(({ startTime, endTime }) =>
                    apiService.getAllActivities(startTime, endTime, fetchedActions)
                )
            );
        }

        return apiService.getAllActivities(fromTime, end, fetchedActions);
    }

    async syncAllRates(apiService, fromTime, toTime) {
        const allActivities = await this.getAllActivities(apiService, fromTime, toTime, { set_rates: "true" });
        let arrayData = [];
        for (const i in allActivities) {
            if (allActivities[i].data) arrayData = [...allActivities[i].data, ...arrayData];
        }

        const filteredData = filterSetRateAction(arrayData);
        const setRateActionList = filteredData.arraySetRate;

        // if(setRateActionList && setRateActionList.length && setRateActionList.length > CONSTANTS.MAX_CHART_POINT){
        //   setRateActionList = setRateActionList.slice(-CONSTANTS.MAX_CHART_POINT)
        // }

        const timeEpsilon = allActivities[0].timeEpsilon;

        // setRateActionList = stretchArray(setRateActionList, 15)
        const mappedRateTokens = mappingSetRateHistory(setRateActionList);

        let currentTimeStamp = fromTime;
        if (setRateActionList && setRateActionList.length) {
            const latestDataTime = Math.round(setRateActionList[0].timestamp);
            currentTimeStamp = timeEpsilon ? latestDataTime - timeEpsilon : latestDataTime;
        }
        return {
            data: mappedRateTokens,
            currentTimeStamp,
            otherAction: filteredData.otherActions,
        };
    }

    async syncOtherActivities(apiService, fromTime, toTime, tokens, fetchedActions) {
        const allActivities = await this.getAllActivities(apiService, fromTime, toTime, fetchedActions);
        let arrayData = [];

        for (const i in allActivities) {
            if (allActivities[i].data) arrayData = [...allActivities[i].data, ...arrayData];
        }

        const filteredData = filterOtherActivities(arrayData, tokens);
        const currentTimeStamp = fromTime;

        return {
            currentTimeStamp,
            filteredData,
        };
    }

    getBlockchainRateFromCore(apiService, timeStamp, toTime) {
        // fromTime++
        const now = new Date().getTime();
        const end = toTime || now;
        const numberPoint = Math.ceil((end - timeStamp) / TIME_STEP);
        const arrayPromiseActivities = [];
        for (let i = 0; i < numberPoint; i++) {
            const startTime = timeStamp + TIME_STEP * i + 1;
            const endTime = i == numberPoint - 1 ? end : timeStamp + TIME_STEP * (i + 1);
            arrayPromiseActivities.push(apiService.getBlockchainRateFromCore(startTime, endTime));
        }
        if (arrayPromiseActivities.length) {
            return Promise.all(arrayPromiseActivities);
        }
        return apiService.getBlockchainRateFromCore(timeStamp, end);
    }

    async syncAllRateFromCore(apiService, timeStamp, toTime) {
        const allCoreRates = await this.getBlockchainRateFromCore(apiService, timeStamp, toTime);
        let arrayData = [];
        for (const i in allCoreRates) {
            if (allCoreRates[i].data) arrayData = [...allCoreRates[i].data, ...arrayData];
        }

        const mappedCoreRates = mappingCoreRates(arrayData);
        let currentCoreTimeStamp = timeStamp;
        if (allCoreRates && allCoreRates.length) {
            const timeEpsilon = allCoreRates[0].timeEpsilon;
            const reallyAllRate = allCoreRates.filter((x) => x.data.length);
            const lastestItem = reallyAllRate[reallyAllRate.length - 1];

            const lastestDataTime = Math.round(lastestItem.data[0].timestamp);
            currentCoreTimeStamp = timeEpsilon ? lastestDataTime - timeEpsilon : lastestDataTime;
        }

        return {
            data: mappedCoreRates,
            currentCoreTimeStamp: +currentCoreTimeStamp,
        };
    }

    getReserveRate(apiService, reserveAddr, fromTime, toTime) {
        fromTime++;
        return apiService.getReserveRate(reserveAddr, fromTime, toTime);
    }

    async syncReserveRate(apiService, reserveAddr, timeStamp, toTime) {
        const reserveRate = await this.getReserveRate(apiService, reserveAddr, timeStamp, toTime);
        // let slicedArray = reserveRate && reserveRate.data ? reserveRate.data.slice(-CONSTANTS.MAX_CHART_POINT) : []

        const mappedReserveRate = mappingReserveRate(reserveRate.data, reserveAddr);

        const currentTimeStamp = timeStamp;
        // if(reserveRate && slicedArray && slicedArray.length){
        //   let timeEpsilon = reserveRate.timeEpsilon
        //   let lastestDataTime = Math.round(slicedArray[slicedArray.length-1].Timestamp)
        //   currentTimeStamp = timeEpsilon ? lastestDataTime - timeEpsilon : lastestDataTime
        // }
        return {
            data: mappedReserveRate,
            currentTimeStamp: +currentTimeStamp,
        };
    }
}
