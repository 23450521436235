import React, { Component } from "react";
import TokenSelectorView from "./tokenSelector.view";
import Select from "react-select";

import { connect } from "react-redux";

@connect((store) => {
    let tokens = store.global.tokens;
    let kyberTokens = store.global.kyberTokens;
    let kyberTokensNotETH = store.global.kyberTokensNotETH;
    let allTokensNotETH = store.global.allTokensNotETH;
    let tokenHasRate = store.global.tokenHasRate;
    return {
        tokens,
        kyberTokens,
        kyberTokensNotETH,
        allTokensNotETH,
        tokenHasRate,
    };
})
class TokenSelector extends Component {
    constructor(props) {
        super(props);
    }

    onSelected(token) {
        this.props.onSelected(token);
    }

    handleChange = (selectedOption) => {
        // this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        this.props.onSelected(selectedOption.value);
    };

    render() {
        let sourceList = this.props.allTokens ? this.props.allTokensNotETH : this.props.kyberTokensNotETH;

        if (this.props.includeEth) sourceList = this.props.kyberTokens;
        if (this.props.allToken) sourceList = this.props.tokens;
        if (this.props.hasSetRate) sourceList = this.props.tokenHasRate;

        const options = sourceList
            ? Object.values(sourceList).map((t) => ({ value: t.id, label: `${t.id} - ${t.symbol}` }))
            : [];

        // return <TokenSelectorView
        // onSelected={this.onSelected.bind(this)}
        // selected={this.props.selected}
        // />

        return (
            <Select
                className="p-1"
                value={
                    this.props.selected && sourceList && sourceList[this.props.selected]
                        ? {
                              value: sourceList[this.props.selected].id,
                              label: `${sourceList[this.props.selected].id} - ${
                                  sourceList[this.props.selected].symbol
                              }`,
                          }
                        : null
                }
                onChange={this.handleChange}
                options={options}
            />
        );
    }
}

export default TokenSelector;
