import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import cx from "classnames";

import TokenCell from "./TokenCell";

const getDetailsFromTokenData = (data) => {
    const getRefETHPrice = () => {
        const isAfpAskPriceOK = data["0xRate"] && data["0xRate"].afpAsk && !!Number(data["0xRate"].afpAsk);
        const isAfpBidPriceOK = data["0xRate"] && data["0xRate"].afpBid && !!Number(data["0xRate"].afpBid);

        if (isAfpAskPriceOK && isAfpBidPriceOK) {
            return new BigNumber(data["0xRate"].afpAsk)
                .plus(new BigNumber(data["0xRate"].afpBid))
                .dividedBy(2)
                .toFixed(5);
        } else if (isAfpAskPriceOK) {
            return data["0xRate"].afpAsk;
        } else if (isAfpBidPriceOK) {
            return data["0xRate"].afpBid;
        }

        return undefined;
    };

    const max_eth_sell = data.info?.zero_x?.max_eth_size_sell;
    const total_target = data.totalTarget;
    const ref_eth_price = getRefETHPrice();

    const max_possible_imb_ratio =
        max_eth_sell && total_target && ref_eth_price
            ? Math.max(max_eth_sell / (total_target * ref_eth_price), 1)
            : undefined;

    return {
        max_eth_sell,
        total_target,
        ref_eth_price,
        max_possible_imb_ratio,
    };
};

const checkWarningMaxPossibleIMBRatio = (max_possible_imb_ratio, max_imb_ratio) => {
    const warning = new BigNumber(max_possible_imb_ratio).isGreaterThan(
        new BigNumber(max_imb_ratio).multipliedBy("1.3")
    );

    return warning;
};

const WarningTokens = ({ className }) => {
    const tokenDataById = useSelector((state) => state.dashboard.tokenDataById);
    const [selectedTokenSymbol, setSelectedTokenSymbol] = useState();

    const handleClickToken = (symbol) => {
        if (selectedTokenSymbol === symbol) {
            setSelectedTokenSymbol();
        } else {
            setSelectedTokenSymbol(symbol);
        }
    };

    const warningTokenDetails = Object.values(tokenDataById)
        .map((data) => {
            const max_imbalance_ratio = data.info.max_imbalance_ratio;
            const symbol = data.info.symbol;
            return {
                ...getDetailsFromTokenData(data),
                max_imbalance_ratio,
                symbol,
            };
        })
        .filter((data) => {
            const { max_possible_imb_ratio, max_imbalance_ratio } = data;

            if (!max_possible_imb_ratio || !max_imbalance_ratio) {
                return false;
            }

            // Keep those which don't pass the check
            const warning = checkWarningMaxPossibleIMBRatio(max_possible_imb_ratio, max_imbalance_ratio);
            return warning;
        });

    const selectedTokenDetail = selectedTokenSymbol
        ? warningTokenDetails.find(({ symbol }) => symbol === selectedTokenSymbol)
        : undefined;

    if (warningTokenDetails.length === 0) {
        return null;
    }

    return (
        <div className={cx(className, "panel-header ml-2")}>
            <span>Warning tokens</span>
            <div className="warning-tokens">
                {warningTokenDetails.map(({ symbol }) => (
                    <TokenCell
                        key={symbol}
                        symbol={symbol}
                        selected={selectedTokenSymbol === symbol}
                        onClick={() => handleClickToken(symbol)}
                    />
                ))}
            </div>

            {selectedTokenDetail ? (
                <div className="token-detail">
                    {Object.entries(selectedTokenDetail).map(([key, value]) => (
                        <div key={key} className="entry">
                            <span className="key">{key}:</span>
                            <span className="value">{value}</span>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default styled(WarningTokens)`
    .warning-tokens {
        display: flex;
        gap: 8px 8px;
        flex-wrap: wrap;
    }

    .token-detail {
        margin-top: 16px;

        .key {
            margin-right: 4px;
        }
    }
`;
