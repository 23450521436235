import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useAlert } from "react-alert";

import { roundingNumber, formatMoney } from "services/utils/converter";
import { TableData, KProgress, Modal } from "components/Share";
import { triggerRebalance } from "../../../services/configuration";
import { getIconSource } from "utils/index.js";

import "react-table/react-table.css";

import loading from "../../../assets/img/waiting.svg";

const loadingImg = <img alt="loading" src={loading} />;

const getReason = (res) => {
    if (res.reason) {
        return res.reason;
    }
    if (res.error) {
        return res.error;
    }
    return "Failed to immediately trigger rebalance";
};

const calculateSpread = (ask, bid) => {
    if (ask > 0 && bid > 0) return (ask - bid) / ((ask + bid) / 2);
};

const findBidAskDiff = (rate, data) => {
    let lAsk = 1e9; // buy with low ask
    let hBid = 0; // sell with high bid
    data.forEach((d) => {
        const a = JSON.parse(d.afpAsk);
        const b = JSON.parse(d.afpBid);
        if (lAsk > a && a > 0) lAsk = a;
        if (hBid < b) hBid = b;
    });

    return {
        bid_diff: rate.afpBid > 0 && hBid > 0 ? ((1 - rate.afpBid / hBid) * 1e4).toFixed(0) : null,
        ask_diff: rate.afpAsk > 0 && lAsk < 1e9 ? ((rate.afpAsk / lAsk - 1) * 1e4).toFixed(0) : null,
    };
};

const getRateTargetValue = (data, shouldReturnOne) => {
    if (shouldReturnOne) return 1;
    let rateObj = data.find((d) => d.exchange == "custom" && d.afpAsk > 0 && d.afpBid > 0);
    if (!rateObj) rateObj = data.find((d) => d.afpAsk > 0 || d.afpBid > 0);
    if (rateObj) return rateObj.afpAsk || rateObj.afpBid;

    return 0;
};

const InfoModal = ({ modalId, apiService }) => {
    const alert = useAlert();
    const tokenID = useSelector((state) => state.dashboard.selectedTokenId);
    const tokenInfo = useSelector((state) => state.dashboard.tokenDataById[tokenID]);
    const [isLoading, setLoading] = useState(false);
    const [quote, setQuote] = useState("ETH");

    if (!modalId || !tokenInfo) {
        return <Modal title={null} id={modalId} content={null} size="lg" />;
    }

    const token = tokenInfo.info;
    const data = tokenInfo.rates.filter(
        (r) =>
            r.exchange != "custom" &&
            r.quoteConversion == quote &&
            token.symbol != r.quoteConversion &&
            r.base == token.id
    );
    const ratesByIntegration =
        tokenInfo.ratesByIntegration
            ?.filter((r) => r.quote == quote)
            .map((rate) => {
                return { ...rate, ...findBidAskDiff(rate, data) };
            }) || [];

    data.unshift(...ratesByIntegration);

    const triggerRebalanceForSymbol = (id) => {
        setLoading(true);
        triggerRebalance(apiService, JSON.stringify({ assets: [id] })).then((res) => {
            if (res.success) {
                alert.success("Success!");
            } else {
                alert.error(getReason(res));
            }
            setLoading(false);
        });
    };

    const tableExchange = (
        <TableData
            data={data}
            columns={[
                {
                    Header: "EXCHANGE",
                    id: "exchange",
                    accessor: (d) => d.exchange,
                },
                {
                    Header: "SYMBOL",
                    id: "symbol",
                    accessor: (d) => d.symbol,
                    minWidth: 140,
                },
                {
                    Header: "BID",
                    id: "bid",
                    accessor: (d) => roundingNumber(d.afpBid, 5),
                    minWidth: 160,
                },
                {
                    Header: "ASK",
                    id: "ask",
                    accessor: (d) => roundingNumber(d.afpAsk, 5),
                    minWidth: 160,
                },
                {
                    Header: "SPREAD",
                    id: "spread",
                    accessor: (d) => {
                        const { afpBid, afpAsk } = d;
                        const rawSpread = calculateSpread(afpAsk, afpBid);
                        return rawSpread?.toFixed(4);
                    },
                },
                {
                    Header: "BID_diff",
                    id: "bid_diff",
                    accessor: (d) => d.bid_diff,
                    width: 80,
                },
                {
                    Header: "ASK_diff",
                    id: "ask_diff",
                    accessor: (d) => d.ask_diff,
                    width: 80,
                },
            ]}
        />
    );

    const reserveBalance = (
        <span data-tip={tokenInfo.reserveBalanceWithWithdrawTitle}>
            {roundingNumber(tokenInfo.reserveBalanceWithWithdraw)}
        </span>
    );

    const modalTitle = (
        <React.Fragment>
            <img src={getIconSource(token.symbol)} className="rounded-circle" />
            <span>
                [{token.id}] {token.symbol}
            </span>
            <span className="text-warning text-size-4">{tokenInfo.percent}%</span>
            <span className="d-none d-lg-inline">
                {token.address}, Decimals: {token.decimals}
            </span>
        </React.Fragment>
    );

    const modalContent = (
        <div className="px-4">
            <span className="text-uppercase text-size-s-1 semi-b">TARGET VALUE:</span>
            <span className="text-info">
                &emsp;
                {formatMoney(
                    getRateTargetValue(
                        [...tokenInfo.rates.filter((r) => r.quoteConversion == quote), ...data],
                        token.symbol == quote
                    ) * tokenInfo.totalTarget,
                    2
                )}
            </span>
            &emsp;
            <span className="dropdown">
                <span className="dropdown-toggle pointer text-size-s-1 semi-b" data-toggle="dropdown">
                    {quote} <span className="caret" />
                </span>

                <div className="dropdown-menu">
                    <small className="dropdown-item pointer" onClick={() => setQuote("ETH")}>
                        ETH
                    </small>
                    <small className="dropdown-item pointer" onClick={() => setQuote("USDT")}>
                        USDT
                    </small>
                </div>
            </span>
            <div className="row mt-3">
                <div className="col-sm-6">
                    <p className="text-uppercase text-size-s-1 semi-b">Reserve</p>
                    <KProgress
                        targetValue={
                            tokenInfo.info.asset_type == "default" ? roundingNumber(tokenInfo.reserveTarget) : 0
                        }
                        currentValue={reserveBalance}
                        percent={tokenInfo.reservePercent}
                    />
                </div>
                <div className="col-sm-6">
                    <p className="text-uppercase text-size-s-1 semi-b">Total</p>
                    <KProgress
                        targetValue={tokenInfo.info.asset_type == "default" ? roundingNumber(tokenInfo.totalTarget) : 0}
                        currentValue={roundingNumber(tokenInfo.totalQty)}
                        percent={tokenInfo.percent}
                    />
                </div>
            </div>
            <div className="mt-5">{tableExchange}</div>
            <h5 className="text-size-s-1 semi-b my-4">BALANCES</h5>
            {Object.values(tokenInfo.exchangeBalance).map((ex, i) => (
                <div className="row ml-3 justify-content-between text-capitalize" key={i}>
                    <div
                        data-for="ptah_id22"
                        data-tip={`Available: ${ex.available} <br/>
                                   Locked: ${ex.locked}`}
                    >
                        <p className="text-gray-4 mb-1">{ex.name} spot</p>
                        {formatMoney(ex.available)}
                    </div>

                    <div
                        data-for="ptah_id22"
                        data-tip={`Balance: ${ex.perpetual_balance} <br/>
                                   Position: ${ex.perpetual_position?.positionAmt || 0} <br/>
                                   Virtual balance: ${ex.perpetual_virtual_balance}`}
                    >
                        <p className="text-gray-4 mb-1">{ex.name} perpetual</p>
                        {formatMoney(ex.perpetual_balance)}
                    </div>

                    <div
                        data-for="ptah_id22"
                        data-tip={`Borrowed: ${ex?.margin_balance?.borrowed || 0} <br/>
                                   Free: ${ex?.margin_balance?.free || 0} <br/>
                                   Interest: ${ex?.margin_balance?.locked || 0} <br/>
                                   Locked: ${ex?.margin_balance?.interest || 0} <br/>
                                   Net asset: ${ex?.margin_balance?.net_asset}`}
                    >
                        <p className="text-gray-4 mb-1">margin borrowed</p>
                        {formatMoney(ex?.margin_balance?.borrowed || 0)}
                    </div>

                    <div className={tokenInfo.info.rebalance ? "" : "d-none"}>
                        <button
                            disabled={isLoading}
                            type="button"
                            className="btn btn-success mr-3 mt-1"
                            onClick={() => triggerRebalanceForSymbol(tokenInfo.info.id)}
                        >
                            {isLoading ? loadingImg : ""} Rebalance
                        </button>
                    </div>
                </div>
            ))}
            <ReactTooltip id="ptah_id22" html={true} />
        </div>
    );

    return <Modal title={modalTitle} id={modalId} content={modalContent} size="lg" className="p-0" />;
};

export default InfoModal;
