import CONSTANTS from "../../constants";
import { isAddress, isEmptyAddress } from "../../utils/validator";
const FETCH_TIMEOUT = 2000;

export default class BaseEthereumProvider {
    version() {
        return this.rpc.version.api;
    }

    getAddressBalanceFromBlockchain(address) {
        return new Promise((resolve, reject) => {
            this.rpc.eth.getBalance(address).then((balance) => {
                if (balance != null) {
                    resolve(balance);
                }
            });
        }).catch((err) => {
            console.log("getAddressBalanceFromBlockchain", err);
            return undefined;
        });
    }

    async getSanityOperatorsFromReserve(reserveAddr) {
        try {
            const reserveContract = new this.rpc.eth.Contract(CONSTANTS.ABIS.KYBER_RESERVE, reserveAddr);
            const sanityAddr = await reserveContract.methods.sanityRatesContract().call();

            if (!isAddress(sanityAddr)) {
                console.error(`Sanity Address (${sanityAddr}) is not address`);
                return [];
            }

            if (isEmptyAddress(sanityAddr)) {
                console.error(`Sanity Address (${sanityAddr}) is empty`);
                return [];
            }

            const sanityContract = new this.rpc.eth.Contract(CONSTANTS.ABIS.SANITY_RATE, sanityAddr);
            return sanityContract.methods.getOperators().call();
        } catch (err) {
            return [];
        }
    }

    getAllRateEachToken(sources, dests, quantity) {
        return Promise.all(
            quantity.map((qty, i) => {
                return new Promise((_resolve, _reject) => {
                    this.wrapperContract.methods
                        .getReserveRate(
                            this.reserveAddress,
                            sources[i],
                            dests[i]
                        )
                        .call()
                        .then((_result) => _resolve(_result))
                        .catch((_err) =>
                            _resolve({
                                0: "0",
                                1: "0",
                                expectedPrice: "0",
                                slippagePrice: "0",
                            })
                        );
                });
            })
        );
    }

    getAllRate(sources, dests, quantity) {
        var dataAbi = this.wrapperContract.methods.getReserveRate(this.reserveAddress, sources, dests).encodeABI();
        return new Promise((resolve, rejected) => {
            this.rpc.eth
                .call({
                    to: this.wrapperAddress,
                    data: dataAbi,
                })
                .then((data) => {
                    try {
                        var dataMapped = this.rpc.eth.abi.decodeParameters(
                            [
                                {
                                    type: "uint256[]",
                                    name: "expectedPrice",
                                },
                                {
                                    type: "uint256[]",
                                    name: "slippagePrice",
                                },
                            ],
                            data
                        );
                        resolve(dataMapped);
                    } catch (e) {
                        console.log(e);
                        resolve([]);
                    }
                })
                .catch((err) => {
                    console.log("GET request error");
                    resolve([]);
                });
        });
    }

    async getRate(isEthToken, tokensObj, arrayToken, weiAmount) { }

    processTokenRateData(isEthToken, tokensObj, result) {
        var returnData = [];
        Object.keys(tokensObj)
            .filter((i) => tokensObj[i].set_rate !== "not_set")
            .map((tokenId, i) => {
                if (isEthToken) {
                    returnData.push({
                        source: "ETH",
                        dest: tokenId,
                        rate: result.expectedPrice[i],
                        minRate: result.slippagePrice[i],
                    });
                } else {
                    returnData.push({
                        source: tokenId,
                        dest: "ETH",
                        rate: result.expectedPrice[i],
                        minRate: result.slippagePrice[i],
                    });
                }
            });
        return returnData;
    }

    processEachTokenRateData(isEthToken, tokensObj, results) {
        var returnData = [];
        Object.keys(tokensObj)
            .filter((i) => tokensObj[i].set_rate !== "not_set")
            .map((tokenId, i) => {
                if (isEthToken) {
                    returnData.push({
                        source: "ETH",
                        dest: tokenId,
                        rate: results[i][0],
                        minRate: results[i][1],
                    });
                } else {
                    returnData.push({
                        source: tokenId,
                        dest: "ETH",
                        rate: results[i][0],
                        minRate: results[i][1],
                    });
                }
            });
        return returnData;
    }

    sendToOtherEndpoint(url, method, data) {
        var fetchParams = {
            // headers: {
            //     'Accept': 'application/json, text/plain, */*',
            //     'Content-Type': 'application/json'
            // }
        };
        if (method == "POST") {
            fetchParams.method = "POST";
            fetchParams.body = data;
        }
        return new Promise((resolve, reject) => {
            let didTimeOut = false;
            const timeout = setTimeout(function () {
                didTimeOut = true;
                reject(new Error("Request timed out: " + url));
            }, FETCH_TIMEOUT);

            fetch(url, fetchParams)
                .then((response) => {
                    if (!response.ok) {
                        reject(response.statusText);
                    } else {
                        resolve(response.json());
                    }
                })
                .catch((err) => {
                    if (didTimeOut) return;
                    reject({
                        error: err,
                        url,
                    });
                });
        });
    }
}
