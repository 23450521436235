import moment from "moment";
import { RootState } from "reducers";
import { useSelector } from "react-redux";
import { useState } from "react";
interface IProps {
    value: string;
}

const TimestampCell: React.FC<IProps> = ({ value }) => {
    const timezone = useSelector((state: RootState) => state.global.timezone);
    const [copied, setCopied] = useState(false);
    const m = moment(value);
    return (
        <div className="d-flex">
            <div
                style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {m.utcOffset(timezone.offset || 0).format("YYYY-MM-DD, HH:mm:ss")}{" "}
                <span className="small">({m.fromNow()})</span>
            </div>
            <div>
                <i
                    className={copied ? "fa fa-check pointer text-success mr-3" : "fa fa-copy pointer mr-3"}
                    onClick={() => {
                        setCopied(true);
                        navigator.clipboard.writeText(m.unix().toString());
                        setTimeout(() => setCopied(false), 2000);
                    }}
                />
            </div>
        </div>
    );
};

export default TimestampCell;
