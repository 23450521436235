import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import md5 from "md5";
import cx from "classnames";

import { RootState } from "reducers";

import Editor from "./Editor";

import { setupGlobalFunctions } from "./utils";

setupGlobalFunctions();

const AdminConfig: React.FC<{ className?: string }> = ({ className }) => {
    const coreKeyId = useSelector((state: RootState) => state.global.hostData.CORE.id);
    const [isOpen, setOpen] = useState(false);

    const gravatar = useMemo(() => {
        return coreKeyId ? md5(coreKeyId) : null;
    }, [coreKeyId]);

    const toggleOpen = () => {
        setOpen((o) => !o);
    };

    return (
        <div className={cx(className, isOpen ? "nav-item dropdown admin active" : "nav-item dropdown admin")}>
            <div className="dropdown-toggle non-caret pointer" onClick={toggleOpen}>
                <span className="admin d-none d-sm-inline">Admin</span>

                {gravatar ? (
                    <img
                        className="avatar"
                        src={`https://www.gravatar.com/avatar/${gravatar}?d=identicon&f=y`}
                        width="25"
                        height="25"
                        alt=""
                    />
                ) : (
                    <i className="fa fa-user-circle user-icon" />
                )}
            </div>

            {isOpen ? <Editor toggleOpen={toggleOpen} /> : null}
        </div>
    );
};

export default styled(AdminConfig)`
    position: relative;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .admin {
        margin-right: 6px;
    }
`;
