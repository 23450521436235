import React from "react";
// import TableOtherActivities from './Table/tableOtherActivities.component'
import FilterOrdersView from "./filterOrders.view";

const OdersView = (props) => {
    return (
        <section className="container-fluid">
            <nav className="px-0 py-4 nav-sub">
                <h4 className="page-tile">Open Orders</h4>
            </nav>

            <div className="row">
                <div className="col-sm-8">
                    <div className="panel panel-table">
                        <div className="panel-header">{props.tableActivities}</div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <FilterOrdersView
                        filterExchange={props.filterExchange}
                        filterPair={props.filterPair}
                        filterType={props.filterType}
                        filterSide={props.filterSide}
                    />
                </div>
            </div>
        </section>
    );
};

export default OdersView;
