import styled from "styled-components";

interface IProps {
    className?: string;
}

const Icon: React.FC<IProps> = ({ className }) => {
    return (
        <div className={className}>
            <img src="./bebop.png" height={17} className="m-0" />
            <svg viewBox="0 0 181 34" xmlns="http://www.w3.org/2000/svg">
                <text className="text" x="5" y="23" transform="matrix(1.023067, 0, 0, 1, -0.855269, 0)">
                    Bebop
                </text>
            </svg>
        </div>
    );
};
export default styled(Icon)`
    > svg {
        color: black;
        height: 23px;
    }
    .text {
        fill: rgb(0, 0, 0);
        font-family: Courier New;
        font-size: 19px;
        white-space: pre;
    }
`;
