import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntegrationName } from "services/const";

interface IState {
    tokenDataById: Record<number | string, { [key: string]: any }>;
    error?: string;
    pendingActivities?: {};
    totalAllQty?: number;
    selectedTokenId?: string; // this id is key of tokenDataById (not the tokenId inside tokenInfo)
}

const initialState: IState = {
    tokenDataById: {},
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        getTokenDataRequest: () => {},
        getTokenDataSuccess: (
            state,
            action: PayloadAction<{ data: Record<number | string, {}>; pendingActivities: {}; totalAllQty: number }>
        ) => {
            const { data, pendingActivities, totalAllQty } = action.payload;

            // To save previous zeroX rates
            // To avoid glitches while fetching new data
            Object.keys(data).forEach((key) => {
                state.tokenDataById[key] = {
                    ...state.tokenDataById[key],
                    ...data[key],
                };
            });

            state.pendingActivities = pendingActivities;
            state.totalAllQty = totalAllQty;
        },
        getTokenDataFailure: (state) => {
            state.error = "Cannot get token data";
        },

        getPricingFromIntegrationRequest: () => {},

        getPricingFromIntegrationSuccess: (
            state,
            action: PayloadAction<
                Array<{
                    assetID: number;
                    quoteID: number;
                    integration: IntegrationName;
                    buyRate: number;
                    sellRate: number;
                }>
            >
        ) => {
            action.payload.forEach(({ assetID }) => {
                state.tokenDataById[assetID].ratesByIntegration = [];
            });

            action.payload.forEach(({ assetID, quoteID, integration, buyRate, sellRate }) => {
                const quote = state.tokenDataById[quoteID].info.symbol;
                state.tokenDataById[assetID].ratesByIntegration.push({
                    quote,
                    quoteID,
                    integration,
                    exchange: integration,
                    symbol: `${state.tokenDataById[assetID].info.symbol}/${quote}`,
                    afpBid: buyRate,
                    afpAsk: sellRate,
                });
            });
        },

        getPricingFromIntegrationFailure: () => {},

        setSelectedTokenId: (state, action: PayloadAction<string>) => {
            state.selectedTokenId = action.payload;
        },
    },
});

export default dashboardSlice;
