import { SUPPORTED_HOST } from "../constants";

export default {
    getPriceBaseQuotePair(base, quote) {
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("prices/" + base + "/" + quote, "GET", null)
            .catch(this.handleNetErr);
    },

    getAuthData() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/authdata", "GET", null, null, false)
            .catch(this.handleNetErr);
    },

    async getPriceAllBaseQuotePair(tokenMap) {
        let feeds = []
        let rates = []
        Object.values(tokenMap).forEach(token => {
            if (token.is_enabled && token.exchanges) {
                token.exchanges.forEach(exchange => {
                    if (exchange.exchange_id == 1 && exchange.trading_pairs) {
                        exchange.trading_pairs.forEach(pair => {
                            feeds.push(`${pair.base_symbol}-${pair.quote_symbol}`.toLowerCase())
                            rates.push({
                                base: pair.base,
                                quote: pair.quote,
                                exchange: exchange.exchange_id,
                            })
                        })
                    }
                })
            }
        })

        let queries = {
            fmt: "json",
            limit: 50,
            feeds: feeds.join(","),
        }

        let res = await this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/market-pricing/depth/binance", "GET", null, queries, false)
            .catch(this.handleNetErr);

        if (res.success) {
            res.data = res.data.map((datum, id) => {
                return { ...datum, ...rates[id] }
            })
        }

        return res
    },

    setRate(sources, dests, rates, expiries) {
        const postData = {
            sources,
            dests,
            rates,
            expiries,
        };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("setrates", "POST", postData)
            .catch(this.handleNetErr);
    },

    trade(exchangeId, base, quote, amount, rate, type) {
        const postData = {
            base,
            quote,
            amount,
            rate,
            type,
        };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("trade/" + exchangeId, "POST", postData)
            .catch(this.handleNetErr);
    },

    cancelOrder(exchange, base, quote, orderId) {
        const postData = {
            base,
            quote,
            order_id: orderId,
        };

        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("cancelorder/" + exchange, "POST", postData)
            .catch(this.handleNetErr);
    },

    getAllKyberRate() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/getrates", "GET", null, null, false)
            .catch(this.handleNetErr);
    },
};
