import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const LinkConfigs = [
    {
        linkTo: "/dashboard",
        iconClassName: "fa fa-home",
        title: "Dashboard",
    },
    {
        linkTo: "/orders",
        iconClassName: "fa fa-list-alt",
        title: "Open Orders",
    },

    {
        linkTo: "/activities",
        iconClassName: "fa fa-list",
        title: "Activities",
    },
    {
        linkTo: "/trade_log",
        iconClassName: "fa fa-file-text-o",
        title: "Trade Log",
    },
    {
        linkTo: "/configuration",
        iconClassName: "fa fa-cog",
        title: "Configuration",
    },
    {
        linkTo: "/lab",
        iconClassName: "fa fa-flask",
        title: "Lab",
    },
    {
        linkTo: "/quote_0x",
        iconClassName: "fa fa-database",
        title: "Quote 0x",
    },
    {
        linkTo: "/integrations",
        iconClassName: "fa fa-cubes",
        title: "integrations",
    },
];

const getBackgroundColorByNetworkId = (networkId: number): string => {
    const DEFAULT_COLOR = "#2e3957";

    const navBarColors: Record<number, string> = {
        1: "#2e3957",
        56: "#F0B90B",
        97: "#F0B90B",
    };

    return navBarColors[networkId] || DEFAULT_COLOR;
};

const Navigator: React.FC = () => {
    // @ts-ignore
    const networkId = useSelector((state) => state.global.networkId);

    const backgroundColor = getBackgroundColorByNetworkId(networkId);

    return (
        <nav className="navbar navbar-dark navbar-expand-md nav-navigation" style={{ backgroundColor }}>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse " id="navbarNav">
                <ul className="navbar-nav d-flex justify-content-center m-auto">
                    {LinkConfigs.map((config) => (
                        <li key={config.linkTo} className="nav-item">
                            <NavLink activeClassName="active" className="nav-link" to={config.linkTo}>
                                <i className={config.iconClassName} /> {config.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default Navigator;
