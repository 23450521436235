import { useState } from "react";
import { useSelector } from "react-redux";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import LabView from "./lab.view";

const Lab = () => {
    const apiService = useSelector((state) => state.global.apiService);

    const [useCurrentCore, set_useCurrentCore] = useState(true);
    const [endPoint, set_endPoint] = useState("");
    const [keyString, set_keyString] = useState("");
    const [keyId, set_keyId] = useState("");
    const [editorData, set_editorData] = useState({});
    const [requestResponse, set_requestResponse] = useState("");
    const [type, set_type] = useState("GET");

    const onChangeInput = (evt, key) => {
        switch (key) {
            case "endPoint":
                set_endPoint(evt.target.value);
                break;
            case "keyId":
                set_keyId(evt.target.value);
                break;
            case "keyString":
                set_keyString(evt.target.value);
                break;
        }
    };

    const onSendRequest = async () => {
        set_requestResponse("");
        try {
            let iEndPoint = endPoint;
            if (!iEndPoint.startsWith("http")) {
                const host = apiService.provider?.hosts?.CORE?.hostUrl || "";
                if (iEndPoint.startsWith("/")) iEndPoint = `${host}${iEndPoint}`;
                else iEndPoint = `${host}/${iEndPoint}`;
            }

            let requestBody = JSON.stringify(editorData);
            if (type == "GET" || type == "DELETE") {
                requestBody = null;
            }

            const urlObject = new URL(iEndPoint);
            const queryParams = Object.fromEntries(new URLSearchParams(urlObject.search).entries());
            let result = "";
            if (useCurrentCore) {
                result = await apiService.provider.call("newSigningSend")(
                    urlObject.pathname,
                    type,
                    requestBody,
                    queryParams,
                    false,
                    urlObject.origin
                );
            } else {
                result = await apiService.provider.call("newSigningSend")(
                    urlObject.pathname,
                    type,
                    requestBody,
                    queryParams,
                    false,
                    urlObject.origin,
                    keyId,
                    keyString,
                    true
                );
            }
            set_requestResponse(result);
        } catch (err) {
            set_requestResponse(err);
        }
    };

    const renderResult = () => {
        return requestResponse ? (
            <Editor
                value={requestResponse}
                allowedModes={["tree", "view", "form", "code", "text"]}
                htmlElementProps={{ style: { height: "calc(100vh - 325px)" } }}
            />
        ) : (
            <div />
        );
    };

    const renderEditListToken = () => (
        <Editor
            value={editorData}
            mode="code"
            allowedModes={["tree", "view", "form", "code", "text"]}
            htmlElementProps={{ style: { height: "calc(100vh - 500px)" } }}
            onChange={set_editorData}
        />
    );

    const onChangeType = (e) => set_type(e.target.value);

    return (
        <LabView
            renderEditListToken={renderEditListToken}
            renderResult={renderResult}
            useCurrentCore={useCurrentCore}
            onCheck={set_useCurrentCore}
            onChangeInput={onChangeInput}
            endPoint={endPoint}
            keyString={keyString}
            keyId={keyId}
            onSendRequest={onSendRequest}
            onChangeType={onChangeType}
            type={type}
        />
    );
};

export default Lab;
