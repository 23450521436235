import React from "react";

import { Switch } from "../Share";

const FilterOrdersView = (props) => {
    // const options = [
    //   { value: 'chocolate', label: 'Chocolate' },
    //   { value: 'strawberry', label: 'Strawberry' },
    //   { value: 'vanilla', label: 'Vanilla' }
    // ]

    return (
        <div className="panel">
            <div className="panel-header">
                <h4 className="panel-title">Filter</h4>
            </div>

            <div className="panel-body">
                <div className="panel-row">
                    <p className="text-dark my-2">Exchange</p>
                    <div className="mb-3">
                        <div className="row mb-3">{props.filterExchange}</div>
                    </div>
                </div>
                <div className="panel-row">
                    <p className="text-dark my-2">Pair</p>
                    {props.filterPair}
                </div>
                <div className="panel-row">
                    <p className="text-dark my-2">Type</p>
                    <div className="mb-3">
                        <div className="row mb-3">{props.filterType}</div>
                    </div>
                </div>

                <div className="panel-row">
                    <p className="text-dark my-2">Side</p>
                    <div className="mb-3">
                        <div className="row mb-3">{props.filterSide}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterOrdersView;
