import React, { Component } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { caculateTotalVolumeInSelectedDate } from "../../../services/utils/converter";
class VolumeChart extends Component {
    constructor(props) {
        super(props);

        this.fromTime;
        this.minuteLimit = 60 * 60 * 1000 * 24; // 1 day
        this.hourLimit = 60 * 60 * 1000 * 24 * 180; // 180 days
        this.dayLimit = 60 * 60 * 1000 * 24 * 364 * 3; // 3 years
        // this.isNew
        this.intervalFetchData;
        this.state = {
            freq: "d",
            tabIndex: 1,
            sumVolume: 0,
        };
        this.graphPeriod = "DD";

        this.updateDataFunc = this.props.updateDataFunc;

        // this.dataProviderChecked
    }

    componentDidMount() {
        this.updateData(true, false);
    }

    // componentWillReceiveProps(nextProps){
    //   this.dataProviderChecked = false
    // }

    componentWillUnmount() {
        // TODO: clearInterval(this.intervalFetchData)
    }

    changeFreq = (e) => {
        this.setState(
            {
                freq: e.target.value,
            },
            () => {
                // this.isNew = true
                this.updateData(true, false);
            }
        );
    };

    updateData(isNew, isZoom) {
        let fromTime = this.fromTime;
        let current = Date.now();
        if (isNew || !this.fromTime) {
            switch (this.state.freq) {
                case "m":
                    fromTime = current - this.minuteLimit;
                    break;
                case "h":
                    fromTime = current - this.hourLimit;
                    break;
                case "d":
                    fromTime = current - this.dayLimit;
                    break;
                // default:
                //     code block
            }
        }
        this.updateDataFunc(isNew, isZoom, this.state.freq, fromTime, current);
    }

    caculateTimeFreq(timeRange) {
        if (timeRange < this.minuteLimit) return "m";
        if (timeRange < this.hourLimit) return "h";
        return "d";
    }

    // zoomChange(startTime, endTime){
    //   let timeRange = endTime - startTime

    //   this.setState({
    //     freq: this.caculateTimeFreq(timeRange)
    //   }, () => {
    //     this.updateDataFunc(false, true, this.state.freq, startTime, endTime)
    //   })
    // }

    render() {
        let totalVolumeLabel = document.getElementById("totalVolume");

        switch (this.state.freq) {
            case "h":
                this.graphPeriod = "hh";
                break;
            case "m":
                this.graphPeriod = "mm";
                break;
            default:
                this.graphPeriod = "DD";
        }

        let config = {
            type: "serial",
            theme: "light",
            dataProvider: [...this.props.data],
            // "dataProviderChecked": this.dataProviderChecked,
            gridAboveGraphs: true,
            categoryField: "date",
            // "dataDateFormat": "YYYY-MM-DD",
            pathToImages: "https://cdn.amcharts.com/lib/3/images/",
            columnWidth: 1,
            valueAxes: [
                {
                    id: "v1",
                    gridColor: "#FFFFFF",
                    // "gridAlpha": 0,
                    // "dashLength": 0,
                    position: "left",
                    // "totalText": "[[total]]"
                },
                {
                    id: "v2",
                    gridColor: "#FFFFFF",
                    // "gridAlpha": 0.2,
                    // "dashLength": 0,
                    // "gridAlpha": 0,
                    offset: 50,
                    position: "right",
                },
                {
                    id: "v3",
                    gridColor: "#FFFFFF",
                    // "gridAlpha": 0,
                    // "dashLength": 0,
                    position: "right",
                },
            ],

            graphs: this.props.graphs,

            applyGapValue: 0,
            chartScrollbar: {
                graph: "g1",
                gridAlpha: 0,
                color: "#888888",
                scrollbarHeight: 55,
                backgroundAlpha: 0,
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: "#888888",
                graphFillAlpha: 0,
                autoGridCount: true,
                selectedGraphFillAlpha: 0,
                graphLineAlpha: 1,
                graphLineColor: "#c2c2c2",
                selectedGraphLineColor: "#888888",
                selectedGraphLineAlpha: 1,
            },

            categoryAxis: {
                parseDates: true,
                // "dashLength": 1,
                // "minorGridEnabled": true,
                // "guides": warningGuilds,
                minPeriod: this.graphPeriod,
                dateFormats: [
                    {
                        period: "fff",
                        format: "NN:SS.QQQ",
                    },
                    {
                        period: "ss",
                        format: "NN:SS",
                    },
                    {
                        period: "mm",
                        format: "JJ:NN",
                    },
                    {
                        period: "hh",
                        format: "JJ:NN",
                    },
                    {
                        period: "DD",
                        format: "MMM DD",
                    },
                    {
                        period: "WW",
                        format: "MMM DD",
                    },
                    {
                        period: "MM",
                        format: "MMM",
                    },
                    {
                        period: "YYYY",
                        format: "YYYY",
                    },
                ],
            },
            legend: {
                spacing: 80,
                valueWidth: 130,
            },

            chartCursor: {
                fullWidth: true,
                valueBalloonsEnabled: true,
                categoryBalloonDateFormat: "MMM DD, JJ:NN:SS",
                // "categoryBalloonText": "abc [[index]] [[category]]"
            },
            listeners: [
                {
                    event: "zoomed",
                    method: (e) => {
                        let sumVolume = caculateTotalVolumeInSelectedDate(this.props.data, e.startIndex, e.endIndex);
                        if (totalVolumeLabel) totalVolumeLabel.innerHTML = sumVolume;
                    },
                },
                {
                    event: "dataUpdated",
                    method: (e) => {
                        if (
                            e.chart.dataProviderChecked ||
                            typeof e.chart.applyGapValue === "undefined" ||
                            typeof e.chart.categoryAxis === "undefined" ||
                            e.chart.categoryAxis.parseDates !== true
                        ) {
                            e.chart.dataProviderChecked = false;
                            return;
                        }

                        AmCharts.applyGapValue.processData(e.chart, true);
                    },
                },

                {
                    event: "clickGraphItem",
                    method: (e) => {
                        let rawData = e.item.dataContext;
                        let rawText = "";
                        Object.keys(rawData).map((key, index) => {
                            let value = Object.values(rawData)[index];
                            if (typeof value === "object") return;
                            rawText += key + " : " + value + "\n";
                        });

                        alert(rawText);
                    },
                },
                {
                    event: "dataUpdated",
                    method: (e) => {
                        this.fromTime =
                            this.props.data && this.props.data.length
                                ? +this.props.data[this.props.data.length - 1].timeStamp + 1
                                : null;
                    },
                },
            ],
        };

        AmCharts.applyGapValue = {
            getDate: function (val, format) {
                if (typeof val === "string" && format !== undefined) {
                    // try parse date from string using dataDateFormat
                    return AmCharts.stringToDate(val, format);
                } else {
                    // last resort: dump everything into Date constructor
                    // and let browser handle it
                    return new Date(val);
                }
            },
            formatDate: function (date, sampleFormat, format) {
                if (typeof sampleFormat === "string" && format !== undefined) {
                    return AmCharts.formatDate(date, format);
                } else if (typeof sampleFormat === "number") {
                    return date.getTime();
                }
                return date;
            },
            getPeriod: function (minPeriod) {
                minPeriod = minPeriod || "DD";
                var period = AmCharts.extractPeriod(minPeriod);
                return AmCharts.getPeriodDuration(period.period, period.count);
            },
            addPeriod: function (date, minPeriod, count) {
                minPeriod = minPeriod || "DD";
                var period = AmCharts.extractPeriod(minPeriod);
                var newDate = new Date(date);
                switch (period.period) {
                    case "YYYY":
                        newDate.setFullYear(newDate.getFullYear() + count);
                        break;
                    case "MM":
                        newDate.setFullYear(newDate.getFullYear(), newDate.getMonth() + count);
                        break;
                    case "DD":
                        newDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + count);
                        break;
                    case "hh":
                        newDate.setHours(newDate.getHours() + count);
                        break;
                    case "mm":
                        newDate.setHours(newDate.getHours(), newDate.getMinutes() + count);
                        break;
                    case "ss":
                        newDate.setHours(newDate.getHours(), newDate.getMinutes(), newDate.getSeconds() + count);
                        break;
                    case "fff":
                        newDate.setTime(newDate.getTime() + count);
                        break;
                }
                return newDate;
            },
            processData: function (chart, validateData) {
                // get references
                var currentData = chart.dataProvider;
                var categoryField = chart.categoryField;
                var dataDateFormat = chart.dataDateFormat;

                // check if data set is maybe empty
                if (!AmCharts.ifArray(currentData) || currentData.length === 0) return;

                // get first and last dates, min period in milliseconds
                var firstDate = AmCharts.applyGapValue.getDate(currentData[0][categoryField], dataDateFormat),
                    lastDate = AmCharts.applyGapValue.getDate(
                        currentData[currentData.length - 1][categoryField],
                        dataDateFormat
                    ),
                    sampleFormat = currentData[0][categoryField];

                /**
                 * Build a new set of data
                 */
                var data = [];
                for (var i = 0; i < currentData.length; i++) {
                    // add current data point
                    data.push(currentData[i]);

                    // check if maybe we have a gap
                    if (i + 1 < currentData.length) {
                        var nextPoint = currentData[i + 1];
                        var nextDate = AmCharts.applyGapValue.getDate(nextPoint[categoryField]);
                        var nextSupposedDate = AmCharts.applyGapValue.addPeriod(
                            AmCharts.applyGapValue.getDate(currentData[i][categoryField]),
                            chart.categoryAxis.minPeriod,
                            1
                        );
                        while (nextSupposedDate < nextDate) {
                            var dp = {};
                            dp[categoryField] = AmCharts.applyGapValue.formatDate(
                                nextSupposedDate,
                                sampleFormat,
                                chart.dataDateFormat
                            );
                            for (var g = 0; g < chart.graphs.length; g++) {
                                dp[chart.graphs[g].valueField] = chart.applyGapValue;
                            }
                            data.push(dp);
                            nextSupposedDate = AmCharts.applyGapValue.addPeriod(
                                nextSupposedDate,
                                chart.categoryAxis.minPeriod,
                                1
                            );
                        }
                    }
                }
                // update dataProvider
                chart.dataProviderChecked = true;
                chart.dataProvider = data;
                // should we revalidate the data?
                if (validateData) chart.validateData();
            },
        };

        return (
            // <div>
            //   <div>
            //     <h4>Frequence: </h4>
            //     <button type="button" className={this.state.freq == "M" ? "btn-highlight p-2 m-1" : "p-2 m-1"} onClick={() => this.changeFreq("M")}>Minute</button>
            //     <button type="button" className={this.state.freq == "H" ? "btn-highlight p-2 m-1" : "p-2 m-1"} onClick={() => this.changeFreq("H")}>Hour</button>
            //     <button type="button" className={this.state.freq == "D" ? "btn-highlight p-2 m-1" : " p-2 m-1"} onClick={() => this.changeFreq("D")}>Day</button>
            //   </div>
            //   <div>
            //     <h5>Total volumes of selected time range: </h5>
            //     <div id="totalVolume" className="info">...</div>
            //   </div>
            //   <AmCharts.React
            //     id="abcas"
            //     style={{
            //       width: "100%",
            //       height: "400px"
            //     }}
            //     options={config}
            //   />
            // </div>

            <React.Fragment>
                <div className="ReactTable-header d-flex align-items-center mb-3">
                    <div className="title">{this.props.title}</div>
                    <div className="ml-auto text-size-s-1">
                        <div className="d-inline-block ml-4">
                            <span className="mr-2 text-gray-4">FREQUENCE</span>
                            <select
                                className="form-control d-inline-block w-auto"
                                onChange={this.changeFreq}
                                value={this.state.freq}
                            >
                                {/* <option value='m'>Minute</option> */}
                                <option value="h">Hour</option>
                                <option value="d">Day</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    {(!this.props.data || !this.props.data.length) && (
                        <div className="text-dark my-2">
                            Loading... <i className="fa fa-refresh fa-spin" />
                        </div>
                    )}
                    <AmCharts.React
                        id="abcas"
                        style={{
                            width: "100%",
                            height: "400px",
                        }}
                        options={config}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default VolumeChart;
