import TokenCell from "../TokenCell";
import ConfigTable from "./table";

const SetTarget = () => (
    <div className="panel panel-table">
        <ConfigTable
            title="SET TARGET"
            assetChild="target"
            pendingUrl="/v3/setting-change-target"
            fields={{
                symbol: {
                    title: "Asset",
                    cell: (row) => {
                        return <TokenCell symbol={row.value} />;
                    },
                    const: true,
                },
                total: {
                    title: "Total Target",
                },
                reserve: {
                    title: "Reserve Target",
                },
                buy_threshold: {
                    title: "Buy Threshold",
                },
                rebalance_threshold: {
                    title: "Rebalance Threshold",
                },
                transfer_threshold: {
                    title: "Transfer Threshold",
                },
                min_withdraw_threshold: {
                    title: "Min Withdraw Threshold",
                },
            }}
        />
    </div>
);

export default SetTarget;
