import tokenPairListingSlice from "components/Configuration/TokenPairListing/tokenPairListing.slice";
import dashboardSlice from "components/Dashboard/dashboard.slice";
import otherActivitiesSlice from "components/OtherActivities/otherActivities.slice";
import quote0xSlice from "components/QuoteZeroX/quote0x.slice";
import toastSlice from "components/_base/Toast/slice";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import global from "./global.reducer";

console.warn("TODO: move reserveAddr out of global.reducer");

const rootReducer = combineReducers({
    global: persistReducer(
        {
            key: "global",
            storage,
            blacklist: ["apiService", "reserveAddr"],
        },
        global
    ),
    [tokenPairListingSlice.name]: tokenPairListingSlice.reducer,
    [dashboardSlice.name]: dashboardSlice.reducer,
    [quote0xSlice.name]: quote0xSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [otherActivitiesSlice.name]: otherActivitiesSlice.reducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
