import React from "react";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import moment from "moment";

const TransferDetailView = ({ data, showSpotToMargin }) => {
    const timezone = useSelector((state) => state.global.timezone);

    return (
        <div className="subrow">
            <div className="row">
                <p className="col offset-2 semi-b">Destination: {data.destination}</p>
            </div>
            <div className="row">
                <div className="col-2 text-right text-gray-4">ID:</div>
                <div className="col-10 text-dark">{data.id}</div>
                <div className="col-2 text-right text-gray-4">Amount:</div>
                <div className="col-10 text-dark">{data.params.amount}</div>
                <div className="col-2 text-right text-gray-4">Token:</div>
                <div className="col-10 text-dark">{data.params.asset}</div>
                {showSpotToMargin && <div className="col-2 text-right text-gray-4">Spot To Margin:</div>}
                {showSpotToMargin && (
                    <div className="col-10 text-dark">{JSON.stringify(data.params.spot_to_margin)}</div>
                )}

                <div className="col-2 text-right text-gray-4">Timestamp:</div>
                <div className="col-10 text-dark">
                    {data.timestamp} (
                    {moment(+data.timestamp)
                        .utcOffset(timezone.offset || 0)
                        .format("YYYY-MM-DD, HH:mm:ss")}{" "}
                    {timezone.label})
                </div>
                {data.result.error && (
                    <React.Fragment>
                        <div className="col-2 text-right text-gray-4">Error:</div>
                        <div className="col-10 text-dark">{JSON.stringify(data.result.error)}</div>
                    </React.Fragment>
                )}
                {data.result.status_error && (
                    <React.Fragment>
                        <div className="col-2 text-right text-gray-4">Status Error:</div>
                        <div className="col-10 text-dark">{data.result.status_error}</div>
                    </React.Fragment>
                )}
            </div>

            <p>
                <a
                    className="btn btn-light"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    <i className="fa fa-expand" aria-hidden="true" />
                    <i> Click to view more details</i>
                </a>
            </p>
            <div className="collapse" id="collapseExample">
                <div className="card card-body">
                    <ReactJson src={data} name={false} />
                </div>
            </div>
        </div>
    );
};

export default TransferDetailView;
