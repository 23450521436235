import PendingActivity from "./PendingActivity/pendingActivity.component";
import PricingOperator from "./PricingOperator/pricingOperator.component";
import AccountBalance from "./AccountBalance";

import TokenInfo from "./TokenInfo";

const Dashboard: React.FC = () => {
    return (
        <section className="panel">
            <nav className="panel-header">
                <h4 className="panel-title">Dashboard</h4>
            </nav>
            <div className="panel-body">
                <div className="row">
                    <div className="col-sm-8">
                        <PendingActivity />
                        <TokenInfo />
                    </div>
                    <div className="col-sm-4">
                        <PricingOperator />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <AccountBalance />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;
