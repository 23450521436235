import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TokenCell from "../TokenCell";
import ConfigTable from "../ConfigTable/trading_pairs_table";
import { getListExchange } from "services/configuration";

const TradingPairs = () => {
    const apiService = useSelector((store) => store.global.apiService);
    const [exchanges, setExchanges] = useState({});

    useEffect(() => getExchange(), []);

    const getExchange = async () => {
        const result = await getListExchange(apiService);
        if (!result.success) return;
        if (result && result.data) {
            const exchangeMap = {};
            result.data.forEach((ex) => (exchangeMap[ex.id] = ex));
            setExchanges(exchangeMap);
        }
    };

    return (
        <div className="panel panel-table">
            <ConfigTable
                title="TRADING PAIRS"
                updateType="update_trading_pair"
                assetChild="trading_pairs"
                pendingUrl="/v3/setting-change-tpair"
                fields={{
                    base_symbol: {
                        title: "Base",
                        cell: (row) => {
                            return <TokenCell symbol={row.value} />;
                        },
                        const: true,
                    },
                    quote_symbol: {
                        title: "Quote",
                        cell: (row) => {
                            return <TokenCell symbol={row.value} />;
                        },
                        const: true,
                    },
                    exchange_id: {
                        title: "Exchange",
                        const: true,
                        cell: (row) => (
                            <div>
                                [{row.value}] {exchanges[row.value]?.name || ""}
                            </div>
                        ),
                    },
                    stale_threshold: {
                        title: "Stale Threshold",
                    },
                    fee: {
                        title: "Fee",
                    },
                    fee_type: {
                        title: "Fee Type",
                        type: "select",
                        options: ["override", "default"],
                    },
                }}
            />
        </div>
    );
};
export default TradingPairs;
