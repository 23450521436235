import { useDispatch, useSelector } from "react-redux";

import { RootState } from "reducers";

import { setTimeZone } from "actions/globalActions";
import { TimezonePicker } from "../Share";
import { timezones } from "./consts";

const TimeZoneSelector: React.FC = () => {
    const dispatch = useDispatch();
    const timezone = useSelector((state: RootState) => state.global.timezone);

    return (
        <div className="nav-item dropdown pointer">
            <div
                className="nav-link dropdown-toggle"
                id="timezoneDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <span className="d-none d-sm-inline">Timezone:</span>{" "}
                {timezone && timezone.offset ? "GMT " + timezone.offset : "UTC"}
            </div>

            <div className="dropdown-menu time-zone p-2" aria-labelledby="timezoneDropdown">
                <div className="text-size-s-3 text-gray-4 mb-2">
                    <i>* Click to select timezone</i>
                </div>

                <TimezonePicker
                    value={timezone && timezone.name}
                    onTimezoneChange={(value: any) => {
                        dispatch(setTimeZone(value));
                    }}
                    timezones={timezones}
                    className="navbar-nav ml-auto h-100"
                    inputProps={{
                        placeholder: "Select Timezone...",
                        name: "timezone-picker",
                    }}
                />
            </div>
        </div>
    );
};

export default TimeZoneSelector;
