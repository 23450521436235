const FETCH_TIMEOUT = 5000;
export default class Host {
    constructor(hostUrl, userName, keyString) {
        this.hostUrl = hostUrl;
        this.timeEpsilon = 0;
        this.userName = userName;
        this.keyString = keyString;
        // this.fetchTimeServer()
    }

    fetchTimeServer() {
        if (!this.hostUrl) return Promise.reject("no host in provider");
        try {
            this.fetchData(this.hostUrl + "timeserver").then((jsonTime) => {
                this.timeEpsilon = jsonTime && jsonTime.data ? jsonTime.data - Date.now() : 0;
            });
        } catch (e) {
            console.log(e);
        }
    }

    changeKey(key) {
        this.keyString = key;
    }

    changeUrl(hostUrl) {
        this.hostUrl = hostUrl;
        // this.fetchTimeServer()
    }

    fetchData(url, params) {
        return new Promise((resolve, reject) => {
            let didTimeOut = false;
            const timeout = setTimeout(function () {
                didTimeOut = true;
                reject(new Error("Request timed out: " + url));
            }, FETCH_TIMEOUT);

            fetch(url, params)
                .then((response) => {
                    clearTimeout(timeout);
                    if (!response.ok) {
                        reject(response.statusText);
                    } else {
                        return response.json();
                    }
                })
                .then((data) => {
                    data.timeEpsilon = this.timeEpsilon;
                    resolve(data);
                })
                .catch((err) => {
                    if (didTimeOut) return;
                    reject({
                        error: err,
                        url,
                    });
                });
        });
    }
}
