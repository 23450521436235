import React from "react";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import moment from "moment";
import { BLOCK_EXPLORER_URL } from "../../../services/constants";

const WithdrawDetailView = ({ data, networkId }) => {
    const bloclExplorerUrl =
        (networkId && BLOCK_EXPLORER_URL[networkId] ? BLOCK_EXPLORER_URL[networkId] : BLOCK_EXPLORER_URL.DEFAULT) +
        "tx/";
    const timezone = useSelector((state) => state.global.timezone);
    return (
        <div className="subrow">
            <div className="row">
                <p className="col offset-2 semi-b">Destination: {data.destination}</p>
            </div>
            <div className="row">
                <div className="col-2 text-right text-gray-4">ID:</div>
                <div className="col-10 text-dark">{data.id}</div>
                <div className="col-2 text-right text-gray-4">Amount:</div>
                <div className="col-10 text-dark">{data.params.amount}</div>
                <div className="col-2 text-right text-gray-4">Exchange:</div>
                <div className="col-10 text-dark">{data.params.exchange}</div>
                <div className="col-2 text-right text-gray-4">Token:</div>
                <div className="col-10 text-dark">{data.params.asset}</div>
                <div className="col-2 text-right text-gray-4">Timestamp:</div>
                <div className="col-10 text-dark">
                    {data.timestamp}{" "}
                    {moment(+data.timestamp)
                        .utcOffset(timezone.offset || 0)
                        .format("YYYY-MM-DD, HH:mm:ss")}{" "}
                    {timezone.label})
                </div>
                <div className="col-2 text-right text-gray-4">Tx:</div>
                <div className="col-10 text-dark">
                    {data.result.tx}
                    <a href={bloclExplorerUrl + data.result.tx} target="blank">
                        <i className="fa fa-link ml-1" />{" "}
                    </a>
                </div>
                {data.result.error && (
                    <React.Fragment>
                        <div className="col-2 text-right text-gray-4">Error:</div>
                        <div className="col-10 text-dark">{JSON.stringify(data.result.error)}</div>
                    </React.Fragment>
                )}
                {data.result.status_error && (
                    <React.Fragment>
                        <div className="col-2 text-right text-gray-4">Status Error:</div>
                        <div className="col-10 text-dark">{data.result.status_error}</div>
                    </React.Fragment>
                )}
            </div>

            <p>
                <a
                    className="btn btn-light"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    <i className="fa fa-expand" aria-hidden="true" />
                    <i> Click to view more details</i>
                </a>
            </p>
            <div className="collapse" id="collapseExample">
                <div className="card card-body">
                    <ReactJson src={data} name={false} />
                </div>
            </div>
        </div>
    );
};

export default WithdrawDetailView;
