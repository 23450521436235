import React, { Component } from "react";
import { connect } from "react-redux";
import { stretchArray, timeToMinisecond, msToTime } from "../../../services/utils/converter";
import AmCharts from "@amcharts/amcharts3-react";

@connect((store) => {
    let timezone = store.global && store.global.timezone ? store.global.timezone.offset : "0";

    return {
        timezone: timezone,
    };
})
export default class MultiplePanelChart extends Component {
    constructor(props) {
        super(props);
        // this.dataProvider = this.props.dataProvider
        // this.isZoomin = false
        // this.selected = this.props.selected

        this.keys = [
            [
                {
                    key: "totalUsdAmount",
                    title: "Total Usd Amount",
                    color: "#9900cc",
                    type: "smoothedLine",
                    unit: "USD",
                },
                {
                    key: "totalBurnFee",
                    title: "Total BurnFee",
                    color: "#669999",
                    type: "smoothedLine",
                    unit: "KNC",
                },
                {
                    key: "totalEthVolume",
                    title: "Total Eth Volume",
                    color: "#999966",
                    type: "step",
                    unit: "ETH",
                },
            ],
            [
                {
                    key: "newUniqueAddresses",
                    title: "New Unique Addresses",
                    color: "#2171b5",
                    type: "step",
                },
                {
                    key: "uniqueAddresses",
                    title: "Unique Addresses",
                    color: "#993366",
                    type: "smoothedLine",
                },
                {
                    key: "kycedAddresses",
                    title: "Kyced Addresses",
                    color: "#33cc33",
                    type: "smoothedLine",
                },
                {
                    key: "retention",
                    title: "Retention",
                    color: "#336600",
                    type: "smoothedLine",
                },
            ],
            [
                {
                    key: "ethPerTrade",
                    title: "Eth Per Trade",
                    color: "#ff3300",
                    type: "smoothedLine",
                    unit: "ETH",
                },
                {
                    key: "usdPerTrade",
                    title: "USD Per Trade",
                    color: "#666699",
                    type: "smoothedLine",
                    unit: "USD",
                },
                {
                    key: "totalTrade",
                    title: "Number of trades",
                    color: "#009900",
                    type: "step",
                },
            ],
        ];

        this.fieldMappings = [];
        this.keys.map((panels) => {
            panels.map((item) => {
                this.fieldMappings.push({
                    fromField: item.key,
                    toField: item.key,
                });
            });
        });

        this.panels = [];
        this.keys.map((panel, panelIndex) => {
            this.panels.push({
                percentHeight: 30,

                showCategoryAxis: panelIndex == this.keys.length - 1 ? true : false,

                valueAxes: panel.map((key, keyIndex) => ({
                    id: "v" + (keyIndex + 1),
                    offset: 30 * Math.pow(-1, Math.floor(keyIndex % 2)) * Math.floor(keyIndex / 2),
                    position: !(keyIndex % 2) ? "left" : "right",
                    // "gridAlpha": 0,
                    axisColor: key.color,
                    color: key.color,
                    gridColor: key.color,
                    unit: key.unit,
                    dashLength: 1,
                    axisThickness: 2,
                })),

                categoryAxis: {
                    dashLength: 5,
                },

                stockGraphs: panel.map((item, index) => ({
                    title: item.title,
                    valueField: item.key,
                    valueAxis: "v" + (index + 1),
                    type: item.type,
                    showBalloon: true,
                    lineColor: item.color,
                    useDataSetColors: false,
                    fillAlphas: item.type == "step" ? 0.3 : 0,
                    lineAlpha: item.type == "step" ? 0.3 : 1,
                    bullet: "round",
                    bulletSize: 5,
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    hideBulletsCount: 80,
                })),

                listeners: [
                    {
                        event: "clickGraphItem",
                        method: (e) => {
                            let dataContext = e.item.dataContext;
                            let rawText = "";

                            rawText += "Date : " + dataContext.date.toUTCString() + "\n";

                            rawText += "------------------------------------------ \n";

                            rawText += "Total USD Amount : " + dataContext.totalUsdAmount + "\n";
                            rawText += "Total Burn Fee : " + dataContext.totalBurnFee + "\n";
                            rawText += "Total ETH Volume : " + dataContext.totalEthVolume + "\n";

                            rawText += "------------------------------------------ \n";

                            rawText += "New Unique Address : " + dataContext.newUniqueAddresses + "\n";
                            rawText += "Unique Address : " + dataContext.uniqueAddresses + "\n";
                            rawText += "Kyced Address : " + dataContext.kycedAddresses + "\n";

                            rawText += "------------------------------------------ \n";

                            rawText += "ETH Per Trade : " + dataContext.ethPerTrade + "\n";
                            rawText += "USD Per Trade : " + dataContext.usdPerTrade + "\n";
                            rawText += "Number Of Trades : " + dataContext.totalTrade + "\n";

                            alert(rawText);
                        },
                    },
                ],

                stockLegend: {
                    spacing: 100,
                    valueWidth: 70,
                },
            });
        });

        this.state = {
            data: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let newArrayData = this.reCalculateRetention(nextProps.data);
        this.setState({
            data: newArrayData,
        });
    }

    reCalculateRetention(data) {
        return data.map((item) => ({ ...item, retention: item.uniqueAddresses - item.newUniqueAddresses }));
    }

    render() {
        let config = {
            type: "stock",
            theme: "light",

            pathToImages: "https://cdn.amcharts.com/lib/3/images/",
            dataSets: [
                {
                    fieldMappings: this.fieldMappings,
                    // "color": "#7f8da9",
                    dataProvider: this.state.data,
                    categoryField: "date",
                },
            ],

            panels: this.panels,

            chartScrollbarSettings: {
                graph: "g1",
                scrollbarHeight: 40,

                dragIcon: "dragIconRectSmall",
                dragIconWidth: 20,
                dragIconHeight: 40,

                backgroundAlpha: 0,
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: "#888888",
                // "graphFillAlpha": 0,
                graphLineAlpha: 0.5,
                // "selectedGraphFillAlpha": 0,
                selectedGraphLineAlpha: 1,
                color: "#AAAAAA",
            },

            balloon: {
                fontSize: 13,
            },

            panelsSettings: {
                fontFamily: "Arial",
                creditsPosition: "bottom-right",
                panelSpacing: 1,
                // "marginLeft": 30,
                // "marginRight": 15
            },

            chartCursorSettings: {
                cursorAlpha: 0.5,
                cursorColor: "#444444",
                valueLineAlpha: 1,
                valueBalloonsEnabled: true,
                // "categoryBalloonEnabled": false,
                oneBalloonOnly: true,
            },

            // "categoryAxesSettings": {
            //   // "axisAlpha": 0,
            //   // "gridAlpha": 0.1,
            //   "labelsEnabled": true,
            //   axisAlpha: 1
            // },

            periodSelector: {
                position: "bottom",
                periods: [
                    {
                        period: "DD",
                        count: 10,
                        label: "10 days",
                    },
                    {
                        period: "MM",
                        selected: true,
                        count: 1,
                        label: "1 month",
                    },
                    {
                        period: "YYYY",
                        count: 1,
                        label: "1 year",
                    },
                    {
                        period: "YTD",
                        label: "YTD",
                    },
                    {
                        period: "MAX",
                        label: "MAX",
                    },
                ],
            },
        };

        return (
            <div className="container">
                Current timezone: {this.props.timezone ? "GMT " + this.props.timezone : "UTC"}
                <AmCharts.React
                    style={{
                        width: "100%",
                        height: "600px",
                        paddingRight: "60px",
                    }}
                    options={config}
                />
            </div>
        );
    }
}
