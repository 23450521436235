import { saveHostData, saveNodeEndpoint } from "actions/signActions";
import { toast } from "components/_base/Toast/slice";
import { store } from "store";

export type AdminConfig = {
    coreKeyId: string;
    coreKeyString: string;
    coreUrl: string;
    statsKeyId: string;
    statsKeyString: string;
    statsUrl: string;
    nodeEndpoint?: string;
};

const unsafe_submitConfig = (config: AdminConfig) => {
    store.dispatch(saveNodeEndpoint(config.nodeEndpoint || ""));

    store.dispatch(
        saveHostData({
            CORE: {
                id: config.coreKeyId,
                url: config.coreUrl,
                key: config.coreKeyString,
            },
            STATS: {
                id: config.statsKeyId,
                url: config.statsUrl,
                key: config.statsKeyString,
            },
        })
    );

    store.dispatch(toast({ type: "success", text: "Config updated" }));
};

const unsafe_getAdminConfigTemplate = (): AdminConfig => {
    return {
        coreKeyId: "",
        coreKeyString: "",
        coreUrl: "",
        statsKeyId: "",
        statsKeyString: "",
        statsUrl: "",
        nodeEndpoint: "",
    };
};

export const setupGlobalFunctions = (): void => {
    // @ts-ignore
    window.unsafe_submitConfig = unsafe_submitConfig;

    // @ts-ignore
    window.unsafe_getAdminConfigTemplate = unsafe_getAdminConfigTemplate;
};
