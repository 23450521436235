import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import Masonry from "react-masonry-component";
import { JsonEditor } from "jsoneditor-react";
import Select from "react-select";

import { TableData, Switch } from "../../Share";
import { NewBtn, EditBtn, CancelBtn, SaveBtn, ConfirmBtn, RejectBtn, AdvanceBtn, BackBtn } from "../Share/btn";

const k8sGwUrl = "https://trading-gateway.kyberengineering.io";
const vtRfqURL = "/reserve-taker/settings/api/v1/ethereum/rfq-params"
const pendingURL = "/reserve-taker/settings/api/v1/ethereum/rfq-params/new"
const finalizeURL = "/reserve-taker/settings/api/v1/ethereum/rfq-params/finalize"


const ConfigTable = (props) => {
    const apiService = useSelector((store) => store.global.apiService);
    const hostData = useSelector((store) => store.global.hostData)
    const tokens = useSelector((store) => store.global.tokens);

    const [mapRawData, set_mapRawData] = useState({})
    const [input, set_input] = useState([])
    const [hasPending, set_hasPending] = useState(false)
    const [pendingTarget, set_pendingTarget] = useState([])
    const [isEdit, set_isEdit] = useState(false)
    const [showEditor, set_showEditor] = useState(false)

    const [k8sURL, set_k8sURL] = useState()

    const [searchTokens, set_searchTokens] = useState([])

    const jsonEditorRef = useRef(null)

    useEffect(() => cook(), [])

    const cook = async () => {
        const k8sURLRes = await apiService.getFromCore("/v3/k8s-gateway-url")
        set_k8sURL(k8sURLRes?.data)

        const res = await apiService.getFromCore(vtRfqURL, k8sURLRes?.data || k8sGwUrl);
        if (!res.success) {
            props.alert.error(res.reason || res.error);
            return
        }
        const newInput = []
        const newMapRawData = {}

        res.data?.forEach(c => {
            const standardizedInput = { ...c }
            newInput.push({ ...standardizedInput })
            newMapRawData[c.asset_id] = standardizedInput
        })
        set_input(newInput)
        set_mapRawData(newMapRawData)

        // get pending
        const pending = await apiService.getFromCore(pendingURL, k8sURLRes?.data || k8sGwUrl);
        if (pending?.success && pending?.data?.length) {
            const pendingData = pending.data.map(e => { return { ...e.data, is_new: true } })
            set_hasPending(true)
            set_pendingTarget(pending.data)
            set_input([...pendingData, ...newInput])

        } else {
            set_hasPending(false)
            set_pendingTarget([])
        }
    }

    const confirmPending = async () => {
        if (pendingTarget?.length) {
            const data = pendingTarget.map(elem => {
                return {
                    request_id: elem.requestId,
                    "status": "approved"
                }
            })
            const result = await apiService.postToCore(finalizeURL, JSON.stringify(data), k8sURL || k8sGwUrl);
            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            cook()
        }
    };

    const cancelPending = async () => {
        if (pendingTarget?.length) {
            const data = pendingTarget.map(elem => {
                return {
                    request_id: elem.requestId,
                    "status": "declined"
                }
            })
            const result = await apiService.postToCore(finalizeURL, JSON.stringify(data), k8sURL || k8sGwUrl);
            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            cook()
        }
    };

    const onChangeInput = (field, value, rowId) => {
        set_input(input?.map(row => {
            if (row.asset_id === rowId) {
                row[field] = value
                return row
            }
            return row
        }))
    }

    const parsedInput = () => {
        const dataArray = [];
        input?.forEach(t => {
            if (!mapRawData[t.asset_id])
                dataArray.push({
                    request_type: "rfq-params",
                    data: {
                        asset_id: +t.asset_id,
                        chain_id: +t.chain_id,
                        ref_eth_amount: +t.ref_eth_amount,
                        eth_step: +t.eth_step,
                        max_eth_size_buy: +t.max_eth_size_buy,
                        max_eth_size_sell: +t.max_eth_size_sell,
                        a: +t.a,
                        b: +t.b,
                        c: +t.c,
                        min_min: +t.min_min,
                        max_imb: +t.max_imb,
                        imb_price_multiplier: +t.imb_price_multiplier,
                        step_multiplier: +t.step_multiplier,
                        default_quote_id: t.default_quote_id ? +t.default_quote_id : null,
                        enabled: t.enabled,
                    },
                })
        })

        return dataArray;
    }

    const submit = async () => {
        if (showEditor) {
            try {
                jsonEditorRef?.current?.jsonEditor?.get()
            } catch (e) {
                props.alert.error("Can not save, please check the data!");
                return
            }
        }

        const data = JSON.stringify(parsedInput());
        const result = await apiService.postToCore(vtRfqURL, data, k8sURL || k8sGwUrl);

        if (result.success) {
            if (result.data.failed?.length)
                props.alert.error("There are some issues: " + JSON.stringify(result.data.failed.length));
            else
                props.alert.success("success");

            set_isEdit(false)
            set_showEditor(false)
            cook()
        }
        else props.alert.error(result.reason || result.error || "Failed to cancel pending!");
    };

    const onButtonClick = (btnName) => {
        switch (btnName) {
            case "new":
                set_input([{
                    is_new: true,
                    asset_id: 0,
                    chain_id: 1,
                    ref_eth_amount: 0,
                    eth_step: 0,
                    max_eth_size_buy: 0,
                    max_eth_size_sell: 0,
                    a: 0,
                    b: 0,
                    c: 0,
                    min_min: 0,
                    max_imb: 0,
                    imb_price_multiplier: 0,
                    eth_amount_to_ignore: 0,
                    step_multiplier: 0,
                    default_quote_id: null,
                    enabled: false
                }, ...input])
                break;
            case "edit":
                set_isEdit(true)
                break;
            case "cancel":
                cook()
                set_isEdit(false)
                break;
            case "save":
                submit();
                break;
            case "confirm":
                confirmPending();
                break;
            case "reject":
                cancelPending();
                break;
            case "advance":
                set_showEditor(true)
                break;
            case "back":
                set_showEditor(false)
                break;
        }
    }
    const newBtn = <NewBtn clickFunc={() => onButtonClick("new")} />
    const editBtn = <EditBtn clickFunc={() => onButtonClick("edit")} />
    const cancelBtn = <CancelBtn clickFunc={() => onButtonClick("cancel")} />
    const saveBtn = <SaveBtn clickFunc={() => onButtonClick("save")} />
    const confirmBtn = <ConfirmBtn clickFunc={() => onButtonClick("confirm")} />
    const rejectBtn = <RejectBtn clickFunc={() => onButtonClick("reject")} />
    const advanceBtn = <AdvanceBtn clickFunc={() => onButtonClick("advance")} />
    const backBtn = <BackBtn clickFunc={() => onButtonClick("back")} />

    const btnElements = () => {
        if (hasPending) {
            if (pendingTarget?.proposer && hostData?.CORE.id == pendingTarget.proposer)
                return [rejectBtn]
            return [confirmBtn, rejectBtn]
        }
        return isEdit ? [advanceBtn, newBtn, saveBtn, cancelBtn] : [editBtn]
    }

    const options = Object.values(tokens).map((asset) => {
        return { value: asset.id, label: asset.symbol };
    })


    const renderEditable = (cell) => {
        const updateField = cell.column.id;
        const data = props.fields[updateField]

        if (data.type == "bool")
            return (
                <Switch
                    onChange={(e) => onChangeInput(updateField, e, cell.original.asset_id)}
                    checked={cell.original[updateField]}
                />
            );
        if (data.type == "select_asset") {
            return <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={options}
                onChange={e => onChangeInput(updateField, e.value, cell.original.asset_id)}
                value={options.filter(o => o.value == cell.original[updateField])}
            />
        }
        return (
            <div
                contentEditable={true}
                suppressContentEditableWarning
                onBlur={(e) => {
                    onChangeInput(updateField, e.target.innerHTML, cell.original.asset_id);
                }}
                onPaste={e => {
                    e.preventDefault()
                    document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
                }}
                dangerouslySetInnerHTML={{
                    __html: cell.original[updateField]
                }}
            />
        );
    };

    const boolCell = (curV, isChange, newV) => {
        if (isChange)
            return <span>
                <p className={newV ? "text-success m-0" : "text-danger mb-0"}
                    style={{ border: "1px dashed blue" }}>
                    {newV ? "Enabled" : "Disabled"}</p>
                <p className="text-warning mb-0" >Old: {curV ? "Enabled" : "Disabled"}</p>
            </span>

        return <p className={curV ? "text-success m-0" : "text-danger mb-0"}>{curV ? "Enabled" : "Disabled"}</p>
    };


    const cellFunc = (row, field_name, field) => {
        if (field.type == "bool") return boolCell(row.value)
        return <div >{field.cell ? field.cell(row) : row.value}</div>
    }

    const columns = []
    Object.keys(props.fields).forEach((field) => {
        const col = props.fields[field]

        columns.push({
            Header: col.title,
            id: field,
            accessor: field,
            Cell: (cell => isEdit && cell.original.is_new ? renderEditable(cell) : cellFunc(cell, field, col))
        })
    });


    const rowClassName = (state, row) => {
        switch (true) {
            case row.original.is_new:
                return "bg-is-new";
            case row.original.is_delete:
                return "bg-is-delete";
        }
    };

    const masonryOptions = {
        transitionDuration: 0,
        percentPosition: true,
        columnWidth: props.columnWidth || 1,
        enableResizableChildren: true,
    };

    const onchangeJsonEditor = (value) => {
        set_input(value)
    }

    const Filter = (
        <div className="d-block d-sm-flex mobile-mb-s" style={{ width: "100%" }}>
            <Select
                className="w-25 w-sm-100 mr-3 mobile-mb-s"
                placeholder="Search tokens..."
                options={[{ value: 0, label: "_" }, ...Object.values(tokens).map(i => { return { value: i.id, label: i.symbol } })]}
                isMulti
                onChange={set_searchTokens}
                value={searchTokens}
                styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
    )

    const filterInput = () => {
        let filterData = input
        if (searchTokens?.length) {
            const assetIds = []
            searchTokens.forEach((s) => {
                assetIds.push(s.value)
            });

            filterData = filterData.filter(t => assetIds.includes(t.asset_id))
        }

        return filterData
    }

    return (
        <div >
            <div className="col-sm-12 p-0" style={{ height: "100vh" }}>
                <div className="panel-header">
                    <div className="panel-title text-dark p-0">
                        VT RFQ PARAMS
                        {showEditor && <span>{backBtn} {saveBtn}
                        </span>}
                    </div>
                    <Masonry options={masonryOptions}>
                        {
                            showEditor ? (
                                <JsonEditor
                                    className="w-100" value={input} mode="code" ref={jsonEditorRef}
                                    allowedModes={["tree", "view", "form", "code", "text"]}
                                    htmlElementProps={{ style: { height: "calc(100vh - 100px)", width: "100%" } }}
                                    onChange={(value) => onchangeJsonEditor(value)}
                                />
                            ) :
                                <div className="col-sm-12 p-0 mt-3 table-group">
                                    <TableData
                                        searchChange
                                        style={{ maxHeight: "calc(100vh - 140px)" }}
                                        data={filterInput()}
                                        buttonElements={btnElements()} columns={columns}
                                        rowClassName={rowClassName}
                                        tableFilter={Filter}
                                        noPagination
                                    />
                                </div>
                        }
                    </Masonry>
                </div>
            </div>
        </div >
    );
}

export default withAlert()(ConfigTable);
