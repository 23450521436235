import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { TableData } from "components/Share";

import { ITokenWithQuoteDetail } from "./model";
import Config from "./Config";
import { RootState } from "reducers";
import quote0xSlice from "./quote0x.slice";
import TokenCell from "./TokenCell";

interface IProps {
    className?: string;
}

const QuoteZeroX: React.FC<IProps> = ({ className }) => {
    const dispatch = useDispatch();

    const mountedRef = useRef(false);
    const intervalRef = useRef<ReturnType<typeof setInterval>>();

    const fromAmount = useSelector((state: RootState) => state.quote0x.fromAmount);
    const toAmount = useSelector((state: RootState) => state.quote0x.toAmount);

    const tokens = useSelector((state: RootState) => state.quote0x.tokens);
    const tokensWithQuotes = useSelector((state: RootState) => state.quote0x.tokensWithQuotes);

    const amounts: number[] = useMemo(() => {
        if (fromAmount > toAmount || fromAmount < 0 || toAmount <= 0) {
            return [];
        }

        const step = (toAmount - fromAmount) / 4;
        const start = fromAmount - step;

        const amounts = [5, 4, 3, 2, 1].map((v) => Math.round((v * step + start) * 100) / 100);

        return amounts;
    }, [fromAmount, toAmount]);

    const BUY_COLUMNS = amounts.map((value, index) => ({
        Header: `Buy ${value}`,
        id: `buy${value}`,
        accessor: (token: ITokenWithQuoteDetail) => {
            return token.quotes.buy[index].rate;
        },
    }));

    const SELL_COLUMNS = [...amounts].reverse().map((value, index) => ({
        Header: `Sell ${value}`,
        id: `sell${value}`,
        accessor: (token: ITokenWithQuoteDetail) => {
            return token.quotes.sell[index].rate;
        },
    }));

    const COLUMNS = [
        {
            Header: "Symbol",
            id: "symbol",
            accessor: "symbol",
            // @ts-ignore
            Cell: (props) => {
                const warning = props.original.quotes.warning;

                return (
                    <div
                        data-warning={warning}
                        style={{ width: "100%", height: "100%", border: warning && "2px solid #e74c3c" }}
                    >
                        <TokenCell symbol={props.value} />
                    </div>
                );
            },
        },
        ...BUY_COLUMNS,
        ...SELL_COLUMNS,
    ];

    useEffect(() => {
        dispatch(quote0xSlice.actions.fetchEnabledTokensRequest());
    }, [dispatch]);

    useEffect(() => {
        const fetch = () => {
            if (amounts.length === 0) {
                return;
            }

            dispatch(quote0xSlice.actions.fetchQuotesRequest({ amounts }));
        };

        const cleanup = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };

        if (tokens.length === 0) {
            return cleanup;
        }

        fetch();

        cleanup();
        intervalRef.current = setInterval(fetch, 30 * 1000);

        return cleanup;
    }, [dispatch, tokens.length, amounts]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <div className={className}>
            <h4 className="pageTitle px-0 py-4">Quote ZeroX</h4>

            <Config
                fromAmount={fromAmount}
                toAmount={toAmount}
                onAmountsChange={(from, to) => {
                    dispatch(quote0xSlice.actions.setConstraintAmounts({ from, to }));
                }}
            />

            <TableData id="quotesZeroX" data={tokensWithQuotes} columns={COLUMNS} />
        </div>
    );
};

export default styled(QuoteZeroX)`
    width: 100%;
    padding: 16px 32px;

    .pageTitle {
        color: #141927;
    }
`;
