import styled from "styled-components";

interface IProps {
    className?: string;
}

const Icon: React.FC<IProps> = ({ className }) => {
    return (
        <div className={className}>
            <svg viewBox="0 0 181 34" fill="#333" xmlns="http://www.w3.org/2000/svg" role="img" className="fill-gray-9">
                <path d="m30.544.237-7.82 15.526h3.389l1.814-3.61h8.769l1.794 3.61h3.434L34.107.237h-3.563Zm1.77 3.135 3.023 6.075h-6.068l3.044-6.075ZM42.318 12.39C43.83 14.575 47.5 16 51.194 16c4.386 0 7.775-1.709 7.775-5.102 0-3.351-3.11-3.934-7.517-4.432-3.93-.454-4.968-.715-4.968-1.838 0-1.04 1.254-1.925 4.017-1.925 2.633 0 4.838.885 6.026 2.42l2.118-2.033C57.245 1.23 54.134 0 50.678 0c-4.406 0-7.277 2.012-7.277 4.952 0 3.069 2.916 3.784 6.716 4.216 4.362.475 5.637.607 5.637 2.075 0 1.256-1.836 2.033-4.428 2.033-2.288 0-5.096-.736-6.87-3.114l-2.138 2.229ZM74.882.237V6.42h-9.956V.237h-3.174v15.526h3.174V9.168h9.956v6.595h3.197V.237h-3.197ZM83.82 15.763V9.6H94.23V6.856H83.82v-3.87h11.642V.236H80.689v15.526h3.131ZM98.353 15.763h14.081v-2.745h-10.907V.238h-3.174v15.525ZM122.219 0c-5.313 0-9.158 3.393-9.158 8s3.845 8 9.158 8c5.291 0 9.158-3.393 9.158-8s-3.867-8-9.158-8Zm0 2.79c3.476 0 5.897 2.075 5.897 5.21s-2.417 5.21-5.897 5.21c-3.48 0-5.898-2.075-5.898-5.21s2.421-5.21 5.898-5.21ZM143.232.237l-3.888 12.021-4.083-12.02h-3.326l5.42 15.525h3.672l3.821-11.871 3.78 11.87h3.692L157.74.238h-3.26l-4.083 12.021L146.53.238h-3.298ZM21.946 7.289V4.53h-2.818V.24H15.95v4.294H5.995V.24H2.818v4.293H0v2.758h2.818v1.423H0v2.758h2.818v4.29h3.177V11.47h9.956v4.293h3.177V11.47h2.818V8.71h-2.818V7.29h2.818ZM15.951 8.71H5.995V7.29h9.956V8.71Z" />
            </svg>
        </div>
    );
};
export default styled(Icon)`
    height: 16px;

    > svg {
        height: 100%;
        color: black;
    }
`;
