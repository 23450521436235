import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableData, Switch } from "components/Share";
import { getAllIntegrations, updateIntegrations } from "services/configuration";
import { toast } from "components/_base/Toast/slice";

const Integrations = () => {
    const [integrations, setIntegrations] = useState([]);
    const apiService = useSelector((state) => state.global.apiService);
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            const data = await getAllIntegrations(apiService);
            setIntegrations(data.data);
            return data;
        }
        fetchData();
    }, [apiService]);

    const StatusCell = ({ value, original }) => {
        const handleChange = async (e) => {
            const res = await updateIntegrations(apiService, [
                {
                    ...original,
                    enabled: !original.enabled,
                },
            ]);
            if (res.error)
                dispatch(
                    toast({
                        type: "error",
                        text: res.error,
                    })
                );
            else
                dispatch(
                    toast({
                        type: "success",
                        text: "Change was applied successfully",
                    })
                );
        };

        return <Switch onChange={handleChange} checked={original.enabled} />;
    };

    return (
        <section className="container-fluid">
            <nav className="px-0 py-4 nav-sub">
                <h4 className="page-tile">Integrations</h4>
            </nav>

            <div
                style={{
                    marginBottom: "32px",
                }}
            >
                <TableData
                    title=""
                    data={integrations}
                    columns={[
                        {
                            Header: "Integration Name",
                            id: "name",
                            accessor: "name",
                        },
                        {
                            Header: "API Key",
                            id: "id",
                            accessor: "id",
                        },
                        {
                            Header: "Info",
                            id: "info",
                            accessor: "info",
                        },
                        {
                            Header: "Status",
                            id: "status",
                            accessor: "isActive",
                            Cell: StatusCell,
                            width: 200,
                        },
                    ]}
                    overWriteProps={{
                        minRows: 3,
                    }}
                />
            </div>
        </section>
    );
};

export default Integrations;
