import { SUPPORTED_HOST } from "../constants";
const apiUrl = "https://api.kyber.network";
export default {
    /////////////// set target

    setTargetQty(type, data, id) {
        const requestData = { type, data, id };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("settargetqty", "POST", requestData)
            .catch(this.handleNetErr);
    },

    confirmTargetQty(type, data, id) {
        const requestData = { type, data, id };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("confirmtargetqty", "POST", requestData)
            .catch(this.handleNetErr);
    },

    cancelTargetQty(type, data, id) {
        const requestData = { type, data, id };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("canceltargetqty", "POST", requestData)
            .catch(this.handleNetErr);
    },

    getPendingTargetQty() {
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("pendingtargetqty", "GET", null)
            .catch(this.handleNetErr);
    },

    getQty() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/targetqty", "GET", null)
            .catch(this.handleNetErr);
    },

    getFromCore(url, host) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(url, "GET", null, null, false, host)
            .catch(this.handleNetErr);
    },

    postToCore(url, data, host) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(url, "POST", data, null, false, host)
            .catch(this.handleNetErr);
    },

    putToCore(url, data) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(url, "PUT", data, null, false)
            .catch(this.handleNetErr);
    },

    patchToCore(url, data) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(url, "PATCH", data, null, false)
            .catch(this.handleNetErr);
    },

    deleteToCore(url, data) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(url, "DELETE", data, null, false)
            .catch(this.handleNetErr);
    },

    /////////////// rebalance

    enableRebalance() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/enable-rebalance", "POST", null, null, false)
            .catch(this.handleNetErr);
    },

    holdRebalance() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/hold-rebalance", "POST", null, null, false)
            .catch(this.handleNetErr);
    },

    getRebalanceStatus() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/rebalance-status", "GET", null, null, false)
            .catch(this.handleNetErr);
    },

    //////////// set rate

    enableSetRate(selector) {
        const query = selector
            ? {
                selector,
            }
            : null;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/enable-set-rate", "POST", null, query, false)
            .catch(this.handleNetErr);
    },

    disableSetRate(selector) {
        const query = selector
            ? {
                selector,
            }
            : null;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/hold-set-rate", "POST", null, query, false)
            .catch(this.handleNetErr);
    },

    getSetRateStatus(selector) {
        const query = selector
            ? {
                selector,
            }
            : null;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/set-rate-status", "GET", null, query, false)
            .catch(this.handleNetErr);
    },

    ////////////// pwis
    setPwisEquation(data) {
        const requestData = { data };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("v2/set-pwis-equation", "POST", requestData)
            .catch(this.handleNetErr);
    },

    confirmPwisEquation(data) {
        const requestData = { data };
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("v2/confirm-pwis-equation", "POST", requestData)
            .catch(this.handleNetErr);
    },

    getPwisEquation() {
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("v2/pwis-equation", "GET", null)
            .catch(this.handleNetErr);
        // return Promise.resolve({
        //   "data": {
        //     "EOS": {
        //       "ask": {
        //         "A": 800,
        //         "B": 600,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       },
        //       "bid": {
        //         "A": 750,
        //         "B": 500,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       }
        //     },
        //     "ETH": {
        //       "ask": {
        //         "A": 800,
        //         "B": 600,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       },
        //       "bid": {
        //         "A": 750,
        //         "B": 500,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       }
        //     }
        //   },
        //   "success": true

        // })
    },

    getPendingPwisEquation() {
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("v2/pending-pwis-equation", "GET", null)
            .catch(this.handleNetErr);
        // return Promise.resolve({
        //   "data": {
        //     "EOS": {
        //       "ask": {
        //         "A": 800,
        //         "B": 600,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       },
        //       "bid": {
        //         "A": 750,
        //         "B": 500,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       }
        //     },
        //     "ETH": {
        //       "ask": {
        //         "A": 800,
        //         "B": 600,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       },
        //       "bid": {
        //         "A": 750,
        //         "B": 500,
        //         "C": 0,
        //         "MinMinSpread": 0,
        //         "PriceMultiplyFactor": 0
        //       }
        //     }
        //   },
        //   "success": true
        // })
    },

    cancelPwisEquation() {
        // let requestData = {data, id}
        return this.provider
            .call("send", SUPPORTED_HOST.CORE.symbol)("v2/reject-pwis-equation", "POST", null, null, false)
            .catch(this.handleNetErr);
    },

    //////////// exchange status
    getExchangeStatus() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/exchange", "GET", null, null, false)
            .catch(this.handleNetErr);
    },

    updateExchangeStatus(exId, data) {
        // let timestamp = new Date().getTime()
        // let requestData = {exchange, status, timestamp}
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(
                `/v3/setting-change-update-exchange`,
                "POST",
                data,
                null,
                false
            )
            .catch(this.handleNetErr);
    },

    confirmUpdateExchange(dataId) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(
                `/v3/setting-change-update-exchange/${dataId}`,
                "PUT",
                null,
                null,
                false
            )
            .catch(this.handleNetErr);
    },

    deleteUpdateExchange(dataId) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(
                `/v3/setting-change-update-exchange/${dataId}`,
                "DELETE",
                null,
                null,
                false
            )
            .catch(this.handleNetErr);
    },

    getRFQTradeSettings() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/rfq-trade-setting", "GET", null)
            .catch(this.handleNetErr);
    },

    postRFQTradeSettings(data) {
        const body = typeof data === "string" ? data : JSON.stringify(data);

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)("/v3/rfq-trade-setting", "POST", body, null, false)
            .catch(this.handleNetErr);
    },

    getBasePricing() {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(`/rfq/current-base-pricing`, "GET", null)
            .catch(this.handleNetErr);
    },

    getPricingFromIntegration(name) {
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.CORE.symbol)(`/rfq/current-pricing?integration=${name}`, "GET", null)
            .catch(this.handleNetErr);
    },
};
