import { useCallback, useEffect, useRef, useState } from "react";
import { BigNumber } from "bignumber.js";
import { useSelector } from "react-redux";
import AmCharts from "@amcharts/amcharts3-react";
import { formatMoney } from "services/utils/converter";

import { Switch } from "../../Share";

const isZeroRegex = /^[0.]+$/;
const filterTokens = (tokens) => {
    return tokens
        .filter((token) => !(isZeroRegex.test(token.locked) && isZeroRegex.test(token.free)))
        .map((token) => ({
            symbol: token.asset,
            free: parseFloat(token.free),
            locked: parseFloat(token.locked),
        }));
};

const filterMarginTokens = (tokens) => {
    return tokens
        .filter(
            (token) =>
                !(isZeroRegex.test(token.borrowed) && isZeroRegex.test(token.free) && isZeroRegex.test(token.locked))
        )
        .map((token) => ({
            asset: token.asset,
            borrowed: parseFloat(token.borrowed),
            free: parseFloat(token.free),
            interest: parseFloat(token.interest),
            locked: parseFloat(token.locked),
            netAsset: parseFloat(token.netAsset),
        }));
};

const AccountBalance = () => {
    const apiService = useSelector((state) => state.global.apiService);
    const [assets, setAssets] = useState([]);
    const [marginBalance, setMarginBalance] = useState({});
    const [marginAssets, setMarginAssets] = useState([]);
    const [totalValueInUSD, setTotalValueInUSD] = useState("0");
    const intervalRef = useRef();

    const getAssetsFromBinance = useCallback(async () => {
        const { data: allTokens } = await apiService.getFromCore("/v3/binance/main?format=raw");
        if (!allTokens) return;

        const { data: marginBalance } = await apiService.getFromCore("/v3/margin/account?exchange=1");
        if (!marginBalance) return;

        // Get Binance Ticker
        const ticker = await fetch("https://api.binance.com/api/v3/ticker/price").then((response) => response.json());
        const priceByPairSymbol = ticker.reduce((acc, pair) => {
            acc[pair.symbol] = pair.price;
            return acc;
        }, {});

        // Process for Binance Spot
        const ownTokens = filterTokens(allTokens);
        let totalValueInUSD = 0;
        const assets = ownTokens.map((token) => {
            const { symbol, free, locked } = token;
            let price = 0;
            if (symbol == "USDT" || symbol == "LDUSDT") {
                price = 1;
            } else if (priceByPairSymbol[symbol + "USDT"]) {
                price = priceByPairSymbol[symbol + "USDT"];
            } else if (symbol.startsWith("LD")) {
                const pair = symbol.slice(2) + "USDT";
                price = priceByPairSymbol[pair] || 0;
            }
            const quantity = free + locked;
            const value = quantity * price;
            totalValueInUSD = totalValueInUSD += value;
            return { symbol, free, locked, quantity, value };
        });

        // Process for Binance Margin
        const ownMarginTokens = filterMarginTokens(marginBalance.userAssets);
        const marginAssets = ownMarginTokens.map((token) => {
            const { asset, borrowed, free, interest, locked, netAsset } = token;
            let price = 0;
            if (asset == "USDT") price = 1;
            if (priceByPairSymbol[asset + "USDT"]) price = priceByPairSymbol[asset + "USDT"];

            const value = netAsset * price;
            return { asset, borrowed, free, interest, locked, netAsset, value };
        });

        setTotalValueInUSD(totalValueInUSD);
        setAssets(assets);
        setMarginBalance(marginBalance);
        setMarginAssets(marginAssets);
    }, [apiService]);

    useEffect(() => {
        const intv = intervalRef.current;
        const cleanup = () => {
            if (intv) {
                clearInterval(intv);
            }
        };

        cleanup();
        getAssetsFromBinance();
        intervalRef.current = setInterval(getAssetsFromBinance, 10_000);

        return cleanup;
    }, [getAssetsFromBinance]);

    return (
        <div className="panel panel-table">
            <div className="panel-header">
                <h5 className="panel-title">Binance Balances</h5>
            </div>
            <div className="panel-body ml-4">
                <div className="row">
                    <div className="col-lg-2 col-md-5">
                        <div>
                            <div className="d-flex justify-content-between" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Spot Balance:</div>
                                <div>
                                    <span className="text-info">{formatMoney(totalValueInUSD)}</span> USD
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-7">
                        <AmCharts.React
                            style={{
                                width: "100%",
                                height: "250px",
                            }}
                            options={{
                                type: "pie",
                                theme: "light",
                                dataProvider: assets,
                                valueField: "value",
                                titleField: "symbol",
                            }}
                        />
                    </div>

                    <div className="col-lg-7 col-md-12">
                        <table className="table table-striped table-responsive-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Asset</th>
                                    <th scope="col">Free</th>
                                    <th scope="col">Locked</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">USD Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assets.map((b, i) => (
                                    <tr key={i}>
                                        <td>{b.symbol}</td>
                                        <td>{b.free}</td>
                                        <td>{b.locked}</td>
                                        <td>{b.quantity}</td>
                                        <td>{formatMoney(b.value, 3)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr />
                <div className="row mt-5">
                    <div className="col-lg-2 col-md-5">
                        <div>
                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Margin Level:</div>
                                <div>
                                    <span className="text-info">{marginBalance.marginLevel}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Borrow Enabled:</div>
                                <div>
                                    <Switch checked={marginBalance.borrowEnabled || false} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Trade Enabled:</div>
                                <div>
                                    <Switch checked={marginBalance.tradeEnabled || false} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Transfer Enabled:</div>
                                <div>
                                    <Switch checked={marginBalance.transferEnabled || false} />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Total Asset:</div>
                                <div>
                                    <span className="text-info">{formatMoney(marginBalance.totalAssetOfBtc, 5)}</span>{" "}
                                    BTC
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Total Liability:</div>
                                <div>
                                    <span className="text-info">
                                        {formatMoney(marginBalance.totalLiabilityOfBtc, 5)}
                                    </span>{" "}
                                    BTC
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-3" style={{ margin: "0 10px 0 0.7vw" }}>
                                <div>Total Net Asset:</div>
                                <div>
                                    <span className="text-info">
                                        {formatMoney(marginBalance.totalNetAssetOfBtc, 5)}
                                    </span>{" "}
                                    BTC
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                    <div className="col-lg-3 col-md-7">
                        <AmCharts.React
                            style={{
                                width: "100%",
                                height: "250px",
                            }}
                            options={{
                                type: "pie",
                                theme: "light",
                                dataProvider: marginAssets,
                                valueField: "value",
                                titleField: "asset",
                            }}
                        />
                    </div>

                    <div className="col-lg-7 col-md-12">
                        <table className="table table-striped table-responsive-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Asset</th>
                                    <th scope="col">Borrowed</th>
                                    <th scope="col">Free</th>
                                    <th scope="col">Interest</th>
                                    <th scope="col">Locked</th>
                                    <th scope="col">Net Asset</th>
                                    <th scope="col">USD Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {marginAssets.map((b, i) => (
                                    <tr key={i}>
                                        <td>{b.asset}</td>
                                        <td>{b.borrowed}</td>
                                        <td>{b.free}</td>
                                        <td>{b.interest}</td>
                                        <td>{b.locked}</td>
                                        <td>{b.netAsset}</td>
                                        <td>{formatMoney(b.value, 3)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountBalance;
