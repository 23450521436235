import { BLOCK_EXPLORER_URL } from "../../../services/constants";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import moment from "moment";

const CrossChainTradeDetailView = ({ data }) => {
    const blockExplorerUrl = BLOCK_EXPLORER_URL.DEFAULT + "tx/";
    const timezone = useSelector((state) => state.global.timezone);
    const trade = data.params.cross_chain_trade;
    return (
        <div className="subrow">
            <div className="row">
                <p className="col offset-2 semi-b">Destination: {data.destination}</p>
            </div>
            <div className="row">
                <div className="col-2 text-right text-gray-4">ID:</div>
                <div className="col-10 text-dark">{data.id}</div>

                {/* data.params.cross_chain_trade */}
                <div className="col-2 text-right text-gray-4">Trade ID:</div>
                <div className="col-10 text-dark">{trade.trade_id}</div>
                <div className="col-2 text-right text-gray-4">To Chain ID:</div>
                <div className="col-10 text-dark">{trade.to_chain_id}</div>
                <div className="col-2 text-right text-gray-4">Taker Asset:</div>
                <div className="col-10 text-dark">{trade.taker_asset}</div>
                <div className="col-2 text-right text-gray-4">Maker Asset:</div>
                <div className="col-10 text-dark">{trade.maker_asset}</div>
                <div className="col-2 text-right text-gray-4">Taker Amount:</div>
                <div className="col-10 text-dark">{trade.taker_amount}</div>
                <div className="col-2 text-right text-gray-4">Maker Amount:</div>
                <div className="col-10 text-dark">{trade.maker_amount}</div>
                <div className="col-2 text-right text-gray-4">Solver Fee:</div>
                <div className="col-10 text-dark">{trade.solver_fee}</div>
                <div className="col-2 text-right text-gray-4">User Received Addr:</div>
                <div className="col-10 text-dark">{trade.user_receiving_address}</div>
                <div className="col-2 text-right text-gray-4">User Vault:</div>
                <div className="col-10 text-dark">{trade.user_vault}</div>
                <div className="col-2 text-right text-gray-4">Unwrap ETH:</div>
                <div className="col-10 text-dark">{JSON.stringify(trade.unwrap_eth)}</div>

                <div className="col-2 text-right text-gray-4">Timestamp:</div>
                <div className="col-10 text-dark">
                    {data.timestamp} (
                    {moment(+data.timestamp)
                        .utcOffset(timezone.offset || 0)
                        .format("YYYY-MM-DD, HH:mm:ss")}{" "}
                    {timezone.label})
                </div>
                <div className="col-2 text-right text-gray-4">Tx:</div>
                <div className="col-10 text-dark">
                    {data.result.tx}
                    <a href={blockExplorerUrl + data.result.tx} target="blank">
                        <i className="fa fa-link ml-1" />{" "}
                    </a>
                </div>
            </div>

            <p>
                <a
                    className="btn btn-light"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    <i className="fa fa-expand" aria-hidden="true" />
                    <i> Click to view more details</i>
                </a>
            </p>
            <div className="collapse" id="collapseExample">
                <div className="card card-body">
                    <ReactJson src={data} name={false} />
                </div>
            </div>
        </div>
    );
};

export default CrossChainTradeDetailView;
