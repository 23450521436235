import styled from "styled-components";

interface IProps {
    className?: string;
}

const Icon: React.FC<IProps> = ({ className }) => {
    return (
        <div className={className}>
            <svg viewBox="0 0 181 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.335.047C4.25.037 4.165.028 4.09.01V0c.076.019.161.038.246.047z"
                    fill="#181B30"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.335.047C4.25.037 4.165.028 4.09.01V0c.076.019.161.038.246.047z"
                    fill="#181B30"
                />
                <mask id="tokenlon-header-logo_svg__a" maskUnits="userSpaceOnUse" x="0" y="3" width="27" height="30">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.292 3.56c-.51 0-1.02.028-1.526.083C5.59 4.403 0 10.466 0 17.833c0 7.878 6.397 14.266 14.292 14.266h.032a14.231 14.231 0 006.534-1.594c.577-.3.328-1.17-.32-1.152h-.027c-5.716.145-10.848-2.269-12.938-6.742-1.255-2.678-1.204-5.157-.62-7.116l.014-.051c.299-1.207.858-2.32 1.684-3.187.064-.076.132-.149.206-.216.046-.049.096-.094.148-.135a.826.826 0 01.06-.06c.02-.014.034-.029.053-.043a.747.747 0 01.12-.097 7.858 7.858 0 014.988-1.761c4.263 0 7.722 3.326 7.722 7.431a7.176 7.176 0 01-1.198 3.97c3.408-1.175 5.846-4.316 5.845-8.003 0-2.099-.792-4.019-2.108-5.505A14.145 14.145 0 0022.7 6.29l-.005-.005a14.235 14.235 0 00-8.38-2.726h-.023z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#tokenlon-header-logo_svg__a)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.292 3.56c-.51 0-1.02.028-1.526.083C5.59 4.403 0 10.466 0 17.833c0 7.878 6.397 14.266 14.292 14.266h.032a14.231 14.231 0 006.534-1.594c.577-.3.328-1.17-.32-1.152h-.027c-5.716.145-10.848-2.269-12.938-6.742-1.255-2.678-1.204-5.157-.62-7.116l.014-.051c.299-1.207.858-2.32 1.684-3.187.064-.076.132-.149.206-.216.046-.049.096-.094.148-.135a.826.826 0 01.06-.06c.02-.014.034-.029.053-.043a.747.747 0 01.12-.097 7.858 7.858 0 014.988-1.761c4.263 0 7.722 3.326 7.722 7.431a7.176 7.176 0 01-1.198 3.97c3.408-1.175 5.846-4.316 5.845-8.003 0-2.099-.792-4.019-2.108-5.505A14.145 14.145 0 0022.7 6.29l-.005-.005a14.235 14.235 0 00-8.38-2.726h-.023z"
                        fill="url(#tokenlon-header-logo_svg__paint0_linear)"
                    />
                </g>
                <mask id="tokenlon-header-logo_svg__b" maskUnits="userSpaceOnUse" x="7" y="7" width="22" height="21">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.513 13.26c0 3.684-2.321 6.826-5.573 8.002a8.255 8.255 0 01-2.852.501c-3.127 0-5.854-1.719-7.309-4.276-.354-.623-.632-1.683-.822-2.584-.178-.854-1.292-1.077-1.783-.356-.17.247-.297.52-.374.81-.01.042-.022.084-.031.125a.123.123 0 00-.005.038 8.723 8.723 0 00-.195 2.987c.045.448.125.891.24 1.327a9.5 9.5 0 00.574 1.669c1.777 3.988 5.76 6.44 11.005 6.014a12.998 12.998 0 001.543-.22c4.327-.914 7.447-4.84 7.473-9.464v-.086a13.635 13.635 0 00-.103-1.715 14.444 14.444 0 00-3.809-8.268 8.486 8.486 0 012.021 5.495z"
                        fill="#fff"
                    />
                </mask>
                <g mask="url(#tokenlon-header-logo_svg__b)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.513 13.26c0 3.684-2.321 6.826-5.573 8.002a8.255 8.255 0 01-2.852.501c-3.127 0-5.854-1.719-7.309-4.276-.354-.623-.632-1.683-.822-2.584-.178-.854-1.292-1.077-1.783-.356-.17.247-.297.52-.374.81-.01.042-.022.084-.031.125a.123.123 0 00-.005.038 8.723 8.723 0 00-.195 2.987c.045.448.125.891.24 1.327a9.5 9.5 0 00.574 1.669c1.777 3.988 5.76 6.44 11.005 6.014a12.998 12.998 0 001.543-.22c4.327-.914 7.447-4.84 7.473-9.464v-.086a13.635 13.635 0 00-.103-1.715 14.444 14.444 0 00-3.809-8.268 8.486 8.486 0 012.021 5.495z"
                        fill="url(#tokenlon-header-logo_svg__paint1_linear)"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M111.819 13.857h1.736v8.384h4.636v1.477h-6.372v-9.861zM68.554 13.742h1.767v4.074l.013.01 3.55-4.084h1.948l-2.956 3.546 3.289 6.315h-1.816l-2.594-4.984-1.434 1.764v3.22h-1.767v-9.861zM144.948 23.69h-1.289a1.07 1.07 0 01-.925-.532l-3.585-6.189v1.34h.006v2.501h-.006v2.88h-1.767v-9.86h1.334c.279 0 .537.147.678.386l3.788 6.413v-6.8h1.771v9.8l-.005.062zM104.595 23.69h-1.297c-.382 0-.734-.202-.925-.532l-3.585-6.188v1.34h.005v2.5h-.005v2.88H97.02v-9.86h1.334c.28 0 .537.147.679.386l3.787 6.413v-6.8h1.771v9.8l.003.062zM44.28 15.22h-3.111v-1.478h7.973v1.477h-3.11v8.384H44.28v-8.384zM89.42 22.216v1.48h-6.374v-9.867h1.736v.006h4.317v1.473h-4.317v2.42h3.892v1.48h-3.892v3.009h4.637zM58.508 22.338c-1.33 0-2.116-1.648-2.116-3.684 0-2.035.786-3.683 2.116-3.683 1.391 0 2.123 1.648 2.123 3.683 0 2.036-.732 3.684-2.123 3.684zm3.604-5.846c-.193-.628-.46-1.159-.793-1.588a3.426 3.426 0 00-1.227-.996 3.792 3.792 0 00-3.156 0 3.455 3.455 0 00-1.221.996c-.34.428-.599.96-.793 1.588-.193.629-.284 1.34-.284 2.138 0 .797.091 1.522.284 2.156.194.633.454 1.171.793 1.618a3.505 3.505 0 005.605 0c.356-.49.624-1.037.793-1.618.193-.635.284-1.353.284-2.156 0-.803-.091-1.51-.285-2.138zM127.209 22.338c-1.331 0-2.117-1.648-2.117-3.684 0-2.035.787-3.683 2.117-3.683 1.391 0 2.122 1.648 2.122 3.683 0 2.036-.731 3.684-2.122 3.684zm3.604-5.846c-.194-.628-.46-1.159-.793-1.588a3.416 3.416 0 00-1.227-.996 3.791 3.791 0 00-3.156 0 3.445 3.445 0 00-1.221.996c-.34.428-.6.96-.793 1.588-.194.629-.284 1.34-.284 2.138 0 .797.09 1.522.284 2.156.193.633.453 1.171.793 1.618a3.505 3.505 0 005.605 0 5.24 5.24 0 00.792-1.618c.194-.635.284-1.353.284-2.156 0-.803-.093-1.51-.284-2.138z"
                    fill="#040000"
                />
                <defs>
                    <linearGradient
                        id="tokenlon-header-logo_svg__paint0_linear"
                        x1="0"
                        y1="32.099"
                        x2="26.595"
                        y2="32.099"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#2D2D4B" />
                        <stop offset="1" stopColor="#1D1D3A" />
                    </linearGradient>
                    <linearGradient
                        id="tokenlon-header-logo_svg__paint1_linear"
                        x1="7.519"
                        y1="27.565"
                        x2="28.404"
                        y2="27.565"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#95A1D4" />
                        <stop offset="1" stopColor="#594DA0" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
export default styled(Icon)`
    height: 20px;

    > svg {
        height: 100%;
        color: black;
    }
`;
