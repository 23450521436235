import styled from "styled-components";

interface IProps {
    className?: string;
}

const Icon: React.FC<IProps> = ({ className }) => {
    return (
        <div className={className}>
            <svg viewBox="0 0 181 34" xmlns="http://www.w3.org/2000/svg" role="img" className="fill-gray-9">
                <path
                    d="M15.8064 26.4891L35.4916 37.8397C35.6491 37.9292 35.8269 37.976 36.0078 37.9755C36.1887 37.975 36.3663 37.9272 36.5233 37.8368C36.6803 37.7465 36.8112 37.6166 36.9032 37.46C36.9951 37.3034 37.045 37.1255 37.048 36.9437L37.0513 16.0415C37.0493 15.8594 37 15.6809 36.9083 15.5237C36.8166 15.3666 36.6856 15.2363 36.5284 15.1457C36.3712 15.0552 36.1931 15.0074 36.0119 15.0073C35.8307 15.0072 35.6526 15.0547 35.4953 15.145L15.8064 26.4891Z"
                    fill="#333"
                />
                <path
                    d="M34.3474 10.2537L21.2798 0.210655C21.1403 0.105358 20.9769 0.0368035 20.8044 0.0112356C20.6319 -0.0143323 20.4558 0.003899 20.2921 0.0642694C20.1284 0.12464 19.9823 0.225231 19.8671 0.356879C19.752 0.488527 19.6714 0.64705 19.6328 0.818007L14.8547 23.1777L34.2414 11.9855C34.3913 11.9012 34.5178 11.7804 34.6092 11.6342C34.7007 11.488 34.7541 11.3211 34.7647 11.1487C34.7752 10.9763 34.7425 10.8041 34.6696 10.6477C34.5967 10.4913 34.486 10.3558 34.3474 10.2537Z"
                    fill="#333"
                />
                <path
                    d="M21.2711 52.7845L34.3723 42.7306C34.5077 42.6264 34.6156 42.4904 34.6864 42.3345C34.7573 42.1786 34.7889 42.0075 34.7785 41.8364C34.768 41.6653 34.7159 41.4994 34.6267 41.3534C34.5374 41.2073 34.4138 41.0855 34.2668 40.9988L14.8535 29.8154L19.6395 52.1767C19.6745 52.3481 19.7524 52.5077 19.8658 52.6406C19.9791 52.7734 20.1243 52.875 20.2875 52.9358C20.4508 52.9966 20.6267 53.0146 20.7988 52.9882C20.9709 52.9617 21.1334 52.8916 21.2711 52.7845Z"
                    fill="#333"
                />
                <path
                    d="M10.7597 26.4887L15.9158 2.3974C15.9606 2.18873 15.9402 1.97125 15.8575 1.77465C15.7748 1.57804 15.6337 1.41188 15.4536 1.29886C15.2735 1.18585 15.0631 1.13147 14.8511 1.14316C14.639 1.15485 14.4358 1.23203 14.269 1.36417L1.07725 11.4941C0.743022 11.751 0.472305 12.0821 0.286179 12.4615C0.100052 12.8409 0.00353824 13.2583 0.00415445 13.6813L4.37658e-06 39.3381C-0.00074845 39.7612 0.09563 40.1786 0.281634 40.5581C0.467638 40.9375 0.738248 41.2687 1.07239 41.5258L14.2609 51.6448C14.4289 51.7714 14.631 51.8441 14.8407 51.8532C15.0505 51.8622 15.2581 51.8073 15.4362 51.6956C15.6144 51.5839 15.7548 51.4207 15.839 51.2273C15.9232 51.0339 15.9472 50.8195 15.908 50.6121L10.7597 26.4887Z"
                    fill="#333"
                />
                <path
                    d="M113.428 19.464C114.37 19.4446 115.301 19.6764 116.126 20.1359C116.95 20.5953 117.639 21.266 118.122 22.0797L116.491 23.3428C116.143 22.8139 115.684 22.3677 115.147 22.0351C114.627 21.7234 114.032 21.5622 113.427 21.5693C112.882 21.5573 112.345 21.7075 111.884 22.0013C111.666 22.1459 111.49 22.3461 111.374 22.5817C111.257 22.8172 111.206 23.0795 111.223 23.3419C111.216 23.5909 111.269 23.8379 111.377 24.0619C111.482 24.2642 111.624 24.4447 111.796 24.5937C111.99 24.7612 112.205 24.9029 112.435 25.0152C112.685 25.1411 112.956 25.2777 113.25 25.4251L114.881 26.179C115.391 26.4118 115.884 26.6785 116.358 26.9772C116.766 27.2305 117.137 27.54 117.46 27.8966C117.748 28.2209 117.979 28.592 118.143 28.9941C118.31 29.4246 118.393 29.8837 118.386 30.3458C118.395 30.9541 118.266 31.5565 118.01 32.1075C117.768 32.6226 117.414 33.0768 116.974 33.4369C116.512 33.8108 115.985 34.096 115.42 34.2786C114.798 34.4825 114.146 34.5835 113.491 34.5775C112.354 34.5993 111.232 34.3039 110.252 33.7241C109.316 33.1602 108.541 32.3621 108.004 31.4076L109.657 30.1449C109.865 30.4583 110.097 30.7548 110.352 31.0315C110.606 31.3101 110.89 31.5592 111.2 31.7739C111.515 31.992 111.86 32.1638 112.224 32.284C112.627 32.4139 113.047 32.4774 113.47 32.4722C114.119 32.5033 114.76 32.312 115.288 31.9296C115.515 31.7549 115.696 31.5278 115.817 31.2675C115.938 31.0072 115.995 30.7213 115.983 30.4342C115.988 30.1782 115.935 29.9244 115.829 29.6919C115.723 29.4741 115.573 29.2814 115.388 29.1262C115.179 28.9524 114.954 28.8002 114.715 28.6719C114.458 28.5318 114.168 28.3878 113.845 28.24L112.237 27.4861C111.783 27.2739 111.342 27.037 110.914 26.7766C110.518 26.5378 110.154 26.2471 109.834 25.912C109.529 25.5925 109.283 25.2212 109.107 24.8152C108.924 24.377 108.834 23.9053 108.843 23.4303C108.834 22.8624 108.959 22.3006 109.207 21.7902C109.445 21.3071 109.784 20.8806 110.198 20.5386C110.63 20.1845 111.124 19.9139 111.654 19.7407C112.226 19.5529 112.826 19.4595 113.428 19.464Z"
                    fill="#333"
                />
                <path
                    d="M131.346 30.5034L134.146 23.4566L136.593 23.4572L131.808 34.5809H131.014L127.599 27.3118L124.226 34.5797H123.432L118.629 23.4541L121.097 23.4544L123.939 30.5685L127.225 23.2119H128.018L131.346 30.5034Z"
                    fill="#333"
                />
                <path
                    d="M141.971 23.2147C142.585 23.2075 143.196 23.305 143.778 23.5031C144.3 23.6825 144.778 23.9737 145.177 24.3565C145.582 24.7566 145.894 25.2409 146.092 25.7753C146.324 26.41 146.436 27.0826 146.423 27.7587L146.422 34.3403L144.261 34.34V33.1213C143.866 33.5885 143.368 33.9564 142.807 34.1955C142.172 34.463 141.489 34.595 140.801 34.5831C140.274 34.5896 139.75 34.5147 139.247 34.3611C138.811 34.2285 138.407 34.01 138.057 33.7183C137.733 33.4467 137.473 33.106 137.296 32.721C137.115 32.3172 137.025 31.8783 137.032 31.4356C137.015 30.9973 137.105 30.5616 137.293 30.166C137.481 29.7703 137.762 29.4266 138.112 29.1644C138.964 28.5706 139.987 28.2785 141.022 28.3339L144.085 28.3342L144.086 27.625C144.123 27.3104 144.089 26.9914 143.986 26.6919C143.883 26.3925 143.714 26.1205 143.491 25.8965C143.268 25.6724 142.997 25.5021 142.7 25.3984C142.402 25.2947 142.084 25.2602 141.771 25.2977C141.193 25.2842 140.621 25.4297 140.119 25.7185C139.602 26.04 139.14 26.4442 138.752 26.9152L137.429 25.5411C137.935 24.7892 138.627 24.1821 139.436 23.7794C140.227 23.4 141.094 23.2068 141.971 23.2147ZM141.285 32.7882C141.654 32.7907 142.02 32.7269 142.366 32.6C142.694 32.4806 142.996 32.3002 143.258 32.0682C143.515 31.8403 143.722 31.5611 143.865 31.2483C144.013 30.9253 144.088 30.5734 144.085 30.2178V30.0184L141.308 30.0181C140.812 29.9877 140.318 30.1031 139.886 30.3502C139.724 30.4583 139.593 30.6073 139.506 30.7823C139.419 30.9573 139.379 31.1522 139.39 31.3475C139.38 31.5596 139.425 31.7706 139.522 31.9594C139.619 32.1481 139.763 32.3078 139.941 32.4223C140.343 32.676 140.811 32.8034 141.285 32.7882H141.285Z"
                    fill="#333"
                />
                <path
                    d="M154.689 23.2158C155.42 23.2092 156.145 23.3565 156.816 23.6484C157.454 23.9258 158.028 24.3333 158.502 24.8451C158.979 25.3675 159.353 25.977 159.604 26.6406C159.874 27.3589 160.008 28.1218 160 28.8898C160.007 29.6613 159.873 30.4277 159.603 31.1501C159.353 31.8198 158.975 32.4336 158.489 32.9561C158.002 33.4776 157.413 33.8916 156.758 34.1718C156.104 34.4521 155.398 34.5925 154.687 34.5842C153.98 34.5924 153.279 34.4566 152.626 34.1851C152.022 33.9343 151.489 33.5385 151.072 33.0324L151.071 38.3287L148.758 38.3284L148.76 23.4586L150.898 23.4589L150.897 25.0324C151.299 24.4522 151.847 23.9897 152.485 23.6918C153.174 23.3683 153.928 23.2055 154.689 23.2158ZM154.314 25.3431C153.912 25.3441 153.512 25.4152 153.134 25.5534C152.745 25.6937 152.389 25.9124 152.087 26.1962C151.764 26.5051 151.509 26.8786 151.338 27.2927C151.135 27.7992 151.037 28.3422 151.051 28.8882C151.037 29.4332 151.131 29.9755 151.326 30.484C151.493 30.9012 151.744 31.2788 152.065 31.5923C152.364 31.882 152.72 32.1048 153.111 32.246C153.496 32.3858 153.903 32.4572 154.313 32.4569C154.755 32.4594 155.193 32.3728 155.602 32.2023C156 32.0369 156.36 31.7918 156.66 31.4819C156.968 31.1607 157.208 30.7801 157.366 30.363C157.703 29.425 157.703 28.398 157.367 27.4598C157.21 27.0448 156.974 26.6646 156.672 26.3407C156.376 26.0288 156.02 25.7801 155.625 25.6095C155.211 25.4299 154.765 25.3392 154.314 25.3431V25.3431Z"
                    fill="#333"
                />
                <path
                    d="M54.3017 34.3246L51.9656 34.3243L51.968 19.6982L54.3042 19.6986L54.3017 34.3246ZM57.2788 26.5466L64.0003 34.3261L61.1127 34.3255L54.3689 26.5463L60.4761 19.6995L63.3415 19.7001L57.2788 26.5466Z"
                    fill="#333"
                />
                <path
                    d="M69.1362 35.5685C68.7956 36.46 68.2293 37.2467 67.4934 37.8506C66.8084 38.3375 65.9841 38.5864 65.1456 38.5595C64.8435 38.5586 64.5418 38.54 64.2419 38.504C63.9446 38.4688 63.6501 38.4133 63.3604 38.3377V36.3655C63.625 36.3946 63.8602 36.4167 64.0661 36.4318C64.2715 36.4464 64.4697 36.4538 64.6607 36.4539C65.1239 36.4628 65.5821 36.3564 65.9944 36.1441C66.4659 35.8389 66.826 35.3879 67.0203 34.859L67.4607 33.8397L63.1426 23.4453L65.6329 23.4459L68.7179 31.3799L71.9374 23.4469L74.4056 23.4472L69.1362 35.5685Z"
                    fill="#333"
                />
                <path
                    d="M77.9316 24.7773C78.3565 24.28 78.888 23.8861 79.4861 23.6252C80.1356 23.3395 80.8382 23.196 81.5471 23.2044C82.2585 23.1964 82.9638 23.3371 83.6183 23.6176C84.2727 23.898 84.8621 24.3122 85.349 24.8337C85.8343 25.3566 86.2125 25.9705 86.4618 26.6404C86.7311 27.3629 86.8654 28.1292 86.8578 28.9007C86.8659 29.6687 86.7314 30.4315 86.4612 31.1499C86.2109 31.8134 85.8367 32.4226 85.3588 32.9448C84.8878 33.4548 84.3175 33.862 83.6834 34.1411C83.0163 34.4335 82.2952 34.5806 81.5675 34.5728C80.8041 34.5823 80.0479 34.4233 79.3523 34.107C78.7092 33.818 78.1567 33.3583 77.7539 32.7773V34.3284L75.6165 34.3281L75.6189 17.7075L77.9328 17.7078L77.9316 24.7773ZM81.1721 25.3317C80.7624 25.3315 80.3559 25.4026 79.9704 25.542C79.5796 25.6835 79.2229 25.9062 78.9235 26.1957C78.6032 26.5091 78.3516 26.8865 78.1851 27.3037C77.9896 27.812 77.8959 28.3542 77.9094 28.8991C77.8961 29.4486 77.9935 29.9951 78.1956 30.5058C78.3645 30.9244 78.62 31.3022 78.9451 31.6141C79.2451 31.8973 79.6018 32.1127 79.9914 32.2458C80.3709 32.3772 80.7694 32.4447 81.1708 32.4455C81.6216 32.4493 82.0682 32.3588 82.4823 32.1797C82.8767 32.0091 83.2329 31.7604 83.5298 31.4485C83.8318 31.1248 84.0678 30.7447 84.2244 30.3297C84.5614 29.3917 84.5616 28.3649 84.225 27.4268C84.0673 27.0096 83.8273 26.6288 83.5193 26.3073C83.2189 25.9975 82.859 25.7524 82.4614 25.5869C82.0529 25.416 81.6145 25.3293 81.1721 25.3317V25.3317Z"
                    fill="#333"
                />
                <path
                    d="M94.0006 23.2068C94.6871 23.2005 95.3683 23.3285 96.0061 23.5839C96.5992 23.8171 97.1381 24.1706 97.5893 24.6225C98.0405 25.0743 98.3942 25.6148 98.6284 26.2102C98.8817 26.8401 99.0089 27.5141 99.0029 28.1936C99.0035 28.4753 98.9849 28.7567 98.9473 29.0358C98.9218 29.2452 98.8814 29.4524 98.8264 29.656L90.7589 29.6548C90.8782 30.4822 91.3033 31.2341 91.9495 31.7604C92.618 32.2754 93.4432 32.5417 94.285 32.5142C94.8757 32.5228 95.4616 32.4059 96.0042 32.171C96.5287 31.9356 97.0127 31.6178 97.4378 31.2295L98.5612 32.8253C97.9961 33.3665 97.3393 33.8017 96.6217 34.1101C95.8681 34.427 95.0579 34.5853 94.2412 34.5753C93.442 34.5823 92.6488 34.4355 91.9044 34.143C91.2178 33.8777 90.5921 33.4745 90.0655 32.9581C89.5389 32.4417 89.1225 31.8229 88.8416 31.1393C88.5447 30.4312 88.3946 29.6697 88.4006 28.9013C88.3948 28.1338 88.5412 27.3729 88.8311 26.663C89.1048 25.9877 89.5093 25.3739 90.0211 24.8572C90.5314 24.3456 91.1337 23.9358 91.7959 23.6497C92.4923 23.3501 93.2431 23.1993 94.0006 23.2068ZM93.9334 25.1791C93.1924 25.1602 92.4705 25.4165 91.9057 25.8991C91.3331 26.4033 90.9451 27.0859 90.8039 27.8379L96.6889 27.8388C96.6974 27.4679 96.6259 27.0996 96.4793 26.7591C96.3328 26.4186 96.1148 26.114 95.8401 25.8662C95.3096 25.4103 94.6312 25.1658 93.9334 25.1791Z"
                    fill="#333"
                />
                <path
                    d="M106.52 23.3415C106.686 23.3399 106.852 23.3511 107.016 23.3749C107.156 23.397 107.284 23.4228 107.402 23.4525L107.401 25.5134L106.696 25.5131C106.281 25.5113 105.868 25.5749 105.473 25.7017C105.1 25.8177 104.755 26.01 104.459 26.2664C104.165 26.5265 103.932 26.8479 103.775 27.2081C103.598 27.6246 103.512 28.074 103.521 28.5267L103.52 34.3328L101.207 34.3324L101.208 23.4516L103.346 23.4519L103.345 24.9146C103.709 24.4134 104.19 24.01 104.746 23.74C105.3 23.4738 105.906 23.3375 106.52 23.3415Z"
                    fill="#333"
                />
            </svg>
        </div>
    );
};
export default styled(Icon)`
    height: 16px;

    > svg {
        overflow: inherit;
        height: 100%;
        color: black;
        position: relative;
        top: -5px;
    }
`;
