import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import { EditBtn, CancelBtn, SaveBtn, ConfirmBtn, RejectBtn } from "../Share/btn";
import ReactJson from "react-json-view";
import { JsonEditor } from "jsoneditor-react";
import ReactJsonViewCompare from "react-json-view-compare";
import * as flat from "flat";

const AlertSetting = (props) => {
    const pendingUrl = "/v3/setting-alert-v2";
    const apiService = useSelector((store) => store.global.apiService);
    const hostData = useSelector((store) => store.global.hostData);

    const [mapRawData, set_mapRawData] = useState({});
    const [input, set_input] = useState([]);
    const [hasPending, set_hasPending] = useState(false);
    const [pendingTarget, set_pendingTarget] = useState({});
    const [targetDiff, set_targetDiff] = useState({});
    const [isEdit, set_isEdit] = useState(false);

    const jsonEditorRef = useRef(null);

    const onButtonClick = (btnName) => {
        switch (btnName) {
            case "edit":
                set_isEdit(true);
                break;
            case "cancel":
                cook();
                set_isEdit(false);
                break;
            case "save":
                submit();
                break;
            case "confirm":
                confirmPending();
                break;
            case "reject":
                cancelPending();
                break;
        }
    };

    useEffect(() => cook(), []);

    const cook = async () => {
        const res = await apiService.getFromCore("/v3/all-alert-setting-v2");
        if (!res.success) {
            props.alert.error(res.reason || res.error);
            return;
        }
        const newInput = [];
        const newMapRawData = {};

        res.data?.forEach((c) => {
            newInput.push({ ...c });
            newMapRawData[c.name] = c;
        });
        set_input(newInput);
        set_mapRawData(newMapRawData);

        // get pending
        const pending = await apiService.getFromCore(pendingUrl);
        if (pending?.success && pending?.data?.length) {
            const parsedData = pending.data[0];
            set_hasPending(true);
            set_pendingTarget(parsedData);
            const newTargetDiff = {};
            let pendingInput = [...newInput];
            parsedData.change_list.forEach((t) => {
                switch (t.type) {
                    case "create_alert_v2":
                        pendingInput = [
                            {
                                ...t.data,
                                is_new: true,
                            },
                            ...pendingInput,
                        ];
                        break;
                    case "update_alert_v2":
                        newTargetDiff[t.data.name] = { ...t.data };
                        break;
                    case "delete_alert_v2":
                        pendingInput = pendingInput.map((p) => {
                            if (p.name === t.data.name) return { ...p, is_delete: true };
                            return p;
                        });
                        break;
                }
            });
            set_input(pendingInput);
            set_targetDiff(newTargetDiff);
        } else {
            set_hasPending(false);
            set_targetDiff({});
            set_pendingTarget({});
        }
    };

    const confirmPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.putToCore(pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            cook();
        }
    };

    const cancelPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.deleteToCore(pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to cancel pending!");

            cook();
        }
    };

    const parsedInput = () => {
        const dataArray = [];
        const inputMap = {};
        input.forEach((t) => {
            if (!t.is_delete) {
                inputMap[t.name] = true;

                if (!mapRawData[t.name])
                    dataArray.push({
                        type: "create_alert_v2",
                        data: {
                            name: t.name,
                            config: t.config,
                        },
                    });
                else if (
                    Object.entries(flat.flatten(mapRawData[t.name])).toString() !==
                    Object.entries(flat.flatten(t)).toString()
                ) {
                    const data = {
                        name: t.name,
                        config: t.config,
                    };
                    dataArray.push({ type: "update_alert_v2", data });
                }
            }
        });

        Object.keys(mapRawData).forEach((name) => {
            if (!inputMap[name])
                dataArray.push({
                    type: "delete_alert_v2",
                    data: { name },
                });
        });

        return dataArray;
    };

    const submit = async () => {
        try {
            jsonEditorRef?.current?.jsonEditor?.get();
        } catch (e) {
            props.alert.error("Can not save, please check the data!");
            return;
        }

        const data = JSON.stringify({ change_list: parsedInput() });
        const result = await apiService.postToCore(pendingUrl, data);

        if (result.success) {
            props.alert.success("success");
            set_isEdit(false);
            cook();
        } else props.alert.error(result.reason || result.error || "Failed to cancel pending!");
    };

    const editBtn = <EditBtn clickFunc={() => onButtonClick("edit")} />;
    const cancelBtn = <CancelBtn clickFunc={() => onButtonClick("cancel")} />;
    const saveBtn = <SaveBtn clickFunc={() => onButtonClick("save")} />;
    const confirmBtn = <ConfirmBtn clickFunc={() => onButtonClick("confirm")} />;
    const rejectBtn = <RejectBtn clickFunc={() => onButtonClick("reject")} />;

    const btnElements = () => {
        if (hasPending) {
            if (pendingTarget?.proposer && hostData?.CORE.id == pendingTarget.proposer) return rejectBtn;
            return (
                <span>
                    {confirmBtn} &emsp;{rejectBtn}
                </span>
            );
        }
        return isEdit ? (
            <span>
                {saveBtn} &emsp;{cancelBtn}
            </span>
        ) : (
            editBtn
        );
    };

    const className = (ele) => {
        if (ele.is_new) return "bg-is-new";
        if (ele.is_delete) return "bg-is-delete";
        return "";
    };
    return (
        <div className="px-3">
            <div className="setting-title d-flex justify-content-between">
                <div>Alert Settings </div>
                <div>{btnElements()}</div>
            </div>
            {isEdit ? (
                <JsonEditor
                    className="w-100"
                    value={input}
                    mode="code"
                    ref={jsonEditorRef}
                    allowedModes={["tree", "view", "form", "code", "text"]}
                    htmlElementProps={{ style: { height: "calc(100vh - 100px)", width: "100%" } }}
                    onChange={(value) => set_input(value)}
                />
            ) : (
                <div className="row">
                    {input.map((elem, id) => (
                        <div className="col-xl-3 col-12" key={id}>
                            {targetDiff[elem.name] ? (
                                <div className="panel" style={{ height: "calc(100% - 30px)" }}>
                                    <div className="panel-header">
                                        <div className="panel-title">
                                            <span
                                                className={
                                                    targetDiff[elem.name].name != elem.name
                                                        ? "pl-2 dashed-border w-100"
                                                        : ""
                                                }
                                            >
                                                {targetDiff[elem.name].name}{" "}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="panel-body pb-0">
                                        <div>
                                            <ReactJsonViewCompare
                                                oldData={elem.config}
                                                newData={targetDiff[elem.name].config}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={"panel " + className(elem)} style={{ height: "calc(100% - 30px)" }}>
                                    <div className="panel-header">
                                        <div className="panel-title">{elem.name} </div>
                                    </div>

                                    <div className="panel-body pb-0">
                                        <div>
                                            <div className="card-body">
                                                <ReactJson
                                                    src={elem.config}
                                                    name={"config"}
                                                    enableClipboard={false}
                                                    displayObjectSize={false}
                                                    displayDataTypes={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default withAlert()(AlertSetting);
