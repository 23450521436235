import React from "react";

const KProgress = (props) => {
    const targetPosition = props.targetPosition ? props.targetPosition : 70;
    const percent = props.percent;

    let statusProgress = <div />;

    if (!percent || percent < 66.6) {
        statusProgress = (
            <React.Fragment>
                <div className="k-progress-value" style={{ width: "0%" }} />
                <div className="k-progress-warnning" style={{ width: (percent * 70) / 100 + "%" }} />
            </React.Fragment>
        );
    } else if (percent >= 66.6 && percent <= 100) {
        statusProgress = (
            <React.Fragment>
                <div className="k-progress-value" style={{ width: "0%" }} />
                <div className="k-progress-value-active" style={{ width: (percent * 70) / 100 + "%" }} />
            </React.Fragment>
        );
    } else {
        if (percent < 140) {
            statusProgress = (
                <React.Fragment>
                    <div className="k-progress-value" style={{ width: (percent * 70) / 100 + "%" }} />
                    <div className="k-progress-value-active" style={{ width: "70%" }} />
                </React.Fragment>
            );
        } else {
            statusProgress = (
                <React.Fragment>
                    <div className="k-progress-value" style={{ width: "100%" }} />
                    <div className="k-progress-value-active" style={{ width: "70%" }} />
                </React.Fragment>
            );
        }
    }

    return (
        <div className="k-progress">
            <div className={"d-flex mb-2 " + (percent > 100 ? "justify-content-end" : "")}>{props.currentValue}</div>
            <div className="k-progress-bar">
                <div className="k-progress-target" style={{ left: targetPosition + "%" }}>
                    <div className="k-progress-label d-flex justify-content-between">
                        <span>Target:</span>
                        <span>{props.targetValue}</span>
                    </div>
                </div>
                {/* <div className="k-progress-value" style={{width: percent + '%'}}></div>
          <div className="k-progress-value-active" style={{width: (percent > 100 ? targetPosition : percent) + '%'}}></div> */}
                {statusProgress}
            </div>
        </div>
    );
};

export default KProgress;
