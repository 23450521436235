import { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useLocalStorage from "hook/useLocalStorage";
import moment from "moment";

import otherActivitiesSlice from "./otherActivities.slice";
import { sumBig } from "services/utils/converter";

import StatusCell from "./StatusCell";
import {
    TableData,
    SetRateDetailView,
    TradeDetailView,
    DepositDetailView,
    TransferDetailView,
    WithdrawDetailView,
    BlankDetailView,
    CrossChainTradeDetailView,
} from "../Share";
import FetchConfig from "./FetchConfig";
import FilterConfig from "./FilterConfig";

const backgroundRow = ["#ebfff0", "#fceceb", "#fcfade", "#fcfade", "#e1fcfc", "#dfdefa", "#ffdeff"];

const getTrProps = (state, rowInfo, instance) => {
    if (
        rowInfo &&
        rowInfo.original &&
        rowInfo.original.mining_status == "failed" &&
        rowInfo.original.result &&
        !rowInfo.original.result.is_replaced
    ) {
        return {
            style: {
                background: backgroundRow[0],
            },
        };
    }
    return {};
};

const useSubComponent = (tokens, networkId) => {
    const SubComponent = useCallback(
        ({ original }) => {
            const rowData = original;
            switch (rowData.action) {
                case "set_rates":
                    return <SetRateDetailView data={rowData} tokens={tokens} networkId={networkId} />;
                case "cancel_set_rates":
                    return <BlankDetailView data={rowData} tokens={tokens} networkId={networkId} />;
                case "trade":
                    return <TradeDetailView data={rowData} networkId={networkId} />;
                case "perpetual_trade":
                    return <TradeDetailView data={rowData} networkId={networkId} />;
                case "cross_margin_transfer":
                    return <TransferDetailView data={rowData} showSpotToMargin />;
                case "cross_chain_trade":
                    return <CrossChainTradeDetailView data={rowData} />;
                case "withdraw":
                    return <WithdrawDetailView data={rowData} networkId={networkId} />;
                case "deposit":
                    return <DepositDetailView data={rowData} networkId={networkId} />;
                case "borrow":
                    return <TransferDetailView data={rowData} />;
                case "borrow_transfer":
                    return <TransferDetailView data={rowData} />;
                case "transfer_repay":
                    return <TransferDetailView data={rowData} />;
                default:
                    return <div />;
            }
        },
        [networkId, tokens]
    );

    return SubComponent;
};

window.results = [];

const OtherActivities = () => {
    const dispatch = useDispatch();

    // TODO: WTF values of fetchedActions is string "true"
    const { networkId } = useSelector((state) => state.global, shallowEqual);
    const tokens = useSelector((state) => state.global.tokens, shallowEqual);
    const filterParams = useSelector((state) => state.otherActivities.filterParams);
    const activities = useSelector((state) => state.otherActivities.activities);
    const lastTimeFetched = useSelector((state) => state.otherActivities.lastTimeFetched);

    const SubComponent = useSubComponent(tokens, networkId);

    const intervalRef = useRef();

    const getDisplayData = () => {
        const shouldFilterAction = !!filterParams.action.length;
        const shouldFilterDestination = Object.values(filterParams.destination).includes(true);
        const shouldFilterExchange = Object.values(filterParams.exchange).includes(true);
        const shouldFilterMining = Object.values(filterParams.mining).includes(true);

        return activities.filter((act) => {
            let passed = true;

            if (shouldFilterAction) {
                passed &= filterParams.action.includes(act.actionDetail);
            }

            if (shouldFilterDestination) {
                passed &= !!filterParams.destination[act.destination];
            }

            if (shouldFilterExchange) {
                passed &= !!filterParams.exchange[act.exchange_status];
            }

            if (shouldFilterMining) {
                passed &= !!filterParams.mining[act.mining_status];
            }

            return passed;
        });
    };

    useEffect(() => {
        const intv = intervalRef.current;

        const cleanup = () => {
            if (intv) {
                clearInterval(intv);
            }
        };

        cleanup();
        intervalRef.current = setInterval(() => {
            dispatch(otherActivitiesSlice.actions.fetchRatesRequest());
        }, 30_000);

        return cleanup;

        // Fetch again in 30s from the last time
    }, [dispatch, lastTimeFetched]);

    useEffect(() => {
        // First time fetch
        dispatch(otherActivitiesSlice.actions.fetchRatesRequest());
    }, [dispatch]);

    const [absTime, setAbsTime] = useLocalStorage("other-activities-absolute-time", false);

    const timezone = useSelector((state) => state.global.timezone);

    const ColumnConfig = [
        {
            Header: "Action",
            id: "Action",
            accessor: "actionDetail",
            minWidth: 200,
            maxWidth: 220,
        },
        {
            Header: "Destination",
            accessor: "destination",
            width: 120,
        },
        {
            Header: "Amount",
            id: "Amount",
            accessor: "display_amount",
            aggregate: (vals) => sumBig(vals, 0),
            Aggregated: (row) => {
                return <span>{row.value} (sum)</span>;
            },
            minWidth: 200,
            maxWidth: 300,
        },
        {
            Header: "Time",
            id: "Timestamp",
            accessor: (d) =>
                absTime
                    ? moment(+d.timestamp)
                          .utcOffset(timezone.offset || 0)
                          .format("YYYY-MM-DD, HH:mm:ss")
                    : moment(+d.timestamp).fromNow(),
            sortMethod: (a, b) => {
                return +a.timestamp > +b.timestamp ? 1 : -1;
            },
            minWidth: 150,
        },
        {
            Header: "Exchange",
            accessor: "exchange_status",
            Cell: StatusCell,
            width: 110,
        },
        {
            Header: "Mining",
            accessor: "mining_status",
            Cell: StatusCell,
            width: 110,
        },
        {
            expander: true,
            Header: () => <strong>More</strong>,
            width: 65,
            Expander: ({ isExpanded }) => <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>,
            style: {
                cursor: "pointer",
                fontSize: 25,
                padding: "0",
                textAlign: "center",
                userSelect: "none",
            },
        },
    ];

    return (
        <section className="container-fluid">
            <nav className="px-0 py-4 nav-sub">
                <h4 className="page-tile">Activities</h4>
            </nav>

            <div className="row">
                <div className="col-sm-8">
                    <div className="panel panel-table">
                        <div className="panel-header">
                            <TableData
                                data={getDisplayData()}
                                title="Activities"
                                columns={ColumnConfig}
                                initialPageSize={200}
                                isShowPageSize={true}
                                isShowPaginate={true}
                                overWriteProps={{
                                    freezeWhenExpanded: true,
                                    SubComponent,
                                    getTrProps,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <FetchConfig setAbsTime={setAbsTime} absTime={absTime} />

                    <FilterConfig />
                </div>
            </div>
        </section>
    );
};

export default OtherActivities;
