import { Switch } from "../Share";

const OtherActivitiesView = (props) => {
    return (
        <section className="container-fluid">
            <nav className="px-0 py-4 nav-sub">
                <h4 className="page-tile">Reserve laboratory</h4>
            </nav>

            <div className="row">
                <div className="col-sm-8">
                    <div className="panel panel-table">
                        <div className="panel-header">
                            <h4 className="panel-title pb-0">Request response</h4>
                        </div>
                        <div className="panel-body">
                            <div className="mx-3">{props.renderResult()}</div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-4">
                    <div className="panel sticky">
                        <div className="panel-header">
                            <h4 className="panel-title">Request config</h4>
                        </div>
                        <div className="panel-body">
                            <div className="panel-row">
                                <button className="btn btn-default btn-lg mt-4 mr-2" onClick={props.toggleAdminBox}>
                                    Reset
                                </button>
                                <button className="btn btn-success btn-lg mt-4 " onClick={props.onSendRequest}>
                                    Submit
                                </button>
                                <div className="dropdown-divider" />

                                <div className="form-group">
                                    <label>Endpoint: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={props.endPoint}
                                        onChange={(e) => props.onChangeInput(e, "endPoint")}
                                        placeholder="http://xxx.xxx.xxx.xxx:port/api/v1/employees?a=12&b=34"
                                        required
                                    />
                                </div>

                                <div className="dropdown-divider" />

                                <div className="form-group">
                                    <label className="mb-0 mt-2">Use custom setting:&emsp; </label>
                                    <Switch
                                        onChange={(checked) => props.onCheck(!checked)}
                                        checked={props.useCurrentCore ? false : true}
                                    />
                                </div>

                                {props.useCurrentCore ? (
                                    <div />
                                ) : (
                                    <div className="form-group">
                                        <label>ID: </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="id"
                                            value={props.keyId}
                                            onChange={(e) => props.onChangeInput(e, "keyId")}
                                        />
                                        <label>Key: </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="key"
                                            value={props.keyString}
                                            onChange={(e) => props.onChangeInput(e, "keyString")}
                                        />
                                    </div>
                                )}

                                <div className="form-group">
                                    <label htmlFor="exampleFormControlSelect1">Type:</label>
                                    <select className="form-control" onChange={props.onChangeType}>
                                        <option value="GET">GET</option>
                                        <option value="POST">POST</option>
                                        <option value="PUT">PUT</option>
                                        <option value="DELETE">DELETE</option>
                                    </select>
                                </div>

                                <div className="dropdown-divider" />

                                {props.type !== "GET" && (
                                    <div>
                                        <h6 className="panel-title">Request body</h6>
                                        {props.renderEditListToken()}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OtherActivitiesView;
