import { useEffect, useState } from "react";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "components/_base/Toast/slice";
import { RootState } from "reducers";

type Config = Record<string, Record<string, number>>;

const RFQTradeSettings: React.FC = () => {
    const apiService = useSelector((state: RootState) => state.global.apiService);
    const dispatch = useDispatch();

    type ErrorMap = {
        [key: string]: string;
    };

    const [config, setConfig] = useState<Config>({});
    const [errors, setErrors] = useState<ErrorMap>({});

    useEffect(() => {
        const run = async () => {
            // @ts-ignore
            const result = await apiService.getRFQTradeSettings();

            if (result.data?.success) {
                setConfig(result.data.data as Config);
            }
        };

        run();
    }, [apiService]);

    const handleChangeInputValue = (exchangeName: string, configName: string, value: number) => {
        const errMap = { ...errors };
        if (value < 0) {
            errMap[exchangeName + configName] = "Must be ≥ 0";
            setErrors(errMap);
        } else {
            delete errMap[exchangeName + configName];
            setErrors(errMap);
        }
        setConfig((config) =>
            produce(config, (draft) => {
                const exchange = draft[exchangeName];

                if (exchange) {
                    exchange[configName] = value;
                }
            })
        );
    };

    const handleClickSubmit = async () => {
        // @ts-ignore
        const result = await apiService.postRFQTradeSettings(config);
        console.log({ result });

        if (result.error) {
            dispatch(toast({ type: "error", text: result.error }));
        } else {
            dispatch(toast({ type: "success", text: "Updated successfully" }));
        }
    };

    return (
        <div className="panel">
            <div className="panel-header">
                <div className="panel-title">RFQ Trade Settings</div>
            </div>
            <div className="panel-body">
                <div className="panel-row row">
                    {Object.keys(config)
                        .map((exchangeName) => {
                            const configs = Object.entries(config[exchangeName]).map(([name, value]) => ({
                                name,
                                value,
                            }));

                            return {
                                exchangeName,
                                configs,
                            };
                        })
                        .map(({ exchangeName, configs }) => (
                            <div key={exchangeName} className="col-xl-4 col-12">
                                <div className="panel">
                                    <div className="panel-header">
                                        <div className="panel-title">{exchangeName}</div>
                                    </div>

                                    <div className="panel-body">
                                        {configs.map((config) => (
                                            <div key={config.name}>
                                                <div className="d-flex pl-3 pr-3 mt-2 mb-2">
                                                    <div className="text-dark">{config.name}</div>
                                                    <div className="ml-auto" style={{ maxWidth: "120px" }}>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            aria-describedby="basic-addon2"
                                                            onChange={(e) =>
                                                                handleChangeInputValue(
                                                                    exchangeName,
                                                                    config.name,
                                                                    +e.target.value
                                                                )
                                                            }
                                                            value={config.value}
                                                        />
                                                        {errors[exchangeName + config.name] ? (
                                                            <small className="text-danger">
                                                                {errors[exchangeName + config.name]}
                                                            </small>
                                                        ) : (
                                                            <div />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
                <button
                    type="button"
                    className="btn btn-success ml-4"
                    onClick={handleClickSubmit}
                    disabled={Object.keys(errors).length > 0}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default RFQTradeSettings;
