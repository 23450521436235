import ConfigTable from "./table";
import "./style.css";

const Integrations = () => (
    <div className="panel panel-table">
        <ConfigTable
            pendingUrl={"/v3/setting-integration"}
            fields={{
                name: {
                    title: "name",
                },
                sample_size: {
                    title: "sample size",
                },
                prices_interval_second: {
                    title: "prices interval second",
                },
                volatility_period_second: {
                    title: "volatility period second",
                },
                target_adjust: {
                    title: "target adjust",
                },
                weight: {
                    title: "weight",
                },
                ddof: {
                    title: "ddof",
                },
                ttl: {
                    title: "ttl",
                },
            }}
        />
    </div>
);

export default Integrations;
