import React from "react";

const Modal = (props) => {
    let modalSize = props.size ? "modal-" + props.size : "";
    return (
        <div className={"modal fade " + props.className} id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className={"modal-dialog " + modalSize} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{props.title}</div>
                        {props.setting || (
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        )}
                    </div>
                    <div className="modal-body px-0">{props.content}</div>
                    {props.footer && <div className="modal-footer">{props.footer}</div>}
                </div>
            </div>
        </div>
    );
};

export default Modal;
