import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withAlert } from "react-alert";
import Masonry from "react-masonry-component";
import { JsonEditor } from "jsoneditor-react";

import { getAllTokens } from "services/configuration";
import { saveTokens } from "actions/globalActions";
import { TableData, Switch } from "../../Share";
import { EditBtn, CancelBtn, SaveBtn, ConfirmBtn, RejectBtn, AdvanceBtn, BackBtn } from "../Share/btn";


const ConfigTable = (props) => {
    const apiService = useSelector((store) => store.global.apiService);
    const hostData = useSelector((store) => store.global.hostData)
    const tokens = useSelector((store) => store.global.tokens);
    const dispatch = useDispatch();

    const [mapRawData, set_mapRawData] = useState({})
    const [input, set_input] = useState([])
    const [hasPending, set_hasPending] = useState(false)
    const [pendingTarget, set_pendingTarget] = useState({})
    const [targetDiff, set_targetDiff] = useState({})
    const [isEdit, set_isEdit] = useState(false)
    const [showEditor, set_showEditor] = useState(false)
    const [searchTokens, set_searchTokens] = useState([])

    const jsonEditorRef = useRef(null)

    useEffect(() => cook(), [tokens])

    const filterInput = () => {
        if (!searchTokens?.length) return input

        const tokenIds = searchTokens.map((s) => s.value);
        return input.filter(t => tokenIds.includes(+t.asset_id))
    }

    const getAssets = async () => {
        const assets = await getAllTokens(apiService)
        if (assets.success) await dispatch(saveTokens(assets.data));
    };

    const cook = async () => {
        const newInput = []
        const newMapRawData = {}
        Object.keys(tokens).map(tokenId => {
            const standardize = {
                ...tokens[tokenId].rebalance_quadratic,
                asset_id: tokenId,
                symbol: tokens[tokenId].symbol,
            }
            newInput.push({ ...standardize })
            newMapRawData[tokenId] = standardize
        })
        set_input(newInput)
        set_mapRawData(newMapRawData)

        // get pending
        const pending = await apiService.getFromCore(props.pendingUrl);
        if (pending?.success && pending?.data?.length) {
            const parsedData = pending.data[0];
            set_hasPending(true)
            set_pendingTarget(parsedData)
            const newTargetDiff = {};
            parsedData.change_list.forEach((t) => {
                newTargetDiff[t.data.asset_id] = {
                    ...t.data.rebalance_quadratic,
                    asset_id: t.data.asset_id,
                }
            });
            set_targetDiff(newTargetDiff)

        } else {
            set_hasPending(false)
            set_pendingTarget({})
        }
    }

    const confirmPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.putToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            await getAssets()
            cook()
        }
    };

    const cancelPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.deleteToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to cancel pending!");

            cook()
        }
    };

    const onChangeInput = (field, value, rowId) => {
        set_input(input.map(row => {
            if (row.asset_id === rowId) {
                row[field] = value
                return row
            }
            return row
        }))
    }

    const parsedInput = () => {
        const dataArray = [];

        input.forEach((t) => {
            if (Object.entries(mapRawData[t.asset_id]).toString() !== Object.entries(t).toString()) {
                const data = { asset_id: +t.asset_id }
                data.rebalance_quadratic = {
                    size_a: +t.size_a,
                    size_b: +t.size_b,
                    size_c: +t.size_c,
                    price_a: +t.price_a,
                    price_b: +t.price_b,
                    price_c: +t.price_c,
                    price_offset: +t.price_offset,
                }

                dataArray.push({ type: "update_asset", data });
            }
        });
        return dataArray;
    }

    const submit = async () => {
        if (showEditor) {
            try {
                jsonEditorRef?.current?.jsonEditor?.get()
            } catch (e) {
                props.alert.error("Can not save, please check the data!");
                return
            }
        }

        const data = JSON.stringify({ change_list: parsedInput() });
        const result = await apiService.postToCore(props.pendingUrl, data);

        if (result.success) {
            props.alert.success("success");
            set_isEdit(false)
            set_showEditor(false)
            cook()
        }
        else props.alert.error(result.reason || result.error || "Failed to cancel pending!");
    };

    const onButtonClick = (btnName) => {
        switch (btnName) {
            case "edit":
                set_isEdit(true)
                break;
            case "cancel":
                cook()
                set_isEdit(false)
                break;
            case "save":
                submit();
                break;
            case "confirm":
                confirmPending();
                break;
            case "reject":
                cancelPending();
                break;
            case "advance":
                set_showEditor(true)
                break;
            case "back":
                set_showEditor(false)
                break;
        }
    }

    const editBtn = <EditBtn clickFunc={() => onButtonClick("edit")} />
    const cancelBtn = <CancelBtn clickFunc={() => onButtonClick("cancel")} />
    const saveBtn = <SaveBtn clickFunc={() => onButtonClick("save")} />
    const confirmBtn = <ConfirmBtn clickFunc={() => onButtonClick("confirm")} />
    const rejectBtn = <RejectBtn clickFunc={() => onButtonClick("reject")} />
    const advanceBtn = <AdvanceBtn clickFunc={() => onButtonClick("advance")} />
    const backBtn = <BackBtn clickFunc={() => onButtonClick("back")} />

    const btnElements = () => {
        if (hasPending) {
            if (pendingTarget?.proposer && hostData?.CORE.id == pendingTarget.proposer)
                return [rejectBtn]
            return [confirmBtn, rejectBtn]
        }
        return isEdit ? [advanceBtn, saveBtn, cancelBtn] : [editBtn]
    }


    const renderEditable = (cell) => {
        const updateField = cell.column.id;
        const data = props.fields[updateField]




        return (
            <div>
                <div
                    contentEditable={!data.const}
                    suppressContentEditableWarning
                    onBlur={(e) => onChangeInput(updateField, e.target.innerHTML, cell.original.asset_id)}
                    dangerouslySetInnerHTML={{ __html: cell.original[updateField] }}
                    onPaste={e => {
                        e.preventDefault()
                        document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
                    }}
                />
            </div>
        );
    };

    const boolCell = (curV, isChange, newV) => {
        if (isChange)
            return <span>
                <p className={newV ? "text-success m-0" : "text-danger mb-0"}
                    style={{ border: "1px dashed blue" }}>
                    {newV ? "Enabled" : "Disabled"}</p>
                <p className="text-warning mb-0" >Old: {curV ? "Enabled" : "Disabled"}</p>
            </span>

        return <p className={curV ? "text-success m-0" : "text-danger mb-0"}>{curV ? "Enabled" : "Disabled"}</p>
    };

    const cellFunc = (row, field_name, field) => {
        let newVal = targetDiff[row.original.asset_id]?.[field_name]
        const hasChange = hasPending && newVal !== undefined && newVal != row.value
        if (field.type == "bool") return boolCell(row.value, hasChange, newVal)
        if (hasChange) {
            return <div>
                <div style={{ border: "1px dashed blue" }}>{newVal != null ? newVal : 'null'}</div>
                <p className="text-center mb-0"><span className="text-warning">Old: {field.cell ? field.cell(row) : row.value}</span></p>
            </div>
        }
        return <div >{field.cell ? field.cell(row) : row.value}</div>
    }

    const columns = Object.keys(props.fields).map((field) => ({
        Header: props.fields[field].title,
        id: field,
        accessor: field,
        Cell: (isEdit && !props.fields[field].const) ?
            renderEditable :
            (cell => cellFunc(cell, field, props.fields[field]))

    }));

    const masonryOptions = {
        transitionDuration: 0,
        percentPosition: true,
        columnWidth: props.columnWidth || 1,
        enableResizableChildren: true,
    };

    return (
        <div >
            <div className="col-sm-12 p-0" style={{ height: "100vh" }}>
                <div className="panel-header">
                    <div className="panel-title text-dark p-0">
                        {props.title}
                        {showEditor && <span>{backBtn} {saveBtn}</span>}
                    </div>
                    <Masonry options={masonryOptions}>
                        {
                            showEditor ? (
                                <JsonEditor
                                    className="w-100" value={input} mode="code" ref={jsonEditorRef}
                                    allowedModes={["tree", "view", "form", "code", "text"]}
                                    htmlElementProps={{ style: { height: "calc(100vh - 100px)", width: "100%" } }}
                                    onChange={(value) => set_input(value)}
                                />
                            ) :
                                <div className="col-sm-12 p-0 mt-3">
                                    <TableData
                                        searchTokens={searchTokens}
                                        searchChange={set_searchTokens}
                                        style={{ maxHeight: "calc(100vh - 140px)" }}
                                        data={filterInput()} buttonElements={btnElements()} columns={columns} />
                                </div>
                        }
                    </Masonry>
                </div>
            </div>
        </div >
    );
}

export default withAlert()(ConfigTable);
