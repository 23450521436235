import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-alert";

import Header from "./components/Header";
import Navigator from "./components/Navigator";
import Dashboard from "./components/Dashboard";
import { Orders } from "./components/Orders";
import { Configuration } from "./components/Configuration";
import OtherActivities from "./components/OtherActivities";
import { Lab } from "./components/Lab";
import { AlertTemplate } from "./components/Share";
import TradeLog from "components/TradeLogZeroX";
import QuoteZeroX from "components/QuoteZeroX";
import Integrations from "components/Integrations";

const options = {
    position: "bottom center",
    timeout: 9000,
    offset: "30px",
    transition: "scale",
    limit: 2,
};

const Routes = () => (
    <Router>
        <Provider template={AlertTemplate} {...options}>
            <Header />
            <Navigator />
            <Route exact path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/orders" component={Orders} />
            <Route path="/activities" component={OtherActivities} />
            <Route path="/trade_log" component={TradeLog} />
            <Route path="/quote_0x" component={QuoteZeroX} />
            <Route path="/integrations" component={Integrations} />

            <Switch>
                <Route exact path="/configuration" component={Configuration} />
                <Route exact path="/configuration/:category" component={Configuration} />
            </Switch>

            <Route path="/lab" component={Lab} />
        </Provider>
    </Router>
);

export default Routes;
