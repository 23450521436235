import React, { Component } from "react";
// import PowerUserView from "./sample.view"
// import { connect } from "react-redux"

// @connect((store) => {
//   let apiService = store.global.apiService
//   let hostData = {...store.global.hostData}

//   return {
//     apiService: apiService,
//     host: store.global.host,
//     hostData: hostData,
//    }

// })

export default class CSVExport extends Component {
    // constructor() {
    //   super();
    //   this.state = {
    //   }
    // }

    export() {
        if (!this.props.data) {
            alert("No data to export");
            return;
        }

        let csvHeader = "data:text/csv;charset=utf-8,";

        let csvColumnsTitle =
            Object.keys(this.props.columns)
                .map((key) => (this.props.columns[key].title ? this.props.columns[key].title : key))
                .join(",") + "\n";
        let csvContent = "";
        csvContent += this.props.data
            .map((d) => {
                return Object.keys(this.props.columns)
                    .map((key) => {
                        return this.props.columns[key].accessor
                            ? this.props.columns[key].accessor(d)
                            : d[key]
                            ? d[key]
                            : "";
                    })
                    .join(",");
            })
            .join("\n")
            .replace(/(^\{)|(\}$)/gm, "");

        let csvData = csvHeader + csvColumnsTitle + csvContent;

        let dataCSV = encodeURI(csvData);

        let link = document.createElement("a");
        link.setAttribute("href", dataCSV);
        link.setAttribute("download", new Date().toUTCString() + " " + (this.props.name ? this.props.name : ""));
        link.click();
    }

    render() {
        return (
            <button type="button" className="btn" onClick={this.export.bind(this)}>
                Export
            </button>
        );
    }
}
