import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITokenDetail, ITokenWithQuoteDetail } from "./model";

interface IState {
    fromAmount: number;
    toAmount: number;
    tokens: ITokenDetail[];
    tokensWithQuotes: ITokenWithQuoteDetail[];
    isLoading: boolean;
}

const initialState: IState = {
    fromAmount: 2,
    toAmount: 10,
    tokens: [],
    tokensWithQuotes: [],
    isLoading: false,
};

const quote0xSlice = createSlice({
    name: "quote0x",
    initialState,
    reducers: {
        setConstraintAmounts: (state, action: PayloadAction<{ from: number; to: number }>) => {
            const { from, to } = action.payload;

            state.fromAmount = from;
            state.toAmount = to;
        },

        fetchEnabledTokensRequest: () => {},
        fetchEnabledTokensSuccess: (state, action: PayloadAction<ITokenDetail[]>) => {
            const tokens = action.payload;
            state.tokens = tokens;
        },
        fetchEnabledTokensFailure: () => {},

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fetchQuotesRequest: (state, action: PayloadAction<{ amounts: number[] }>) => {
            state.isLoading = true;
        },
        fetchQuotesSuccess: () => {},
        fetchQuotesFailure: (state) => {
            state.isLoading = false;
        },
        fetchQuoteSuccess: (state, action: PayloadAction<ITokenWithQuoteDetail>) => {
            const detail = action.payload;
            const index = state.tokensWithQuotes.findIndex((token) => token.address === detail.address);

            if (index !== -1) {
                state.tokensWithQuotes[index] = action.payload;
            } else {
                state.tokensWithQuotes.push(action.payload);
            }
        },
    },
});

export default quote0xSlice;
