import Web3 from "web3";
import BaseEthereumProvider from "./baseProvider";

import CONSTANTS from "../../constants";
import Crypto from "crypto";
import { qs } from "../../utils/converter";
const FETCH_TIMEOUT = 20000;

export default class HttpProvider extends BaseEthereumProvider {
    constructor(addresses, nodeEndpoint) {
        super(addresses, nodeEndpoint);

        if (!addresses) {
            return;
        }

        this.wrapperAddress = addresses.wrapper;
        this.reserveAddress = addresses.reserve;

        this.initRPC(nodeEndpoint);

        // this.initContract()
        this.extractUrl = qs("extractUrl");
    }

    initRPC(nodeEndpoint = CONSTANTS.NODE.INFURA.ENDPOINT) {
        this.rpc = new Web3(new Web3.providers.HttpProvider(nodeEndpoint, 9000));
        this.erc20Contract = new this.rpc.eth.Contract(CONSTANTS.ABIS.ERC20);

        this.wrapperContract = new this.rpc.eth.Contract(CONSTANTS.ABIS.KYBER_WRAPPER, this.wrapperAddress);
    }

    signHeader(message, secretKey) {
        return Crypto.createHmac("sha512", secretKey).update(message).digest("hex");
    }

    paramQuery(data) {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
        // For application/x-www-form-urlencoded, spaces are to be replaced by '+',
        // so one may wish to follow a encodeURIComponent replacement with an additional
        // replacement of "%20" with "+".
        return Object.keys(data)
            .sort()
            .map((k) => {
                if (data[k] instanceof Array) {
                    return data[k].map((i) => this.encodeComponent(k, i)).join("&");
                }
                return this.encodeComponent(k, data[k]);
            })
            .join("&");
    }

    formEncodeURIComponent(uri) {
        return encodeURIComponent(uri).replace(/%20/g, "+");
    }

    encodeComponent(key, value) {
        if (value instanceof Array) {
            return value.map((i) => this.formEncodeURIComponent(key) + "=" + this.formEncodeURIComponent(i)).join("&");
        } else {
            return this.formEncodeURIComponent(key) + "=" + this.formEncodeURIComponent(value);
        }
    }

    newSigningSend(
        path,
        method,
        body,
        queryParams,
        includeData = true,
        hostUrl = this.hostUrl,
        userName = this.userName,
        keyString = this.keyString,
        returnStatus
    ) {
        const data = body || {};
        const fetchParams = {};
        const containPrams = path.indexOf("?") > -1;

        if (queryParams) {
            path = path + "?" + this.paramQuery(queryParams);
        }

        const url = hostUrl ? hostUrl + path : path;
        const digest = body ? "SHA-256=" + Crypto.createHash("SHA256").update(data).digest("base64") : "";
        let signString = "";

        fetchParams.headers = {
            digest,
            nonce: new Date().getTime(),
            "content-length": Buffer.byteLength(data),
            "access-control-request-headers": "nonce, digest, content-length, signature",
        };

        if (keyString) {
            const signingHeader = ["(request-target)", "nonce", "digest"];
            signingHeader.map((h, i, arr) => {
                switch (h) {
                    case "(request-target)":
                        signString = signString + h + ": " + method.toLowerCase() + " " + path;
                        break;
                    default:
                        signString = signString + h + ": " + fetchParams.headers[h];
                        break;
                }
                if (i < arr.length - 1) signString = signString + "\n";
            });

            const sign = Crypto.createHmac("sha512", keyString).update(signString).digest("base64");
            const signatureHeader =
                'keyId="' +
                userName +
                '",algorithm="hmac-sha512",headers="(request-target) nonce digest",signature="' +
                sign +
                '"';
            fetchParams.headers["Signature"] = signatureHeader;
        }

        if (body) {
            fetchParams.headers["Content-Type"] = "application/json";
            fetchParams.body = body;
        }

        if (method) {
            fetchParams.method = method;
        }
        return this.fetchData(url, fetchParams, includeData, returnStatus);
    }

    fetchData(url, params, includeData) {
        return new Promise((resolve, reject) => {
            let didTimeOut = false;
            const timeout = setTimeout(function () {
                didTimeOut = true;
                reject(new Error("Request timed out: " + url));
            }, FETCH_TIMEOUT);

            fetch(url, params)
                .then((response) => {
                    clearTimeout(timeout);
                    if (!response.ok) {
                        return Promise.reject("Request fail with status: " + response.status);
                    } else {
                        return response.text();
                    }
                })
                .then((dataStr) => {
                    if (!dataStr) return Promise.reject("Response return null");
                    try {
                        const returnObj = JSON.parse(dataStr);
                        return returnObj;
                    } catch (err) {
                        return Promise.reject("Cannot parse string to Object: " + dataStr);
                    }
                })
                .then((data) => {
                    if (url.includes(this.extractUrl)) console.log("___extract__: ", data);
                    if (includeData) {
                        resolve({ data });
                    } else {
                        resolve(data);
                    }
                })
                .catch((err) => {
                    if (didTimeOut) return;
                    reject({
                        error: err,
                        url,
                    });
                });
        });
    }

    promiseFetch(url, params) {
        return new Promise((resolve, reject) => {
            fetch(url, params)
                .then(function (response) {
                    resolve(response.json());
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
