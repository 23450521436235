/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Modal } from "../../Share";
import TokenDetail from "./TokenDetail";
import Select from "react-select";

const TokenPairListingView = (props) => {
    const isSubmitting = useSelector((state) => state.tokenPairListing.isSubmitting);

    const renderWillBeRemovedTradingPairs = (assetId) => {
        return Object.values(props.willBeDeleteTradingPair).filter((item) => item.asset_id === assetId).length ? (
            <div className="card mb-3">
                <div className="card-header">This trading pairs will be remove</div>
                <ul className="list-group list-group-flush">
                    {Object.values(props.willBeDeleteTradingPair).map((p, i) => (
                        <li key={i} className="list-group-item">
                            Id: {p.id} - Asset: {props.listToken[assetId].symbol} - Exchange:{" "}
                            {props.listExchanges[p.exchange_id].name}
                            <br />
                            Pairs: Base: {props.listToken[p.base].symbol} - Quote: {props.listToken[p.quote].symbol}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const renderWillBeRemovedFutureTradingPairs = (assetId) => {
        return Object.values(props.willBeDeleteFutureTradingPair).filter((item) => item.asset_id === assetId).length ? (
            <div className="card mb-3">
                <div className="card-header">This future trading pairs will be remove</div>
                <ul className="list-group list-group-flush">
                    {Object.values(props.willBeDeleteFutureTradingPair).map((p, i) => (
                        <li key={i} className="list-group-item">
                            Id: {p.id} - Asset: {props.listToken[assetId].symbol} - Exchange:{" "}
                            {props.listExchanges[p.exchange_id].name}
                            <br />
                            Pairs: Base: {props.listToken[p.base_id].symbol} - Quote:{" "}
                            {props.listToken[p.quote_id].symbol}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const renderWillBeAddTradingPairs = (idAssetDisplay) => {
        let hasIncludeAsset = false;
        const arrayAdding = Object.keys(props.addingTradingPair).map((key) => {
            const keyItem = key.split("-");
            const assetId = +keyItem[0];
            const assetExchangeId = +keyItem[1];
            const type = keyItem[2];
            const tokenPairId = +keyItem[3];

            const exchangeId = props.addingTradingPair[key];

            if (assetId == idAssetDisplay) {
                hasIncludeAsset = true;
            }
            return {
                assetId,
                assetExchangeId,
                type,
                tokenPairId,
                exchangeId,
            };
        });

        return hasIncludeAsset ? (
            <div className="card mb-3">
                <div className="card-header">This trading pairs will be added</div>
                <ul className="list-group list-group-flush">
                    {Object.values(arrayAdding).map((p, i) => (
                        <li key={i} className="list-group-item">
                            Id: {p.assetExchangeId} - Asset: {props.listToken[p.assetId].symbol} - Exchange:{" "}
                            {props.listExchanges[p.exchangeId].name}
                            <br />
                            Pairs: {p.type.toUpperCase()}: {props.listToken[p.tokenPairId].symbol}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const renderWillBeAddFutureTradingPairs = (idAssetDisplay) => {
        let hasIncludeAsset = false;
        const arrayAdding = Object.keys(props.addingFutureTradingPair).map((key) => {
            const keyItem = key.split("-");
            const assetId = +keyItem[0];
            const assetExchangeId = +keyItem[1];
            const type = keyItem[2];
            const tokenPairId = +keyItem[3];

            const exchangeId = props.addingFutureTradingPair[key];

            if (assetId == idAssetDisplay) {
                hasIncludeAsset = true;
            }
            return {
                assetId,
                assetExchangeId,
                type,
                tokenPairId,
                exchangeId,
            };
        });

        return hasIncludeAsset ? (
            <div className="card mb-3">
                <div className="card-header">This future trading pairs will be added</div>
                <ul className="list-group list-group-flush">
                    {Object.values(arrayAdding).map((p, i) => (
                        <li key={i} className="list-group-item">
                            Id: {p.assetExchangeId} - Asset: {props.listToken[p.assetId].symbol} - Exchange:{" "}
                            {props.listExchanges[p.exchangeId].name}
                            <br />
                            Pairs: {p.type.toUpperCase()}: {props.listToken[p.tokenPairId].symbol}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const renderUpdatingAssetExchangeInfo = (tokenData) => {
        const hasEditedEx = tokenData.exchanges ? tokenData.exchanges.filter((ex) => ex.edited) : [];

        return hasEditedEx.length ? (
            <div className="card mb-3">
                <div className="card-header">This asset exchange info will be update</div>
                <ul className="list-group list-group-flush">
                    {hasEditedEx.map((p, i) => (
                        <li key={i} className="list-group-item">
                            <b>Id:</b> {p.id}&emsp;
                            <b>Symbol:</b> {p.symbol}&emsp;
                            <b>Exchange:</b>&nbsp;
                            {p.exchange_id && props.listExchanges[p.exchange_id]
                                ? props.listExchanges[p.exchange_id].name
                                : "NULL"}
                            &emsp;
                            <b>D/W Symbol:</b> {p.deposit_withdraw_symbol}&emsp;
                            <b>Min Deposit:</b> {p.min_deposit}&emsp;
                            <b>Target Recommended:</b> {p.target_recommended}&emsp;
                            <b>Target Ratio:</b> {p.target_ratio}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const renderTradingPairsData = (tokenSymbol, tradingPairs) => {
        return (
            <div>
                {Object.keys(tradingPairs.quote).map(
                    (q, qi) =>
                        tradingPairs.quote[q] && (
                            <div className="row" key={qi}>
                                <div className="col">{props.listToken[q].symbol}</div>
                                <div className="col">
                                    <b>{tokenSymbol || "New Asset"}</b>
                                </div>
                            </div>
                        )
                )}
                {Object.keys(tradingPairs.base).map(
                    (b, bi) =>
                        tradingPairs.base[b] && (
                            <div className="row" key={bi}>
                                <div className="col">
                                    <b>{tokenSymbol || "New Asset"}</b>
                                </div>
                                <div className="col">
                                    {props.listToken[b] ? (
                                        props.listToken[b].symbol
                                    ) : (
                                        <p className="text-danger">(not found)</p>
                                    )}
                                </div>
                            </div>
                        )
                )}
            </div>
        );
    };

    const renderFutureTradingPairsData = (tokenSymbol, tradingPairs) => {
        return (
            <div>
                {Object.keys(tradingPairs.quote_id).map(
                    (q, qi) =>
                        tradingPairs.quote_id[q] && (
                            <div className="row" key={qi}>
                                <div className="col">{props.listToken[q].symbol}</div>
                                <div className="col">
                                    <b>{tokenSymbol || "New Asset"}</b>
                                </div>
                            </div>
                        )
                )}
                {Object.keys(tradingPairs.base_id).map(
                    (b, bi) =>
                        tradingPairs.base_id[b] && (
                            <div className="row" key={bi}>
                                <div className="col">
                                    <b>{tokenSymbol || "New Asset"}</b>
                                </div>
                                <div className="col">
                                    {props.listToken[b] ? (
                                        props.listToken[b].symbol
                                    ) : (
                                        <p className="text-danger">(not found)</p>
                                    )}
                                </div>
                            </div>
                        )
                )}
            </div>
        );
    };

    const renderWillBeCreateAssetExchange = (tokenData) => {
        const willCreate = tokenData.exchanges ? tokenData.exchanges.filter((ex) => !ex.id) : [];

        return willCreate.length ? (
            <div className="card mb-3">
                <div className="card-header">This asset exchange will be create</div>
                <ul className="list-group list-group-flush">
                    {willCreate.map((p, i) => (
                        <li key={i} className="list-group-item">
                            <b>Id:</b> {p.id}&emsp;
                            <b>Symbol:</b> {p.symbol}&emsp;
                            <b>Exchange:</b>&nbsp;
                            {p.exchange_id && props.listExchanges[p.exchange_id]
                                ? props.listExchanges[p.exchange_id].name
                                : "NULL"}{" "}
                            &emsp;
                            <b>D/W Symbol</b> {p.deposit_withdraw_symbol}&emsp;
                            <b>Min Deposit:</b> {p.min_deposit}&emsp;
                            <b>Target Recommended:</b> {p.target_recommended}&emsp;
                            <b>Target Ratio:</b> {p.target_ratio}
                            <br />
                            <br />
                            <b>Trading Pairs (Base - Quote):</b> {renderTradingPairsData(p.symbol, p.trading_pairs)}
                            <br />
                            <b>Future Pairs (Base - Quote):</b>
                            {renderFutureTradingPairsData(p.future_symbol, p.future_trading_pairs)}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const renderWillBeDeleteAssetExchange = (assetId) => {
        return Object.values(props.willBeDeleteAssetExchange).filter((item) => item.asset_id === assetId).length ? (
            <div className="card mb-3">
                <div className="card-header">This asset exchange will be remove</div>
                <ul className="list-group list-group-flush">
                    {Object.values(props.willBeDeleteAssetExchange).map((p, i) => (
                        <li key={i} className="list-group-item">
                            <b>Asset ID:</b> {p.asset_id}&emsp;
                            <b>Asset:</b> {p.symbol}&emsp;
                            <b>Exchange:</b> {props.listExchanges[p.exchange_id].name}
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <div />
        );
    };

    const tradingPairSelector = (newT, i) => (
        <div className="dropdown">
            {renderTradingPairsData(newT.newExchange.symbol, newT.newExchange.trading_pairs)}
            <button
                className="btn btn-outline-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                Select Assets
            </button>

            <div className="dropdown-menu select-trading-pair" aria-labelledby="dropdownMenuButton">
                {newT.data["is_quote"] ? (
                    <div>
                        {newT.newExchange && newT.newExchange.exchange_id ? (
                            <div>
                                {Object.keys(props.listToken).map((assetId) => {
                                    if (
                                        assetId != newT.data.asset_id &&
                                        props.listToken[assetId].exchanges &&
                                        props.listToken[assetId].exchanges.length &&
                                        props.listToken[assetId].exchanges
                                            .map((ex) => +ex.exchange_id)
                                            .indexOf(+newT.newExchange.exchange_id) >= 0
                                    ) {
                                        return (
                                            <div className="dropdown-item" key={"quote" + assetId}>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        onChange={(e) =>
                                                            props.onSelectTradingPair(i, e, "quote", assetId)
                                                        }
                                                        checked={
                                                            newT.newExchange["trading_pairs"] &&
                                                            newT.newExchange["trading_pairs"].quote[assetId]
                                                        }
                                                    />
                                                    <label className="form-check-label">
                                                        [{assetId}] {props.listToken[assetId].symbol} |{" "}
                                                        <b>{newT.newExchange.symbol || "(New Asset)"}</b>
                                                    </label>
                                                </div>
                                                {props.listToken[assetId].is_quote ? (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            onChange={(e) =>
                                                                props.onSelectTradingPair(i, e, "base", assetId)
                                                            }
                                                            checked={
                                                                newT.newExchange["trading_pairs"] &&
                                                                newT.newExchange["trading_pairs"].base[assetId]
                                                            }
                                                        />
                                                        <label className="form-check-label">
                                                            <b>
                                                                {newT.newExchange.symbol || "(New Asset)"} | [{assetId}]{" "}
                                                                {props.listToken[assetId].symbol}
                                                            </b>
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                ) : (
                    <div>
                        {newT.newExchange && newT.newExchange.exchange_id ? (
                            Object.keys(props.listToken).map((assetId) => {
                                if (
                                    assetId != newT.data.asset_id &&
                                    props.listToken[assetId].is_quote &&
                                    props.listToken[assetId].exchanges &&
                                    props.listToken[assetId].exchanges.length &&
                                    props.listToken[assetId].exchanges
                                        .map((ex) => +ex.exchange_id)
                                        .indexOf(+newT.newExchange.exchange_id) >= 0
                                ) {
                                    return (
                                        <div className="dropdown-item" key={"base" + assetId}>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                onChange={(e) => props.onSelectTradingPair(i, e, "base", assetId)}
                                                checked={
                                                    newT.newExchange["trading_pairs"] &&
                                                    newT.newExchange["trading_pairs"].base[assetId]
                                                }
                                            />
                                            <label className="form-check-label">
                                                <b>
                                                    {newT.newExchange.symbol || "(New Asset)"} | [{assetId}]{" "}
                                                    {props.listToken[assetId].symbol}
                                                </b>
                                            </label>
                                        </div>
                                    );
                                }
                            })
                        ) : (
                            <div />
                        )}
                    </div>
                )}
            </div>
        </div>
    );

    const futureTradingPairSelector = (newT, i) => {
        return (
            <div className="dropdown">
                {renderFutureTradingPairsData(newT.newExchange.future_symbol, newT.newExchange.future_trading_pairs)}
                <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    id="dropdownFutureMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Select Assets
                </button>

                <div className="dropdown-menu select-trading-pair" aria-labelledby="dropdownFutureMenuButton">
                    {newT.data["is_quote"] ? (
                        <div>
                            {newT.newExchange && newT.newExchange.exchange_id ? (
                                <div>
                                    {Object.keys(props.listToken).map((assetId) => {
                                        if (
                                            assetId != newT.data.asset_id &&
                                            props.listToken[assetId].exchanges
                                                ?.map((ex) => +ex.exchange_id)
                                                .indexOf(+newT.newExchange.exchange_id) >= 0
                                        ) {
                                            return (
                                                <div className="dropdown-item" key={"quote_id" + assetId}>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            onChange={(e) =>
                                                                props.onSelectFutureTradingPair(
                                                                    i,
                                                                    e,
                                                                    "quote_id",
                                                                    assetId
                                                                )
                                                            }
                                                            checked={
                                                                newT.newExchange["future_trading_pairs"] &&
                                                                newT.newExchange["future_trading_pairs"].quote_id[
                                                                    assetId
                                                                ]
                                                            }
                                                        />
                                                        <label className="form-check-label">
                                                            [{assetId}] {props.listToken[assetId].symbol} |{" "}
                                                            <b>{newT.newExchange.future_symbol || "(New Asset)"}</b>
                                                        </label>
                                                    </div>
                                                    {props.listToken[assetId].is_quote ? (
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={(e) =>
                                                                    props.onSelectFutureTradingPair(
                                                                        i,
                                                                        e,
                                                                        "base_id",
                                                                        assetId
                                                                    )
                                                                }
                                                                checked={
                                                                    newT.newExchange["future_trading_pairs"] &&
                                                                    newT.newExchange["future_trading_pairs"].base_id[
                                                                        assetId
                                                                    ]
                                                                }
                                                            />
                                                            <label className="form-check-label">
                                                                <b>
                                                                    {newT.newExchange.future_symbol || "(New Asset)"} |
                                                                    [{assetId}] {props.listToken[assetId].symbol}
                                                                </b>
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div />
                                                    )}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    ) : (
                        <div>
                            {newT.newExchange && newT.newExchange.exchange_id ? (
                                Object.keys(props.listToken).map((assetId) => {
                                    if (
                                        assetId != newT.data.asset_id &&
                                        props.listToken[assetId].is_quote &&
                                        props.listToken[assetId].exchanges
                                            ?.map((ex) => +ex.exchange_id)
                                            .indexOf(+newT.newExchange.exchange_id) >= 0
                                    ) {
                                        return (
                                            <div className="dropdown-item" key={"base_id" + assetId}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    onChange={(e) =>
                                                        props.onSelectFutureTradingPair(i, e, "base_id", assetId)
                                                    }
                                                    checked={
                                                        newT.newExchange["future_trading_pairs"]?.base_id?.[assetId]
                                                    }
                                                />
                                                <label className="form-check-label">
                                                    <b>
                                                        {newT.newExchange.future_symbol || "(New Asset)"} | [{assetId}]{" "}
                                                        {props.listToken[assetId].symbol}
                                                    </b>
                                                </label>
                                            </div>
                                        );
                                    }
                                })
                            ) : (
                                <div />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const selectedLength = Object.values(props.selected).filter((s) => s).length;

    const assetCatOptions = [
        { value: 0, label: "_" },
        ...Object.values(props.listAssetCategories).map((assetCat) => {
            return { value: assetCat.id, label: assetCat.name };
        }),
    ];

    const rfqBaseOptions = [
        { value: 0, label: "_" },
        ...Object.values(props.listRFQBases).map((base) => {
            return { value: base.id, label: `[${base.id}] ${base.name}` };
        }),
    ];

    const renderAssetCategoryChange = (assetCat) => {
        if (!assetCat) return <div />;
        return (
            <div>
                <div className="card-header">There values will be applied to the asset:</div>
                <div className="m-3">
                    <i>(Rebalance quadratic)</i>&ensp;
                    <b>Size A</b>: {assetCat.rebalance_quadratic?.size_a}&emsp;
                    <b>Size B</b>: {assetCat.rebalance_quadratic?.size_b}&emsp;
                    <b>Size C</b>: {assetCat.rebalance_quadratic?.size_c}&emsp;
                    <b>Price A</b>: {assetCat.rebalance_quadratic?.price_a}&emsp;
                    <b>Price B</b>: {assetCat.rebalance_quadratic?.price_b}&emsp;
                    <b>Price C</b>: {assetCat.rebalance_quadratic?.price_c}&emsp;
                    <b>Price offset</b>: {assetCat.rebalance_quadratic?.price_offset}&emsp;
                    <br />
                    <b>Max ETH Size</b>: {assetCat.max_eth_size}&emsp;
                    <b>Blocking Trade Duration</b>: {assetCat.blocking_trade_duration_millis}ms&emsp;
                    <b>Order Duration</b>: {assetCat.order_duration_millis}ms&emsp;
                    <b>Price ETH Amount</b>: {assetCat.price_eth_amount}&emsp;
                    <b>Exchange ETH Amount</b>: {assetCat.exchange_eth_amount}&emsp;
                </div>
            </div>
        );
    };

    const renderRFQBaseChange = (base) => {
        if (!base) return <div />;
        return (
            <div>
                <div className="card-header">There values will be applied to the rfq base of asset:</div>
                <div className="m-3">
                    <b>Ref ETH Amount</b>: {base.ref_eth_amount}&emsp;
                    <b>ETH step</b>: {base.eth_step}&emsp;
                    <b>Max ETH size buy</b>: {base.max_eth_size_buy}&emsp;
                    <b>Max ETH size sell</b>: {base.max_eth_size_sell}&emsp;
                    <b>A</b>: {base.a}&emsp;
                    <b>B</b>: {base.b}&emsp;
                    <b>C</b>: {base.c}&emsp;
                    <br />
                    <b>Min min</b>: {base.min_min}&emsp;
                    <b>Max imb</b>: {base.max_imb}&emsp;
                    <b>Imb price multiplier</b>: {base.imb_price_multiplier}&emsp;
                    <b>ETH amount to ignore</b>: {base.eth_amount_to_ignore}&emsp;
                    <b>Step multiplier</b>: {base.step_multiplier}&emsp;
                    <b>Default quote id</b>: {base.default_quote_id}&emsp;
                    <b>Enabled</b>: {base.enabled.toString()}&emsp;
                </div>
            </div>
        );
    };

    const settingModalContent = props.isEdit ? (
        props.renderEditListToken()
    ) : (
        <React.Fragment>
            <ul className="nav nav-tabs" id="tokenListingTab" role="tablist">
                {props.newToken.map((newT, i) => (
                    <li className="nav-item" key={i}>
                        <a
                            className={`nav-link d-inline-block ${props.focusToken == i && "active"}`}
                            id={(newT.data["symbol"] || `${i}`) + "-tab"}
                            data-toggle="tab"
                            href={"#" + (newT.data["token.symbol"] || `token-${i}`)}
                            aria-controls="home"
                            aria-selected="true"
                        >
                            {newT.data["symbol"] || `[${i}]`}
                        </a>
                        <a className="d-inline-block" onClick={() => props.removeToken(i)}>
                            <i className="fa fa-minus-circle" aria-hidden="true" />
                        </a>
                    </li>
                ))}
                {!props.updateAssetMode && (
                    <li className="nav-item add-more" onClick={props.onAddMoreToken}>
                        <a className="nav-link">+ Add more</a>
                    </li>
                )}
            </ul>
            <div className="tab-content" id="myTabContent">
                {props.newToken.map((newT, i) => (
                    <div
                        key={i}
                        id={newT.data["token.symbol"] || `token-${i}`}
                        className={`tab-pane fade ${props.focusToken == i && "active show"}`}
                        role="tabpanel"
                        aria-labelledby="home-tab"
                    >
                        {!props.createAssetExchangeMode && (
                            <div className="row px-2 py-4 m-0">
                                <div className="col-sm-2">
                                    <label>Symbol</label>
                                    <input
                                        type="text"
                                        className="form-control text-left"
                                        value={newT.data["symbol"]}
                                        onChange={(e) => props.onInputChange(i, "symbol", e)}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control text-left"
                                        value={newT.data["name"]}
                                        onChange={(e) => props.onInputChange(i, "name", e)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <label>Asset Type</label>
                                    <select
                                        className="custom-select"
                                        onChange={(e) => props.onInputChange(i, "asset_type", e)}
                                    >
                                        <option selected={newT.data["asset_type"] == "default"} value="default">
                                            default
                                        </option>
                                        <option selected={newT.data["asset_type"] == "unlisted"} value="unlisted">
                                            unlisted
                                        </option>
                                    </select>
                                    {/* <input type="number" step="any" className="form-control" value={newT.data['decimals']} onChange={(e) => props.onInputChange(i, 'decimals', e, true)} /> */}
                                </div>
                                <div className="col-sm-4">
                                    <label>Address</label>
                                    <input
                                        type="text"
                                        className="form-control text-left"
                                        value={newT.data["address"]}
                                        onChange={(e) => props.onInputChange(i, "address", e)}
                                    />
                                </div>
                                <div className="col-sm-2 mt-3">
                                    <label>Decimal</label>
                                    <input
                                        type="number"
                                        step="any"
                                        className="form-control"
                                        value={newT.data["decimals"]}
                                        onChange={(e) => props.onInputChange(i, "decimals", e, true)}
                                    />
                                </div>
                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Set Rate</label>
                                        <select
                                            className="custom-select"
                                            onChange={(e) => props.onInputChange(i, "set_rate", e)}
                                        >
                                            <option selected={newT.data["set_rate"] == "not_set"} value="not_set">
                                                Not Set
                                            </option>
                                            <option
                                                selected={newT.data["set_rate"] == "exchange_feed"}
                                                value="exchange_feed"
                                            >
                                                Exchange Feed
                                            </option>
                                            <option selected={newT.data["set_rate"] == "gold_feed"} value="gold_feed">
                                                Gold Feed
                                            </option>
                                            <option selected={newT.data["set_rate"] == "btc_feed"} value="btc_feed">
                                                BTC Feed
                                            </option>
                                            <option selected={newT.data["set_rate"] == "usd_feed"} value="usd_feed">
                                                USD Feed
                                            </option>
                                        </select>
                                        {/* <input type="number" step="any" className="form-control" value={newT.data['decimals']} onChange={(e) => props.onInputChange(i, 'decimals', e, true)} /> */}
                                    </div>
                                )}

                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Normal Update Per Period</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["normal_update_per_period"]}
                                            onChange={(e) =>
                                                props.onInputChange(i, "normal_update_per_period", e, true)
                                            }
                                        />
                                    </div>
                                )}
                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Max Imbalance Ratio</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["max_imbalance_ratio"]}
                                            onChange={(e) => props.onInputChange(i, "max_imbalance_ratio", e, true)}
                                        />
                                    </div>
                                )}

                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Sanity Threshold</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["sanity_info.threshold"]}
                                            onChange={(e) => props.onInputChange(i, "sanity_info.threshold", e, true)}
                                        />
                                    </div>
                                )}
                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Sanity Rate Provider</label>
                                        {/* <input type="text" step="any" className="form-control" value={newT.data['sanity_rate_provider']} onChange={(e) => props.onInputChange(i, 'sanity_rate_provider', e, false)} /> */}
                                        <select
                                            className="custom-select"
                                            onChange={(e) => props.onInputChange(i, "sanity_info.provider", e)}
                                        >
                                            <option
                                                selected={
                                                    newT.data["sanity_info.provider"] == "" ||
                                                    newT.data["sanity_info.provider"] == null
                                                }
                                                value=""
                                            />
                                            <option
                                                selected={newT.data["sanity_info.provider"] == "binance"}
                                                value="binance"
                                            >
                                                binance
                                            </option>
                                            <option
                                                selected={newT.data["sanity_info.provider"] == "huobi"}
                                                value="huobi"
                                            >
                                                huobi
                                            </option>
                                            <option
                                                selected={newT.data["sanity_info.provider"] == "dgx-global"}
                                                value="dgx-global"
                                            >
                                                dgx-global
                                            </option>
                                        </select>
                                    </div>
                                )}
                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Sanity Rate Path</label>
                                        <input
                                            type="text"
                                            step="any"
                                            className="form-control"
                                            value={
                                                newT.data["sanity_info.path"]
                                                    ? newT.data["sanity_info.path"].toString()
                                                    : ""
                                            }
                                            onChange={(e) => props.onInputChange(i, "sanity_info.path", e, false, true)}
                                        />
                                    </div>
                                )}

                                {newT.data.asset_type != "unlisted" && (
                                    <div className="col-sm-2 mt-3">
                                        <label>Transfer Gas</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["transfer_gas"]}
                                            onChange={(e) => props.onInputChange(i, "transfer_gas", e, true)}
                                        />
                                    </div>
                                )}
                                <div className="col-sm-4 mt-3">
                                    <label>Asset Category</label>
                                    <Select
                                        menuTarget={document.body}
                                        styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                                        options={assetCatOptions}
                                        onChange={(e) => props.onInputChange(i, "asset_category_id", e.value, true)}
                                        value={assetCatOptions.filter((o) => o.value == newT.data.asset_category_id)}
                                    />
                                </div>
                                <div className="col-sm-4 mt-3">
                                    <label>RFQ Base</label>
                                    <Select
                                        menuTarget={document.body}
                                        styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                                        options={rfqBaseOptions}
                                        onChange={(e) => props.onInputChange(i, "rfq_params_base_v2_id", e.value, true)}
                                        value={rfqBaseOptions.filter((o) => o.value == newT.data.rfq_params_base_v2_id)}
                                    />
                                </div>
                                <div className="col-12 mt-3">
                                    {props.currentFocusToken?.asset_category_id != newT.data?.asset_category_id &&
                                        renderAssetCategoryChange(
                                            props.listAssetCategories?.[newT.data.asset_category_id]
                                        )}
                                </div>
                                <div className="col-12 mt-3">
                                    {props.currentFocusToken?.rfq_params_base_v2_id !=
                                        newT.data?.rfq_params_base_v2_id &&
                                        renderRFQBaseChange(props.listRFQBases?.[newT.data.rfq_params_base_v2_id])}
                                </div>

                                <div className="col-12 mt-4">
                                    <div className="pretty p-default mr-5">
                                        <input
                                            type="checkbox"
                                            checked={newT.data["is_enabled"]}
                                            onChange={() => props.onToggleChecbox(i, "is_enabled")}
                                        />
                                        <div className="state p-success">
                                            <label>Is Enabled</label>
                                        </div>
                                    </div>
                                    <div className="pretty p-default mr-5">
                                        <input
                                            type="checkbox"
                                            checked={newT.data["transferable"]}
                                            onChange={() => props.onToggleChecbox(i, "transferable")}
                                        />
                                        <div className="state p-success">
                                            <label>Transferable</label>
                                        </div>
                                    </div>
                                    <div className="pretty p-default mr-5">
                                        <input
                                            type="checkbox"
                                            checked={newT.data["rebalance"]}
                                            onChange={() => props.onToggleChecbox(i, "rebalance")}
                                        />
                                        <div className="state p-success">
                                            <label>Rebalance</label>
                                        </div>
                                    </div>
                                    <div className="pretty p-default mr-5">
                                        <input
                                            type="checkbox"
                                            checked={newT.data["is_quote"]}
                                            onChange={() => props.onToggleChecbox(i, "is_quote")}
                                        />
                                        <div className="state p-success">
                                            <label>Is Quote</label>
                                        </div>
                                    </div>
                                    <div className="pretty p-default mr-5">
                                        <input
                                            type="checkbox"
                                            checked={newT.data["margin_enable"]}
                                            onChange={() => props.onToggleChecbox(i, "margin_enable")}
                                        />
                                        <div className="state p-success">
                                            <label>Margin Enable</label>
                                        </div>
                                    </div>
                                    {newT.data.asset_type != "unlisted" && (
                                        <div className="pretty p-default mr-5">
                                            <input
                                                type="checkbox"
                                                checked={newT.data["perpetual_enable"]}
                                                onChange={() => props.onToggleChecbox(i, "perpetual_enable")}
                                            />
                                            <div className="state p-success">
                                                <label>Perpetual Enable</label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="dropdown-divider" />

                        {/* Margin settings */}
                        {newT.data.asset_type != "unlisted" && (
                            <div>
                                <div className="row px-2 py-3 m-0">
                                    <div className="col-12 text-dark mb-3">Margin setting</div>

                                    <div className="col-3">
                                        <div className="pretty p-default mt-4">
                                            <input
                                                type="checkbox"
                                                checked={newT.data["margin_enable_with_cfg"]}
                                                onChange={() => props.onToggleChecbox(i, "margin_enable_with_cfg")}
                                            />
                                            <div className="state p-success ">
                                                <label className="fw-500">Margin Enable With Config</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <label> Margin Target Amount</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["margin_target_amount"]}
                                            onChange={(e) => props.onInputChange(i, "margin_target_amount", e, true)}
                                        />
                                    </div>

                                    <div className="col-3">
                                        <label> Margin Min Amount</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["margin_min_amount"]}
                                            onChange={(e) => props.onInputChange(i, "margin_min_amount", e, true)}
                                        />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )}

                        {/* Deprecated »»» Stable Token Pricing */}
                        {!props.createAssetExchangeMode &&
                            (newT.data["set_rate"] == "btc_feed" || newT.data["set_rate"] == "usd_feed") && (
                                <React.Fragment>
                                    <div className="row px-2 py-4 m-0">
                                        <div className="col-12 text-dark mb-3">Stable Token Pricing</div>
                                        <div className="col">
                                            <label>Price Update Threshold</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["stable_param.price_update_threshold"]}
                                                onChange={(e) =>
                                                    props.onInputChange(
                                                        i,
                                                        "stable_param.price_update_threshold",
                                                        e,
                                                        true
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Ask Spread</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["stable_param.ask_spread"]}
                                                onChange={(e) =>
                                                    props.onInputChange(i, "stable_param.ask_spread", e, true)
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Bid Spread</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["stable_param.bid_spread"]}
                                                onChange={(e) =>
                                                    props.onInputChange(i, "stable_param.bid_spread", e, true)
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Single Feed Max Spread</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["stable_param.single_feed_max_spread"]}
                                                onChange={(e) =>
                                                    props.onInputChange(
                                                        i,
                                                        "stable_param.single_feed_max_spread",
                                                        e,
                                                        true
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Multiple Feed Max Diff</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["stable_param.multiple_feeds_max_diff"]}
                                                onChange={(e) =>
                                                    props.onInputChange(
                                                        i,
                                                        "stable_param.multiple_feeds_max_diff",
                                                        e,
                                                        true
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                        {/* Deprecated »»» Feed Weight */}
                        {!props.createAssetExchangeMode &&
                            (newT.data["set_rate"] == "btc_feed" || newT.data["set_rate"] == "usd_feed") && (
                                <div className="row px-2 py-4 m-0">
                                    <div className="col-12 text-dark mb-3">Feed Weight</div>
                                    {newT.data["set_rate"] == "btc_feed" &&
                                        props.initFeedWeight["btc_feed"] &&
                                        Object.keys(props.initFeedWeight["btc_feed"]).map((feedKey, ikb) => (
                                            <div className="col" key={ikb}>
                                                <label>{feedKey}</label>
                                                <input
                                                    type="number"
                                                    step="any"
                                                    className="form-control"
                                                    value={newT.data[`feed_weight.${feedKey}`]}
                                                    onChange={(e) =>
                                                        props.onInputChange(i, `feed_weight.${feedKey}`, e, true)
                                                    }
                                                />
                                            </div>
                                        ))}
                                    {newT.data["set_rate"] == "usd_feed" &&
                                        props.initFeedWeight["usd_feed"] &&
                                        Object.keys(props.initFeedWeight["usd_feed"]).map((feedKey, ikb) => (
                                            <div className="col" key={ikb}>
                                                <label>{feedKey}</label>
                                                <input
                                                    type="number"
                                                    step="any"
                                                    className="form-control"
                                                    value={newT.data[`feed_weight.${feedKey}`]}
                                                    onChange={(e) =>
                                                        props.onInputChange(i, `feed_weight.${feedKey}`, e, true)
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            )}

                        {/* PWIS */}
                        {newT.data["set_rate"] !== "not_set" && !props.createAssetExchangeMode && (
                            <div>
                                <div className="row px-2 py-3 m-0">
                                    <div className="col-12 text-dark mb-3">PWIS</div>
                                    <div className="col-1 text-dark">Ask:</div>
                                    <div className="col">
                                        <label>A</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.ask.a"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.ask.a", e, true)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>B</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.ask.b"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.ask.b", e, true)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>C</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.ask.c"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.ask.c", e, true)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>Min Spread</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.ask.min_min_spread"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.ask.min_min_spread", e, true)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label>Price Multiply Factor:</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.ask.price_multiply_factor"]}
                                            onChange={(e) =>
                                                props.onInputChange(i, "pwi.ask.price_multiply_factor", e, true)
                                            }
                                        />
                                    </div>
                                    <div className="w-100 mb-4" />
                                    <div className="col-1 text-dark">Bid:</div>
                                    <div className="col">
                                        <label>A</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.bid.a"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.bid.a", e, true)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>B</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.bid.b"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.bid.b", e, true)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>C</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.bid.c"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.bid.c", e, true)}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>Min Spread</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.bid.min_min_spread"]}
                                            onChange={(e) => props.onInputChange(i, "pwi.bid.min_min_spread", e, true)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label>Price Multiply Factor:</label>
                                        <input
                                            type="number"
                                            step="any"
                                            className="form-control"
                                            value={newT.data["pwi.bid.price_multiply_factor"]}
                                            onChange={(e) =>
                                                props.onInputChange(i, "pwi.bid.price_multiply_factor", e, true)
                                            }
                                        />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )}

                        {/* Target Quantity */}
                        {!props.createAssetExchangeMode && newT.data.asset_type != "unlisted" && (
                            <React.Fragment>
                                <div>
                                    <div className="row px-2 py-3 m-0">
                                        <div className="col-12 text-dark mb-3">Target Quantity</div>
                                        <div className="col">
                                            <label>Total Target</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["target.total"]}
                                                onChange={(e) => props.onInputChange(i, "target.total", e, true)}
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Reserve Target</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["target.reserve"]}
                                                onChange={(e) => props.onInputChange(i, "target.reserve", e, true)}
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Buy Threshold</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["target.buy_threshold"]}
                                                onChange={(e) =>
                                                    props.onInputChange(i, "target.buy_threshold", e, true)
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Rebalance Threshold</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["target.rebalance_threshold"]}
                                                onChange={(e) =>
                                                    props.onInputChange(i, "target.rebalance_threshold", e, true)
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Transfer Threshold</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["target.transfer_threshold"]}
                                                onChange={(e) =>
                                                    props.onInputChange(i, "target.transfer_threshold", e, true)
                                                }
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Min Withdraw Threshold</label>
                                            <input
                                                type="number"
                                                step="any"
                                                className="form-control"
                                                value={newT.data["target.min_withdraw_threshold"]}
                                                onChange={(e) =>
                                                    props.onInputChange(i, "target.min_withdraw_threshold", e, true)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </React.Fragment>
                        )}

                        {/* Exchanges */}
                        <div className="row px-2 py-3 m-0">
                            <div className="col-12 text-dark mb-3">Exchanges</div>
                            <div className="col-12">
                                {renderWillBeRemovedTradingPairs(newT.data.asset_id)}
                                {renderWillBeRemovedFutureTradingPairs(newT.data.asset_id)}
                                {renderWillBeAddTradingPairs(newT.data.asset_id)}
                                {renderWillBeAddFutureTradingPairs(newT.data.asset_id)}
                                {renderUpdatingAssetExchangeInfo(newT)}
                                {renderWillBeCreateAssetExchange(newT)}
                                {renderWillBeDeleteAssetExchange(newT.data.asset_id)}
                                <table className="table table-bordered table-exchange">
                                    <thead className="thead-custom">
                                        <tr>
                                            <th>Exchange</th>
                                            <th>Symbol</th>
                                            {newT.data.asset_type != "unlisted" && <th>Future Symbol</th>}
                                            {newT.data.asset_type != "unlisted" && <th>D/W Symbol</th>}
                                            {newT.data.asset_type != "unlisted" && <th>Min Deposit</th>}
                                            {newT.data.asset_type != "unlisted" && (
                                                <th>
                                                    Target <br /> Recommended
                                                </th>
                                            )}
                                            {newT.data.asset_type != "unlisted" && (
                                                <th>
                                                    Target <br /> Ratio
                                                </th>
                                            )}
                                            <th className="min-width-150">
                                                <span>Trading Pairs</span>
                                                <br />
                                                <span> Base | Quote </span>
                                            </th>
                                            <th style={{ minWidth: "175px" }}>
                                                <span>Future Trading Pairs</span>
                                                <br />
                                                <span> Base | Quote </span>
                                            </th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newT.exchanges &&
                                            newT.exchanges.map((ex, ei) => (
                                                <tr key={ex.exchange_id}>
                                                    <td>
                                                        [{ex.exchange_id}]{" "}
                                                        {ex.exchange_id !== null && props.listExchanges[ex.exchange_id]
                                                            ? props.listExchanges[ex.exchange_id].name
                                                            : "select exchange"}
                                                    </td>
                                                    <td>{ex.symbol}</td>
                                                    {newT.data.asset_type != "unlisted" && <td>{ex.future_symbol}</td>}
                                                    {newT.data.asset_type != "unlisted" && (
                                                        <td>{ex.deposit_withdraw_symbol}</td>
                                                    )}
                                                    {newT.data.asset_type != "unlisted" && <td>{ex.min_deposit}</td>}
                                                    {newT.data.asset_type != "unlisted" && (
                                                        <td>{ex.target_recommended}</td>
                                                    )}
                                                    {newT.data.asset_type != "unlisted" && <td>{ex.target_ratio}</td>}
                                                    <td>{renderTradingPairsData(ex.symbol, ex.trading_pairs)}</td>
                                                    <td>
                                                        {renderFutureTradingPairsData(
                                                            ex.future_symbol,
                                                            ex.future_trading_pairs
                                                        )}
                                                    </td>

                                                    <td>
                                                        {ex.id ? (
                                                            <span>
                                                                <i
                                                                    className="fa fa-pencil pr-3 pointer"
                                                                    aria-hidden="true"
                                                                    onClick={() => props.editExchange(i, ei)}
                                                                />
                                                                <i
                                                                    className="fa fa-trash pointer"
                                                                    aria-hidden="true"
                                                                    onClick={() => props.removeExchange(i, ei)}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <i
                                                                className="fa fa-minus-square-o pointer"
                                                                aria-hidden="true"
                                                                onClick={() => props.removeExchange(i, ei)}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}

                                        <tr>
                                            <td>
                                                <div className="dropdown">
                                                    <select
                                                        className="custom-select"
                                                        onChange={(e) => props.onChangeExchange(i, "exchange_id", e)}
                                                    >
                                                        <option
                                                            value="nil"
                                                            key="nil"
                                                            selected={
                                                                !newT.newExchange || !newT.newExchange.exchange_id
                                                            }
                                                        >
                                                            Select Exchange
                                                        </option>
                                                        {Object.keys(props.listExchanges).map((exId) => (
                                                            <option
                                                                value={exId}
                                                                key={exId}
                                                                selected={
                                                                    newT.newExchange &&
                                                                    newT.newExchange.exchange_id == exId
                                                                }
                                                            >
                                                                [{exId}] {props.listExchanges[exId].name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control limit-width"
                                                    value={newT.newExchange.symbol}
                                                    onChange={(e) => props.onChangeExchange(i, "symbol", e)}
                                                />
                                            </td>
                                            {newT.data.asset_type != "unlisted" && (
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control limit-width"
                                                        value={newT.newExchange.future_symbol}
                                                        onChange={(e) => props.onChangeExchange(i, "future_symbol", e)}
                                                    />
                                                </td>
                                            )}
                                            {newT.data.asset_type != "unlisted" && (
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control limit-width"
                                                        value={newT.newExchange.deposit_withdraw_symbol}
                                                        onChange={(e) =>
                                                            props.onChangeExchange(i, "deposit_withdraw_symbol", e)
                                                        }
                                                    />
                                                </td>
                                            )}
                                            {newT.data.asset_type != "unlisted" && (
                                                <td>
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        className="form-control limit-width"
                                                        value={newT.newExchange.min_deposit}
                                                        onChange={(e) =>
                                                            props.onChangeExchange(i, "min_deposit", e, true)
                                                        }
                                                    />
                                                </td>
                                            )}
                                            {newT.data.asset_type != "unlisted" && (
                                                <td>
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        className="form-control limit-width"
                                                        value={newT.newExchange.target_recommended}
                                                        onChange={(e) =>
                                                            props.onChangeExchange(i, "target_recommended", e, true)
                                                        }
                                                    />
                                                </td>
                                            )}
                                            {newT.data.asset_type != "unlisted" && (
                                                <td>
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        className="form-control limit-width"
                                                        value={newT.newExchange.target_ratio}
                                                        onChange={(e) =>
                                                            props.onChangeExchange(i, "target_ratio", e, true)
                                                        }
                                                    />
                                                </td>
                                            )}
                                            <td>{tradingPairSelector(newT, i)}</td>
                                            <td>{futureTradingPairSelector(newT, i)}</td>
                                            <td>
                                                <i
                                                    className="fa fa-plus-circle pointer"
                                                    aria-hidden="true"
                                                    onClick={() => props.addExchange(i)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );

    const footerModalContent = (
        <React.Fragment>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Close
            </button>
            <button type="button" className="btn btn-secondary" onClick={props.resetNewToken}>
                Reset
            </button>
            <button
                disabled={isSubmitting}
                type="button"
                className={cx("btn", !isSubmitting && "btn-success")}
                onClick={props.onSubmitNewToken}
            >
                Save changes
            </button>
        </React.Fragment>
    );
    const modalSettingButton = (
        <button type="button" className="close" onClick={props.toggleTextEditor} aria-label="Close">
            <i className="fa fa-exchange" aria-hidden="true" />
        </button>
    );
    const newTokenPopup = (
        <Modal
            title={selectedLength ? "Edit Selected Tokens" : "Add New Token"}
            id={"newTokenPopup"}
            content={settingModalContent}
            footer={props.createAssetExchangeMode ? null : footerModalContent}
            setting={modalSettingButton}
            size="xl"
        />
    );

    const formularPlotPopup = (
        <Modal
            title={"Plot Formula"}
            id={"formulaPlotPopup"}
            content={props.renderPlotFormula()}
            // setting={modalSettingButton}
            size="xl"
        />
    );

    return (
        <div>
            <div className="row">
                <div className="col-sm-6 col-md-7">
                    {/* <div className="panel sticky">
                        <div className="panel-header pb-4"> */}
                    {props.renderPendingSetting()}
                    {props.renderPendingListToken()}
                    {props.renderTableListToken()}
                    {/* </div>
                    </div> */}
                </div>
                <div className="col-sm-6 col-md-5">
                    <TokenDetail {...props} />
                </div>
            </div>
            {newTokenPopup}
            {formularPlotPopup}
        </div>
    );
};

export default TokenPairListingView;
