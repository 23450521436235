import styled from "styled-components";

import { getIconSource } from "utils/index.js";

interface IProps {
    className?: string;
    symbol: string;
    hideIcon?: boolean;
    selected: boolean;
    onClick: () => void;
}

const TokenCell: React.FC<IProps> = ({ className, symbol, hideIcon = false, selected, onClick }) => {
    return (
        <div className={className} onClick={onClick} data-selected={selected}>
            {hideIcon ? null : (
                <div className="iconWrapper">
                    <img src={getIconSource(symbol)} className="rounded-circle" />
                </div>
            )}
            <span className="symbol">{symbol.toUpperCase()}</span>
        </div>
    );
};

export default styled(TokenCell)`
    display: flex;
    align-items: center;
    column-gap: 4px;

    border: 1px solid #bdc3c7;
    padding: 4px 8px;
    border-radius: 4px;

    cursor: pointer;
    user-select: none;

    transition: background-color 150ms;

    &[data-selected="true"] {
        background-color: #fec5bb;
    }

    .iconWrapper {
        height: 25px;
        flex: 0 0 25px;
    }

    .symbol {
        font-weight: bold;
    }

    img {
        width: 25px;
        height: 25px;
        border: 1px solid #bdc3c7;
        border-radius: 50%;
        overflow: hidden;
    }
`;
