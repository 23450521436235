import { round } from "../utils";
import { DisplayMode } from "./consts";
import SimpleDisplay from "./SimpleDisplay";
import FullDisplay from "./FullDisplay";

const DISPLAY_MAP = {
    [DisplayMode.Simple]: SimpleDisplay,
    [DisplayMode.Full]: FullDisplay,
};

const TokenDisplay = ({ tokenId, tokenData, onClickToken, displayMode }) => {
    const { rebalanceThreshold, percent } = tokenData;

    const shouldBeWarning = rebalanceThreshold && Math.ceil(+percent) < (1 - rebalanceThreshold) * 100;

    const title = [
        tokenId,
        `Reserve: ${round(tokenData.reserveBalanceWithWithdraw)}/${round(tokenData.reserveTarget)}`,
        `Total: ${round(tokenData.totalQty)}/${round(tokenData.totalTarget)}`,
        `Rebalance Threshold: ${tokenData.rebalanceThreshold}`,
    ].join("\x0A");

    const Component = DISPLAY_MAP[displayMode];

    if (!Component) {
        return null;
    }

    return (
        <Component
            tokenId={tokenId}
            tokenData={tokenData}
            onClickToken={onClickToken}
            title={title}
            shouldBeWarning={shouldBeWarning}
            indicatorColor={"transparent"}
        />
    );
};

export default TokenDisplay;
