export const round = (num: number): number => {
    if (num >= 1000) {
        return Math.round(num);
    }
    if (num >= 10) {
        return Math.round(num * 100) / 100;
    }

    return Math.round(num * 10000) / 10000;
};
