import { Switch } from "../../Share";
import Select from "react-select";

import loading from "../../../assets/img/waiting.svg";
import RFQTradeSettings from "./RFQTradeSettings";
import AdminManual from "./adminManual";

const ExchangeStatusView = (props) => {
    const loadingImg = <img alt="loading" src={loading} />;

    const assetCatOpts = props.listAssetCategories.map((a) => {
        return { value: a.id, label: a.name };
    });

    return (
        <div>
            <div className="row">
                <div className="col-sm-6 col-md-4">
                    <div className="panel">
                        <div className="panel-header">
                            <div className="panel-title">Rebalance and Rate</div>
                        </div>
                        <div className="panel-body">
                            <div className="panel-row">
                                <div className="d-flex mb-3">
                                    <div className="text-dark">Rebalance status</div>
                                    <div className="ml-auto">
                                        {props.rebalanceStatus !== 0 ? (
                                            <Switch
                                                onChange={(checked) =>
                                                    checked ? props.enableRebalance() : props.holdRebalance()
                                                }
                                                checked={props.rebalanceStatus == 2}
                                            />
                                        ) : (
                                            loadingImg
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex mb-3">
                                    <div className="text-dark">Margin status</div>
                                    <div className="ml-auto">
                                        {props.marginStatus !== 0 ? (
                                            <Switch
                                                onChange={(checked) => props.setMarginStatus(checked)}
                                                checked={props.marginStatus == 2}
                                            />
                                        ) : (
                                            loadingImg
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex mb-3">
                                    <div className="text-dark">Margin trade status</div>
                                    <div className="ml-auto">
                                        {props.marginTradeStatus !== 0 ? (
                                            <Switch
                                                onChange={(checked) => props.setMarginTradeStatus(checked)}
                                                checked={props.marginTradeStatus == 2}
                                            />
                                        ) : (
                                            loadingImg
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex mb-3">
                                    <div className="text-dark">Perpetual status</div>
                                    <div className="ml-auto">
                                        {props.perpetualStatus !== 0 ? (
                                            <Switch
                                                onChange={(checked) => props.setPerpetualStatus(checked)}
                                                checked={props.perpetualStatus == 2}
                                            />
                                        ) : (
                                            loadingImg
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex mb-3">
                                    <div className="text-dark">Set 0x rate status</div>
                                    <div className="ml-auto">
                                        {props.set0xRateStatus !== undefined ? (
                                            <Switch
                                                onChange={(checked) =>
                                                    checked
                                                        ? props.enableSetRate("set_rate_0x")
                                                        : props.disableSetRate("set_rate_0x")
                                                }
                                                checked={props.set0xRateStatus}
                                            />
                                        ) : (
                                            loadingImg
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark">Predictor status</div>
                                    <div className="ml-auto">
                                        {props.predictorConfigStatus !== 0 ? (
                                            <Switch
                                                onChange={(checked) => props.setPredictorConfigStatus(checked)}
                                                checked={props.predictorConfigStatus == 2}
                                            />
                                        ) : (
                                            loadingImg
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">Margin level threshold</div>
                                    <div className="ml-auto d-flex justify-content-end w-50">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Margin level threshold"
                                            aria-label="Margin level threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={props.onChangeMarginLevelThreshold}
                                            value={props.marginLevelThreshold}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-success ml-2"
                                            onClick={props.submitMarginLevelThreshold}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">Perpetual utilization threshold</div>
                                    <div className="ml-auto d-flex justify-content-end w-50">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Perpetual utilization threshold"
                                            aria-label="Perpetual utilization threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={props.onChangePerpetualUtilizationThreshold}
                                            value={props.perpetualUtilizationThreshold}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-success ml-2"
                                            onClick={props.submitPerpetualUtilizationThreshold}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <div className="text-dark my-auto">Margin reload</div>
                                    <div className="ml-auto d-flex justify-content-end w-50">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="binance_1"
                                            aria-label="binance_1"
                                            aria-describedby="basic-addon2"
                                            disabled
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-success ml-2"
                                            onClick={props.marginReload}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <AdminManual apiService={props.apiService} alert={props.alert} />

                                <div className="d-flex mt-3">
                                    <div className="text-dark my-auto">Volatility adjustment</div>
                                    <div className="ml-auto d-flex justify-content-end w-50">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Volatility adjustment"
                                            aria-label="Volatility adjustment"
                                            aria-describedby="basic-addon2"
                                            onChange={props.onChangeVolatilityAdjustment}
                                            value={props.volatilityAdjustment}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-success ml-2"
                                            onClick={props.submitVolatilityAdjustment}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <div className="d-flex mt-3">
                                    <div className="text-dark my-auto">Unlisted asset category</div>
                                    <div className="ml-auto d-flex justify-content-end w-50">
                                        <Select
                                            className="w-100"
                                            options={assetCatOpts}
                                            onChange={(v) => props.onChangeUnlistedAssetCategory(v.value)}
                                            value={assetCatOpts.find((a) => a.value == props.unlistedAssetCategoryId)}
                                        />

                                        <button
                                            type="button"
                                            className="btn btn-success ml-2"
                                            onClick={props.submitUnlistedAssetCategory}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-header">
                            <div className="panel-title">Alert Settings</div>
                        </div>
                        <div className="panel-body">
                            <div className="panel-row">
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(PnL Alert) Pnl Threshold</div>
                                    <div className="ml-auto d-flex justify-content-end w-33">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="pnl threshold"
                                            aria-label="pnl threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) => props.onChangeAlertSettings("pnl_alert_pnl_threshold", e)}
                                            value={props.pnlAlertPnlThreshold}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(PnL Alert) Imbalance Threshold</div>
                                    <div className="ml-auto d-flex justify-content-end">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="imbalance threshold"
                                            aria-label="imbalance threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) =>
                                                props.onChangeAlertSettings("pnl_alert_imbalance_threshold", e)
                                            }
                                            value={props.pnlAlertImbalanceThreshold}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(Volume Alert) Range Second</div>
                                    <div className="ml-auto d-flex justify-content-end">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="range second"
                                            aria-label="vol alert range second"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) =>
                                                props.onChangeAlertSettings("volume_alert_range_second", e)
                                            }
                                            value={props.volumeAlertRangeSecond}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(Volume Alert) Ratio Threshold</div>
                                    <div className="ml-auto d-flex justify-content-end">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="ratio threshold"
                                            aria-label="ratio threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) =>
                                                props.onChangeAlertSettings("volume_alert_ratio_threshold", e)
                                            }
                                            value={props.volumeAlertRatioThreshold}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(Volume Alert) Volume Threshold</div>
                                    <div className="ml-auto d-flex justify-content-end">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="volume threshold"
                                            aria-label="volume threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) =>
                                                props.onChangeAlertSettings("volume_alert_volume_threshold", e)
                                            }
                                            value={props.volumeAlertVolumeThreshold}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(Imbalance Alert) Imbalance Threshold</div>
                                    <div className="ml-auto d-flex justify-content-end">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="imbalance threshold"
                                            aria-label="imbalance threshold"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) =>
                                                props.onChangeAlertSettings("imbalance_alert_imbalance_threshold", e)
                                            }
                                            value={props.imbalanceAlertImbalanceThreshold}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="text-dark my-auto">(Imbalance Alert) Duration Second</div>
                                    <div className="ml-auto d-flex justify-content-end">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="duration second"
                                            aria-label="duration second"
                                            aria-describedby="basic-addon2"
                                            onChange={(e) =>
                                                props.onChangeAlertSettings("imbalance_alert_duration_second", e)
                                            }
                                            value={props.imbalanceAlertDurationSecond}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn btn-success ml-4"
                                onClick={() => props.submitAlertSettings()}
                            >
                                Submit
                            </button>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panel-header">
                            <div className="panel-title">Enable Exchanges</div>
                        </div>
                        <div className="panel-body">
                            <div className="panel-row">
                                {props.arrayExchanges
                                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                                    .map((ex, i) => (
                                        <div className="d-flex mb-3" key={i}>
                                            <div className="text-dark">{ex.name}</div>
                                            <div className="ml-auto">
                                                <Switch
                                                    onChange={() => props.toggleExchange(ex.id)}
                                                    checked={!ex.disable}
                                                    disabled={props.onLoading}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-md-8">
                    {props.pendingUpdateExchange && props.pendingUpdateExchange.length > 0 ? (
                        <div className="panel">
                            <div className="panel-header">
                                <div className="pb-4 pt-1">
                                    <b>PENDING UPDATE EXCHANGE</b>
                                </div>

                                <div className="panel-body">
                                    {props.pendingUpdateExchange.map((pendingItem, index) => (
                                        <div key={index}>
                                            <span>Created at: {pendingItem.created}</span>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Trading Fee Maker</th>
                                                        <th scope="col">Trading Fee Taker</th>
                                                        <th scope="col">Enabled</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pendingItem.change_list &&
                                                        pendingItem.change_list.map((exchange, i) => (
                                                            <tr key={i}>
                                                                <th scope="row">{exchange.data.exchange_id}</th>
                                                                <td className="pr-5">
                                                                    {exchange.data.trading_fee_maker}
                                                                </td>
                                                                <td className="pr-5">
                                                                    {exchange.data.trading_fee_taker}
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    {exchange.data.disable ? (
                                                                        <p className="text-danger">False</p>
                                                                    ) : (
                                                                        <p className="text-success">True</p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <div className="">
                                                <button
                                                    type="button"
                                                    className="btn btn-light"
                                                    onClick={() => props.deleteConfirmExchange(pendingItem.id)}
                                                >
                                                    Cancel All
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success"
                                                    onClick={() => props.submitConfirmExchange(pendingItem.id)}
                                                >
                                                    Confirm All
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="panel">
                            <div className="panel-header">
                                <div className="pb-4 pt-1">
                                    <b>EXCHANGE STATUS</b>
                                </div>
                                <div className="panel-body">
                                    <table className="table table-striped table-responsive-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col" style={{ minWidth: "180px" }}>
                                                    Trading Fee Maker
                                                </th>
                                                <th scope="col" style={{ minWidth: "180px" }}>
                                                    Trading Fee Taker
                                                </th>
                                                {/* <th scope="col">Enabled</th> */}
                                                <th scope="col">Reset/Submit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.arrayExchanges.map((exchange, i) => (
                                                <tr key={i}>
                                                    <th scope="row">{exchange.id}</th>
                                                    <td>{exchange.name.toUpperCase()}</td>
                                                    <td className="pr-5">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className="form-control"
                                                            value={exchange.trading_fee_maker}
                                                            onChange={(e) =>
                                                                props.onChangeExchangeValue(
                                                                    i,
                                                                    "trading_fee_maker",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td className="pr-5">
                                                        <input
                                                            type="number"
                                                            step="any"
                                                            className="form-control"
                                                            value={exchange.trading_fee_taker}
                                                            onChange={(e) =>
                                                                props.onChangeExchangeValue(
                                                                    i,
                                                                    "trading_fee_taker",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </td>

                                                    <td className="d-inline-flex">
                                                        <button type="button" className="btn btn-light">
                                                            <i className="fa fa-minus-square-o" aria-hidden="true" />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            onClick={() => props.submitUpdateExchange(exchange.id)}
                                                        >
                                                            <i className="fa fa-check-circle" aria-hidden="true" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="">
                                        <button type="button" className="btn btn-light">
                                            Reset
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => props.submitUpdateExchange()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div className="pb-4" />
                            </div>
                        </div>
                    )}

                    <div className="panel">
                        <div className="panel-header">
                            <div className="panel-title">Gas Source</div>
                        </div>
                        <div className="panel-body">
                            <div className="panel-row">
                                <Select
                                    className="mb-3"
                                    options={props.gasSource || []}
                                    onChange={props.onChangeGasSource.bind(this)}
                                    value={props.selectedGasSource}
                                />

                                <button type="button" className="btn btn-success" onClick={props.onSubmitGasSource}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                    <RFQTradeSettings />
                </div>
            </div>
        </div>
    );
};

export default ExchangeStatusView;
