import AceEditor from "react-ace";
import { useState } from "react";
import { marginTransfer, marginRepay } from "../../../services/configuration";

const AdminManual = (props) => {
    const [showTransfer, setShowTransfer] = useState(false);
    const [transferData, setTransferData] = useState(
        '{\n "amount":"0.001",\n "asset":"KNC",\n "spot_to_margin":false\n}'
    );
    const [showRepay, setShowRepay] = useState(false);
    const [repayData, setRepayData] = useState('{\n "amount":"0.001",\n "asset":"KNC"\n}');

    const marginTransferFunc = async () => {
        try {
            const result = await marginTransfer(props.apiService, "binance_1", JSON.parse(transferData));
            if (result.error) props.alert.error(result.error);
            else props.alert.success("success");
        } catch {
            props.alert.error("Please check, invalid format json");
        }
    };

    const marginRepayFunc = async () => {
        try {
            const result = await marginRepay(props.apiService, "binance_1", JSON.parse(repayData));
            if (result.error) props.alert.error(result.error);
            else props.alert.success("success");
        } catch {
            props.alert.error("Please check, invalid format json");
        }
    };

    return (
        <div>
            <div
                className="text-dark my-3 d-flex justify-content-between pointer"
                onClick={() => setShowTransfer(!showTransfer)}
            >
                Margin transfer
                <div>
                    <i className={`text-success fa fa-chevron-${showTransfer ? "up" : "down"}`} aria-hidden="true" />
                </div>
            </div>
            <div className={showTransfer ? "d-flex" : "d-none"}>
                <AceEditor
                    mode="javascript"
                    theme="github"
                    name="blah2"
                    onChange={(value) => setTransferData(value)}
                    value={transferData}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        width: "100%",
                    }}
                    height="100px"
                    width="100%"
                />
                <div className="mt-auto ml-3">
                    <button type="button" className="btn btn-success" onClick={marginTransferFunc}>
                        Submit
                    </button>
                </div>
            </div>

            <div
                className="text-dark my-3 d-flex justify-content-between pointer"
                onClick={() => setShowRepay(!showRepay)}
            >
                Margin repay
                <div>
                    <i className={`text-success fa fa-chevron-${showRepay ? "up" : "down"}`} aria-hidden="true" />
                </div>
            </div>
            <div className={showRepay ? "d-flex" : "d-none"}>
                <AceEditor
                    mode="javascript"
                    theme="github"
                    name="blah2"
                    onChange={(value) => setRepayData(value)}
                    value={repayData}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        width: "100%",
                    }}
                    height="80px"
                    width="100%"
                />
                <div className="mt-auto ml-3">
                    <button type="button" className="btn btn-success" onClick={marginRepayFunc}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AdminManual;
