import { toT } from "../../../services/utils/converter";
import { useSelector } from "react-redux";
import moment from "moment";
import { BLOCK_EXPLORER_URL } from "../../../services/constants";
import ReactJson from "react-json-view";

const SetRateDetailView = ({ data, tokens, networkId }) => {
    const bloclExplorerUrl =
        (networkId && BLOCK_EXPLORER_URL[networkId] ? BLOCK_EXPLORER_URL[networkId] : BLOCK_EXPLORER_URL.DEFAULT) +
        "tx/";
    const timezone = useSelector((state) => state.global.timezone);

    return (
        <div className="p-2 subrow">
            <pre>
                Id: {data.id}{" "}
                <a href={bloclExplorerUrl + data.id.split("|")[1]} target="blank">
                    <i className="fa fa-link ml-1" />{" "}
                </a>{" "}
            </pre>
            <pre>Error: {data.result.status_error || data.result.error}</pre>
            <strong className="bold">Block: {data.params.block} </strong>
            <span>
                Timestamp: {data.timestamp} (
                {moment(+data.timestamp)
                    .utcOffset(timezone.offset || 0)
                    .format("YYYY-MM-DD, HH:mm:ss")}{" "}
                {timezone.label})
            </span>
            <br />
            <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Token</th>
                        <th>Buy</th>
                        <th>AfpMid</th>
                        <th>Sell</th>
                        <th>Triggered</th>
                    </tr>
                </thead>
                <tbody>
                    {data.params && data.params.assets.length && data.params.buys.length && data.params.sells.length ? (
                        data.params.assets.map((tokenId, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        {tokens && tokens[tokenId]
                                            ? `[${tokenId}] ${tokens[tokenId].symbol}`
                                            : `[${tokenId}]`}
                                    </td>
                                    <td>{toT(data.params.buys[i], 18, 10, true)}</td>
                                    <td>{toT(data.params.afpMid[i], 18, 10)}</td>
                                    <td>{toT(data.params.sells[i], 18, 10)}</td>
                                    <td>{data.params.triggers[i].toString()}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <div />
                    )}
                </tbody>
            </table>

            <p>
                <a
                    className="btn btn-light"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    <i className="fa fa-expand" aria-hidden="true" />
                    <i> Click to view more details</i>
                </a>
            </p>
            <div className="collapse" id="collapseExample">
                <div className="card card-body">
                    <ReactJson src={data} name={false} />
                </div>
            </div>
        </div>
    );
};

export default SetRateDetailView;
