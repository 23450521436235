import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
    isSubmitting: boolean;
}

const initialState: IState = {
    isSubmitting: false,
};

const tokenPairListingSlice = createSlice({
    name: "tokenPairListing",
    initialState,
    reducers: {
        setSubmitting: (state, action: PayloadAction<boolean>) => {
            state.isSubmitting = action.payload;
        },
    },
});

export default tokenPairListingSlice;
