import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TokenCell from "../TokenCell";
import ConfigTable from "../ConfigTable/trading_pairs_table";
import { getListExchange } from "services/configuration";

const FutureTradingPairs = () => {
    const apiService = useSelector((store) => store.global.apiService);
    const [exchanges, setExchanges] = useState({});

    useEffect(() => getExchange(), []);

    const getExchange = async () => {
        const result = await getListExchange(apiService);
        if (!result.success) return;
        if (result && result.data) {
            const exchangeMap = {};
            result.data.forEach((ex) => (exchangeMap[ex.id] = ex));
            setExchanges(exchangeMap);
        }
    };

    return (
        <div className="panel panel-table">
            <ConfigTable
                title="FUTURE TRADING PAIRS"
                updateType="update_future_trading_pair"
                assetChild="future_trading_pairs"
                pendingUrl="/v3/setting-change-future-tpair"
                fields={{
                    spot_base: {
                        title: "Spot Base",
                        const: true,
                    },
                    spot_quote: {
                        title: "Spot Quote",
                        const: true,
                    },
                    future_base: {
                        title: "Future Base",
                        const: true,
                    },
                    future_quote: {
                        title: "Future Quote",
                        const: true,
                    },
                    exchange_id: {
                        title: "Exchange",
                        const: true,
                        cell: (row) => (
                            <div>
                                [{row.value}] {exchanges[row.value]?.name || ""}
                            </div>
                        ),
                    },
                    status: {
                        title: "Status",
                        const: true,
                    },
                    upper_threshold_premium: {
                        title: "Upper threshold premium",
                    },
                    lower_threshold_premium: {
                        title: "Lower threshold premium",
                    },
                    spot_amp: {
                        title: "Spot amp",
                    },
                    enable: {
                        type: "bool",
                        title: "Enable",
                    },
                    step: {
                        title: "Step",
                    },
                    step_multiplier: {
                        title: "Step multiplier",
                    },
                }}
            />
        </div>
    );
};

export default FutureTradingPairs;
