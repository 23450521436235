import ConfigTable from "./table";
import "./style.css";

const RFQBaseV2 = () => (
    <div className="panel panel-table">
        <ConfigTable
            pendingUrl={"/v3/setting-change-rfq-params-base-v2"}
            fields={{
                name: {
                    title: "name",
                },
                ref_eth_amount: {
                    title: "ref eth\n amount",
                },
                eth_step: {
                    title: "eth step",
                },
                max_eth_size_buy: {
                    title: (
                        <span>
                            max eth <br /> size buy
                        </span>
                    ),
                },
                max_eth_size_sell: {
                    title: (
                        <span>
                            max eth <br /> size sell
                        </span>
                    ),
                },
                a: {
                    title: "A",
                },
                b: {
                    title: "B",
                },
                c: {
                    title: "C",
                },
                min_min: {
                    title: "min min",
                },
                max_imb: {
                    title: "max imb",
                },
                imb_price_multiplier: {
                    title: (
                        <span>
                            imb price <br /> multiplier
                        </span>
                    ),
                },
                eth_amount_to_ignore: {
                    title: (
                        <span>
                            eth amount <br /> to ignore
                        </span>
                    ),
                },
                step_multiplier: {
                    title: (
                        <span>
                            step <br /> multiplier
                        </span>
                    ),
                },
                default_quote_id: {
                    title: (
                        <span>
                            default <br /> quote id
                        </span>
                    ),
                },
                enabled: {
                    title: "Enabled",
                    type: "bool",
                },
            }}
        />
    </div>
);

export default RFQBaseV2;
