import React from "react";

import HttpProvider from "./httpProvider";

import { SUPPORTED_HOST } from "../../constants";
import Host from "./host";

export default class Provider extends React.Component {
    constructor(props) {
        super(props);

        this.httpProvider = new HttpProvider(props.addresses, props.nodeEndpoint);
        this.hosts = {};
        if (props.hosts) {
            Object.keys(SUPPORTED_HOST).map((hostName) => {
                if (props.hosts[hostName]) {
                    this.updateHostData(
                        hostName,
                        props.hosts[hostName].url,
                        props.hosts[hostName].id,
                        props.hosts[hostName].key
                    );
                }
            });
        }

        this.initProvider(props.type);
    }

    initProvider(type) {
        switch (type) {
            case "http":
                this.currentProvider = this.httpProvider;
                this.providerLabel = "http";
                break;
            case "ws":
                this.currentProvider = this.wsProvider;
                this.providerLabel = "ws";
                break;
            default:
                this.currentProvider = this.httpProvider;
                this.providerLabel = "http";
                break;
        }
    }

    updateNodeEndpoint(nodeEndpoint) {
        this.httpProvider.initRPC(nodeEndpoint);
    }

    updateHostData(hostName, hostUrl, userName, keyString) {
        if (!SUPPORTED_HOST[hostName]) return "not supported host";
        this.hosts[hostName] = new Host(hostUrl, userName, keyString);
    }

    updateNetworkAddress(addresses, nodeEndpoint) {
        this.httpProvider = new HttpProvider(addresses, nodeEndpoint);
        this.initProvider("http");
    }

    setProvider(provider) {
        this.currentProvider = provider;
    }

    call(fn, hostName) {
        if (!this.hosts[hostName] && fn == "send") return () => Promise.reject("no host");
        if (!this.currentProvider) return () => Promise.reject("no provider");

        return this.currentProvider[fn].bind(Object.assign(this.currentProvider, this.hosts[hostName]));
    }

    callFn(fn) {
        if (!this.currentProvider) return () => Promise.reject("no provider");

        return this.currentProvider[fn].bind(this);
    }
}
