import styled from "styled-components";

import { COLORS } from "./TokenDisplay/consts";

interface IProps {
    className: string;
}

const IndicatorInfo: React.FC<IProps> = ({ className }) => {
    return (
        <div className={className + " d-sm-flex"}>
            <div className="item">
                <div className="circle" style={{ backgroundColor: COLORS.RED }} />
                <span>No Kyber rates and No 0x rates</span>
            </div>

            <div className="item">
                <div className="circle" style={{ backgroundColor: COLORS.YELLOW }} />
                <span>No Kyber rates</span>
            </div>

            <div className="item">
                <div className="circle" style={{ backgroundColor: COLORS.PURPLE }} />
                <span>No 0x rates</span>
            </div>
        </div>
    );
};

export default styled(IndicatorInfo)`
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .item {
        display: flex;
        align-items: center;
    }

    .circle {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin-right: 4px;
    }
`;
