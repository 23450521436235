const classMap: Record<string, string> = {
    failed: "btn-danger",
    done: "btn-success",
    pending: "btn-warning",
    minded: "btn-info",
};

const StatusCell: React.FC<{ value: string }> = ({ value }) => {
    const buttonClassName = classMap[value] || "";

    return <div>{value ? <span className={`btn ${buttonClassName} btn-label`}>{value}</span> : null}</div>;
};

export default StatusCell;
