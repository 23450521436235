import ConfigTable from "./table";
import "./style.css";

const CategoryQuadratic = () => (
    <div className="panel panel-table">
        <ConfigTable
            pendingUrl={"/v3/setting-change-asset-category"}
            fields={{
                name: {
                    title: "Name",
                },
                rebalance_quadratic: {
                    title: "Rebalance Quadratic",
                    children: {
                        size_a: {
                            title: "size A",
                        },
                        size_b: {
                            title: "size B",
                        },
                        size_c: {
                            title: "size C",
                        },
                        price_a: {
                            title: "price A",
                        },
                        price_b: {
                            title: "price B",
                        },
                        price_c: {
                            title: "price C",
                        },
                        price_offset: {
                            title: "PRICE OFFSET",
                        },
                    },
                },
                max_eth_size: {
                    title: "max eth size",
                },
                blocking_trade_duration_millis: {
                    title: "blocking trade duration ms",
                },
                order_duration_millis: {
                    title: "order duration ms",
                },
                price_eth_amount: {
                    title: "price eth amount",
                },
                exchange_eth_amount: {
                    title: "exchange eth amount",
                },
            }}
        />
    </div>
);

export default CategoryQuadratic;
