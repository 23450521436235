import { SUPPORTED_HOST } from "../constants";

export default {
    getAssetVolume(asset, freq, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        requestData.asset = asset;
        requestData.freq = freq;

        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-asset-volume", "GET", requestData).catch(this.handleNetErr)
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/asset-volume", "GET", null, requestData)
            .catch(this.handleNetErr);
    },

    getReserveVolume(token, reserveAddr, freq, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        requestData.asset = token;
        requestData.freq = freq;
        requestData.reserve = reserveAddr;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/reserve-volume", "GET", null, requestData)
            .catch(this.handleNetErr);
        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-reserve-volume", "GET", requestData).catch(this.handleNetErr)
    },

    getAllUserVolume(timezone, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        if (timezone) requestData.timeZone = timezone;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/user-list", "GET", null, requestData)
            .catch(this.handleNetErr);

        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-user-list", "GET", requestData).catch(this.handleNetErr)
    },

    ///////////
    getBurnFee(reserveAddr, freq, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        if (reserveAddr) requestData.reserve = reserveAddr;
        if (freq) requestData.freq = freq;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/burn-fee", "GET", null, requestData)
            .catch(this.handleNetErr);
        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-burn-fee", "GET", requestData).catch(this.handleNetErr)
    },

    getWalletFee(walletAddr, reserveAddr, freq, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        if (walletAddr) requestData.walletAddr = walletAddr;
        if (reserveAddr) requestData.reserve = reserveAddr;
        if (freq) requestData.freq = freq;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/wallet-fee", "GET", null, requestData)
            .catch(this.handleNetErr);
        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-wallet-fee", "GET", requestData).catch(this.handleNetErr)
    },

    getUserVolume(userAddr, freq, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        if (userAddr) requestData.userAddr = userAddr;
        if (freq) requestData.freq = freq;

        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/user-volume", "GET", null, requestData)
            .catch(this.handleNetErr);
        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-user-volume", "GET", requestData).catch(this.handleNetErr)
    },

    getTradeSumary(walletAddr, timeZone, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        if (timeZone) requestData.timezone = timeZone;

        if (walletAddr) {
            requestData.walletAddr = walletAddr;
            // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-wallet-stats", "GET", requestData).catch(this.handleNetErr)
            return this.provider
                .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/wallet-stats", "GET", null, requestData)
                .catch(this.handleNetErr);
        }

        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-trade-summary", "GET", requestData).catch(this.handleNetErr)
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/trade-summary", "GET", null, requestData)
            .catch(this.handleNetErr);
    },

    getCountryStats(country, timeZone, fromTime, toTime) {
        const requestData = {};
        if (fromTime) requestData.from = fromTime;
        if (toTime) requestData.to = toTime;
        if (timeZone) requestData.timezone = timeZone;
        requestData.country = country;

        // return this.provider.call("send", SUPPORTED_HOST.STATE.symbol)("get-country-stats", "GET", requestData).catch(this.handleNetErr)
        return this.provider
            .call("newSigningSend", SUPPORTED_HOST.STATS.symbol)("/country-stats", "GET", null, requestData)
            .catch(this.handleNetErr);
    },
};
