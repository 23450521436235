import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { RootState } from "reducers";

import { IFilterParams, IOption } from "./model";
import otherActivitiesSlice from "./otherActivities.slice";

const FilterConfig: React.FC = () => {
    const dispatch = useDispatch();

    const filterOptions = useSelector((state: RootState) => state.otherActivities.filterOptions);

    const handleChangeActionFilter = (selectedOptions: IOption<string, string>[]) => {
        dispatch(otherActivitiesSlice.actions.updateActionFilterParam(selectedOptions.map((option) => option.value)));
    };

    const handleChangeOtherFilter = (group: keyof Omit<IFilterParams, "action">, key: string) => {
        dispatch(otherActivitiesSlice.actions.updateOtherFilterParam({ group, key }));
    };

    return (
        <div className="panel">
            <div className="panel-header">
                <h4 className="panel-title">Filter</h4>
            </div>

            {filterOptions ? (
                <div className="panel-body">
                    <div className="panel-row">
                        <p className="text-dark my-2">Action</p>
                        <Select
                            className="mb-3"
                            options={filterOptions.action || []}
                            // @ts-ignore
                            onChange={handleChangeActionFilter}
                            isMulti
                        />
                    </div>

                    {filterOptions.destination ? (
                        <div className="panel-row">
                            <p className="text-dark my-2">Destination</p>
                            <div className="row mb-3">
                                {filterOptions.destination.map((d, i) => (
                                    <div key={i} className="col-3">
                                        <div className="pretty p-default">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleChangeOtherFilter("destination", d.value)}
                                            />
                                            <div className="state">
                                                <label>{d.value || "none"}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {filterOptions.exchange ? (
                        <div className="panel-row">
                            <p className="text-dark my-2">Exchange</p>
                            <div className="row mb-3">
                                {filterOptions.exchange.map((ex, i) => (
                                    <div key={i} className="col-3">
                                        <div className="pretty p-default">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleChangeOtherFilter("exchange", ex.value)}
                                            />
                                            <div className="state p-success">
                                                <label>{ex.value || "none"}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {filterOptions.mining ? (
                        <div className="panel-row">
                            <p className="text-dark my-2">Mining</p>
                            <div className="row mb-3">
                                {filterOptions.mining.map((ex, i) => (
                                    <div key={i} className="col-3">
                                        <div className="pretty p-default">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleChangeOtherFilter("mining", ex.value)}
                                            />
                                            <div className="state p-success">
                                                <label>{ex.value || "none"}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default FilterConfig;
