import styled from "styled-components";
import { useState } from "react";

interface IProps {
    value: string;
    explorer: string;
    className?: string;
}

const TransactionHashCell: React.FC<IProps> = ({ value, className, explorer }) => {
    const [copied, setCopied] = useState(false);
    return (
        <div className={className}>
            <div className="link">
                <a href={explorer + value} target="blank">
                    {value}
                </a>
            </div>
            <i
                style={{ marginLeft: "-9px" }}
                className={copied ? "fa fa-check pointer text-success mr-3" : "fa fa-copy pointer mr-3"}
                onClick={() => {
                    setCopied(true);
                    navigator.clipboard.writeText(value);
                    setTimeout(() => setCopied(false), 2000);
                }}
            />
        </div>
    );
};

export default styled(TransactionHashCell)`
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;

    .link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
