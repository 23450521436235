import BigNumber from "bignumber.js";

type TokenData = {
    totalTarget: string | number;
    "0xRate"?: {
        afpAsk: string;
        afpBid: string;
    };
};

const bnum = (s: string | number | BigNumber) => {
    return new BigNumber(s.toString());
};

export const calculateInventoryValue = (tokenData: TokenData): undefined | string => {
    // Remove undefined, 0, "0"
    if (tokenData["0xRate"] && !!Number(tokenData["0xRate"].afpAsk) && !!Number(tokenData["0xRate"].afpBid)) {
        const value = bnum(tokenData.totalTarget).times(
            bnum(tokenData["0xRate"].afpAsk).plus(bnum(tokenData["0xRate"].afpBid)).dividedBy(bnum(2))
        );

        return value.toFixed(2);
    }

    return undefined;
};
