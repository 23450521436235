import BigNumber from "bignumber.js";

export function validURL(str) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return pattern.test(str);
}

export function isAddress(address) {
    return /^(0x)?[0-9a-f]{40}$/i.test(address);
}

export function isTxHash(hash) {
    return /^0x([A-Fa-f0-9]{64})$/i.test(hash);
}

export function isEmptyAddress(address) {
    return new BigNumber(address).isZero();
}
