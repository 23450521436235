import { useSelector } from "react-redux";
import { roundingNumber } from "services/utils/converter";
import { getIconSource } from "utils/index.js";

const TotalETH = () => {
    const tokenInfo = useSelector((state) => {
        return Object.values(state.dashboard.tokenDataById).reduce((prev, token) => {
            if (["ETH", "WETH", "STETH"].includes(token.info.symbol?.toUpperCase()))
                return prev + parseFloat(token.totalQty);
            return prev;
        }, 0);
    });

    return (
        <div style={{ paddingRight: "5%" }}>
            <img src={getIconSource("ETH")} className="rounded-circle" width={23} style={{ paddingBottom: "2px" }} />
            &nbsp;
            <span className="text-info">{roundingNumber(tokenInfo, 5)}</span>
        </div>
    );
};

export default TotalETH;
