import { title } from "process";
import TokenCell from "../TokenCell";
import ConfigTable from "./table"

const expo = (x, f) => Number.parseFloat(x).toExponential(f);

const ManualAsset = () => (
    <div className="panel panel-table">
        <ConfigTable
            title="MANUAL ASSETS"
            updateType="update_manual_asset"
            getDataUrl="/v3/all-manual-asset"
            pendingUrl={"/v3/setting-manual-asset"}
            fields={{
                symbol: {
                    title: "Asset",
                    cell: (row) => {
                        return <TokenCell symbol={row.value} />;
                    },
                    const: true,
                },
                rate_query_frequency_sec: { title: "rate query frequency sec" },
                target_rate_multiplier: { title: "target rate multiplier" },
                desired_target_ratio: { title: "desired target ratio" },
                below_desired_pwi_a: { title: "below desired pwi a" },
                below_desired_pwi_b: { title: "below desired pwi b" },
                below_desired_pwi_c: { title: "below desired pwi c" },
                above_desired_pwi_a: { title: "above desired pwi a" },
                above_desired_pwi_b: { title: "above desired pwi b" },
                above_desired_pwi_c: { title: "above desired pwi c" },
                max_target_ratio: { title: "max target ratio" },
                onchain_diff: { title: "onchain diff" },
                history_limit: { title: "history limit" },
                time_shift: { title: "time shift" },
                min_spread: { title: "min spread" },
            }}
        />
    </div>
);

export default ManualAsset
