import React, { Component } from "react";

import LineChartView from "./lineChart.view";
import { stretchArray, timeToMinisecond, msToTime } from "../../../services/utils/converter";

export default class LineChart extends Component {
    constructor(props) {
        super(props);
        this.dataProvider = this.props.dataProvider;
        this.isZoomin = false;
        this.selected = this.props.selected;
    }

    checkWarningArea = (data, duration) => {
        if (!data || !data.length) {
            return [];
        }

        let arrayGuild = [];
        let guildError;
        data.forEach((item, index) => {
            if (index >= data.length - 1) return;

            if (data[index + 1].date - data[index].date > duration) {
                arrayGuild.push({
                    date: data[index].date,
                    toDate: data[index + 1].date,
                    lineColor: "#CC0000",
                    lineAlpha: 1,
                    fillAlpha: 0.2,
                    fillColor: "#CC0000",
                    dashLength: 2,
                    inside: true,
                    labelRotation: 90,
                    label: "Duration too long",
                    above: true,
                });
            }

            if (data[index].error) {
                if (!guildError) {
                    guildError = {
                        date: data[index].date,
                        lineColor: "#CC0000",
                        lineAlpha: 0.2,
                        dashLength: 1,
                        fillAlpha: 0.1,
                        fillColor: "#CC0000",
                        inside: true,
                        labelRotation: 90,
                        above: true,
                    };
                } else {
                    guildError.toDate = data[index].date;
                }
            } else {
                if (guildError) {
                    arrayGuild.push(guildError);
                    guildError = null;
                }
            }
        });
        return arrayGuild;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return !this.isZoomin || nextProps.selected != this.selected;
    }

    render() {
        let newData = this.props.data || [];

        this.dataProvider = [...newData];
        let warningDuration = this.props.warningDuration;
        let warningInMilisecond = timeToMinisecond(warningDuration);

        let warningGuilds = this.checkWarningArea(this.dataProvider, warningInMilisecond);

        let blockNumberLabel = document.getElementById(this.props.name + "-blocknumber");
        let config = {
            type: "serial",
            theme: "light",
            // "startEffect": "easeOutSine",
            dataProvider: this.dataProvider,
            gridAboveGraphs: true,
            // "startDuration": 1,
            categoryField: "date",
            dataDateFormat: "YYYY-MM-DD",
            pathToImages: "https://cdn.amcharts.com/lib/3/images/",

            valueAxes: [
                {
                    gridColor: "#FFFFFF",
                    gridAlpha: 0.2,
                    dashLength: 0,
                    // "totalText": "[[total]]"
                },
            ],

            graphs: [
                {
                    id: "g1",
                    title: "Buy",
                    balloonText: "[[title]]: <b>[[value]]</b>",
                    bullet: "round",
                    bulletSize: 5,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    valueField: "buy",
                    hideBulletsCount: 120,
                },
                {
                    id: "g2",
                    title: "Sell",
                    balloonText: "[[title]]: <b>[[value]]</b>",
                    bullet: "round",
                    bulletSize: 5,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    valueField: "sell",
                    hideBulletsCount: 120,
                },
                {
                    id: "g3",
                    title: "afpMid",
                    balloonText: "[[title]]: <b>[[value]]</b>",
                    bullet: "round",
                    bulletSize: 5,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    valueField: "afpMid",
                    hideBulletsCount: 120,
                },
            ],

            chartScrollbar: {
                graph: "g1",
                oppositeAxis: false,
                offset: 30,
                scrollbarHeight: 80,
                backgroundAlpha: 0,
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: "#888888",
                graphFillAlpha: 0,
                graphLineAlpha: 0.5,
                selectedGraphFillAlpha: 0,
                selectedGraphLineAlpha: 1,
                autoGridCount: true,
                color: "#AAAAAA",
            },

            categoryAxis: {
                parseDates: true,
                dashLength: 1,
                minorGridEnabled: true,
                guides: warningGuilds,
                minPeriod: "fff",
                dateFormats: [
                    {
                        period: "fff",
                        format: "NN:SS.QQQ",
                    },
                    {
                        period: "ss",
                        format: "NN:SS.QQQ",
                    },
                    {
                        period: "mm",
                        format: "JJ:NN",
                    },
                    {
                        period: "hh",
                        format: "JJ:NN",
                    },
                    {
                        period: "DD",
                        format: "MMM DD",
                    },
                    {
                        period: "WW",
                        format: "MMM DD",
                    },
                    {
                        period: "MM",
                        format: "MMM",
                    },
                    {
                        period: "YYYY",
                        format: "YYYY",
                    },
                ],
            },
            legend: {
                spacing: 100,
                valueWidth: 70,
            },

            chartCursor: {
                valueBalloonsEnabled: true,
                categoryBalloonDateFormat: "MMM DD, JJ:NN:SS",
                // "categoryBalloonText": "abc [[index]] [[category]]"
            },
            listeners: [
                {
                    event: "zoomed",
                    method: (e) => {
                        let fromTime = `${e.startDate.getDate()}/${e.startDate.getMonth()} ${e.startDate.getHours()}:${e.startDate.getMinutes()}:${e.startDate.getSeconds()}`;
                        let endTime = `${e.endDate.getDate()}/${e.endDate.getMonth()} ${e.endDate.getHours()}:${e.endDate.getMinutes()}:${e.endDate.getSeconds()}`;

                        let totalPoint = e.endIndex - e.startIndex + 1;
                        let interval = Math.round((e.endDate.getTime() - e.startDate.getTime()) / totalPoint);

                        var text = fromTime + " (index: " + e.startIndex + ")";
                        text += " -- " + endTime + " (index: " + e.endIndex + ")";
                        text += " || Total point: " + totalPoint + " (interval: " + msToTime(interval) + ")";
                        document.getElementById(this.props.name).innerHTML = text;

                        if (e.startIndex !== 0 || e.endIndex !== this.dataProvider.length - 1) {
                            this.isZoomin = true;
                        } else {
                            this.isZoomin = false;
                        }
                    },
                },
                // {
                //   "event": "changed",
                //   "method": (e) => {
                //     if(this.dataProvider[e.index] && blockNumberLabel) blockNumberLabel.innerHTML = "Blocknumber: " + this.dataProvider[e.index].blockNumber;
                //   }
                // },
                {
                    event: "clickGraphItem",
                    method: (e) => {
                        let rawData = e.item.dataContext.rawData;
                        console.log(e.item.dataContext);
                        let rawText = "";
                        Object.keys(rawData).map((key, index) => {
                            let value = Object.values(rawData)[index];
                            if (typeof value == "object") {
                                if (key != "Result") return;

                                let resultText = "";
                                Object.keys(rawData.Result).map((resultKey) => {
                                    resultText += "\n   " + resultKey + " : " + rawData.Result[resultKey];
                                });

                                rawText += "Result : " + resultText + "\n";
                            } else {
                                rawText += key + " : " + value + "\n";
                            }
                        });
                        if (e.item.dataContext && e.item.dataContext.blockNumber) {
                            rawText += "Blocknumber : " + e.item.dataContext.blockNumber + "\n";
                        }

                        if (e.item.dataContext && e.item.dataContext.msgs) {
                            rawText += "Msgs : " + e.item.dataContext.msgs + "\n";
                        }

                        if (e.item.dataContext && e.item.dataContext.triggers != undefined) {
                            rawText += "Trigger : " + e.item.dataContext.triggers.toString() + "\n";
                        }

                        if (e.item.dataContext && e.item.dataContext.error) {
                            rawText += "Error : " + e.item.dataContext.error + "\n";
                        }

                        alert(rawText);
                    },
                },
            ],
        };

        return (
            <div>
                <div className="text-dark semi-b">Selection:</div>
                <div id={this.props.name} className="text-dark my-2">
                    Loading... <i className="fa fa-refresh fa-spin" />
                </div>
                <LineChartView config={config} />
                <br />
            </div>
        );
    }
}
