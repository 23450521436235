import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import moment from "moment";

const BlankDetailView = ({ data, tokens }) => {
    const timezone = useSelector((state) => state.global.timezone);

    return (
        <div className="p-2 subrow">
            <span>
                Timestamp: {data.timestamp} (
                {moment(+data.timestamp)
                    .utcOffset(timezone.offset || 0)
                    .format("YYYY-MM-DD, HH:mm:ss")}{" "}
                {timezone.label})
            </span>

            <ReactJson src={data} name={false} />
        </div>
    );
};

export default BlankDetailView;
