import styled from "styled-components";

import { round } from "../utils";
import { calculateInventoryValue } from "./utils";

// 2 types of assets (tokenData.info.asset_type): "default" & "unlisted"
const Background = ({ tokenData, shouldBeWarning }) => {
    if (tokenData.info.asset_type == "unlisted") {
        return <div />;
    }
    return shouldBeWarning ? (
        <div className="background" data-state="warning" style={{ width: tokenData.percent + "%" }} />
    ) : (
        <>
            <div className="background" data-state="within" style={{ width: Math.min(tokenData.percent, 100) + "%" }} />

            {tokenData.percent > 100 && (
                <div
                    className="background"
                    data-state="over"
                    style={{
                        width: Math.min(tokenData.percent - 100, 100) + "%",
                    }}
                />
            )}
        </>
    );
};

const SimpleDisplay = ({ className, tokenId, tokenData, title, indicatorColor, shouldBeWarning, onClickToken }) => {
    const inventoryValue = calculateInventoryValue(tokenData);

    return (
        <div className={className} data-token={tokenId} title={title} onClick={() => onClickToken(tokenId)}>
            <Background tokenData={tokenData} shouldBeWarning={shouldBeWarning} />
            <div className="content">
                <div className="tokenSymbol">{tokenData.info.symbol}</div>

                <div className="tokenPercent" data-warning={shouldBeWarning}>
                    {inventoryValue && (
                        <>
                            <span>{inventoryValue}</span>
                            <span className="separator">|</span>
                        </>
                    )}
                    {tokenData.info.asset_type == "default" ? (
                        <span>{round(tokenData.percent)}%</span>
                    ) : (
                        <span>
                            {round(tokenData.totalQty)} <small>{tokenData.info.symbol}</small>
                        </span>
                    )}
                </div>
            </div>

            <div
                className="rateIndicator"
                style={{
                    backgroundColor: indicatorColor,
                }}
            />
        </div>
    );
};

export default styled(SimpleDisplay)`
    position: relative;

    width: 12rem;
    height: 2.5rem;
    max-width: 45vw;

    padding: 0 8px;

    font-size: 12px;

    background-color: #f0f0f0;
    border-radius: 4px;
    overflow: hidden;

    cursor: pointer;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        background-color: transparent;

        &[data-state="warning"] {
            background-color: #fec5bb;
        }
        &[data-state="within"] {
            background-color: #caf0f8;
        }
        &[data-state="over"] {
            background-color: #90e0ef;
        }
    }

    .content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        z-index: 2;
        width: calc(100% - 0.8rem - 4px);
        height: 100%;
    }

    .tokenSymbol {
        font-weight: bold;
    }

    .tokenPercent {
        font-size: 10px;
        line-height: 1;

        padding: 4px 4px;
        border-radius: 4px;

        &[data-warning="true"] {
            font-weight: bold;
        }

        .separator {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    .rateIndicator {
        position: absolute;
        z-index: 3;

        top: 50%;
        right: 8px;

        width: 0.8rem;
        height: 0.8rem;

        border-radius: 50%;

        transform: translate3d(0, -50%, 0);
    }
`;
