export async function getAllSetting(apiService) {
    const result = await apiService.getAllSetting();
    return result;
}

export async function getBalanceFromBlockchain(apiService, address) {
    const result = await apiService.getAddressBalanceFromBlockchain(address);
    return result;
}

export function getSanityOperatorsFromReserve(apiService, reserveAddr) {
    return apiService.getSanityOperatorsFromReserve(reserveAddr);
}
