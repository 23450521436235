import React, { Component } from "react";
import ReactAutocomplete from "react-autocomplete";

class ReserveAddressInput extends Component {
    constructor(props) {
        super(props);
        this.dropdownOptions = [
            {
                id: "Kyber",
                value: "0x63825c174ab367968EC60f061753D3bbD36A0D8F",
                label: "Kyber - 0x63825c174ab367968EC60f061753D3bbD36A0D8F",
            },
            {
                id: "Prycto",
                value: "0x2aab2b157a03915c8a73adae735d0cf51c872f31",
                label: "Prycto - 0x2aab2b157a03915c8a73adae735d0cf51c872f31",
            },
            {
                id: "Olympus",
                value: "0x6f50e41885fdc44dbdf7797df0393779a9c0a3a6",
                label: "Olympus - 0x6f50e41885fdc44dbdf7797df0393779a9c0a3a6",
            },
            {
                id: "BBO",
                value: "0x91Be8fA21dc21Cff073E07Bae365669E154d6Ee1",
                label: "BBO - 0x91Be8fA21dc21Cff073E07Bae365669E154d6Ee1",
            },
            {
                id: "Mossland",
                value: "0x742e8BB8e6bDE9CB2DF5449f8de7510798727fB1",
                label: "Mossland - 0x742e8BB8e6bDE9CB2DF5449f8de7510798727fB1",
            },
        ];
    }

    onDropdownSelect(e) {
        this.props.addressChange(e);
    }

    onInputChange(e) {
        this.props.addressChange(e.target.value);
    }

    render() {
        return (
            <ReactAutocomplete
                items={this.dropdownOptions}
                shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                wrapperStyle={{ position: "relative" }}
                getItemValue={(item) => item.value}
                inputProps={{
                    class: "form-control w-400",
                    disabled: !this.props.isEdit,
                }}
                renderMenu={(children) => <div className="suggest-menu">{children}</div>}
                renderItem={(item, highlighted) => (
                    <div key={item.id} className={`suggest-item ${highlighted ? "suggest-item-highlighted" : ""}`}>
                        {item.label}
                    </div>
                )}
                value={this.props.reserveAddr}
                onChange={this.onInputChange.bind(this)}
                onSelect={this.onDropdownSelect.bind(this)}
            />
        );
    }
}

export default ReserveAddressInput;
