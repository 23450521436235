import { useEffect, useState } from "react";
import styled from "styled-components";
import moment, { Moment } from "moment";
import Select, { MultiValue } from "react-select";
import { TimeRangePicker } from "components/Share";
import { IOption } from "./index";

interface IProps {
    className?: string;
    fromTime: number;
    toTime: number;
    address: string;
    defaultAddress: string;
    onFromTimeChange: (time: number) => void;
    onToTimeChange: (time: number) => void;
    onAddressChange: (addr: string) => void;
    minUSD: number;
    setMinUSD: (value: number) => void;
    dexOptions: IOption[];
    integrations: MultiValue<IOption>;
    setIntegrations: (value: MultiValue<IOption>) => void;
    tokenOptions: IOption[];
    tokens: MultiValue<IOption>;
    setTokens: (value: MultiValue<IOption>) => void;
}

const Filter: React.FC<IProps> = ({
    className,
    fromTime,
    toTime,
    onFromTimeChange,
    onToTimeChange,
    address,
    onAddressChange,
    defaultAddress,
    minUSD,
    setMinUSD,
    dexOptions,
    integrations,
    setIntegrations,
    tokenOptions,
    tokens,
    setTokens,
}) => {
    const [localAddress, setLocalAddress] = useState(address);
    const [localMinUSD, setLocalMinUSD] = useState(minUSD);

    const handleDateChange = (date: Moment, key: "from" | "to") => {
        if (!date?.valueOf()) return;

        localStorage.setItem("dateTimePickerLastUpdate", new Date().getTime().toString());

        const value = Math.round(date.valueOf() / 1000);

        if (key === "from") onFromTimeChange(value);
        else if (key === "to") onToTimeChange(value);
    };

    useEffect(() => {
        setLocalAddress(address);
    }, [address]);

    useEffect(() => {
        setLocalMinUSD(minUSD);
    }, [minUSD]);

    return (
        <div className={className}>
            <div className="row">
                <div className="col-md-4 mobile-mb-l">
                    <TimeRangePicker
                        onDateChange={handleDateChange}
                        initFrom={moment(fromTime * 1000)}
                        initTo={moment(toTime * 1000)}
                    />
                </div>

                <div className="col-md-8">
                    <p className="text-dark mb-2">Filter</p>
                    <div className="row">
                        <div className="col-md-5 d-none d-md-inline">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control text-left"
                                value={localAddress}
                                placeholder={defaultAddress ? `Default: ${defaultAddress}` : ""}
                                onChange={(e) => setLocalAddress(e.target.value)}
                                onBlur={() => onAddressChange(localAddress)}
                            />
                        </div>
                        <div className="col-md-3 mobile-mb-s">
                            <label>Integrations</label>
                            <Select
                                className="w-100"
                                placeholder="Select Integrations..."
                                options={dexOptions}
                                isMulti
                                onChange={(e) => {
                                    setIntegrations(e);
                                }}
                                value={integrations}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-8 mobile-mb-s">
                                    <label>Tokens</label>
                                    <Select
                                        className="w-100"
                                        placeholder="Select..."
                                        options={tokenOptions}
                                        isMulti
                                        onChange={(e) => {
                                            setTokens(e);
                                        }}
                                        value={tokens}
                                    />
                                </div>
                                <div className="col-md-4 mobile-mb-s">
                                    <label>Min USD</label>
                                    <input
                                        type="number"
                                        className="form-control text-left"
                                        value={localMinUSD}
                                        onChange={(e) => setLocalMinUSD(parseFloat(e.target.value || "0"))}
                                        onBlur={() => setMinUSD(localMinUSD)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default styled(Filter)`
    margin-bottom: 16px;
`;
