import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import dashboardSlice from "../dashboard.slice";
import useLocalStorage from "hook/useLocalStorage";

import TokenDisplay from "./TokenDisplay";

const TokenSummary = ({ onClickToken, error, display, className, unlistedOnly, title }) => {
    const dispatch = useDispatch();
    const tokenDataById = useSelector((state) => state.dashboard.tokenDataById);
    const intervalRef = useRef();
    const [filterUnlistedUSD, setFilterUnlistedUSD] = useLocalStorage("filterUnlistedUSD", 0);

    const keys = Object.keys(tokenDataById).filter((k) => {
        if (!unlistedOnly) {
            return tokenDataById[k].info.asset_type == "default";
        }

        const rate = tokenDataById[k].rates?.find((r) => r.quoteConversion == "USDT" && r.exchange == "custom");
        const total = tokenDataById[k].totalQty;

        return rate && tokenDataById[k].info.asset_type == "unlisted" && rate.afpAsk * total >= filterUnlistedUSD;
    });

    const isDataAvailable = keys.length > 0;

    useEffect(() => {
        const call = () => {
            dispatch(dashboardSlice.actions.getPricingFromIntegrationRequest());
        };
        const intv = intervalRef.current;
        clearInterval(intv);

        if (isDataAvailable) {
            call();
            intervalRef.current = setInterval(call, 20_000);
        }

        return () => {
            clearInterval(intv);
        };
    }, [dispatch, isDataAvailable]);

    return (
        <div className={className}>
            {unlistedOnly && (
                <form className="form-inline ml-3">
                    <div className="form-group">
                        <label className="font-weight-bold">Unlisted assets - min total qty (USD):</label>
                    </div>
                    <div className="form-group ml-3">
                        <input
                            type="number"
                            style={{ width: "100px" }}
                            className="form-control"
                            placeholder="min total (usd)"
                            aria-label="min total"
                            aria-describedby="basic-addon2"
                            onChange={(e) => setFilterUnlistedUSD(e.target.value)}
                            value={filterUnlistedUSD}
                        />
                    </div>
                </form>
            )}

            <div className="list">
                {error && <div className="text-danger font-weight-bold">{error}</div>}

                {keys
                    .filter(
                        (tokenId) =>
                            tokenDataById[tokenId].info.rebalance ||
                            tokenDataById[tokenId].info.set_rate !== "not_set" ||
                            tokenDataById[tokenId].info.transferable ||
                            tokenId == 1
                    )
                    .sort(function (a, b) {
                        return tokenDataById[a].info.symbol < tokenDataById[b].info.symbol ? -1 : 1;
                    })
                    .map((tokenId) => {
                        return (
                            <TokenDisplay
                                key={tokenId}
                                tokenId={tokenId}
                                tokenData={tokenDataById[tokenId]}
                                onClickToken={onClickToken}
                                displayMode={display}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default styled(TokenSummary)`
    background-color: #fff;
    margin-bottom: 0.5rem;

    .list {
        padding: 20px 20px;
        display: flex;

        flex-wrap: wrap;
        gap: 8px 8px;
    }
`;
