export function saveSecretKey(seyString) {
    return {
        type: "SIGN.SAVE_SECRET_KEY",
        payload: seyString,
    };
}

export function saveHost(host) {
    return {
        type: "HOST.SAVE_HOST",
        payload: host,
    };
}

export function clearSecretKey() {
    return {
        type: "SIGN.CLEAR_SECRET_KEY",
    };
}

export function saveNodeEndpoint(nodeEndpoint) {
    return {
        type: "SIGN.SAVE_NODE_ENDPOINT",
        payload: nodeEndpoint,
    };
}

export function saveNetworkId(networkId) {
    return {
        type: "SIGN.SAVE_NETWORK_ID",
        payload: networkId,
    };
}

export function saveHostData(hostData) {
    return {
        type: "SIGN.SAVE_HOSTS_DATA",
        payload: hostData,
    };
}

export function saveNetworkAddress(addresses) {
    return {
        type: "SIGN.SAVE_NETWORK_ADDRESS",
        payload: addresses,
    };
}

export function saveNodeUrl(url) {
    return {
        type: "SIGN.SAVE_NODE_URL",
        payload: url,
    };
}

export function saveReserveAddress(reserveAddr) {
    return {
        type: "SIGN.SAVE_RESERVE_ADDR",
        payload: reserveAddr,
    };
}

export function saveBalanceThreshold(threshold) {
    return {
        type: "SIGN.SAVE_BALANCE_THRESHOLD",
        payload: threshold,
    };
}
