import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "reducers";
import { toast } from "components/_base/Toast/slice";
import { saveHostData, saveNodeEndpoint } from "actions/signActions";

import Input from "./Input";

const isValidUrl = (url: string) => {
    try {
        new URL(url);

        return true;
    } catch (e) {
        return false;
    }
};

interface IProps {
    className?: string;
    toggleOpen: () => any;
}

interface Config {
    coreKeyId: string;
    coreKeyString: string;
    coreUrl: string;

    statsKeyId: string;
    statsKeyString: string;
    statsUrl: string;

    nodeEndpoint: string;
}

const Editor: React.FC<IProps> = ({ toggleOpen, className }) => {
    const dispatch = useDispatch();
    const defaultNode = useSelector((state: RootState) => state.global.defaultNode);
    const nodeEndpoint = useSelector((state: RootState) => state.global.nodeEndpoint);
    const hostData = useSelector((state: RootState) => state.global.hostData);

    const [config, setConfig] = useState<Config>({
        coreKeyId: hostData.CORE.id,
        coreKeyString: hostData.CORE.key,
        coreUrl: hostData.CORE.url,

        statsKeyId: hostData.STATS.id,
        statsKeyString: hostData.STATS.key,
        statsUrl: hostData.STATS.url,

        nodeEndpoint,
    });

    const handleChange = (key: keyof Config, value: string) => {
        setConfig((conf) => ({
            ...conf,
            [key]: value,
        }));
    };

    const removeSlash = (s: String) => {
        if (s?.length && s[s.length - 1] == "/") return s.slice(0, s.length - 1);
        return s;
    };

    const handleSubmit = () => {
        if (!isValidUrl(config.coreUrl)) {
            console.error(`Invalid core url: ${config.coreUrl}`);
        }

        if (!isValidUrl(config.statsUrl)) {
            console.error(`Invalid stats url: ${config.statsUrl}`);
        }

        if (config.nodeEndpoint && !isValidUrl(config.nodeEndpoint)) {
            console.error(`Invalid node endpoint: ${config.nodeEndpoint}`);
        }

        dispatch(saveNodeEndpoint(config.nodeEndpoint || ""));

        dispatch(
            saveHostData({
                CORE: {
                    id: config.coreKeyId,
                    url: removeSlash(config.coreUrl),
                    key: config.coreKeyString,
                },
                STATS: {
                    id: config.statsKeyId,
                    url: config.statsUrl,
                    key: config.statsKeyString,
                },
            })
        );

        dispatch(toast({ type: "success", text: "Config updated" }));
        toggleOpen();
    };

    return (
        <div className={className}>
            <label>Core Gateway</label>

            <Input
                prependingText="ID"
                id="core-id"
                name="coreKeyId"
                text={config.coreKeyId}
                onTextChange={(value) => handleChange("coreKeyId", value)}
                placeholder="ID"
                required
            />

            <Input
                prependingText="Key"
                id="core-key"
                name="coreKeyString"
                text={config.coreKeyString}
                onTextChange={(value) => handleChange("coreKeyString", value)}
                placeholder="Key"
                required
            />

            <Input
                prependingText="URL"
                id="core-endpoint"
                name="coreUrl"
                text={config.coreUrl}
                onTextChange={(value) => handleChange("coreUrl", value)}
                placeholder="URL"
                required
            />

            <div className="dropdown-divider" />

            <div className="form-group">
                <label>Node endpoint</label>
                <input
                    type="text"
                    className="form-control"
                    value={config.nodeEndpoint}
                    onChange={(e) => handleChange("nodeEndpoint", e.target.value)}
                    placeholder="http://xxx.xxx.xxx.xxx:port/"
                />
                <small className="form-text text-muted">Node default is {defaultNode} after restart</small>
            </div>

            <button className="btn btn-success btn-lg float-right ml-2" onClick={handleSubmit}>
                Submit
            </button>

            <button className="btn btn-lg float-right" onClick={toggleOpen}>
                Cancel
            </button>
        </div>
    );
};

export default styled(Editor)`
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 300;

    width: 92vw;
    max-width: 640px;
    padding: 16px;

    background-color: #ffffff;
    box-shadow: 0px 2px 8px rgba(1, 18, 34, 0.16);
    border-radius: 4px;
    border: 1px solid rgba(1, 18, 34, 0.16);
`;
