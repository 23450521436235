import React, { Component } from "react";
import RSwitch from "react-switch";

class Switch extends Component {
    constructor(props) {
        super(props);
    }

    handleChange(checked) {
        if (this && this.props && this.props.onChange) {
            this.props.onChange(checked);
        }
    }

    render() {
        return (
            <RSwitch
                checked={this.props.checked}
                onChange={this.handleChange.bind(this)}
                onColor="#91edd2"
                onHandleColor="#31cb9e"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={this.props.disabled}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                height={10}
                width={30}
                className="react-switch align-bottom"
                id="material-switch"
            />
        );
    }
}

export default Switch;
