import classNames from "classnames";
import React from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { getIconSource } from "utils/index.js";

const renderValueChange = (oldValue, newValue) => {
    if (!newValue) return oldValue;

    if (newValue != oldValue) {
        const textDiff = `Old value: ${oldValue}, new value: ${newValue}`;
        return (
            <span
                className="text-warning"
                data-tip={textDiff}
                data-toggle="change-tooltip"
                data-html="true"
                title={textDiff}
            >
                {newValue}
            </span>
        );
        // return newValue
    }

    return oldValue;
};

const TokenDetail = (props) => {
    const editData = props.currentFocusToken
        ? props.pendingChangeRefactor.edit.asset[props.currentFocusToken.id]
        : null;
    const isDefaultAsset = props.currentFocusToken?.asset_type != "unlisted" || editData?.asset_type != "unlisted";
    return (
        //sticky
        <div className={classNames(props.className, "panel pl-2 pr-2")}>
            <div className="panel-header">
                {props.currentFocusToken && (
                    <div className="panel-title">
                        <img className="icon rounded-circle" src={getIconSource(props.currentFocusToken.symbol)} />
                        <span className="ml-2">{props.currentFocusToken.name}</span>
                        {!props.currentFocusToken.id && (
                            <button type="button" className="btn btn-success ml-2 p-1">
                                new
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className="panel-body">
                <div className="panel-row">
                    {props.currentFocusToken && (
                        <div>
                            <div className="">
                                <div className="row">
                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">ID</div>
                                        <div className="pl-0 mb-2">{props.currentFocusToken.id}</div>
                                    </div>
                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Name</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(props.currentFocusToken.name, editData?.name)}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="text-gray-4 font-weight-bold text-dark">Address</div>
                                        <div className="pl-0 text-hide-more mb-2">
                                            {renderValueChange(props.currentFocusToken.address, editData?.address)}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Asset Type</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.asset_type,
                                                editData?.asset_type
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Decimals</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.decimals.toString(),
                                                editData?.decimals?.toString()
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Is Enabled</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.is_enabled.toString(),
                                                editData?.is_enabled?.toString()
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Transferable</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.transferable.toString(),
                                                editData?.transferable?.toString()
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Rebalance</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.rebalance.toString(),
                                                editData?.rebalance?.toString()
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Is Quote</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.is_quote.toString(),
                                                editData?.is_quote?.toString()
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Margin Enable</div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.margin_enable?.toString(),
                                                editData?.margin_enable?.toString()
                                            )}
                                        </div>
                                    </div>

                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Perpetual Enable
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.perpetual_enable?.toString(),
                                                    editData?.perpetual_enable?.toString()
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">Set Rate</div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.set_rate,
                                                    editData?.set_rate
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Normal Update Per Period
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.normal_update_per_period.toString(),
                                                    editData?.normal_update_per_period?.toString()
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Max Imbalance Ratio
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.max_imbalance_ratio,
                                                    editData?.max_imbalance_ratio
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Order Duration Millis
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.order_duration_millis,
                                                    editData?.order_duration_millis
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">
                                            Blocking Trade Duration Millis
                                        </div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.blocking_trade_duration_millis,
                                                editData?.blocking_trade_duration_millis
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">
                                            Price {props.tokens && props.tokens[1] ? props.tokens[1].symbol : "ETH"}{" "}
                                            Amount
                                        </div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.price_eth_amount,
                                                editData?.price_eth_amount
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">
                                            Exchange {props.tokens && props.tokens[1] ? props.tokens[1].symbol : "ETH"}{" "}
                                            Amount
                                        </div>
                                        <div className="pl-0 mb-2">
                                            {renderValueChange(
                                                props.currentFocusToken.exchange_eth_amount,
                                                editData?.exchange_eth_amount
                                            )}
                                        </div>
                                    </div>
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Sanity Threshold
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.sanity_info.threshold.toString(),
                                                    editData?.sanity_info?.threshold.toString()
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Sanity Rate Provider
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.sanity_info.provider.toString(),
                                                    editData?.sanity_info?.provider.toString()
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">
                                                Sanity Rate Path
                                            </div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    (props.currentFocusToken.sanity_info.path ?? []).join(","),
                                                    (editData?.sanity_info?.path ?? []).join(",")
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {isDefaultAsset && (
                                        <div className="col-3">
                                            <div className="text-gray-4 font-weight-bold text-dark">Transfer Gas</div>
                                            <div className="pl-0 mb-2">
                                                {renderValueChange(
                                                    props.currentFocusToken.transfer_gas,
                                                    editData?.transfer_gas
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">Asset category</div>
                                        <div className="pl-0 mb-2">
                                            {editData?.asset_category_id
                                                ? renderValueChange(
                                                      props.getAssetCategoryName(
                                                          props.currentFocusToken.asset_category_id
                                                      ),
                                                      props.getAssetCategoryName(editData?.asset_category_id)
                                                  )
                                                : props.getAssetCategoryName(props.currentFocusToken.asset_category_id)}
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="text-gray-4 font-weight-bold text-dark">RFQ base</div>
                                        <div className="pl-0 mb-2">
                                            {editData?.rfq_params_base_v2_id
                                                ? renderValueChange(
                                                      props.getRFQBaseName(
                                                          props.currentFocusToken.rfq_params_base_v2_id
                                                      ),
                                                      props.getRFQBaseName(editData?.rfq_params_base_v2_id)
                                                  )
                                                : props.getRFQBaseName(props.currentFocusToken.rfq_params_base_v2_id)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Margin settings */}
                            {isDefaultAsset && (
                                <React.Fragment>
                                    <div className="text-gray-4 font-weight-bold text-dark mb-2">Margin Setting</div>
                                    <div className="pl-0 mb-2">
                                        <table className="table table-exchange">
                                            <thead className="thead-custom">
                                                <tr>
                                                    <th scope="col" className="font-weight-normal">
                                                        Margin Enable With Config
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Margin Target Amount
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Margin Min Amount
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.margin_enable_with_cfg?.toString(),
                                                            editData?.margin_enable_with_cfg?.toString()
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.margin_target_amount?.toString(),
                                                            editData?.margin_target_amount?.toString()
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.margin_min_amount?.toString(),
                                                            editData?.margin_min_amount?.toString()
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )}

                            {props.currentFocusToken.pwi && (
                                <React.Fragment>
                                    <div className="text-gray-4 font-weight-bold text-dark mb-2">PWIS</div>
                                    <div className="pl-0 mb-2">
                                        <table className="table table-exchange">
                                            <thead className="thead-custom">
                                                <tr>
                                                    <th scope="col" className="font-weight-normal">
                                                        Type
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        A
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        B
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        C
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Min Spread
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Price Multiply Factor
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Plot
                                                    </th>
                                                    {editData?.pwi?.ask && (
                                                        <th scope="col" className="font-weight-normal">
                                                            Plot Pending
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="font-weight-normal">
                                                        ASK
                                                    </th>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.ask.a,
                                                            editData?.pwi?.ask?.a
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.ask.b,
                                                            editData?.pwi?.ask?.b
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.ask.c,
                                                            editData?.pwi?.ask?.c
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.ask.min_min_spread,
                                                            editData?.pwi?.ask?.min_min_spread
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.ask.price_multiply_factor,
                                                            editData?.pwi?.ask?.price_multiply_factor
                                                        )}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className="pointer"
                                                            onClick={() =>
                                                                props.openModalPlot(
                                                                    props.currentFocusToken.pwi.ask.a,
                                                                    props.currentFocusToken.pwi.ask.b,
                                                                    props.currentFocusToken.pwi.ask.c,
                                                                    props.currentFocusToken.pwi.ask.min_min_spread,
                                                                    props.currentFocusToken.pwi.ask
                                                                        .price_multiply_factor
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-line-chart" aria-hidden="true" />
                                                        </a>
                                                    </td>
                                                    {editData?.pwi?.ask && (
                                                        <td>
                                                            <a
                                                                className="pointer"
                                                                onClick={() =>
                                                                    props.openModalPlot(
                                                                        editData.pwi.ask.a,
                                                                        editData.pwi.ask.b,
                                                                        editData.pwi.ask.c,
                                                                        editData.pwi.ask.min_min_spread,
                                                                        editData.pwi.ask.price_multiply_factor
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-line-chart" aria-hidden="true" />
                                                            </a>
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="font-weight-normal">
                                                        BID
                                                    </th>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.bid.a,
                                                            editData?.pwi?.bid?.a
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.bid.b,
                                                            editData?.pwi?.bid?.b
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.bid.c,
                                                            editData?.pwi?.bid?.c
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.bid.min_min_spread,
                                                            editData?.pwi?.bid?.min_min_spread
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.pwi.bid.price_multiply_factor,
                                                            editData?.pwi?.bid?.price_multiply_factor
                                                        )}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className="pointer"
                                                            onClick={() =>
                                                                props.openModalPlot(
                                                                    props.currentFocusToken.pwi.bid.a,
                                                                    props.currentFocusToken.pwi.bid.b,
                                                                    props.currentFocusToken.pwi.bid.c,
                                                                    props.currentFocusToken.pwi.bid.min_min_spread,
                                                                    props.currentFocusToken.pwi.bid
                                                                        .price_multiply_factor
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-line-chart" aria-hidden="true" />
                                                        </a>
                                                    </td>
                                                    {editData?.pwi?.bid && (
                                                        <td>
                                                            <a
                                                                className="pointer"
                                                                onClick={() =>
                                                                    props.openModalPlot(
                                                                        editData.pwi.bid.a,
                                                                        editData.pwi.bid.b,
                                                                        editData.pwi.bid.c,
                                                                        editData.pwi.bid.min_min_spread,
                                                                        editData.pwi.bid.price_multiply_factor
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-line-chart" aria-hidden="true" />
                                                            </a>
                                                        </td>
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )}

                            {props.currentFocusToken.target && isDefaultAsset && (
                                <React.Fragment>
                                    <div className="text-gray-4 font-weight-bold text-dark mb-2">Target Quantity</div>
                                    <div className="pl-0 mb-2">
                                        <table className="table table-exchange">
                                            <thead className="thead-custom">
                                                <tr>
                                                    <th scope="col" className="font-weight-normal">
                                                        Total
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Reserve
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Buy
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Rebalance
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Transfer
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Withdraw
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.target.total,
                                                            editData?.target?.total
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.target.reserve,
                                                            editData?.target?.reserve
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.target.buy_threshold,
                                                            editData?.target?.buy_threshold
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.target.rebalance_threshold,
                                                            editData?.target?.rebalance_threshold
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.target.transfer_threshold,
                                                            editData?.target?.transfer_threshold
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.target.min_withdraw_threshold,
                                                            editData?.target?.min_withdraw_threshold
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )}

                            {props.currentFocusToken.stable_param && (
                                <React.Fragment>
                                    <div className="text-gray-4 font-weight-bold text-dark mb-2">Stable Params</div>
                                    <div className="pl-0 mb-2">
                                        <table className="table table-exchange">
                                            <thead className="thead-custom">
                                                <tr>
                                                    <th scope="col" className="font-weight-normal">
                                                        Price Update Threshold
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Ask Spread
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Bid Spread
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Single Feed Max Spread
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Multiple Feeds Max Diff
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.stable_param.price_update_threshold,
                                                            editData?.stable_param?.price_update_threshold
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.stable_param.ask_spread,
                                                            editData?.stable_param?.ask_spread
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.stable_param.bid_spread,
                                                            editData?.stable_param?.bid_spread
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.stable_param.single_feed_max_spread,
                                                            editData?.stable_param?.single_feed_max_spread
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.stable_param
                                                                .multiple_feeds_max_diff,
                                                            editData?.stable_param?.multiple_feeds_max_diff
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )}

                            {props.currentFocusToken.rebalance_quadratic && (
                                <React.Fragment>
                                    <div className="text-gray-4 font-weight-bold text-dark mb-2">
                                        Rebalance Quadratic
                                    </div>
                                    <div className="pl-0 mb-2">
                                        <table className="table table-exchange">
                                            <thead className="thead-custom">
                                                <tr>
                                                    <th />
                                                    <th scope="col" className="font-weight-normal">
                                                        A
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        B
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        C
                                                    </th>
                                                    <th scope="col" className="font-weight-normal">
                                                        Plot
                                                    </th>
                                                    {editData?.rebalance_quadratic && (
                                                        <th scope="col" className="font-weight-normal">
                                                            Plot Pending
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Size</td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.rebalance_quadratic.size_a,
                                                            editData?.rebalance_quadratic?.size_a
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.rebalance_quadratic.size_b,
                                                            editData?.rebalance_quadratic?.size_b
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.rebalance_quadratic.size_c,
                                                            editData?.rebalance_quadratic?.size_c
                                                        )}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className="pointer"
                                                            onClick={() =>
                                                                props.openModalPlot(
                                                                    props.currentFocusToken.rebalance_quadratic.size_a,
                                                                    props.currentFocusToken.rebalance_quadratic.size_b,
                                                                    props.currentFocusToken.rebalance_quadratic.size_c
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-line-chart" aria-hidden="true" />
                                                        </a>
                                                    </td>
                                                    {editData?.rebalance_quadratic && (
                                                        <td>
                                                            <a
                                                                className="pointer"
                                                                onClick={() =>
                                                                    props.openModalPlot(
                                                                        editData.rebalance_quadratic?.size_a,
                                                                        editData.rebalance_quadratic?.size_b,
                                                                        editData.rebalance_quadratic?.size_c
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-line-chart" aria-hidden="true" />
                                                            </a>
                                                        </td>
                                                    )}
                                                </tr>
                                                <tr>
                                                    <td>Price</td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.rebalance_quadratic.price_a,
                                                            editData?.rebalance_quadratic?.price_a
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.rebalance_quadratic.price_b,
                                                            editData?.rebalance_quadratic?.price_b
                                                        )}
                                                    </td>
                                                    <td>
                                                        {renderValueChange(
                                                            props.currentFocusToken.rebalance_quadratic.price_c,
                                                            editData?.rebalance_quadratic?.price_c
                                                        )}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className="pointer"
                                                            onClick={() =>
                                                                props.openModalPlot(
                                                                    props.currentFocusToken.rebalance_quadratic.price_a,
                                                                    props.currentFocusToken.rebalance_quadratic.price_b,
                                                                    props.currentFocusToken.rebalance_quadratic.price_c
                                                                )
                                                            }
                                                        >
                                                            <i className="fa fa-line-chart" aria-hidden="true" />
                                                        </a>
                                                    </td>
                                                    {editData?.rebalance_quadratic && (
                                                        <td>
                                                            <a
                                                                className="pointer"
                                                                onClick={() =>
                                                                    props.openModalPlot(
                                                                        editData.rebalance_quadratic.price_a,
                                                                        editData.rebalance_quadratic.price_b,
                                                                        editData.rebalance_quadratic.price_c
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-line-chart" aria-hidden="true" />
                                                            </a>
                                                        </td>
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <span className="text-dark">Price offset: </span>
                                            <span className="text-gray-4">
                                                {renderValueChange(
                                                    props.currentFocusToken.rebalance_quadratic.price_offset,
                                                    editData?.rebalance_quadratic?.price_offset
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {props.currentFocusToken.exchanges && (
                                <React.Fragment>
                                    <div className="text-gray-4 font-weight-bold text-dark mb-2">Exchanges</div>
                                    <div className="pl-0 mb-2">
                                        {props.currentFocusToken.exchanges.map((ex, i) => {
                                            const assetExchangeEditData =
                                                props.pendingChangeRefactor.edit.assetExchange[ex.id];
                                            return (
                                                <div key={i} className="">
                                                    <div className="pl-4">
                                                        <div className="row mb-2">
                                                            <div className="col-4">
                                                                <div className="text-dark">Name</div>
                                                                <div className="text-gray-4">
                                                                    {renderValueChange(
                                                                        props.listExchanges[ex.exchange_id] &&
                                                                            props.listExchanges[ex.exchange_id].name,
                                                                        assetExchangeEditData &&
                                                                            props.listExchanges[
                                                                                assetExchangeEditData.exchange_id
                                                                            ] &&
                                                                            props.listExchanges[
                                                                                assetExchangeEditData.exchange_id
                                                                            ].name
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-3">
                                                                <div className="text-dark">Symbol </div>
                                                                <div className="text-gray-4">
                                                                    {renderValueChange(
                                                                        ex.symbol,
                                                                        assetExchangeEditData &&
                                                                            assetExchangeEditData.symbol
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {isDefaultAsset && (
                                                                <div className="col-5">
                                                                    <div className="text-dark">Future Symbol </div>
                                                                    <div className="text-gray-4">
                                                                        {renderValueChange(
                                                                            ex.future_symbol,
                                                                            assetExchangeEditData &&
                                                                                assetExchangeEditData.future_symbol
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {isDefaultAsset && (
                                                                <div className="col-12 mt-2">
                                                                    <div className="text-dark">
                                                                        Deposit Withdraw Symbol
                                                                    </div>
                                                                    <div className="text-gray-4 text-hide-more">
                                                                        {renderValueChange(
                                                                            ex.deposit_withdraw_symbol,
                                                                            assetExchangeEditData &&
                                                                                assetExchangeEditData.deposit_withdraw_symbol
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="col-12 mt-2">
                                                                <div className="text-dark">Deposit Address</div>
                                                                <div className="text-gray-4 text-hide-more">
                                                                    {renderValueChange(
                                                                        ex.deposit_address,
                                                                        assetExchangeEditData &&
                                                                            assetExchangeEditData.deposit_address
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {isDefaultAsset && (
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="text-dark">Min Deposit</div>
                                                                    <div className="text-gray-4">
                                                                        {renderValueChange(
                                                                            ex.min_deposit,
                                                                            assetExchangeEditData &&
                                                                                assetExchangeEditData.min_deposit
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="text-dark">Target Recommended</div>
                                                                    <div className="text-gray-4">
                                                                        {renderValueChange(
                                                                            ex.target_recommended,
                                                                            assetExchangeEditData &&
                                                                                assetExchangeEditData.target_recommended
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="text-dark">Target Ratio</div>
                                                                    <div className="text-gray-4">
                                                                        {renderValueChange(
                                                                            ex.target_ratio,
                                                                            assetExchangeEditData &&
                                                                                assetExchangeEditData.target_ratio
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="text-dark">Trading Pairs</div>
                                                    {((ex.trading_pairs && ex.trading_pairs.length) ||
                                                        props.pendingChangeRefactor.add.tradingPair[
                                                            ex.asset_id + "-" + ex.exchange_id
                                                        ]) && (
                                                        <table className="table table-striped mt-2 mb-4 table-exchange">
                                                            <thead className="thead-custom">
                                                                <tr>
                                                                    <th scope="col">Base</th>
                                                                    <th scope="col">Quote</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ex.trading_pairs &&
                                                                    props.listToken &&
                                                                    Object.keys(props.listToken).length &&
                                                                    ex.trading_pairs.map((pair, pi) => (
                                                                        <tr
                                                                            key={pi}
                                                                            className={
                                                                                props.pendingChangeRefactor.remove
                                                                                    .tradingPair[pair.id]
                                                                                    ? "border border-danger"
                                                                                    : ""
                                                                            }
                                                                            title="Will be remove this pair"
                                                                        >
                                                                            <td>
                                                                                {pair.base == ex.asset_id
                                                                                    ? `[${ex.asset_id}] ${ex.symbol}`
                                                                                    : `[${pair.base}] ${
                                                                                          props.listToken[pair.base]
                                                                                              .symbol
                                                                                      }`}
                                                                            </td>
                                                                            <td>
                                                                                {pair.quote == ex.asset_id
                                                                                    ? `[${ex.asset_id}] ${ex.symbol}`
                                                                                    : `[${pair.quote}] ${
                                                                                          props.listToken[pair.quote]
                                                                                              .symbol
                                                                                      }`}
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                {props.pendingChangeRefactor.add.tradingPair[
                                                                    ex.asset_id + "-" + ex.exchange_id
                                                                ] &&
                                                                    props.listToken &&
                                                                    Object.keys(props.listToken).length &&
                                                                    props.pendingChangeRefactor.add.tradingPair[
                                                                        ex.asset_id + "-" + ex.exchange_id
                                                                    ].map((pair, pi) => (
                                                                        <tr
                                                                            key={pi}
                                                                            className={
                                                                                props.pendingChangeRefactor.add
                                                                                    .tradingPair[
                                                                                    ex.asset_id + "-" + ex.exchange_id
                                                                                ]
                                                                                    ? "border border-success"
                                                                                    : ""
                                                                            }
                                                                            title="will be add this pair"
                                                                        >
                                                                            <td>
                                                                                {pair.base == ex.asset_id
                                                                                    ? `[${ex.asset_id}] ${ex.symbol}`
                                                                                    : `[${pair.base}] ${
                                                                                          props.listToken[pair.base]
                                                                                              .symbol
                                                                                      }`}
                                                                            </td>
                                                                            <td>
                                                                                {pair.quote == ex.asset_id
                                                                                    ? `[${ex.asset_id}] ${ex.symbol}`
                                                                                    : `[${pair.quote}] ${
                                                                                          props.listToken[pair.quote]
                                                                                              .symbol
                                                                                      }`}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    )}

                                                    <div className="text-dark">Future Trading Pairs</div>
                                                    {(ex.future_trading_pairs?.length ||
                                                        props.pendingChangeRefactor.add.futureTradingPair[
                                                            ex.asset_id + "-" + ex.exchange_id
                                                        ]) && (
                                                        <table className="table table-striped mt-2 mb-4 table-exchange">
                                                            <thead className="thead-custom">
                                                                <tr>
                                                                    <th scope="col">Base</th>
                                                                    <th scope="col">Quote</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ex.future_trading_pairs &&
                                                                    ex.future_trading_pairs.map((pair, pi) => (
                                                                        <tr
                                                                            key={pi}
                                                                            className={
                                                                                props.pendingChangeRefactor.remove
                                                                                    .futureTradingPair[pair.id]
                                                                                    ? "border border-danger"
                                                                                    : ""
                                                                            }
                                                                            title="Will be remove this pair"
                                                                        >
                                                                            <td>{pair.base_symbol}</td>
                                                                            <td>{pair.quote_symbol}</td>
                                                                        </tr>
                                                                    ))}

                                                                {props.pendingChangeRefactor.add.futureTradingPair[
                                                                    ex.asset_id + "-" + ex.exchange_id
                                                                ] &&
                                                                    props.pendingChangeRefactor.add.futureTradingPair[
                                                                        ex.asset_id + "-" + ex.exchange_id
                                                                    ].map((pair, pi) => (
                                                                        <tr
                                                                            key={pi}
                                                                            className={
                                                                                props.pendingChangeRefactor.add
                                                                                    .futureTradingPair[
                                                                                    ex.asset_id + "-" + ex.exchange_id
                                                                                ]
                                                                                    ? "border border-success"
                                                                                    : ""
                                                                            }
                                                                            title="will be add this pair"
                                                                        >
                                                                            <td>{pair.base_symbol}</td>
                                                                            <td>{pair.quote_symbol}</td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    )}

                                                    <div className="dropdown-divider" />
                                                    <br />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}

                    <ReactTooltip />
                </div>
                {props.currentFocusToken && (
                    <div className="panel-row">
                        <p>
                            <a
                                className="btn btn-light"
                                data-toggle="collapse"
                                href="#collapseExample"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i className="fa fa-expand" aria-hidden="true" />
                                <i> Click to view more details</i>
                            </a>
                        </p>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                <ReactJson src={props.currentFocusToken} name={false} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default styled(TokenDetail)`
    .zerox-table {
        width: 100%;
        overflow-x: hidden;

        .table {
            width: 100%;
            overflow-x: auto;
        }
    }
`;
