import React, { Component } from "react";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from "react-dates";
require("react-dates/lib/css/_datepicker.css");
import moment from "moment";

import TimeRangePickerView from "./timeRangePicker.view";

class TimeRangePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayValue: "total_eth_value",
            selectedDate: moment().utc(),
            fromFocused: false,
            toFocused: false,
        };
    }

    render() {
        const fromTimePicker = (
            <SingleDatePicker
                numberOfMonths={1}
                date={this.props.initFrom} // momentPropTypes.momentObj or null
                onDateChange={(date) => this.props.onDateChange(date, "from")} // PropTypes.func.isRequired
                focused={this.state.fromFocused} // PropTypes.bool
                onFocusChange={({ focused: fromFocused }) => this.setState({ fromFocused })} // PropTypes.func.isRequired
                isOutsideRange={(day) => day.isAfter(moment().add(1, "days"))}
                id="from_date_picker"
                displayFormat="YYYY/MM/DD"
            />
        );

        const toTimePicker = (
            <SingleDatePicker
                numberOfMonths={1}
                date={this.props.initTo} // momentPropTypes.momentObj or null
                onDateChange={(date) => this.props.onDateChange(date, "to")} // PropTypes.func.isRequired
                focused={this.state.toFocused} // PropTypes.bool
                onFocusChange={({ focused: toFocused }) => this.setState({ toFocused })} // PropTypes.func.isRequired
                isOutsideRange={(day) => day.isAfter(moment().add(1, "days"))}
                id="to_date_picker"
                displayFormat="YYYY/MM/DD"
            />
        );

        // const datePicker = (
        //     <DateRangePicker
        //         minimumNights={0}
        //         numberOfMonths={1}
        //         showClearDates={true}
        //         startDate={this.state.startDate} // momentPropTypes.momentObj or null,
        //         startDateId="startDate" // PropTypes.string.isRequired,
        //         endDate={this.state.endDate} // momentPropTypes.momentObj or null,
        //         endDateId="endDate" // PropTypes.string.isRequired,
        //         onDatesChange={this.onDatesRangeChange.bind(this)} // PropTypes.func.isRequired,
        //         focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        //         onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        //         isOutsideRange={(day) => day.isAfter(moment().add(1, "days"))}
        //     />
        // );

        return (
            // datePicker={datePicker}
            <TimeRangePickerView fromTimePicker={fromTimePicker} toTimePicker={toTimePicker} />
        );
    }
}

export default TimeRangePicker;
