import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import Masonry from "react-masonry-component";
import { JsonEditor } from "jsoneditor-react";
import { getIconSource } from "utils/index.js";
import OverlapImg from "./OverlapImg.jsx"
import Select from "react-select";

import { TableData, Switch } from "../../Share";
import { NewBtn, EditBtn, CancelBtn, SaveBtn, ConfirmBtn, RejectBtn, AdvanceBtn, BackBtn } from "../Share/btn";

const isIntersectArr = (ar1, ar2) => {
    if (!ar1?.length || !ar2?.length) return false
    return ar1.find(ele => ar2.includes(ele))
}

const ConfigTable = (props) => {
    const apiService = useSelector((store) => store.global.apiService);
    const hostData = useSelector((store) => store.global.hostData)
    const tokens = useSelector((store) => store.global.tokens);

    const [mapRawData, set_mapRawData] = useState({})
    const [input, set_input] = useState([])
    const [hasPending, set_hasPending] = useState(false)
    const [pendingTarget, set_pendingTarget] = useState({})
    const [targetDiff, set_targetDiff] = useState({})
    const [isEdit, set_isEdit] = useState(false)
    const [showEditor, set_showEditor] = useState(false)

    const [searchTokens, set_searchTokens] = useState([])
    const [searchChange, set_searchChange] = useState([])
    const [mapAffected, setMapAffected] = useState({})

    const jsonEditorRef = useRef(null)

    useEffect(() => cook(), [])
    useEffect(() => updateMapAffected(), [tokens, input])

    const cook = async () => {
        const res = await apiService.getFromCore("/v3/rfq-params-base-v2");
        if (!res.success) {
            props.alert.error(res.reason || res.error);
            return
        }
        const newInput = []
        const newMapRawData = {}

        res.data?.forEach(c => {
            const standardizedInput = { ...c }
            newInput.push({ ...standardizedInput })
            newMapRawData[c.id] = standardizedInput
        })
        set_input(newInput)
        set_mapRawData(newMapRawData)

        // get pending
        const pending = await apiService.getFromCore(props.pendingUrl);
        if (pending?.success && pending?.data?.length) {
            const parsedData = pending.data[0];
            set_hasPending(true)
            set_pendingTarget(parsedData)
            const newTargetDiff = {};
            let pendingInput = [...newInput]
            parsedData.change_list.forEach((t) => {
                switch (t.type) {
                    case "create_rfq_params_base_v2":
                        const newId = new Date().getTime();
                        pendingInput = [{
                            ...t.data,
                            is_new: true,
                            id: newId,
                        }, ...pendingInput]
                        break
                    case "update_rfq_params_base_v2":
                        newTargetDiff[t.data.id] = { ...t.data }
                        break
                }
            });
            set_input(pendingInput)
            set_targetDiff(newTargetDiff)

        } else {
            set_hasPending(false)
            set_pendingTarget({})
        }
    }

    const confirmPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.putToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to confirm pending!");
            cook()
        }
    };

    const cancelPending = async () => {
        if (pendingTarget?.id) {
            const result = await apiService.deleteToCore(props.pendingUrl + "/" + pendingTarget.id);

            if (result.success) props.alert.success("success");
            else props.alert.error(result.reason || result.error || "Failed to cancel pending!");

            cook()
        }
    };

    const onChangeInput = (field, value, rowId) => {
        set_input(input?.map(row => {
            if (row.id === rowId) {
                row[field] = value
                return row
            }
            return row
        }))
    }

    const parsedInput = () => {
        const dataArray = [];
        const inputMap = {}
        input?.forEach(t => {
            if (!t.is_delete) {
                inputMap[t.id] = true

                if (!mapRawData[t.id])
                    dataArray.push({
                        type: "create_rfq_params_base_v2",
                        data: {
                            name: t.name,
                            ref_eth_amount: +t.ref_eth_amount,
                            eth_step: +t.eth_step,
                            max_eth_size_buy: +t.max_eth_size_buy,
                            max_eth_size_sell: +t.max_eth_size_sell,
                            a: +t.a,
                            b: +t.b,
                            c: +t.c,
                            min_min: +t.min_min,
                            max_imb: +t.max_imb,
                            imb_price_multiplier: +t.imb_price_multiplier,
                            eth_amount_to_ignore: +t.eth_amount_to_ignore,
                            step_multiplier: +t.step_multiplier,
                            default_quote_id: t.default_quote_id ? +t.default_quote_id : null,
                            enabled: t.enabled,
                            multiply_factor: 0

                        },
                    });
                else if (Object.entries(mapRawData[t.id]).toString() !== Object.entries(t).toString()) {
                    const data = {
                        id: +t.id,
                        name: t.name,
                        ref_eth_amount: +t.ref_eth_amount,
                        eth_step: +t.eth_step,
                        max_eth_size_buy: +t.max_eth_size_buy,
                        max_eth_size_sell: +t.max_eth_size_sell,
                        a: +t.a,
                        b: +t.b,
                        c: +t.c,
                        min_min: +t.min_min,
                        max_imb: +t.max_imb,
                        imb_price_multiplier: +t.imb_price_multiplier,
                        eth_amount_to_ignore: +t.eth_amount_to_ignore,
                        step_multiplier: +t.step_multiplier,
                        default_quote_id: t.default_quote_id ? +t.default_quote_id : null,
                        enabled: t.enabled,
                        multiply_factor: 0
                    };
                    dataArray.push({ type: "update_rfq_params_base_v2", data });
                }
            }
        })

        return dataArray;
    }

    const submit = async () => {
        if (showEditor) {
            try {
                jsonEditorRef?.current?.jsonEditor?.get()
            } catch (e) {
                props.alert.error("Can not save, please check the data!");
                return
            }
        }

        const data = JSON.stringify({ change_list: parsedInput() });
        const result = await apiService.postToCore(props.pendingUrl, data);

        if (result.success) {
            props.alert.success("success");
            set_isEdit(false)
            set_showEditor(false)
            cook()
        }
        else props.alert.error(result.reason || result.error || "Failed to cancel pending!");
    };

    const onButtonClick = (btnName) => {
        switch (btnName) {
            case "new":
                const id = new Date().getTime()
                set_input([{
                    id,
                    is_new: true,
                    name: "base " + Math.floor(id / 1000) % 9999,
                    ref_eth_amount: 0,
                    eth_step: 0,
                    max_eth_size_buy: 0,
                    max_eth_size_sell: 0,
                    a: 0,
                    b: 0,
                    c: 0,
                    min_min: 0,
                    max_imb: 0,
                    imb_price_multiplier: 0,
                    eth_amount_to_ignore: 0,
                    step_multiplier: 0,
                    default_quote_id: null,
                    enabled: false
                }, ...input])
                break;
            case "edit":
                set_isEdit(true)
                break;
            case "cancel":
                cook()
                set_isEdit(false)
                break;
            case "save":
                submit();
                break;
            case "confirm":
                confirmPending();
                break;
            case "reject":
                cancelPending();
                break;
            case "advance":
                set_showEditor(true)
                break;
            case "back":
                set_showEditor(false)
                break;
        }
    }
    const newBtn = <NewBtn clickFunc={() => onButtonClick("new")} />
    const editBtn = <EditBtn clickFunc={() => onButtonClick("edit")} />
    const cancelBtn = <CancelBtn clickFunc={() => onButtonClick("cancel")} />
    const saveBtn = <SaveBtn clickFunc={() => onButtonClick("save")} />
    const confirmBtn = <ConfirmBtn clickFunc={() => onButtonClick("confirm")} />
    const rejectBtn = <RejectBtn clickFunc={() => onButtonClick("reject")} />
    const advanceBtn = <AdvanceBtn clickFunc={() => onButtonClick("advance")} />
    const backBtn = <BackBtn clickFunc={() => onButtonClick("back")} />

    const btnElements = () => {
        if (hasPending) {
            if (pendingTarget?.proposer && hostData?.CORE.id == pendingTarget.proposer)
                return [rejectBtn]
            return [confirmBtn, rejectBtn]
        }
        return isEdit ? [advanceBtn, newBtn, saveBtn, cancelBtn] : [editBtn]
    }

    const options = Object.values(tokens).map((asset) => {
        return { value: asset.id, label: asset.symbol };
    })

    const updateMapAffected = () => {
        const newMapAffected = {}
        input?.forEach(base => {
            newMapAffected[base.id] = getAffectedAsset(base.id)
        })
        setMapAffected(newMapAffected)
    }

    const renderEditable = (cell) => {
        const updateField = cell.column.id;
        const data = props.fields[updateField]

        if (data.type == "bool")
            return (
                <Switch
                    onChange={(e) => onChangeInput(updateField, e, cell.original.id)}
                    checked={cell.original[updateField]}
                />
            );
        return (
            <div
                contentEditable={true}
                suppressContentEditableWarning
                onBlur={(e) => {
                    onChangeInput(updateField, e.target.innerHTML, cell.original.id);
                }}
                onPaste={e => {
                    e.preventDefault()
                    document.execCommand('insertText', false, e.clipboardData.getData('text/plain'))
                }}
                dangerouslySetInnerHTML={{
                    __html: cell.original[updateField]
                }}
            />
        );
    };

    const boolCell = (curV, isChange, newV) => {
        if (isChange)
            return <span>
                <p className={newV ? "text-success m-0" : "text-danger mb-0"}
                    style={{ border: "1px dashed blue" }}>
                    {newV ? "Enabled" : "Disabled"}</p>
                <p className="text-warning mb-0" >Old: {curV ? "Enabled" : "Disabled"}</p>
            </span>

        return <p className={curV ? "text-success m-0" : "text-danger mb-0"}>{curV ? "Enabled" : "Disabled"}</p>
    };


    const cellFunc = (row, field_name, field) => {
        const newVal = targetDiff[row.original.id]?.[field_name]
        const hasChange = hasPending && newVal !== undefined && newVal !== null && newVal != row.value
        if (field.type == "bool") return boolCell(row.value, hasChange, newVal)
        if (hasChange) {
            return <div>
                <div style={{ border: "1px dashed blue" }}>{newVal}</div>
                <p className="text-center mb-0"><span className="text-warning">Old: {field.cell ? field.cell(row) : row.value}</span></p>
            </div>
        }
        return <div >{field.cell ? field.cell(row) : row.value}</div>
    }

    const columns = []
    Object.keys(props.fields).forEach((field) => {
        const col = props.fields[field]
        if (col.children) {
            columns.push({
                Header: col.title,
                id: field,

                columns: Object.keys(col.children).map(child => {
                    return {
                        Header: col.children[child].title,
                        id: child,
                        accessor: child,
                        Cell: isEdit ? renderEditable : (cell => cellFunc(cell, child, col.children[child]))
                    }
                })
            })

        } else {
            columns.push({
                Header: col.title,
                id: field,
                accessor: field,
                Cell: isEdit ? renderEditable : (cell => cellFunc(cell, field, col))
            })
        }
    });
    columns.push(
        {
            expander: true,
            Header: "Affected",
            width: 150,
            Expander: (row) => (
                <OverlapImg images={mapAffected?.[row.original.id]?.map(a => getIconSource(a.symbol))} />
            ),
            style: {
                cursor: "pointer",
                fontSize: 25,
                padding: "0",
                textAlign: "center",
                userSelect: "none",
            },
        },
    )

    const getAffectedAsset = (v2BaseID) => {
        return Object.values(tokens).filter(asset => asset.rfq_params_base_v2_id == v2BaseID)
    }

    const rowClassName = (state, row) => {
        switch (true) {
            case row.original.is_new:
                return "bg-is-new";
            case row.original.is_delete:
                return "bg-is-delete";
        }
    };

    const masonryOptions = {
        transitionDuration: 0,
        percentPosition: true,
        columnWidth: props.columnWidth || 1,
        enableResizableChildren: true,
    };

    const onchangeJsonEditor = (value) => {
        set_input(value)
    }

    const SubComponent = (row) => {
        const assets = mapAffected[row.original.id]
        if (!assets?.length)
            return <div />
        return (<div className="border px-3 py-4">
            Affected assets:<br /><br />
            <div className="row">
                {assets.map((asset, id) => (
                    <div key={id} className="col-2 mb-2">
                        <img src={getIconSource(asset.symbol)} className="rounded-circle" width={23} style={{ paddingBottom: "2px" }} />
                        {asset.symbol} [{asset.id}]
                    </div>
                ))}
            </div>
        </div>)
    }

    const Filter = (
        <div className="d-block d-sm-flex mobile-mb-s" style={{ width: "100%" }}>
            <Select
                className="w-25 w-sm-100 mr-3 mobile-mb-s"
                placeholder="Search tokens..."
                options={[{ value: 0, label: "_" }, ...Object.values(tokens).map(i => { return { value: i.id, label: i.symbol } })]}
                isMulti
                onChange={set_searchTokens}
                value={searchTokens}
                styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {
                hasPending && <Select
                    className="w-25 w-sm-100 mr-3 mobile-mb-s"
                    placeholder="Search changes..."
                    options={[
                        { value: "new", label: "New" },
                        { value: "change", label: "Change" },
                        { value: "unchange", label: "Unchange" },
                    ]}
                    isMulti
                    onChange={set_searchChange}
                    value={searchChange}
                    styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
                />
            }
        </div>
    )

    const filterInput = () => {
        let filterData = input
        if (searchTokens?.length) {
            const assetIds = []
            let noAffected = false
            searchTokens.forEach((s) => {
                if (!s.value) noAffected = true
                else assetIds.push(s.value)
            });

            filterData = filterData.filter(t => {
                return (noAffected && mapAffected[t.id].length == 0) || isIntersectArr(mapAffected[t.id].map(a => a.id), assetIds)
            })
        }

        if (searchChange?.length) {
            const searchChangeMap = {}
            searchChange.forEach(s => searchChangeMap[s.value] = true)
            filterData = filterData.filter(t => (searchChangeMap["new"] && t.is_new) ||
                (searchChangeMap["delete"] && t.is_delete) ||
                (searchChangeMap["change"] && targetDiff[t.id]) ||
                (searchChangeMap["unchange"] && !(t.is_new || t.is_delete || targetDiff[t.id]))
            )
        }

        return filterData
    }

    return (
        <div >
            <div className="col-sm-12 p-0" style={{ height: "100vh" }}>
                <div className="panel-header">
                    <div className="panel-title text-dark p-0">
                        RFQ BASES
                        {showEditor && <span>{backBtn} {saveBtn}
                        </span>}
                    </div>
                    <Masonry options={masonryOptions}>
                        {
                            showEditor ? (
                                <JsonEditor
                                    className="w-100" value={input} mode="code" ref={jsonEditorRef}
                                    allowedModes={["tree", "view", "form", "code", "text"]}
                                    htmlElementProps={{ style: { height: "calc(100vh - 100px)", width: "100%" } }}
                                    onChange={(value) => onchangeJsonEditor(value)}
                                />
                            ) :
                                <div className="col-sm-12 p-0 mt-3 table-group">
                                    <TableData
                                        searchChange
                                        style={{ maxHeight: "calc(100vh - 140px)" }}
                                        data={filterInput()}
                                        buttonElements={btnElements()} columns={columns}
                                        rowClassName={rowClassName}
                                        overWriteProps={{
                                            freezeWhenExpanded: false,
                                            SubComponent: SubComponent,
                                        }}
                                        tableFilter={Filter}
                                        noPagination
                                    />
                                </div>
                        }
                    </Masonry>
                </div>
            </div>
        </div >
    );
}

export default withAlert()(ConfigTable);
