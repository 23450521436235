import { Component } from "react";
import { connect } from "react-redux";
import { withAlert } from "react-alert";

import { toast } from "components/_base/Toast/slice";

import ExchangeStatusView from "./exchangeStatus.view";

import {
    getExchangeStatus,
    updateExchangeStatus,
    enableRebalance,
    holdRebalance,
    getRebalanceStatus,
    enableSetRate,
    disableSetRate,
    getSetRateStatus,
    submitTriggerPeriod,
    getCurrentTriggerPeriod,
    getCurrentMarginLevelThreshold,
    submitMarginLevelThreshold,
    getPendingExchangeUpdate,
    confirmUpdateExchange,
    deleteUpdateExchange,
    setExchangeStatus,
    submitHighLowGas,
    getGasConfig,
    getGasSource,
    submitGasSource,
    getMarginStatus,
    setMarginStatus,
    getMarginTradeStatus,
    setMarginTradeStatus,
    getPerpetualStatus,
    setPerpetualStatus,
    getAlertSettings,
    updateAlertSettings,
    submitPerpetualUtilizationThreshold,
    getCurrentPerpetualUtilizationThreshold,
    getVolatilityAdjustment,
    submitVolatilityAdjustment,
    marginReload,
    getPredictorConfigStatus,
    setPredictorConfigStatus,
} from "../../../services/configuration";

@connect(
    (store) => {
        const apiService = store.global.apiService;
        const hostData = { ...store.global.hostData };
        return {
            apiService,
            hostData,
            exchanges: store.global.exchanges,
        };
    },
    { toast }
)
class ExchangeStatus extends Component {
    constructor() {
        super();

        this.state = {
            exchangeData: {},
            arrayExchanges: [],
            pendingUpdateExchange: [],
            rebalanceStatus: 0,
            btcStatus: 0,
            triggerPeriod: 0,
            marginLevelThreshold: 0,
            highGas: 0,
            lowGas: 0,
            gasSource: [],
            selectedGasSource: null,
            marginStatus: 0,
            marginTradeStatus: 0,
            pnlAlertPnlThreshold: 0,
            pnlAlertImbalanceThreshold: 0,
            volumeAlertRangeSecond: 0,
            volumeAlertRatioThreshold: 0,
            volumeAlertVolumeThreshold: 0,
            imbalanceAlertImbalanceThreshold: 0,
            imbalanceAlertDurationSecond: 0,
            perpetualStatus: 0,
            perpetualUtilizationThreshold: 0,
            volatilityAdjustment: 0,
            predictorConfigStatus: 0,
            listAssetCategories: [],
            unlistedAssetCategoryId: 0,
        };
    }

    componentDidMount = async () => {
        this.syncExchangeData();
        this.getRebalanceStatus();
        this.getSetRateStatus();
        this.getSet0xRateStatus();
        this.getPendingExchangeUpdate();
        this.getCurrentTriggerPeriod();
        this.getCurrentMarginLevelThreshold();
        await this.getGasConfig();
        this.getGasSource();
        this.getPerpetualStatus();
        this.getMarginStatus();
        this.getMarginTradeStatus();
        this.getAlertSettings();
        this.getCurrentPerpetualUtilizationThreshold();
        this.getVolatilityAdjustment();
        this.getPredictorConfigStatus();
        this.getAssetCategory();
    };

    enableRebalance = async () => {
        const result = await enableRebalance(this.props.apiService);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getRebalanceStatus();
    };

    onChangeTriggerPeriod = async (e) => {
        this.setState({ triggerPeriod: e.target.value });
    };

    submitTriggerPeriod = async () => {
        const data = { value: +this.state.triggerPeriod };

        const result = await submitTriggerPeriod(this.props.apiService, JSON.stringify(data));
        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
    };

    getCurrentTriggerPeriod = async () => {
        const data = await getCurrentTriggerPeriod(this.props.apiService);

        if (!data.success || !data || !data.data) {
            this.props.alert.error("Cannot get current trigger period !");
            return;
        }
        this.setState({
            triggerPeriod: data.data.rate_trigger_period,
        });
    };

    onChangeMarginLevelThreshold = async (e) => {
        this.setState({ marginLevelThreshold: e.target.value });
    };

    submitMarginLevelThreshold = async () => {
        const data = { margin_level_threshold: +this.state.marginLevelThreshold };

        const result = await submitMarginLevelThreshold(this.props.apiService, JSON.stringify(data));
        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
    };

    getCurrentMarginLevelThreshold = async () => {
        const data = await getCurrentMarginLevelThreshold(this.props.apiService);

        if (!data.success || !data || !data.data) {
            this.props.alert.error("Cannot get current margin level threshold !");
            return;
        }
        this.setState({
            marginLevelThreshold: data.data.margin_level_threshold,
        });
    };

    // Perpetual-utilization-threshold
    onChangePerpetualUtilizationThreshold = async (e) => {
        this.setState({ perpetualUtilizationThreshold: e.target.value });
    };

    submitPerpetualUtilizationThreshold = async () => {
        const data = { perpetual_utilization_threshold: +this.state.perpetualUtilizationThreshold };

        const result = await submitPerpetualUtilizationThreshold(this.props.apiService, JSON.stringify(data));
        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
    };

    // volatility-adjustment
    getVolatilityAdjustment = async () => {
        const data = await getVolatilityAdjustment(this.props.apiService);

        if (!data.success || !data || !data.data) {
            this.props.alert.error("Cannot get volatility adjustment!");
            return;
        }
        this.setState({
            volatilityAdjustment: data.data,
        });
    };
    onChangeVolatilityAdjustment = async (e) => {
        this.setState({ volatilityAdjustment: e.target.value });
    };

    submitVolatilityAdjustment = async () => {
        const data = { value: +this.state.volatilityAdjustment };
        if (data.value > 1) {
            this.props.alert.error("Value must be ≤ 1");
            return;
        }

        const result = await submitVolatilityAdjustment(this.props.apiService, JSON.stringify(data));
        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
    };

    marginReload = async () => {
        const result = await marginReload(this.props.apiService, "binance_1");
        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
    };

    getCurrentPerpetualUtilizationThreshold = async () => {
        const data = await getCurrentPerpetualUtilizationThreshold(this.props.apiService);

        if (!data.success || !data || !data.data) {
            this.props.alert.error("Cannot get current perpetual utilization threshold !");
            return;
        }
        this.setState({
            perpetualUtilizationThreshold: data.data.perpetual_utilization_threshold,
        });
    };

    holdRebalance = async () => {
        const result = await holdRebalance(this.props.apiService);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getRebalanceStatus();
    };

    getRebalanceStatus = async () => {
        const data = await getRebalanceStatus(this.props.apiService);

        if (!data.success) return;
        this.setState({
            rebalanceStatus: data.data ? 2 : 1,
        });
    };

    getMarginStatus = async () => {
        const data = await getMarginStatus(this.props.apiService);

        if (!data.success) return;
        this.setState({
            marginStatus: data?.data?.margin_enable ? 2 : 1,
        });
    };

    setMarginStatus = async (isEnable) => {
        const result = await setMarginStatus(this.props.apiService, isEnable);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getMarginStatus();
    };

    getMarginTradeStatus = async () => {
        const data = await getMarginTradeStatus(this.props.apiService);

        if (!data.success) return;
        this.setState({
            marginTradeStatus: data?.data?.margin_trade_enable ? 2 : 1,
        });
    };

    setMarginTradeStatus = async (isEnable) => {
        const result = await setMarginTradeStatus(this.props.apiService, isEnable);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getMarginTradeStatus();
    };

    getPredictorConfigStatus = async () => {
        const data = await getPredictorConfigStatus(this.props.apiService);

        if (!data.success) return;
        this.setState({
            predictorConfigStatus: data?.data?.predictor_enable ? 2 : 1,
        });
    };

    setPredictorConfigStatus = async (isEnable) => {
        const result = await setPredictorConfigStatus(this.props.apiService, isEnable);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getPredictorConfigStatus();
    };

    getAssetCategory = async () => {
        const assetCat = await this.props.apiService.getFromCore("/v3/asset-categories");
        if (!assetCat.success) return;
        if (assetCat && assetCat.data) {
            this.setState({ listAssetCategories: assetCat.data });
        }

        const unlistedAssetCat = await this.props.apiService.getFromCore("/v3/unlisted-asset-category-id");
        if (!unlistedAssetCat.success) return;
        this.setState({ unlistedAssetCategoryId: unlistedAssetCat.data.unlisted_asset_category_id });
    };

    onChangeUnlistedAssetCategory = async (value) => {
        this.setState({ unlistedAssetCategoryId: value });
    };

    submitUnlistedAssetCategory = async () => {
        const result = await this.props.apiService.postToCore(
            "/v3/unlisted-asset-category-id",
            JSON.stringify({ unlisted_asset_category_id: this.state.unlistedAssetCategoryId })
        );

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getAssetCategory();
    };

    getPerpetualStatus = async () => {
        const data = await getPerpetualStatus(this.props.apiService);

        if (!data.success) return;
        this.setState({
            perpetualStatus: data?.data?.perpetual_enable ? 2 : 1,
        });
    };

    setPerpetualStatus = async (isEnable) => {
        const result = await setPerpetualStatus(this.props.apiService, isEnable);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getPerpetualStatus();
    };

    getAlertSettings = async () => {
        const data = await getAlertSettings(this.props.apiService);
        if (data?.data?.length)
            data.data.forEach((d) => {
                switch (d.name) {
                    case "pnl_alert_pnl_threshold":
                        this.setState({ pnlAlertPnlThreshold: d.value });
                        break;
                    case "pnl_alert_imbalance_threshold":
                        this.setState({ pnlAlertImbalanceThreshold: d.value });
                        break;
                    case "volume_alert_range_second":
                        this.setState({ volumeAlertRangeSecond: d.value });
                        break;
                    case "volume_alert_ratio_threshold":
                        this.setState({ volumeAlertRatioThreshold: d.value });
                        break;
                    case "volume_alert_volume_threshold":
                        this.setState({ volumeAlertVolumeThreshold: d.value });
                        break;
                    case "imbalance_alert_imbalance_threshold":
                        this.setState({ imbalanceAlertImbalanceThreshold: d.value });
                        break;
                    case "imbalance_alert_duration_second":
                        this.setState({ imbalanceAlertDurationSecond: d.value });
                        break;
                }
            });
    };

    onChangeAlertSettings = async (name, e) => {
        switch (name) {
            case "pnl_alert_pnl_threshold":
                this.setState({ pnlAlertPnlThreshold: e.target.value });
                break;
            case "pnl_alert_imbalance_threshold":
                this.setState({ pnlAlertImbalanceThreshold: e.target.value });
                break;
            case "volume_alert_range_second":
                this.setState({ volumeAlertRangeSecond: e.target.value });
                break;
            case "volume_alert_ratio_threshold":
                this.setState({ volumeAlertRatioThreshold: e.target.value });
                break;
            case "volume_alert_volume_threshold":
                this.setState({ volumeAlertVolumeThreshold: e.target.value });
                break;
            case "imbalance_alert_imbalance_threshold":
                this.setState({ imbalanceAlertImbalanceThreshold: e.target.value });
                break;
            case "imbalance_alert_duration_second":
                this.setState({ imbalanceAlertDurationSecond: e.target.value });
                break;
        }
    };

    submitAlertSettings = async () => {
        const settings = {
            pnl_alert_pnl_threshold: this.state.pnlAlertPnlThreshold,
            pnl_alert_imbalance_threshold: this.state.pnlAlertImbalanceThreshold,
            volume_alert_range_second: this.state.volumeAlertRangeSecond,
            volume_alert_ratio_threshold: this.state.volumeAlertRatioThreshold,
            volume_alert_volume_threshold: this.state.volumeAlertVolumeThreshold,
            imbalance_alert_imbalance_threshold: this.state.imbalanceAlertImbalanceThreshold,
            imbalance_alert_duration_second: this.state.imbalanceAlertDurationSecond,
        };
        let muteAlert = false;
        for (const setting of Object.keys(settings)) {
            const result = await updateAlertSettings(this.props.apiService, setting, settings[setting]);
            if (!result.success) {
                if (result.reason) this.props.alert.error(result.reason);
                else if (!muteAlert) this.props.alert.error("Cannot send request to core");
                muteAlert = true;
            }
        }

        if (!muteAlert) this.props.alert.success("success");
        this.getAlertSettings();
    };

    getGasConfig = async () => {
        const data = await getGasConfig(this.props.apiService);
        if (!data.success) {
            this.props.toast({
                type: "error",
                title: "Get gas threshold",
                text: data.reason || "Cannot get gas threshold from core",
            });
            return;
        }

        this.setState({
            highGas: data.data.high,
            lowGas: data.data.low,
        });

        if (data.data.gas_price) {
            const gasPriceSelect = [];
            Object.keys(data.data.gas_price).map((key) => {
                gasPriceSelect.push({
                    value: key,
                    label: `${key} - slow: ${data.data.gas_price[key].value.slow} standard: ${data.data.gas_price[key].value.standard} fast: ${data.data.gas_price[key].value.fast}`,
                });
            });

            this.setState({
                highGas: data.data.high,
                lowGas: data.data.low,
                gasSource: gasPriceSelect,
            });
        }
    };

    getGasSource = async () => {
        const data = await getGasSource(this.props.apiService);
        if (!data.success) {
            this.props.toast({
                type: "error",
                title: "Get gas source",
                text: data.reason || "Cannot get gas source from core",
            });
            return;
        }

        const gasSourceName = data.data.name;
        const foundSource = this.state.gasSource.find((i) => i.value == gasSourceName);
        if (foundSource) {
            this.setState({
                selectedGasSource: foundSource,
            });
        }
    };

    onSubmitGasSource = async () => {
        if (!this.state.selectedGasSource) return this.props.alert.error("Please select gas source!");

        const data = await submitGasSource(this.props.apiService, this.state.selectedGasSource);
        if (data.success) {
            this.props.alert.success("Success update gas source !");
        } else {
            this.props.alert.error(data.reason);
        }
    };

    onChangeGasSource = (data) => {
        this.setState({
            selectedGasSource: data,
        });
    };

    onChangeLowGas(e) {
        this.setState({
            lowGas: +e.target.value,
        });
    }

    onChangeHighGas(e) {
        this.setState({
            highGas: +e.target.value,
        });
    }

    submitGas = async () => {
        const data = await submitHighLowGas(this.props.apiService, this.state.highGas, this.state.lowGas);
        if (data.success) {
            this.props.alert.success("Success update gas threshold !");
        } else {
            this.props.alert.error(data.reason);
        }
    };

    enableSetRate = async (selector) => {
        const result = await enableSetRate(this.props.apiService, selector);

        if (result.success) {
            this.props.alert.success("success");

            if (selector === "set_rate_bc") {
                this.setState({
                    setRateStatus: true,
                });
            }

            if (selector === "set_rate_0x") {
                this.setState({
                    set0xRateStatus: true,
                });
            }
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getSetRateStatus();
    };

    disableSetRate = async (selector) => {
        const result = await disableSetRate(this.props.apiService, selector);

        if (result.success) {
            this.props.alert.success("success");

            if (selector === "set_rate_bc") {
                this.setState({
                    setRateStatus: false,
                });
            }

            if (selector === "set_rate_0x") {
                this.setState({
                    set0xRateStatus: false,
                });
            }
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
        }
        this.getSetRateStatus();
    };

    getSetRateStatus = async () => {
        const data = await getSetRateStatus(this.props.apiService, "set_rate_bc");

        if (!data.success) return;

        this.setState({
            setRateStatus: data.data,
        });
    };

    getSet0xRateStatus = async () => {
        const data = await getSetRateStatus(this.props.apiService, "set_rate_0x");

        if (!data.success) return;

        this.setState({
            set0xRateStatus: data.data,
        });
    };

    syncExchangeData = async () => {
        const data = await getExchangeStatus(this.props.apiService);

        if (!data || !data.success || !data.data) {
            this.props.alert.error("Cannot get exchange status !");
            return;
        }

        this.setState({
            arrayExchanges: data.data,
        });
    };

    getPendingExchangeUpdate = async () => {
        const data = await getPendingExchangeUpdate(this.props.apiService);

        if (!data.success || !data || !data.data) {
            this.props.alert.error("Cannot get exchange update pending !");
            return;
        }
        this.setState({
            pendingUpdateExchange: data.data,
        });
    };

    setExchangeStatus = async (exchange, status) => {
        const data = await updateExchangeStatus(this.props.apiService, exchange, status);

        if (data.success) {
            this.props.alert.success("Success update exchange status !");
        } else {
            this.props.alert.error(data.reason);
        }
        this.syncExchangeData();
    };

    onChangeExchangeValue(exchangeIndex, valueName, value) {
        const currentExchangeArrayData = this.state.arrayExchanges;
        currentExchangeArrayData[exchangeIndex][valueName] = value;

        this.setState({
            arrayExchanges: currentExchangeArrayData,
        });
    }

    submitUpdateExchange = async (exId) => {
        let requestData = {};
        if (exId !== undefined) {
            requestData = [
                {
                    type: "update_exchange",
                    data: {
                        exchange_id: exId,
                        trading_fee_maker: this.state.arrayExchanges[exId].trading_fee_maker
                            ? +this.state.arrayExchanges[exId].trading_fee_maker
                            : 0,
                        trading_fee_taker: this.state.arrayExchanges[exId].trading_fee_taker
                            ? +this.state.arrayExchanges[exId].trading_fee_taker
                            : 0,
                        disable: this.state.arrayExchanges[exId].disable,
                    },
                },
            ];
        } else {
            requestData = this.state.arrayExchanges.map((ex) => ({
                type: "update_exchange",
                data: {
                    exchange_id: ex.id,
                    trading_fee_maker: ex.trading_fee_maker ? +ex.trading_fee_maker : 0,
                    trading_fee_taker: ex.trading_fee_taker ? +ex.trading_fee_taker : 0,
                    disable: ex.disable,
                },
            }));
        }
        const data = await updateExchangeStatus(
            this.props.apiService,
            exId,
            JSON.stringify({
                change_list: requestData,
            })
        );

        if (data && data.success) {
            this.props.alert.success("Success update exchange status !");
            this.getPendingExchangeUpdate();
            this.syncExchangeData();
        } else {
            this.props.alert.error(data.reason);
        }
    };

    submitConfirmExchange = async (dataId) => {
        const data = await confirmUpdateExchange(this.props.apiService, dataId);
        if (data && data.success) {
            this.props.alert.success("Success update exchange status !");
            this.getPendingExchangeUpdate();
            this.syncExchangeData();
        } else {
            this.props.alert.error(data.reason);
        }
    };

    deleteConfirmExchange = async (dataId) => {
        const data = await deleteUpdateExchange(this.props.apiService, dataId);
        if (data && data.success) {
            this.props.alert.success("Success update exchange status !");
            this.getPendingExchangeUpdate();
            this.syncExchangeData();
        } else {
            this.props.alert.error(data.reason);
        }
    };

    toggleExchange = async (exID) => {
        let exchangeDisable;
        this.state.arrayExchanges.map((ex) => {
            if (ex.id == exID) exchangeDisable = ex.disable;
        });

        if (exchangeDisable == undefined) {
            console.log("no exchange data for id", exID);
            return;
        }

        const result = await setExchangeStatus(this.props.apiService, exID, !exchangeDisable);

        if (result.success) {
            this.props.alert.success("success");
        } else {
            this.props.alert.error(result.reason || result.error || "Cannot send request to core");
            return;
        }
        this.syncExchangeData();
    };

    render() {
        return (
            <ExchangeStatusView
                alert={this.props.alert}
                apiService={this.props.apiService}
                exchangeData={this.state.exchangeData}
                arrayExchanges={this.state.arrayExchanges}
                pendingUpdateExchange={this.state.pendingUpdateExchange}
                onChangeExchangeValue={this.onChangeExchangeValue.bind(this)}
                submitUpdateExchange={this.submitUpdateExchange.bind(this)}
                submitConfirmExchange={this.submitConfirmExchange.bind(this)}
                deleteConfirmExchange={this.deleteConfirmExchange.bind(this)}
                setExchangeStatus={this.setExchangeStatus.bind(this)}
                enableRebalance={this.enableRebalance.bind(this)}
                holdRebalance={this.holdRebalance.bind(this)}
                rebalanceStatus={this.state.rebalanceStatus}
                setRateStatus={this.state.setRateStatus}
                enableSetRate={this.enableSetRate.bind(this)}
                disableSetRate={this.disableSetRate.bind(this)}
                set0xRateStatus={this.state.set0xRateStatus}
                toggleExchange={this.toggleExchange.bind(this)}
                onChangeTriggerPeriod={this.onChangeTriggerPeriod.bind(this)}
                triggerPeriod={this.state.triggerPeriod}
                submitTriggerPeriod={this.submitTriggerPeriod.bind(this)}
                gasSource={this.state.gasSource}
                onChangeGasSource={this.onChangeGasSource.bind(this)}
                selectedGasSource={this.state.selectedGasSource}
                onSubmitGasSource={this.onSubmitGasSource.bind(this)}
                lowGas={this.state.lowGas}
                highGas={this.state.highGas}
                onChangeHighGas={this.onChangeHighGas.bind(this)}
                onChangeLowGas={this.onChangeLowGas.bind(this)}
                submitGas={this.submitGas.bind(this)}
                onChangeMarginLevelThreshold={this.onChangeMarginLevelThreshold.bind(this)}
                marginLevelThreshold={this.state.marginLevelThreshold}
                submitMarginLevelThreshold={this.submitMarginLevelThreshold.bind(this)}
                marginStatus={this.state.marginStatus}
                setMarginStatus={this.setMarginStatus.bind(this)}
                marginTradeStatus={this.state.marginTradeStatus}
                setMarginTradeStatus={this.setMarginTradeStatus.bind(this)}
                predictorConfigStatus={this.state.predictorConfigStatus}
                setPredictorConfigStatus={this.setPredictorConfigStatus.bind(this)}
                pnlAlertPnlThreshold={this.state.pnlAlertPnlThreshold}
                pnlAlertImbalanceThreshold={this.state.pnlAlertImbalanceThreshold}
                volumeAlertRangeSecond={this.state.volumeAlertRangeSecond}
                volumeAlertRatioThreshold={this.state.volumeAlertRatioThreshold}
                volumeAlertVolumeThreshold={this.state.volumeAlertVolumeThreshold}
                imbalanceAlertImbalanceThreshold={this.state.imbalanceAlertImbalanceThreshold}
                imbalanceAlertDurationSecond={this.state.imbalanceAlertDurationSecond}
                onChangeAlertSettings={this.onChangeAlertSettings.bind(this)}
                submitAlertSettings={this.submitAlertSettings.bind(this)}
                perpetualStatus={this.state.perpetualStatus}
                setPerpetualStatus={this.setPerpetualStatus.bind(this)}
                perpetualUtilizationThreshold={this.state.perpetualUtilizationThreshold}
                onChangePerpetualUtilizationThreshold={this.onChangePerpetualUtilizationThreshold.bind(this)}
                submitPerpetualUtilizationThreshold={this.submitPerpetualUtilizationThreshold.bind(this)}
                marginReload={this.marginReload.bind(this)}
                volatilityAdjustment={this.state.volatilityAdjustment}
                onChangeVolatilityAdjustment={this.onChangeVolatilityAdjustment.bind(this)}
                submitVolatilityAdjustment={this.submitVolatilityAdjustment.bind(this)}
                submitUnlistedAssetCategory={this.submitUnlistedAssetCategory.bind(this)}
                listAssetCategories={this.state.listAssetCategories}
                unlistedAssetCategoryId={this.state.unlistedAssetCategoryId}
                onChangeUnlistedAssetCategory={this.onChangeUnlistedAssetCategory.bind(this)}
            />
        );
    }
}

export default withAlert()(ExchangeStatus);
