import { useSelector } from "react-redux";

import TokenCell from "../TokenCell";
import ConfigTable from "./table"
import "./style.css"

const RFQPairs = () => {
    const tokens = useSelector((store) => store.global.tokens);

    return (
        <div className="panel panel-table">
            <ConfigTable
                pendingUrl={"/v3/setting-change-pair-rfq"}
                fields={{
                    base_asset_id: {
                        title: "Base Asset",
                        cell: (row) => {
                            const symbol = tokens[row.value]?.symbol || row.value.toString();
                            return <TokenCell symbol={symbol} />;
                        },
                        sortMethod: (r1, r2) => {
                            const s1 = tokens[r1]?.symbol || r1.toString();
                            const s2 = tokens[r2]?.symbol || r2.toString();
                            return s1.toUpperCase() > s2.toUpperCase() ? 1 : -1;
                        },
                        type: "select_asset"
                    },
                    quote_asset_id: {
                        title: "Quote Asset",
                        cell: (row) => {
                            const symbol = tokens[row.value]?.symbol || row.value.toString();
                            return <TokenCell symbol={symbol} />;
                        },
                        sortMethod: (r1, r2) => {
                            const s1 = tokens[r1]?.symbol || r1.toString();
                            const s2 = tokens[r2]?.symbol || r2.toString();
                            return s1.toUpperCase() > s2.toUpperCase() ? 1 : -1;
                        },
                        type: "select_asset"
                    },
                    integration: {
                        title: "Integration",
                        type: "select",
                    },
                    enabled: {
                        title: "Enabled",
                        type: "bool",
                    },
                    ask_offset: {
                        title: "Ask Offset",
                    },
                    bid_offset: {
                        title: "Bid Offset",
                    },
                    sanity_accept_diff: {
                        title: "Sanity Accept Diff",
                    },
                    amm_adjust_diff: {
                        title: "Amm Adjust Diff",
                    },
                    price_type: {
                        title: "Price Type",
                    },
                    min_profit: {
                        title: "Min Profit",
                    },
                    swap_enabled: {
                        title: "Swap Enabled",
                        type: "bool",
                    },
                }}
            />
        </div>
    )
}
export default RFQPairs
