export const NewBtn = ({ clickFunc }) => (
    <button type="button" className="btn btn-success" onClick={() => clickFunc()}>
        New
    </button>
);


export const EditBtn = ({ clickFunc }) => (
    <button type="button" className="btn btn-info" onClick={() => clickFunc()}>
        Edit
    </button>
);

export const CancelBtn = ({ clickFunc }) => (
    <button type="button" className="btn btn-light" onClick={() => clickFunc()}>
        Cancel
    </button>
);

export const SaveBtn = ({ clickFunc }) => (
    <button type="button" className="btn btn-primary" onClick={() => clickFunc()}>
        Save
    </button>
);

export const ConfirmBtn = ({ clickFunc }) => (
    <button type="button" className="btn btn-success" onClick={() => clickFunc()}>
        Confirm
    </button>
);

export const RejectBtn = ({ clickFunc }) => (
    <button type="button" className="btn btn-default" onClick={() => clickFunc()}>
        Reject
    </button>
);

export const AdvanceBtn = ({ clickFunc }) => (
    <a className="btn" onClick={() => clickFunc()}>
        <i className="fa fa-cog fa-1x" aria-hidden="true" />
    </a>
);

export const BackBtn = ({ clickFunc }) => (
    <a className="btn" onClick={() => clickFunc()}>
        <i className="fa fa-chevron-circle-left" aria-hidden="true" /> <u>Back</u>
    </a>
);
