import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import { setAutoFreeze } from "immer";
import ToastNotificationContainer from "components/_base/Toast";

import { persistor, store } from "./store";
import Routes from "./routes";

import "index.css";
import "./assets/css/app.scss";
import "react-dates/initialize";

console.log(`Built time: %c${new Date(Number(process.env.BUILT_TIME))}`, "color: #bada55");

setAutoFreeze(false);

ReactDOM.render(
    <PersistGate persistor={persistor}>
        <Provider store={store}>
            <ToastNotificationContainer />
            <Routes />
        </Provider>
    </PersistGate>,
    document.getElementById("app")
);
