import { getTokenAssetIcon } from "services/utils/converter";

export const getIconSource = (symbol) => {
    const tokenSymbol = getTokenAssetIcon(symbol.toLowerCase());
    const tokens = {
        "1inch": true,
        aave: true,
        agix: true,
        ape: true,
        bnt: true,
        c98: true,
        crv: true,
        cvc: true,
        dgx: true,
        dydx: true,
        elf: true,
        fet: true,
        ftm: true,
        fxs: true,
        gala: true,
        grt: true,
        id: true,
        kncl: true,
        kp3r: true,
        ldo: true,
        loom: true,
        lqty: true,
        lrc: true,
        matic: true,
        mc: true,
        ogn: true,
        omg: true,
        ost: true,
        pepe: true,
        pnt: true,
        poly: true,
        powr: true,
        rep: true,
        req: true,
        rlc: true,
        shib: true,
        snt: true,
        stg: true,
        stmx: true,
        syn: true,
        uni: true,
        usdp: true,
        wbtc: true,
        yfi: true,
        zrx: true,
        bnb: true,
        bat: true,
        blz: true,
        btc: true,
        busd: true,
        dai: true,
        enj: true,
        eth: true,
        knc: true,
        link: true,
        mana: true,
        mkr: true,
        qnt: true,
        snx: true,
        usdc: true,
        usdt: true,
        mav: true,
        comp: true,
        ens: true,
        rndr: true,
        steth: true,
        pendle: true,
        rpl: true,
        wld: true,
        arb: true,
        sand: true,
        arkm: true,
        hft: true,
        ant: true,
        imx: true,
        sushi: true,
        cyber: true,
        perp: true,
        spell: true,
        mask: true,
        woo: true,
        cvx: true,
        floki: true,
        ocean: true,
        meme: true,
        ilv: true,
        blur: true,
        super: true,
        beamx: true,
        bico: true,
        ethfi: true,
        portal: true,
        alt: true,
        glm: true,
        rsr: true,
        vanry: true,
        pyr: true,
        pixel: true,
        dusk: true,
        coti: true,
        ena: true,
        chz: true,
        ssv: true,
        aevo: true,
        jasmy: true,
        om: true,
        high: true,
        audio: true,
        metis: true,
        lpt: true,
        ygg: true,
        hot: true,
        gtc: true
    };

    if (tokens[tokenSymbol]) return `/icons/${tokenSymbol}.png`;
    return `/favicon.png`;
};
