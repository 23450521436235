import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ONE_DAY_IN_MILLIS } from "./consts";
import { IActionConfig, IFilterOptions, IFilterParams } from "./model";

const FIVE_MINS = 5 * 60 * 1000;
interface IState {
    isLoading: boolean;
    fetchConfig: {
        actionConfig: IActionConfig;
        fromTime: number;
        toTime: number;
    };

    // available options to filter
    filterOptions?: IFilterOptions;

    // options selected by users
    filterParams: IFilterParams;

    activities: Array<any>;

    lastTimeFetched?: number;
}

const initialState: IState = {
    isLoading: false,
    fetchConfig: {
        actionConfig: {
            set_rates: false,
            deposit: true,
            withdraw: true,
            trade: true,
            borrow: true,
            borrow_transfer: true,
            transfer_repay: true,
            cross_margin_transfer: true,
            cross_chain_trade: true,
            perpetual_trade: true,
        },
        fromTime: Date.now() + FIVE_MINS - ONE_DAY_IN_MILLIS,
        toTime: Date.now() + FIVE_MINS,
    },
    filterParams: {
        action: [],
        destination: {},
        exchange: {},
        mining: {},
    },
    activities: [],
};

const otherActivitiesSlice = createSlice({
    name: "otherActivities",
    initialState,
    reducers: {
        fetchRatesRequest: (state) => {
            state.isLoading = true;
        },
        fetchRatesSuccess: (state, action: PayloadAction<Array<any>>) => {
            state.isLoading = false;
            state.activities = action.payload;
            state.lastTimeFetched = Date.now();
        },
        fetchRatesFailure: (state) => {
            state.isLoading = false;
            state.lastTimeFetched = Date.now();
        },

        updateActionConfig: (state, action: PayloadAction<{ key: keyof IActionConfig; nextValue: boolean }>) => {
            const { key, nextValue } = action.payload;
            state.fetchConfig.actionConfig[key] = nextValue;
        },
        updateFromTimeConfig: (state, action: PayloadAction<number>) => {
            state.fetchConfig.fromTime = action.payload;
        },
        updateToTimeConfig: (state, action: PayloadAction<number>) => {
            state.fetchConfig.toTime = action.payload;
        },

        setFilterOptions: (state, action: PayloadAction<IFilterOptions>) => {
            state.filterOptions = action.payload;
        },

        updateActionFilterParam: (state, action: PayloadAction<string[]>) => {
            state.filterParams.action = action.payload;
        },
        updateOtherFilterParam: (
            state,
            action: PayloadAction<{ group: keyof Omit<IFilterParams, "action">; key: string }>
        ) => {
            const { group, key } = action.payload;

            if (state.filterParams[group][key]) {
                delete state.filterParams[group][key];
            } else {
                state.filterParams[group][key] = true;
            }
        },
    },
});

export default otherActivitiesSlice;
