import { css } from "styled-components";

const styles = css`
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
`;

export default styles;
