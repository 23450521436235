import { put, call, takeLatest, ForkEffect, select, all } from "redux-saga/effects";
import { IntegrationName, IntegrationNames } from "services/const";
import TokensService from "services/tokens";
import { RootState } from "reducers";
import dashboardSlice from "./dashboard.slice";

function* getPricingFromIntegrationSaga() {
    const state: RootState = yield select((state: RootState) => state);
    const apiService = state.global.apiService;

    // @ts-ignore
    const sagas = IntegrationNames.map((name) => call([apiService, apiService.getPricingFromIntegration], name));

    // @ts-ignore
    const results = yield all(sagas);

    // @ts-ignore
    const basePricing = yield call([apiService, apiService.getBasePricing]);
    basePricing.data.integration = "*base";
    results.push(basePricing);
    const qtyGetRate = state.global.qtyGetRate;

    const ethUsdtRateObj = state.dashboard.tokenDataById[1]?.rates.find((r: any) => r.symbol == "ETH/USDT");
    const ethUsdtRate = ethUsdtRateObj?.afpBid || ethUsdtRateObj?.afpAsk || 2000;

    interface IntegrationRate {
        assetID: number;
        quoteID: number;
        integration: IntegrationName;
        buyRate: number;
        sellRate: number;
    }
    const payload: Array<IntegrationRate> = results.flatMap((result: any) => {
        const {
            data: { data, integration },
        } = result;

        if (!data) return [];

        const returnRes: Array<IntegrationRate> = [];
        // we only care the rates that quote is ETH or USDT
        data.forEach(({ asset, quote_id, buy_rates, buy_amounts, sell_rates, sell_amounts }: any) => {
            const qty = qtyGetRate * (quote_id == 1 ? 1 : ethUsdtRate);
            returnRes.push({
                assetID: asset,
                buyRate: getRateForQty(buy_rates, buy_amounts, qty),
                sellRate: getRateForQty(sell_rates, sell_amounts, qty),
                integration,
                quoteID: quote_id,
            });
            // for ETH/USDC, need add rate for USDC/ETH
            if (asset == 1) {
                const buyRate = getRateForETHQty(sell_rates, sell_amounts, qtyGetRate);
                const sellRate = getRateForETHQty(buy_rates, buy_amounts, qtyGetRate);
                returnRes.push({
                    assetID: quote_id,
                    buyRate: buyRate > 0 ? 1 / buyRate : 0,
                    sellRate: sellRate > 0 ? 1 / sellRate : 0,
                    integration,
                    quoteID: asset,
                });
            }
        });
        return returnRes;
    });

    yield put(dashboardSlice.actions.getPricingFromIntegrationSuccess(payload));
}

function getRateForETHQty(rates: Array<number>, amounts: Array<number>, qty: any) {
    let quoteQty = 0;
    let baseQty = 0; // eth is base, must upto qty
    if (rates && rates.length) {
        for (let i = 0; i < rates.length; i++) {
            if (qty < baseQty + amounts[i]) {
                quoteQty += (qty - baseQty) * rates[i];
                return quoteQty / qty;
            } else {
                quoteQty += amounts[i] * rates[i];
                baseQty += amounts[i];
            }
        }
    }
    return 0;
}

function getRateForQty(rates: Array<number>, amounts: Array<number>, qty: any) {
    let quoteQty = 0; // must upto qty
    let baseQty = 0;
    if (rates && rates.length) {
        for (let i = 0; i < rates.length; i++) {
            if (qty < quoteQty + amounts[i] * rates[i]) {
                baseQty += (qty - quoteQty) / rates[i];
                return qty / baseQty;
            } else {
                quoteQty += amounts[i] * rates[i];
                baseQty += amounts[i];
            }
        }
    }
    return 0;
}

function* getTokenDataSaga() {
    const state: RootState = yield select((state: RootState) => state);

    const apiService = state.global.apiService;
    const qtyGetRate = state.global.qtyGetRate;

    const tokens = state.global.tokens;
    const kyberTokens = state.global.kyberTokens;
    const exchanges = state.global.exchanges;

    const tokensService = new TokensService({
        tokens,
        kyberTokens,
        exchanges,
    });

    // @ts-ignore
    const response = yield call([tokensService, tokensService.syncAll], apiService, qtyGetRate);

    if (!response) {
        yield put(dashboardSlice.actions.getTokenDataFailure());
        return;
    }

    yield put(dashboardSlice.actions.getTokenDataSuccess(response));
}

function* dashboardSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(dashboardSlice.actions.getTokenDataRequest, getTokenDataSaga);
    yield takeLatest(dashboardSlice.actions.getPricingFromIntegrationRequest, getPricingFromIntegrationSaga);
}

export default dashboardSaga;
