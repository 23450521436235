import BigNumber from "bignumber.js";
const DEFAULT_DIGITS = 4;

export function roundingNumber(input, maxDigits) {
    const MAX_DIGITS = !maxDigits && maxDigits !== 0 ? DEFAULT_DIGITS : maxDigits;

    const CustomBigNumber = BigNumber.clone({
        FORMAT: {
            // the decimal separator
            decimalSeparator: ".",
            // the grouping separator of the integer part
            groupSeparator: ",",
            // the primary grouping size of the integer part
            groupSize: 3,
            // the secondary grouping size of the integer part
            secondaryGroupSize: 0,
            // the grouping separator of the fraction part
            fractionGroupSeparator: " ",
            // the grouping size of the fraction part
            fractionGroupSize: 0,
        },
    });

    const bigInput = new CustomBigNumber(input);
    if (bigInput.toString() === "NaN" || bigInput.toString() === "Infinity") {
        return "NaN";
    }
    const precision =
        bigInput.e < 0
            ? bigInput.toPrecision(MAX_DIGITS || DEFAULT_DIGITS)
            : bigInput.toPrecision(Math.max(MAX_DIGITS, bigInput.e + 1) || DEFAULT_DIGITS);

    return new CustomBigNumber(precision).toFormat();
}

export function flatten(object) {
    return Object.assign(
        {},
        ...(function _flatten(objectBit, path = "") {
            return [].concat(
                ...Object.keys(objectBit).map((key) =>
                    typeof objectBit[key] === "object"
                        ? _flatten(objectBit[key], `${path}/${key}`)
                        : { [`${path}/${key}`]: objectBit[key] }
                )
            );
        })(object)
    );
}

export function flattenSumObjValue(object, initState) {
    if (!object || !Object.keys(object).length) return "0";
    const flattenData = flatten(object);
    return Object.values(flattenData)
        .map((x) => x.toString())
        .reduce((a, b) => {
            const bigA = new BigNumber(a);
            const bigB = new BigNumber(b);
            return bigA.plus(bigB);
        }, new BigNumber(initState))
        .toString();
}

export function stretchArray(array, number) {
    const returnArray = [];
    if (array && Array.isArray(array)) {
        array.map((item, index) => {
            if (index % number == 0) {
                returnArray.push(item);
            }
        });
    }
    return returnArray;
}

function acceptableTyping(number) {
    // ends with a dot
    if (number.length > 0 && number[number.length - 1] == ".") {
        return true;
    }

    return false;
}

export function toT(number, decimal, round, reverse) {
    if (!number) return 0;
    var bigNumber = new BigNumber(number.toString());
    var result;
    if (bigNumber == "NaN" || bigNumber == "Infinity") {
        return number;
    } else if (acceptableTyping(number)) {
        return number;
    }
    if (decimal) {
        result = bigNumber.dividedBy(Math.pow(10, decimal));
    } else {
        result = bigNumber.dividedBy(1000000000000000000);
    }
    if (reverse) {
        result = result.pow(-1);
    }
    if (round) {
        return result.decimalPlaces(round).toString();
    } else {
        return result.toString();
    }
}

function caculateAverageExchangeRate(rates) {
    if (!rates || !Array.isArray(rates) || !rates.length) {
        return 0;
    }
    let rateSum = new BigNumber(0);
    let rateLength = 0;
    const rateExchanges = rates.map((rate) => {
        if (!rate.ask || !rate.bid) return 0;
        const bigRateAsk = new BigNumber(rate.ask[0].Rate.toString());
        const bigRateBid = new BigNumber(rate.bid[0].Rate.toString());
        rateLength++;
        const arv = bigRateAsk.plus(bigRateBid).dividedBy(2);
        rateSum = rateSum.plus(arv);
    });
    if (!rateLength) return 0;
    return rateSum.dividedBy(rateLength);
}

export function caculateEthBalance(tokenBalance, ratesToEth) {
    if (!tokenBalance) return 0;

    const rateAverage = caculateAverageExchangeRate(ratesToEth);
    if (!rateAverage) return 0;

    const balanceBig = new BigNumber(tokenBalance.toString());

    const balanceEth = balanceBig.multipliedBy(rateAverage);
    return balanceEth.toString();
}

export function caculatePercent(a, b) {
    if (!b) return 0;

    const bigA = new BigNumber(a.toString());
    const bigB = new BigNumber(b.toString());

    return bigA.dividedBy(bigB).multipliedBy(100).toFixed(2);
}

export function timeToMinisecond(times) {
    if (!times || times.indexOf(":") < 0) return 0;

    const t = times.split(":");
    const durationMilisecond = t[0] * 60 * 60 * 1000 + t[1] * 60 * 1000;
    return durationMilisecond;
}

export function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ":" + mins + ":" + secs + "." + ms;
}

export function shortenAddress(address, startNum, endNum) {
    if (!address) return "";
    return address.slice(0, startNum) + "..." + address.slice(-endNum);
}

export function getTokenAssetIcon(symbol) {
    if (!symbol) return symbol;

    const symbolSplit = symbol.split("-");
    return symbolSplit[0];
}

export function isLessThan(a, b) {
    const bigA = a ? new BigNumber(a) : new BigNumber(0);
    const bigB = b ? new BigNumber(b) : new BigNumber(0);
    return bigA.lt(bigB);
}

export function sumBig(arrayParams, initState) {
    return arrayParams
        .reduce((a, b) => {
            // let bigA = a ? new BigNumber(a) : new BigNumber(0)
            // let bigB = b ? new BigNumber(b) : new BigNumber(0)
            // return bigA.plus(bigB)
            return new BigNumber(a || 0).plus(new BigNumber(b || 0));
        }, new BigNumber(initState || 0))
        .toString();
}

export function caculateTotalVolumeInSelectedDate(data, startIndex, endIndex) {
    if (!data || !data.length || isNaN(+startIndex) || isNaN(+endIndex)) return 0;

    const arraySelectedVolume = data.slice(startIndex, endIndex + 1).map((x) => x.value.toString());
    const sumVolume = sumBig(arraySelectedVolume, 0);

    return sumVolume;
}

export function qs(key) {
    key = key.replace(/[*+?^$.[]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    const match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export function formatMoney(number, decimal) {
    if (!decimal) decimal = 2
    if (typeof number == "string") number = parseFloat(number)
    if (typeof number == "number")
        return number.toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return 0
}
