import styled from "styled-components";
import cx from "classnames";

import styles from "./styles";

interface IProps {
    className?: string;
}

const CheckCircle: React.FC<IProps> = ({ className }) => {
    return (
        <div className={cx(className, "icon")}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.2412 2.66126C8.65896 2.66126 7.11224 3.13045 5.79665 4.0095C4.48106 4.88855 3.45568 6.13798 2.85018 7.59979C2.24468 9.0616 2.08625 10.6701 2.39493 12.222C2.70361 13.7738 3.46554 15.1993 4.58436 16.3181C5.70318 17.4369 7.12864 18.1989 8.68049 18.5075C10.2323 18.8162 11.8409 18.6578 13.3027 18.0523C14.7645 17.4468 16.0139 16.4214 16.893 15.1058C17.772 13.7902 18.2412 12.2435 18.2412 10.6613C18.2388 8.54027 17.3951 6.50686 15.8954 5.00709C14.3956 3.50733 12.3622 2.66369 10.2412 2.66126V2.66126ZM13.8652 9.08526L9.86521 13.0853C9.75269 13.1978 9.60009 13.261 9.44097 13.261C9.28185 13.261 9.12925 13.1978 9.01673 13.0853L6.61673 10.6853C6.50908 10.5718 6.45 10.4207 6.45208 10.2643C6.45416 10.1079 6.51724 9.95851 6.62788 9.84793C6.73852 9.73735 6.88797 9.67436 7.04438 9.67236C7.20079 9.67037 7.35179 9.72954 7.46521 9.83726L9.44121 11.8133L13.0172 8.23726C13.131 8.13127 13.2814 8.07357 13.4368 8.07632C13.5923 8.07906 13.7406 8.14203 13.8505 8.25196C13.9604 8.36189 14.0234 8.5102 14.0262 8.66564C14.0289 8.82108 13.9712 8.97152 13.8652 9.08526V9.08526Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

export default styled(CheckCircle)`
    ${styles}

    color: #00BC3C;

    width: 24px;
    height: 24px;
`;
